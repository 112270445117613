import LineChart from './LineChart';
import LineChartPipes from './LineChartPipes';
import '../../styles/Сharts.css'
import moment from "moment";
import React, {useMemo} from "react";
import {area, curveCatmullRom} from "d3-shape";
import {Defs, useAnimatedPath} from "@nivo/core";
import {animated} from "@react-spring/web";
import {disabledRedZone} from "../../utils/constants";
import {useTooltip} from "@nivo/tooltip";
import {Mesh} from "@nivo/voronoi";
import {useTranslation} from "react-i18next";

const _lineTheme = {
    disabledLine: '#b4b4b459',
    background: "#ffffff",
    textColor: "#8E8EA9",
    fontSize: 14,
    grid: {
        line: {
            stroke: "#F6F6F9",
            strokeWidth: 1
        }
    },
    crosshair: {
        line: {
            stroke: '#00205B',
            strokeWidth: 3,
            strokeOpacity: 1,
            strokeDasharray: '1 0',
        },
    }
}

const ActiveLine = ({ activeLinePosition = {} }) => {
    const { x = 0, y = 0 } = activeLinePosition;
    return (
        <line
            x1={x} y1={0} x2={x} y2={y}
            style={_lineTheme.crosshair.line}
            className={`show-line`}
        />
    )
};

const getPositionForTitle = (d, i) => {
    if (!d[i] || !d[i].data.length) return 0;
    const { length } = d[i].data || [];
    const data = [...d[i].data];
    const iLastData = length - 1 - data.reverse().findIndex(v => v.y);

    let difference;
    const array = [];
    for(let i = 0; i < d.length; i++){
        array[i] = d[i].data[iLastData >= length - 1 ? length - 1 : iLastData]?.y || 0;
    }

    for(let i = 0; i < array.length - 1; i++){
        difference = Math.abs(array[i]-array[i+1]);
        if(difference < 2) {
            if (array[i] >= array[i + 1]) {
                array[i + 1] -= 2 - difference;
            } else {
                array[i] -= 2 - difference;
            }
        }
    }

    return array[i]
}

const _getPredictionTextForSVG = (xScale, t, series) => (
    <g transform={`translate(${xScale( moment(series[0]?.data[series[0]?.data.length - 1]?.data.x).diff(moment().startOf('day'), 'days') > 1 ? moment().add(2, 'days').hour(0) : moment().add(0, 'days').hour(20))}, -14)`} >
        <g transform={`translate(${moment().locale() === 'en' ? 82 : 90}, -11)`}>
            <svg width="30" height="12" viewBox="0 0 30 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M29.2129 6.53033C29.5058 6.23744 29.5058 5.76256 29.2129 5.46967L24.44 0.696699C24.1471 0.403806 23.6722 0.403806 23.3793 0.696699C23.0864 0.989593 23.0864 1.46447 23.3793 1.75736L27.622 6L23.3793 10.2426C23.0864 10.5355 23.0864 11.0104 23.3793 11.3033C23.6722 11.5962 24.1471 11.5962 24.44 11.3033L29.2129 6.53033ZM0.682617 6.75H28.6826V5.25H0.682617V6.75Z" fill="#1CE783"/>
            </svg>
        </g>
        <text fill={'#00205B'}>{t('lineChart.Prediction')}</text>
    </g>
)

const PredictionZone = ({ series, xScale, yScale, isPrediction = false, t, innerHeight }) => useMemo(() => {
    if (!series[0]) return null;
    const areaGenerator = area()
        .x(d => xScale(d.data.x) < xScale(moment().hour(0)) ? xScale(moment().hour(0)) : xScale(d.data.x) || 0)
        .y0(() => 0)
        .y1(() => innerHeight)
    return (
        <g className={`${!isPrediction ? 'opacity-0' : ''} def-opacity-0`}>
            <defs>
                <linearGradient id="prediction_gradient">
                    <stop offset="0%"/>
                    <stop offset="100%"/>
                </linearGradient>
            </defs>
            <path d={areaGenerator(series[0]?.data)}
                  id={'prediction_fill'}
                  fill="cornflowerblue"
                  opacity={.1} />
            {_getPredictionTextForSVG(xScale, t, series)}
        </g>
    )
}, [series, xScale, yScale, t, innerHeight]);


const LineItem = ({ line, ...props }) => {
    const { id, data, color, strokeDasharray } = line;
    const { lineGenerator, xScale, yScale} = props;
    const path = useMemo(() => lineGenerator(data.map((d) => ({
        x: xScale(d.data.x ? new Date(d.data.x) : null),
        y: d.data.y ? yScale((d.data.y).toString()) : null
    }))), [lineGenerator, data, xScale, yScale])
    const animatedPath = useAnimatedPath(path)
    return useMemo(() => (
        <animated.path
            key={id}
            d={animatedPath}
            fill="none"
            stroke={color}
            style={{strokeDasharray: strokeDasharray || 0, strokeWidth: 1}}
        />
    ), [id, animatedPath, color, strokeDasharray])
}

const _MemoAreaLayerRedContent = ({ d, index, yMinValue, innerWidth }) => useMemo(() => (
    <React.Fragment key={`redArea${index}`}>
        <Defs
            defs={[{
                id: `patternRed${index}`,
                type: "patternLines",
                background: "#D02B20",
                color: "#D02B20",
                pointerEvents:'none',
            }]}
        />
        <clipPath id={`cut-off-bottom${index}`}>
            <rect x="0" y="0" width={innerWidth} height={yMinValue < 0 ? 0 : yMinValue} />
        </clipPath>
        <path
            clipPath={`url(#cut-off-bottom${index})`}
            d={d}
            fill={`url(#patternRed${index})`}
            fillOpacity={0.1}
        />
    </React.Fragment>
), [d, index, yMinValue, innerWidth])

const _setDefaultActiveData = ({ series, innerHeight }) => {
    const lastIndexWithData = Math.abs(
        series[0]?.data.length - 1 - [...(series[0]?.data || [])]
        .reverse()
        .findIndex(d => d.data?.x)
    );
    return {
        slice: {
            height: innerHeight,
            points: series.map(s => ({
                    serieId: s.id,
                    id: `${s.id}.${lastIndexWithData}`,
                    data: s.data[lastIndexWithData]?.data || [],
                }
            )),
            x: series[0].data[lastIndexWithData].position.x,
            y: 0,
        }
    }
};

const MemoAreaLayerRed = ({
        series, xScale, yScale, rule, index, setXScale,
        setYScale, activeLineIsNull = true, setActiveLine,
        innerHeight, propertyId, innerWidth
    }) => useMemo(() => {
        if (!series[index] || !rule) return null;
        if (activeLineIsNull) {
            setActiveLine(_setDefaultActiveData({ series, innerHeight }));
        }
        setXScale(xScale)
        setYScale(yScale)
        if (disabledRedZone[propertyId] && disabledRedZone[propertyId].includes(parseInt(series[index]?.id))) {
             return null;
        }
        const minValue = rule.max || 100
        const yMinValue = yScale(minValue) > innerHeight ? innerHeight : yScale(minValue);
        const areaGenerator = area()
            .x(d => xScale(d.data.x))
            .y0(d => yScale(d.data.y || minValue))
            .y1(d => d.data.y >= minValue
                ? yMinValue + 50 // 50 to remove the rounding of the bottom line
                : yScale(!!d.data.y
                    ? d.data.y
                    : minValue))
            .curve(curveCatmullRom);
        const d = areaGenerator(series[index]?.data)
        return <_MemoAreaLayerRedContent d={d} yMinValue={yMinValue} index={index} innerWidth={innerWidth} />
    }, [series, xScale, yScale, rule, index, setXScale, setYScale, activeLineIsNull, setActiveLine, innerHeight, innerWidth]);

const EmptyTooltip = (text) => (
    <div className={`flex-column line-chart-tooltip-value`}>
        <span>{text}</span>
    </div>
)

const EmptyZoneMesh = (layerData) => {
    const { t } = useTranslation();
    const { showTooltipAt, hideTooltip } = useTooltip();

    const handleMouseEnter = (point) => {
        showTooltipAt(
            // layerData.tooltip({ point }),
            EmptyTooltip(t('lineChart.NoData')),
            [point.x + layerData.margin.left, point.y + layerData.margin.top],
            'top'
        );
    };
    const handleMouseMove = (point) => {
        showTooltipAt(
            EmptyTooltip(t('lineChart.NoData')),
            [point.x + layerData.margin.left, point.y + layerData.margin.top],
            'top'
        );
    };
    const nullValuePoints = layerData.series.reduce((acc, cur) => {
        cur.data.forEach(({ data, position }) => {
            if (data.y === null) {
                const point = {
                    x: position.x,
                    y: 100, //whatever you want
                    data: {
                        x: data.x,
                    },
                };
                acc.push(point);
            }
        });
        return acc;
    }, []);
    return (
        <Mesh
            nodes={[...layerData.points, ...nullValuePoints]}
            width={layerData.innerWidth}
            height={layerData.innerHeight}
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseMove}
            onMouseLeave={hideTooltip}
            debug={layerData.debugMesh}
        />
    );
};


const getTimeFormatTitle = (tick, rangeType, dateType) => {
    if (rangeType === 1 && moment(tick).hour() === 0) {
        switch (dateType) {
            case 'day': return moment(tick).format( 'MMM DD')
            case 'week': return moment(tick).date() === 1 ? moment(tick).format( 'MMM DD') : ''
            default: return '';
        }
    } else if (rangeType === 2 && moment(tick).date() === 1) {
        switch (dateType) {
            case 'month': return moment(tick).format( 'MMM')
            case 'year':
            case 'max': return moment(tick).month() === 0 ? moment(tick).format( 'YYYY') : ''
            default: return '';
        }
    } else return '';
}

const getTimeFormat = (tick, rangeType, dateType) => {
    switch (dateType) {
        case 'day': return moment(tick).format( 'HH:mm')
        case 'week': return  moment(tick).hour() === 0 ? moment(tick).format( 'dd') : ''
        case 'month': return <tspan style={{fontSize: 10}}>{moment(tick).format( 'DD')}</tspan>
        case 'year':
        case 'max': return  moment(tick).format( 'MMM')
        default: return '';
    }
}

const getTickValues = ({ dateType }) => {
    switch (dateType) {
        case 'month': return `every ${window.innerWidth < 1300 ? 2 : 1} days`;
        case 'year':
        case 'max': return `every 1 month`;
        default: return undefined
    }
}

export {
    LineChart,
    LineChartPipes,

    _lineTheme,
    PredictionZone,
    getPositionForTitle,
    getTimeFormatTitle,
    getTimeFormat,
    LineItem,
    MemoAreaLayerRed,
    ActiveLine,
    getTickValues,
    EmptyZoneMesh,
}