import React, {useEffect, useState} from 'react';
import styles from '../../styles/components/MapPanel/MapPanelCards.module.css'
import arrowSVG from '../../icons/Icon.png'
import Filters from "../Filters";
import {Accordion, Card, Col, Container, Row, useAccordionButton} from "react-bootstrap";
import { MapLayoutGrid} from "../index";
import {useSelector} from "react-redux";
import {getDataProperties, getFilters} from "../../redux/selectors";
import {useTranslation} from "react-i18next";

const CustomToggle = ({ children, eventKey }) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => setIsOpen(!isOpen));
  const [isOpen, setIsOpen] = useState(true);
  return <button type="button" className={[(isOpen ? styles.isOpen : '') + " w-100 h-100"]} onClick={decoratedOnClick}>
    <img src={arrowSVG} alt="v"/>
  </button>;
};

const MapPanelCards = props => {
  const name = "MapPanelCards";
  const { nameForm, imgSupport } = props;
  const properties = useSelector(getDataProperties);
  const [hasWarning, setHasWarning] = useState(false);
  const [cityList, setCityList] = useState();
  const [zipList, setZipList] = useState();
  const filters = useSelector(getFilters);
  const filter = filters[nameForm];
  const { t } = useTranslation();


  useEffect(() => {
    const filterProp = name => filter[name] ? properties.filter(p => p[name] === filter[name]) : properties;
    if (properties) {
      setHasWarning(properties.find(p => p.sensors.find(s => !s.status)) !== null);
      setCityList(
        filterProp('zip').reduce((acc, p) =>
          acc.includes(a => a.city === p.city)
            ? [...acc]
            : [...acc, {city: p.city, count: 1}],
          []));
      setZipList(
        filterProp('city').reduce((acc, p) =>
          acc.includes(a => a.zip === p.zip)
            ? [...acc]
            : [...acc, {zip: p.zip, count: 1}],
          []));
    }
  }, [properties, filter]);

  const reduceFunc = (obj, name) => obj.reduce((acc, c) => ([...acc, { value: c[name], label: c[name], count: c.count}]), []);

  const filterList = [
    { text: t(`${name}.warnings`), value: 'display_warnings', id: 0, showIf: hasWarning && properties.length >= 7},
    {
      defaultValue: t(`${name}.city`),
      value: 'city',
      options: cityList ? reduceFunc(cityList, 'city') : [],
      id: 1,
      type: 'selector',
      show: false
    },
    {
      defaultValue: t(`${name}.zip`),
      value: 'zip',
      options: zipList ? reduceFunc(zipList, 'zip') : [],
      id: 2,
      type: 'selector',
      show: false
    },
  ];
  return (
    <Accordion defaultActiveKey="0" className={styles.container}>
      <Card className={styles.card}>
        <Card.Header className={styles.card_header}>
          <CustomToggle eventKey="0" />
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body className={styles.card_body}>
            <Container fluid="md" className={styles.container_md}>
              <Row className="mb-20">
                <Col>
                  <Filters filterList={filterList} nameForm={nameForm} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <MapLayoutGrid nameForm={nameForm} imgSupport={imgSupport}/>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )
};

export default MapPanelCards;
