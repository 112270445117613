const defaultTooltips = ({styles, name, renderElement, renderElementPrototype, selectorGroups, renderArrows, activeBuildingGroup}) => {
    return [
        {
            width: "20px",
            height: "40px",
            left: "31%",
            top: "39.3%",
            id: 'm1',
            className: `${styles.elements} ${activeBuildingGroup?.devices.length > 15 ? styles.showArrows : styles.hiddenArrows}`,
            render: renderArrows,
        },
        {
            width: "1.7%",
            height: "2.7%",
            left: "33.5%",
            top: "13.5%",
            id: 'm',
            className: `${styles.elements}`,
            render: selectorGroups,
        },
        {
            width: "1.7%",
            height: "2.7%",
            left: "68.4%",
            top: "60.2%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "2.7%",
            left: "74.2%",
            top: "63.1%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "2.7%",
            left: "80%",
            top: "66.1%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "2.7%",
            left: "47.7%",
            top: "63.2%",
            id: 'm103',
            tooltip_text: [`${name}.storage_charging_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4.8%",
            height: "21.5%",
            left: "42.8%",
            top: "53%",
            id: 'm100',
            tooltip_text: [`${name}.storage_water_heater`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "7.7%",
            left: "51.3%",
            top: "64.7%",
            id: 'm100',
            tooltip_text: [`${name}.heat_exchanger`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "7.7%",
            left: "89.7%",
            top: "84.3%",
            id: 'm100',
            tooltip_text: [`${name}.heat_exchanger`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "3%",
            left: "40.5%",
            top: "54%",
            id: 'm104',
            tooltip_text: [`${name}.circulation_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2.2%",
            height: "7.9%",
            left: "37.4%",
            top: "75.4%",
            id: 'm105',
            tooltip_text: [`${name}.backwash_filters`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4.5%",
            height: "3.9%",
            left: "7.5%",
            top: "60.5%",
            id: 'm108',
            tooltip_text: [`${name}.domestic_water_meters`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4.5%",
            height: "3.9%",
            left: "30.6%",
            top: "72.5%",
            id: 'm108',
            tooltip_text: [`${name}.domestic_water_meters`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2%",
            height: "9.5%",
            left: "40.1%",
            top: "61.3%",
            id: 'm108',
            tooltip_text: [`${name}.ultratfiltration`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4%",
            height: "4.9%",
            left: "30.6%",
            top: "47.4%",
            id: 'd1',
            type: "valve",
            tooltip_text: [`${name}.balancing_valve`],
            className: `${styles.elements} ${styles.cursor} ${styles.flush_valve_style}`,
            trackers_positions: [
                {top: 10, left: -65},
                {top: 15, left: -65},
            ],
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4%",
            height: "4.9%",
            left: "36.39%",
            top: "50.3%",
            id: 'd2',
            type: "valve",
            tooltip_text: [`${name}.flush_valve_central_heating`],
            className: `${styles.elements} ${styles.cursor} ${styles.flush_valve_style}`,
            trackers_positions: [
                {top: 10, left: -65},
                {top: 15, left: -65},
            ],
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "36.86%",
            top: "57.05%",
            id: 'd3',
            type: "sensor",
            tooltip_text: [`${name}.hot_water_central_heating`],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "31.06%",
            top: "54.07%",
            id: 'd4',
            type: "sensor",
            tooltip_text: [`${name}.hot`],
            trackers_positions: [
                {top: 10, left: -65},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "54.47%",
            top: "66.03%",
            id: 'd5',
            type: "sensor",
            tooltip_text: [`${name}.heater_forward`],
            trackers_positions: [
                {top: 10, left: -65},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "54.46%",
            top: "71.2%",
            id: 'd6',
            type: "sensor",
            tooltip_text: [`${name}.heating_return_flow`],
            trackers_positions: [
                {top: 10, left: -65},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "40.86%",
            top: "77.97%",
            id: 'd7',
            type: "sensor",
            tooltip_text: [`${name}.point_of_entry`],
            trackers_positions: [
                {top: 10, left: -65},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "4.8%",
            height: "4.7%",
            left: "62.5%",
            top: "74.5%",
            id: 'm200',
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            tooltip_text: [`${name}.energy_valve`],
            className: `image-map__map ${styles.elements} ${styles.cursor}`,
            // trackers_positions: [
            //     {top: -30, left: 75},
            //     {top: 15, left: -10},
            //     {top: 80, left: -10},
            // ],
            render: renderElementPrototype,
        },
        {
            width: "4.8%",
            height: "4.7%",
            left: "81%",
            top: "84%",
            id: 'm200',
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            tooltip_text: [`${name}.energy_valve`],
            className: `image-map__map ${styles.elements} ${styles.cursor}`,
            // trackers_positions: [
            //     {top: -30, left: 75},
            //     {top: 15, left: -10},
            //     {top: 80, left: -10},
            // ],
            render: renderElementPrototype,
        },
    ];
}
const defaultTooltipsForBrusseler = ({styles, name, renderElement, renderArrows, activeBuildingGroup}) => {
    return [
        {
            width: "20px",
            height: "40px",
            left: "31%",
            top: "39.3%",
            id: 'm1',
            className: `${styles.elements} ${activeBuildingGroup?.devices.length > 10 ? styles.showArrows : styles.hiddenArrows}`,
            render: renderArrows,
        },
        {
            width: "1.7%",
            height: "2.7%",
            left: "70.7%",
            top: "61.3%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "2.7%",
            left: "73.9%",
            top: "63%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "2.7%",
            left: "77.1%",
            top: "64.7%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "3.1%",
            left: "48.6%",
            top: "68.3%",
            id: 'm103',
            tooltip_text: [`${name}.heating_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4.8%",
            height: "21.8%",
            left: "42.8%",
            top: "53%",
            id: 'm100',
            tooltip_text: [`${name}.storage_water_heater`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "7.7%",
            left: "51.5%",
            top: "64.8%",
            id: 'm100',
            tooltip_text: [`${name}.heat_exchanger`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "7.7%",
            left: "86.2%",
            top: "82.6%",
            id: 'm100',
            tooltip_text: [`${name}.heat_exchanger`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "3%",
            left: "39.9%",
            top: "53.7%",
            id: 'm104',
            tooltip_text: [`${name}.circulation_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4.5%",
            height: "3.9%",
            left: "7.5%",
            top: "60.5%",
            id: 'm108',
            tooltip_text: [`${name}.domestic_water_meters`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4%",
            height: "4.9%",
            left: "36.37%",
            top: "50.36%",
            id: 'd2',
            type: "valve",
            tooltip_text: [`${name}.flush_valve`],
            className: `${styles.elements} ${styles.cursor} ${styles.flush_valve_style}`,
            trackers_positions: [
                {top: 10, left: -65},
                {top: 15, left: -65},
            ],
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "36.87%",
            top: "57.02%",
            id: 'd4',
            type: "sensor",
            tooltip_text: [`${name}.hot`],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        }
    ];
}
const defaultTooltipsForDerfflinger = ({styles, name, renderElement, renderArrows, activeBuildingGroup}) => {
    return [
        {
            width: "20px",
            height: "40px",
            left: "31%",
            top: "39.3%",
            id: 'm1',
            className: `${styles.elements} ${activeBuildingGroup?.devices.length > 10 ? styles.showArrows : styles.hiddenArrows}`,
            render: renderArrows,
        },
        {
            width: "8.08%",
            height: "19.5%",
            left: "90.2%",
            top: "78.4%",
            id: 'm101',
            tooltip_text: [`${name}.boiler`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "2.7%",
            left: "81.2%",
            top: "80.3%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "3.1%",
            left: "48.6%",
            top: "68.3%",
            id: 'm103',
            tooltip_text: [`${name}.heating_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4.8%",
            height: "21.8%",
            left: "42.8%",
            top: "53%",
            id: 'm100',
            tooltip_text: [`${name}.storage_water_heater`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.8%",
            height: "8.2%",
            left: "86.2%",
            top: "82.4%",
            id: 'm100',
            tooltip_text: [`${name}.heat_exchanger`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "3%",
            left: "39.9%",
            top: "53.7%",
            id: 'm104',
            tooltip_text: [`${name}.circulation_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2.2%",
            height: "8%",
            left: "14.3%",
            top: "63.6%",
            id: 'm105',
            tooltip_text: [`${name}.backwash_filters`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4.5%",
            height: "3.9%",
            left: "7.5%",
            top: "60.5%",
            id: 'm108',
            tooltip_text: [`${name}.domestic_water_meters`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4%",
            height: "4.9%",
            left: "36.37%",
            top: "50.36%",
            id: 'd2',
            type: "valve",
            tooltip_text: [`${name}.flush_valve`],
            className: `${styles.elements} ${styles.cursor} ${styles.flush_valve_style}`,
            trackers_positions: [
                {top: 10, left: -65},
                {top: 15, left: -65},
            ],
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "36.87%",
            top: "57.02%",
            id: 'd4',
            type: "sensor",
            tooltip_text: [`${name}.hot`],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        }
    ];
}
const defaultTooltipsForAmBlomkenberg = ({styles, name, renderElement, renderArrows, activeBuildingGroup}) => {
    return [
        {
            width: "20px",
            height: "40px",
            left: "31%",
            top: "39.3%",
            id: 'm1',
            className: `${styles.elements} ${activeBuildingGroup?.devices.length > 10 ? styles.showArrows : styles.hiddenArrows}`,
            render: renderArrows,
        },
        {
            width: "8.08%",
            height: "19.5%",
            left: "90.2%",
            top: "78.4%",
            id: 'm101',
            tooltip_text: [`${name}.boiler`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "2.7%",
            left: "64.4%",
            top: "58%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "2.7%",
            left: "67.5%",
            top: "59.6%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "3.1%",
            left: "48.6%",
            top: "68.3%",
            id: 'm103',
            tooltip_text: [`${name}.heating_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4.8%",
            height: "21.8%",
            left: "42.8%",
            top: "53%",
            id: 'm100',
            tooltip_text: [`${name}.storage_water_heater`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4.8%",
            height: "22%",
            left: "78.8%",
            top: "50.9%",
            id: 'm100',
            tooltip_text: [`${name}.storage_heater`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "7.7%",
            left: "51.5%",
            top: "64.8%",
            id: 'm100',
            tooltip_text: [`${name}.heat_exchanger`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "3%",
            left: "39.9%",
            top: "53.7%",
            id: 'm104',
            tooltip_text: [`${name}.circulation_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2.2%",
            height: "8%",
            left: "14.3%",
            top: "63.6%",
            id: 'm105',
            tooltip_text: [`${name}.backwash_filters`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4.5%",
            height: "3.9%",
            left: "7.5%",
            top: "60.5%",
            id: 'm108',
            tooltip_text: [`${name}.domestic_water_meters`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4%",
            height: "4.9%",
            left: "36.37%",
            top: "50.35%",
            id: 'd2',
            type: "valve",
            tooltip_text: [`${name}.flush_valve`],
            className: `${styles.elements} ${styles.cursor} ${styles.flush_valve_style}`,
            trackers_positions: [
                {top: 10, left: -65},
                {top: 15, left: -65},
            ],
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "36.87%",
            top: "57.02%",
            id: 'd4',
            type: "sensor",
            tooltip_text: [`${name}.hot`],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "2.7%",
            left: "54.3%",
            top: "66.6%",
            id: 'm102',
            tooltip_text: [`${name}.fresh_water_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "2.7%",
            left: "86.8%",
            top: "62.7%",
            id: 'm102',
            tooltip_text: [`${name}.bhkw_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "8.2%",
            height: "18.7%",
            left: "89.8%",
            top: "58%",
            id: 'm102',
            tooltip_text: [`${name}.bhkw`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
    ];
}
const defaultTooltipsForPloetzenseer_Charlottenburger = ({styles, name, renderElement, renderArrows, activeBuildingGroup, circulationTabs}) => {
    return [
        {
            width: "20px",
            height: "40px",
            left: "32.2%",
            top: "40%",
            id: 'm1',
            className: `${styles.elements} ${activeBuildingGroup?.devices.length > 10 ? styles.showArrows : styles.hiddenArrows}`,
            render: renderArrows,
        },
        {
            width: "1.7%",
            height: "2.7%",
            left: "44.9%",
            top: "5.6%",
            id: 'm',
            className: `${styles.elements}`,
            render: circulationTabs,
        },
        {
            width: "1.7%",
            height: "2.7%",
            left: "67%",
            top: "59.5%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "2.7%",
            left: "71.6%",
            top: "61.8%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "2.7%",
            left: "76.2%",
            top: "64.1%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "2.7%",
            left: "48.6%",
            top: "63.7%",
            id: 'm103',
            tooltip_text: [`${name}.heating_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4.8%",
            height: "21.8%",
            left: "42.8%",
            top: "53%",
            id: 'm100',
            tooltip_text: [`${name}.storage_water_heater`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "7.7%",
            left: "51.5%",
            top: "64.8%",
            id: 'm100',
            tooltip_text: [`${name}.heat_exchanger`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "3%",
            left: "40.5%",
            top: "54%",
            id: 'm104',
            tooltip_text: [`${name}.circulation_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2.2%",
            height: "8%",
            left: "14.3%",
            top: "63.6%",
            id: 'm105',
            tooltip_text: [`${name}.backwash_filters`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4.5%",
            height: "3.9%",
            left: "7.5%",
            top: "60.5%",
            id: 'm108',
            tooltip_text: [`${name}.domestic_water_meters`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4%",
            height: "4.9%",
            left: "36.38%",
            top: "50.33%",
            id: 'd2',
            type: "valve",
            tooltip_text: [`${name}.flush_valve`],
            className: `${styles.elements} ${styles.cursor} ${styles.flush_valve_style}`,
            trackers_positions: [
                {top: 10, left: -65},
                {top: 15, left: -65},
            ],
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "36.41%",
            top: "57.1%",
            id: 'd4',
            type: "sensor",
            tooltip_text: [`${name}.hot_water`],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        }
    ];
}
const defaultTooltipsForOberhutte = ({styles, name, renderElement, renderArrows, activeBuildingGroup, renderElementPrototype}) => {
    return [
        {
            width: "20px",
            height: "40px",
            left: "32.2%",
            top: "40%",
            id: 'm1',
            className: `${styles.elements} ${activeBuildingGroup?.devices.length > 10 ? styles.showArrows : styles.hiddenArrows}`,
            render: renderArrows,
        },
        {
            width: "1.7%",
            height: "2.7%",
            left: "64.1%",
            top: "58%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "2.7%",
            left: "67.3%",
            top: "59.6%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "2.7%",
            left: "70.5%",
            top: "61.2%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4.8%",
            height: "21.8%",
            left: "42.8%",
            top: "53%",
            id: 'm100',
            tooltip_text: [`${name}.storage_water_heater`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4.8%",
            height: "21.8%",
            left: "49.4%",
            top: "42.7%",
            id: 'm100',
            tooltip_text: [`${name}.storage_water_heater`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.9%",
            height: "8.2%",
            left: "57.3%",
            top: "67.6%",
            id: 'm100',
            tooltip_text: [`${name}.heat_exchanger`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "3%",
            left: "36.7%",
            top: "52.1%",
            id: 'm104',
            tooltip_text: [`${name}.circulation_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "3%",
            left: "54.4%",
            top: "71.3%",
            id: 'm104',
            tooltip_text: [`${name}.storage_charging_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4.5%",
            height: "3.9%",
            left: "7.5%",
            top: "60.5%",
            id: 'm108',
            tooltip_text: [`${name}.domestic_water_meters`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "7.7%",
            left: "81.9%",
            top: "80.4%",
            id: 'm110',
            tooltip_text: [`${name}.hydraulic_switch`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4.8%",
            height: "4.7%",
            left: "76.4%",
            top: "81.3%",
            id: 'm200',
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            tooltip_text: [`${name}.energy_valve`],
            className: `image-map__map ${styles.elements} ${styles.cursor}`,
            // trackers_positions: [
            //     {top: -30, left: 75},
            //     {top: 15, left: -10},
            //     {top: 80, left: -10},
            // ],
            render: renderElementPrototype,
        },
        {
            width: "4%",
            height: "4.9%",
            left: "33.19%",
            top: "48.7%",
            id: 'd1',
            type: "valve",
            tooltip_text: [`${name}.flush_valve`],
            className: `${styles.elements} ${styles.cursor} ${styles.flush_valve_style}`,
            trackers_positions: [
                {top: 10, left: -65},
                {top: 15, left: -65},
            ],
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "35.4%",
            top: "56.3%",
            id: 'd2',
            type: "sensor",
            tooltip_text: [`${name}.hot`],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "40.32%",
            top: "58.82%",
            id: 'd3',
            type: "sensor",
            tooltip_text: [`${name}.dynamic_hot_water_storage`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "46.99%",
            top: "48.6%",
            id: 'd4',
            type: "sensor",
            tooltip_text: [`${name}.dynamic_hot_water_storage`, {n: 2}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "55.06%",
            top: "52.72%",
            id: 'd5',
            type: "sensor",
            tooltip_text: [`${name}.dynamic_storage_charging_flow`, {n: 2}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "48.38%",
            top: "62.94%",
            id: 'd6',
            type: "sensor",
            tooltip_text: [`${name}.dynamic_storage_charging_flow`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "55.07%",
            top: "57.8%",
            id: 'd7',
            type: "sensor",
            tooltip_text: [`${name}.dynamic_storage_charging_return`, {n: 2}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "48.4%",
            top: "68.07%",
            id: 'd8',
            type: "sensor",
            tooltip_text: [`${name}.dynamic_storage_charging_return`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "20.07%",
            top: "67.23%",
            id: 'd9',
            type: "sensor",
            tooltip_text: [`${name}.cold_water_inlet`],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2.7%",
            height: "8.2%",
            left: "14.1%",
            top: "63.7%",
            id: 'd10',
            tooltip_text: [`${name}.dynamic_backwash_filter`, {n: 2}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.backwash_filter_style}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2.7%",
            height: "8.2%",
            left: "10.7%",
            top: "68.8%",
            id: 'd11',
            tooltip_text: [`${name}.dynamic_backwash_filter`, {n: 1}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.backwash_filter_style}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "59.97%",
            top: "68.88%",
            id: 'd12',
            type: "sensor",
            tooltip_text: [`${name}.hot_water_supply_flow`],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "63.92%",
            top: "49.53%",
            id: 'd13',
            type: "sensor",
            tooltip_text: [`${name}.dynamic_heating_circuit_flow`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "67.12%",
            top: "51.15%",
            id: 'd14',
            type: "sensor",
            tooltip_text: [`${name}.dynamic_heating_circuit_flow`, {n: 2}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "70.28%",
            top: "52.82%",
            id: 'd15',
            type: "sensor",
            tooltip_text: [`${name}.dynamic_heating_circuit_flow`, {n: 3}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "74.09%",
            top: "76.15%",
            id: 'd16',
            type: "sensor",
            tooltip_text: [`${name}.heating_circuit_distributor_flow`],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "84.5%",
            top: "81.48%",
            id: 'd17',
            type: "sensor",
            tooltip_text: [`${name}.hydraulic_switch_flow`],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "82%",
            top: "65.1%",
            id: 'd18',
            type: "sensor",
            tooltip_text: [`${name}.dynamic_boiler_feed`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "89.25%",
            top: "68.8%",
            id: 'd19',
            type: "sensor",
            tooltip_text: [`${name}.dynamic_boiler_feed`, {n: 2}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "96.6%",
            top: "72.2%",
            id: 'd20',
            type: "sensor",
            tooltip_text: [`${name}.dynamic_boiler_feed`, {n: 3}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "59.97%",
            top: "74%",
            id: 'd21',
            type: "sensor",
            tooltip_text: [`${name}.hot_water_supply_return`],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "65.08%",
            top: "50.2%",
            id: 'd22',
            type: "sensor",
            tooltip_text: [`${name}.dynamic_heating_circuit_return`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "68.26%",
            top: "51.8%",
            id: 'd23',
            type: "sensor",
            tooltip_text: [`${name}.dynamic_heating_circuit_return`, {n: 2}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "71.44%",
            top: "53.4%",
            id: 'd24',
            type: "sensor",
            tooltip_text: [`${name}.dynamic_heating_circuit_return`, {n: 3}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "74.09%",
            top: "81.28%",
            id: 'd25',
            type: "sensor",
            tooltip_text: [`${name}.heating_circuit_distributor_return`],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "84.5%",
            top: "86.6%",
            id: 'd26',
            type: "sensor",
            tooltip_text: [`${name}.hydraulic_switch_return`],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "82%",
            top: "70.22%",
            id: 'd27',
            type: "sensor",
            tooltip_text: [`${name}.dynamic_boiler_return_flow`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "89.25%",
            top: "73.95%",
            id: 'd28',
            type: "sensor",
            tooltip_text: [`${name}.dynamic_boiler_return_flow`, {n: 2}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "96.6%",
            top: "77.35%",
            id: 'd29',
            type: "sensor",
            tooltip_text: [`${name}.dynamic_boiler_return_flow`, {n: 3}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 35, left: -55},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "5.3%",
            height: "19.7%",
            left: "76.9%",
            top: "56.7%",
            id: 'm120',
            tooltip_text: [`${name}.boiler`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "5.3%",
            height: "19.7%",
            left: "84.1%",
            top: "60.2%",
            id: 'm120',
            tooltip_text: [`${name}.boiler`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "5.3%",
            height: "19.7%",
            left: "91.3%",
            top: "64%",
            id: 'm120',
            tooltip_text: [`${name}.boiler`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
    ];
}
const defaultTooltipsForNiehlerGurtel = ({styles, name, renderElement, renderArrows, activeBuildingGroup}) => {
    return [
        {
            width: "20px",
            height: "40px",
            left: "4.1%",
            top: "38.4%",
            id: 'm1',
            className: `${styles.elements} ${activeBuildingGroup?.devices.length > 10 ? styles.showArrows : styles.hiddenArrows}`,
            render: renderArrows,
        },
        {
            width: "7%",
            height: "16.5%",
            left: "81.7%",
            top: "79.5%",
            id: 'm101',
            tooltip_text: [`${name}.boiler`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "7%",
            height: "16.5%",
            left: "90.2%",
            top: "66.5%",
            id: 'm101',
            tooltip_text: [`${name}.boiler`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "2.5%",
            left: "54.9%",
            top: "54.5%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "2.5%",
            left: "59.7%",
            top: "57%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "2.5%",
            left: "64.6%",
            top: "59.5%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "2.5%",
            left: "69.4%",
            top: "62%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "2.5%",
            left: "88%",
            top: "72.6%",
            id: 'm102',
            tooltip_text: [`${name}.boiler_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "2.5%",
            left: "79.5%",
            top: "85.7%",
            id: 'm102',
            tooltip_text: [`${name}.boiler_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "2.5%",
            left: "70.8%",
            top: "77%",
            id: 'm104',
            tooltip_text: [`${name}.feed_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "2.5%",
            left: "41.9%",
            top: "62%",
            id: 'm103',
            tooltip_text: [`${name}.storage_charging_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "2.5%",
            left: "50.4%",
            top: "66.4%",
            id: 'm103',
            tooltip_text: [`${name}.storage_charging_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4%",
            height: "18.5%",
            left: "37.2%",
            top: "53.3%",
            id: 'm100',
            tooltip_text: [`${name}.storage_water_heater`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.6%",
            height: "7%",
            left: "45.3%",
            top: "63.5%",
            id: 'm100',
            tooltip_text: [`${name}.heat_exchanger`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.6%",
            height: "7%",
            left: "82.9%",
            top: "65.5%",
            id: 'm100',
            tooltip_text: [`${name}.heat_exchanger`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.6%",
            height: "7%",
            left: "74.4%",
            top: "78.5%",
            id: 'm100',
            tooltip_text: [`${name}.heat_exchanger`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "2.5%",
            left: "29.3%",
            top: "55.5%",
            id: 'm104',
            tooltip_text: [`${name}.circulation_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.8%",
            height: "6.7%",
            left: "13.9%",
            top: "51%",
            id: 'm105',
            tooltip_text: [`${name}.backwash_filters`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4%",
            height: "3.5%",
            left: "8%",
            top: "48.5%",
            id: 'm108',
            tooltip_text: [`${name}.domestic_water_meters`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2%",
            height: "4.2%",
            left: "35%",
            top: " 48.68%",
            id: 'd3',
            type: "sensor",
            tooltip_text: [`${name}.hot_water`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            render: renderElement,
        }
    ];
}
const config_1 = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltips(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "27.55%",
            top: "37.33%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.2%",
            height: "7.9%",
            left: "14.3%",
            top: "63.5%",
            id: 'm105',
            tooltip_text: [`${name}.backwash_filters`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
    ]
}
const config_1_nofilter = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltips(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "27.55%",
            top: "37.33%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        }
    ]
}
const config_2 = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltips(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "24.37%",
            top: "35.7%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "27.55%",
            top: "37.33%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.2%",
            height: "7.9%",
            left: "14.3%",
            top: "63.5%",
            id: 'm105',
            tooltip_text: [`${name}.backwash_filters`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
    ]
}
const config_2_nofilter = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltips(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "24.37%",
            top: "35.7%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "27.55%",
            top: "37.33%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
    ]
}
const config_3 = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltips(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "21.18%",
            top: "34.08%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "24.37%",
            top: "35.7%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "27.55%",
            top: "37.33%",
            id: 3,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 3}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.2%",
            height: "7.9%",
            left: "14.3%",
            top: "63.5%",
            id: 'm105',
            tooltip_text: [`${name}.backwash_filters`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
    ]
}
const config_3_nofilter = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltips(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "21.18%",
            top: "34.08%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "24.37%",
            top: "35.7%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "27.55%",
            top: "37.33%",
            id: 3,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 3}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
    ]
}
const config_4 = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltips(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "18.01%",
            top: "32.45%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "21.18%",
            top: "34.08%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "24.37%",
            top: "35.7%",
            id: 3,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 3}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "27.55%",
            top: "37.33%",
            id: 4,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 4}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.2%",
            height: "7.9%",
            left: "14.3%",
            top: "63.5%",
            id: 'm105',
            tooltip_text: [`${name}.backwash_filters`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
    ]
}
const config_4_nofilter = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltips(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "18.01%",
            top: "32.45%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "21.18%",
            top: "34.08%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "24.37%",
            top: "35.7%",
            id: 3,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 3}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "27.55%",
            top: "37.33%",
            id: 4,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 4}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        }
    ]
}
const config_5 = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltips(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "14.84%",
            top: "30.83%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "18.01%",
            top: "32.45%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "21.18%",
            top: "34.08%",
            id: 3,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 3}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "24.37%",
            top: "35.7%",
            id: 4,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 4}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "27.55%",
            top: "37.33%",
            id: 5,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 5}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.2%",
            height: "7.9%",
            left: "14.3%",
            top: "63.5%",
            id: 'm105',
            tooltip_text: [`${name}.backwash_filters`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        }
    ]
}
const config_5_nofilter = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltips(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "14.84%",
            top: "30.83%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "18.01%",
            top: "32.45%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "21.18%",
            top: "34.08%",
            id: 3,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 3}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "24.37%",
            top: "35.7%",
            id: 4,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 4}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "27.55%",
            top: "37.33%",
            id: 5,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 5}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        }
    ]
}
const config_6 = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltips(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "11.66%",
            top: "29.2%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "14.84%",
            top: "30.83%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "18.01%",
            top: "32.45%",
            id: 3,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 3}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "21.18%",
            top: "34.08%",
            id: 4,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 4}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "24.37%",
            top: "35.7%",
            id: 5,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 5}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "27.55%",
            top: "37.33%",
            id: 6,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 6}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.2%",
            height: "7.9%",
            left: "14.3%",
            top: "63.5%",
            id: 'm105',
            tooltip_text: [`${name}.backwash_filters`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
    ]
}
const config_6_nofilter = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltips(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "11.66%",
            top: "29.2%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "14.84%",
            top: "30.83%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "18.01%",
            top: "32.45%",
            id: 3,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 3}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "21.18%",
            top: "34.08%",
            id: 4,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 4}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "24.37%",
            top: "35.7%",
            id: 5,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 5}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "27.55%",
            top: "37.33%",
            id: 6,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 6}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        }
    ]
}
const config_7 = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltips(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "8.48%",
            top: "27.55%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "11.66%",
            top: "29.2%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "14.84%",
            top: "30.83%",
            id: 3,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 3}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "18.01%",
            top: "32.45%",
            id: 4,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 4}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "21.18%",
            top: "34.08%",
            id: 5,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 5}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "24.37%",
            top: "35.7%",
            id: 6,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 6}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "27.55%",
            top: "37.33%",
            id: 7,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 7}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.2%",
            height: "7.9%",
            left: "14.3%",
            top: "63.5%",
            id: 'm105',
            tooltip_text: [`${name}.backwash_filters`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
    ]
}
const config_7_nofilter = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltips(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "8.48%",
            top: "27.55%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "11.66%",
            top: "29.2%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "14.84%",
            top: "30.83%",
            id: 3,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 3}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "18.01%",
            top: "32.45%",
            id: 4,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 4}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "21.18%",
            top: "34.08%",
            id: 5,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 5}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "24.37%",
            top: "35.7%",
            id: 6,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 6}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "27.55%",
            top: "37.33%",
            id: 7,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 7}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        }
    ]
}
const config_8 = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltips(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "5.31%",
            top: "25.91%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "8.48%",
            top: "27.55%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "11.66%",
            top: "29.2%",
            id: 3,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 3}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "14.84%",
            top: "30.83%",
            id: 4,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 4}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "18.01%",
            top: "32.45%",
            id: 5,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 5}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "21.18%",
            top: "34.08%",
            id: 6,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 6}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "24.37%",
            top: "35.7%",
            id: 7,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 7}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "27.55%",
            top: "37.33%",
            id: 8,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 8}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.2%",
            height: "7.9%",
            left: "14.3%",
            top: "63.5%",
            id: 'm105',
            tooltip_text: [`${name}.backwash_filters`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
    ]
}
const config_8_nofilter = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltips(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "5.31%",
            top: "25.91%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "8.48%",
            top: "27.55%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "11.66%",
            top: "29.2%",
            id: 3,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 3}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "14.84%",
            top: "30.83%",
            id: 4,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 4}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "18.01%",
            top: "32.45%",
            id: 5,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 5}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "21.18%",
            top: "34.08%",
            id: 6,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 6}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "24.37%",
            top: "35.7%",
            id: 7,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 7}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "27.55%",
            top: "37.33%",
            id: 8,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 8}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        }
    ]
}
const config_12 = props => {
    const { styles, renderElement, name } = props;
    return [
        {
            width: "8.08%",
            height: "19%",
            left: "67.6%",
            top: "66.34%",
            id: 'm101',
            tooltip_text: [`${name}.boiler`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.78%",
            height: "2.93%",
            left: "66.25%",
            top: "55.3%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.92%",
            height: "3.5%",
            left: "60.77%",
            top: "64%",
            id: 'm103',
            tooltip_text: [`${name}.storage_charging_pump`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "5.34%",
            height: "20%",
            left: "52%",
            top: "58.5%",
            id: 'm100',
            tooltip_text: [`${name}.storage_water_heater`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.92%",
            height: "3.3%",
            left: "48.5%",
            top: "58%",
            id: 'm104',
            tooltip_text: [`${name}.circulation_pump`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2.6%",
            height: "8.5%",
            left: "37.76%",
            top: "58.78%",
            id: 'm105',
            tooltip_text: [`${name}.backwash_filters`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4.5%",
            height: "4%",
            left: "30%",
            top: "55%",
            id: 'm108',
            tooltip_text: [`${name}.domestic_water_meters`],
            className: `${styles.elements}`,
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "37.62%",
            top: "50.02%",
            id: 1,
            type: "sensor",
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            tooltip_text: [`${name}.pipe`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "40.54%",
            top: "51.66%",
            id: 2,
            type: "sensor",
            tooltip_text: [`${name}.pipe`, {n: 2}],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "43.49%",
            top: "53.26%",
            id: 3,
            type: "sensor",
            tooltip_text: [`${name}.pipe`, {n: 3}],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "50.66%",
            top: "55.73%",
            id: 4,
            type: "sensor",
            tooltip_text: [`${name}.hot_water`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: -25, left: 35},
                {top: 0, left: 35},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "50.42%",
            top: "58.73%",
            id: 5,
            type: "sensor",
            tooltip_text: [`${name}.circulation_flow`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "59.25%",
            top: "63.04%",
            id: 6,
            type: "sensor",
            tooltip_text: [`${name}.hot_water_storage_tank`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "66.31%",
            top: "52.04%",
            id: 7,
            type: "sensor",
            tooltip_text: [`${name}.heating_circuit_flow`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "67.82%",
            top: "52.2%",
            id: 8,
            type: "sensor",
            tooltip_text: [`${name}.heating_circuit_return`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: 35},
                {top: 45, left: 35},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "65.85%",
            top: "66.68%",
            id: 9,
            type: "sensor",
            tooltip_text: [`${name}.boiler_feed`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "63.91%",
            top: "74.07%",
            id: 10,
            type: "sensor",
            tooltip_text: [`${name}.boiler_return_flow`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: 35},
                {top: 45, left: 35},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "58.53%",
            top: "71.14%",
            id: 11,
            type: "sensor",
            tooltip_text: [`${name}.hot_tank_flow`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "35.23%",
            top: "58.2%",
            id: 12,
            type: "sensor",
            tooltip_text: [`${name}.cold_water_inlet`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
    ]
}
const config_12_prototype = props => {
    const { styles, renderElement, name, renderElementPrototype } = props;
    return [
        {
            width: "8.08%",
            height: "19%",
            left: "67.6%",
            top: "66.34%",
            id: 'm101',
            tooltip_text: [`${name}.boiler`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.78%",
            height: "2.93%",
            left: "66.25%",
            top: "55.3%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.92%",
            height: "3.5%",
            left: "60.77%",
            top: "64%",
            id: 'm103',
            tooltip_text: [`${name}.storage_charging_pump`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "5.34%",
            height: "20%",
            left: "52%",
            top: "58.5%",
            id: 'm100',
            tooltip_text: [`${name}.storage_water_heater`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.92%",
            height: "3.3%",
            left: "48.5%",
            top: "58%",
            id: 'm104',
            tooltip_text: [`${name}.circulation_pump`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2.6%",
            height: "8.5%",
            left: "37.76%",
            top: "58.78%",
            id: 'm105',
            tooltip_text: [`${name}.backwash_filters`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4.5%",
            height: "4%",
            left: "30%",
            top: "55%",
            id: 'm108',
            tooltip_text: [`${name}.domestic_water_meters`],
            className: `${styles.elements}`,
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "37.62%",
            top: "50.02%",
            id: 1,
            type: "sensor",
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            tooltip_text: [`${name}.pipe`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "40.54%",
            top: "51.66%",
            id: 2,
            type: "sensor",
            tooltip_text: [`${name}.pipe`, {n: 2}],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "43.49%",
            top: "53.26%",
            id: 3,
            type: "sensor",
            tooltip_text: [`${name}.pipe`, {n: 3}],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "50.66%",
            top: "55.73%",
            id: 4,
            type: "sensor",
            tooltip_text: [`${name}.hot_water`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: -25, left: 35},
                {top: 0, left: 35},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "50.42%",
            top: "58.73%",
            id: 5,
            type: "sensor",
            tooltip_text: [`${name}.circulation_flow`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "59.25%",
            top: "63.04%",
            id: 6,
            type: "sensor",
            tooltip_text: [`${name}.hot_water_storage_tank`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "66.31%",
            top: "52.04%",
            id: 7,
            type: "sensor",
            tooltip_text: [`${name}.heating_circuit_flow`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "67.82%",
            top: "52.2%",
            id: 8,
            type: "sensor",
            tooltip_text: [`${name}.heating_circuit_return`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: 35},
                {top: 45, left: 35},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "58.53%",
            top: "71.14%",
            id: 9,
            type: "sensor",
            tooltip_text: [`${name}.hot_tank_flow`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "35.23%",
            top: "58.2%",
            id: 10,
            type: "sensor",
            tooltip_text: [`${name}.cold_water_inlet`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "4%",
            height: "4%",
            left: "62.3%",
            top: "73%",
            id: 11,
            type: "sensor",
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            tooltip_text: [`${name}.energy_valve`],
            className: `image-map__map ${styles.elements} ${styles.cursor}`,
            trackers_positions: [
                {top: -30, left: 75},
                {top: 15, left: -10},
                {top: 80, left: -10},
            ],
            render: renderElementPrototype,
        },

    ];
}
const config_18 = props => {
    const { styles, renderElement, name } = props;
    return [
        {
            width: "5.34%",
            height: "20%",
            left: "52%",
            top: "58.5%",
            id: 'm100',
            tooltip_text: [`${name}.storage_water_heater`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "8.08%",
            height: "19%",
            left: "67.6%",
            top: "66.34%",
            id: 'm101',
            tooltip_text: [`${name}.boiler`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.78%",
            height: "2.93%",
            left: "66.25%",
            top: "55.3%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.92%",
            height: "3.5%",
            left: "60.77%",
            top: "64%",
            id: 'm103',
            tooltip_text: [`${name}.storage_charging_pump`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.92%",
            height: "3.3%",
            left: "48.5%",
            top: "58%",
            id: 'm104',
            tooltip_text: [`${name}.circulation_pump`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2.6%",
            height: "8.5%",
            left: "37.76%",
            top: "58.78%",
            id: 'm105',
            tooltip_text: [`${name}.backwash_filters`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2.19%",
            height: "6.83%",
            left: "34.74%",
            top: "67%",
            id: 'm106',
            tooltip_text: [`${name}.backwash_filters`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4.5%",
            height: "4.5%",
            left: "26.5%",
            top: "62%",
            id: 'm107',
            tooltip_text: [`${name}.domestic_water_meters`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4.5%",
            height: "4%",
            left: "30%",
            top: "55%",
            id: 'm108',
            tooltip_text: [`${name}.domestic_water_meters`],
            className: `${styles.elements}`,
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "28.78%",
            top: "45.15%",
            id: 1,
            type: "sensor",
            tooltip_text: [`${name}.pipe`, {n: 1}],
            show: true,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "31.73%",
            top: "46.73%",
            id: 2,
            type: "sensor",
            tooltip_text: [`${name}.pipe`, {n: 2}],
            show: true,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "34.64%",
            top: "48.37%",
            id: 3,
            type: "sensor",
            tooltip_text: [`${name}.pipe`, {n: 3}],
            show: true,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "37.62%",
            top: "50.01%",
            id: 4,
            type: "sensor",
            tooltip_text: [`${name}.pipe`, {n: 4}],
            show: true,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "40.54%",
            top: "51.62%",
            id: 5,
            type: "sensor",
            tooltip_text: [`${name}.pipe`, {n: 5}],
            show: true,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "43.49%",
            top: "53.25%",
            id: 6,
            type: "sensor",
            tooltip_text: [`${name}.pipe`, {n: 6}],
            show: true,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "46.4%",
            top: "54.87%",
            id: 7,
            type: "sensor",
            tooltip_text: [`${name}.pipe`, {n: 7}],
            show: true,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "50.66%",
            top: "55.71%",
            id: 8,
            type: "sensor",
            tooltip_text: [`${name}.hot_water`],
            show: true,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: -25, left: 35},
                {top: 0, left: 35},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "50.42%",
            top: "58.72%",
            id: 9,
            type: "sensor",
            tooltip_text: [`${name}.circulation_flow`],
            show: true,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "59.26%",
            top: "63.05%",
            id: 10,
            type: "sensor",
            tooltip_text: [`${name}.hot_water_storage_tank`],
            show: true,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "66.3%",
            top: "52.05%",
            id: 11,
            type: "sensor",
            tooltip_text: [`${name}.heating_circuit_flow`],
            show: true,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "67.82%",
            top: "52.17%",
            id: 12,
            type: "sensor",
            tooltip_text: [`${name}.heating_circuit_return`],
            show: true,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: 45},
                {top: 45, left: 45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "65.86%",
            top: "66.71%",
            id: 13,
            type: "sensor",
            tooltip_text: [`${name}.boiler_feed`],
            show: true,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "63.91%",
            top: "74.07%",
            id: 14,
            type: "sensor",
            tooltip_text: [`${name}.boiler_return_flow`],
            show: true,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: 35},
                {top: 45, left: 35},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "58.53%",
            top: "71.05%",
            id: 15,
            type: "sensor",
            tooltip_text: [`${name}.hot_tank_flow`],
            show: true,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: 35},
                {top: 45, left: 35},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "35.23%",
            top: "58.2%",
            id: 17,
            type: "sensor",
            tooltip_text: [`${name}.cold_water_inlet`],
            show: false,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "31.63%",
            top: "65.35%",
            id: 18,
            type: "sensor",
            tooltip_text: [`${name}.cold_water_inlet`],
            show: false,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            className: `image-map__map ${styles.sensor_style} ${styles.large_sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
    ]
}
const config_8_NiehlerGurtel = props => {
    const { styles, renderElement, name, activeGroup } = props;
    return [
        ...defaultTooltipsForNiehlerGurtel(props),
        {
            width: "3.5%",
            height: "4.1%",
            left: "25.61%",
            top: "52.31%",
            id: 'd1',
            type: "valve",
            tooltip_text: [`${name}.flush_valve`],
            className: `${styles.elements} ${styles.cursor} ${styles.flush_valve_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            style: { zIndex: activeGroup === 1 ? 3 : 2, opacity: activeGroup === 1 ? 1 : 0 },
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "3.5%",
            height: "4.1%",
            left: "25.61%",
            top: "52.31%",
            id: 'd2',
            type: "valve",
            tooltip_text: [`${name}.flush_valve`],
            className: `${styles.elements} ${styles.cursor} ${styles.flush_valve_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            style: { zIndex: activeGroup === 1 ? 2 : 3, opacity: activeGroup === 1 ? 0 : 1 },
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        
        {
            width: "1.7%",
            height: "5.6%",
            left: "6.3%",
            top: "38.15%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "5.6%",
            left: "8.75%",
            top: "39.43%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "5.6%",
            left: "11.2%",
            top: "40.65%",
            id: 3,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 3}],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "5.6%",
            left: "13.61%",
            top: "41.91%",
            id: 4,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 4}],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "5.6%",
            left: "16.03%",
            top: "43.15%",
            id: 5,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 5}],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "5.6%",
            left: "18.45%",
            top: "44.39%",
            id: 6,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 6}],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "5.6%",
            left: "20.89%",
            top: "45.63%",
            id: 7,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 7}],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            render: renderElement,
        },
        {
            width: "1.7%",
            height: "5.6%",
            left: "23.31%",
            top: "46.87%",
            id: 8,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 8}],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            render: renderElement,
        }
    ];
}
const config_Brusseler_2 = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltipsForBrusseler(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "24.35%",
            top: "35.7%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "27.52%",
            top: "37.32%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            // style: { zIndex: activeGroup === 1 ? 3 : 2 },
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        }
    ]
}
const config_Brusseler_8 = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltipsForBrusseler(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "5.305%",
            top: "25.92%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "8.48%",
            top: "27.54%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "11.65%",
            top: "29.16%",
            id: 3,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 3}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "14.82%",
            top: "30.81%",
            id: 4,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 4}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "18.005%",
            top: "32.44%",
            id: 5,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 5}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "21.16%",
            top: "34.06%",
            id: 6,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 6}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "24.35%",
            top: "35.7%",
            id: 7,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 7}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "27.52%",
            top: "37.32%",
            id: 8,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 8}],
            // style: { zIndex: activeGroup === 1 ? 3 : 2 },
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        }
    ]
}
const config_Derfflinger_5 = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltipsForDerfflinger(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "14.83%",
            top: "30.81%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: '3 (34a)'}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "18.01%",
            top: "32.43%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: '4 (34a)'}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "21.18%",
            top: "34.08%",
            id: 3,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: '5 (34a)'}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "24.36%",
            top: "35.7%",
            id: 4,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: '6 (34a)'}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "27.53%",
            top: "37.32%",
            id: 5,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: '7 (34a)'}],
            // style: { zIndex: activeGroup === 1 ? 3 : 2 },
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        }
    ]
}
const config_Derfflinger_8 = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltipsForDerfflinger(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "5.31%",
            top: "25.92%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: '1 (32)'}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "8.48%",
            top: "27.53%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: '2 (32)'}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "11.67%",
            top: "29.14%",
            id: 3,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: '1 (34)'}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "14.83%",
            top: "30.81%",
            id: 4,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: '2 (34)'}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "18.01%",
            top: "32.43%",
            id: 5,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: '3 (34)'}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "21.18%",
            top: "34.08%",
            id: 6,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: '4 (34)'}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "24.36%",
            top: "35.7%",
            id: 7,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: '1 (34a)'}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "27.53%",
            top: "37.32%",
            id: 8,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: '2 (34a)'}],
            // style: { zIndex: activeGroup === 1 ? 3 : 2 },
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        }
    ]
}
const config_AmBlomkenberg_7 = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltipsForAmBlomkenberg(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "8.485%",
            top: "27.53%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "11.65%",
            top: "29.2%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "14.85%",
            top: "30.81%",
            id: 3,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 3}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "18.003%",
            top: "32.43%",
            id: 4,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 4}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "21.18%",
            top: "34.08%",
            id: 5,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 5}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "24.37%",
            top: "35.73%",
            id: 6,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 6}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "27.53%",
            top: "37.32%",
            id: 7,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 7}],
            // style: { zIndex: activeGroup === 1 ? 3 : 2 },
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        }
    ]
}
const config_AmBlomkenberg_8 = props => {
    const { styles, renderElement, name, activeGroup } = props;
    return [
        ...defaultTooltipsForAmBlomkenberg(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "5.305%",
            top: "25.91%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            style: { zIndex: activeGroup === 1 ? 3 : 2 },
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "8.485%",
            top: "27.53%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            style: { zIndex: activeGroup === 1 ? 3 : 2 },
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "11.65%",
            top: "29.2%",
            id: 3,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 3}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            style: { zIndex: activeGroup === 1 ? 3 : 2 },
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "14.85%",
            top: "30.81%",
            id: 4,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 4}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            style: { zIndex: activeGroup === 1 ? 3 : 2 },
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "18.003%",
            top: "32.43%",
            id: 5,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 5}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            style: { zIndex: activeGroup === 1 ? 3 : 2 },
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "21.18%",
            top: "34.08%",
            id: 6,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 6}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            style: { zIndex: activeGroup === 1 ? 3 : 2 },
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "24.37%",
            top: "35.73%",
            id: 7,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 7}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            style: { zIndex: activeGroup === 1 ? 3 : 2 },
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "27.53%",
            top: "37.32%",
            id: 8,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 8}],
            // style: { zIndex: activeGroup === 1 ? 3 : 2 },
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            style: { zIndex: activeGroup === 1 ? 3 : 2 },
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        }
    ]
}
const config_3Heizkreise_6 = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltipsForPloetzenseer_Charlottenburger(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "12.82%",
            top: "29.77%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "15.98%",
            top: "31.39%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "19.16%",
            top: "33.04%",
            id: 3,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 3}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "22.33%",
            top: "34.65%",
            id: 4,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 4}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "25.51%",
            top: "36.28%",
            id: 5,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 5}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "28.69%",
            top: "37.91%",
            id: 6,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 6}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        }
    ]
}
const config_3Heizkreise_8 = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltipsForPloetzenseer_Charlottenburger(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "6.45%",
            top: "26.54%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "9.63%",
            top: "28.15%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "12.82%",
            top: "29.77%",
            id: 3,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 3}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "15.98%",
            top: "31.39%",
            id: 4,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 4}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "19.16%",
            top: "33.04%",
            id: 5,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 5}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "22.33%",
            top: "34.65%",
            id: 6,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 6}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "25.51%",
            top: "36.28%",
            id: 7,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 7}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "28.69%",
            top: "37.91%",
            id: 8,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 8}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        }
    ]
}
const config_4Heizkreise_7 = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltipsForPloetzenseer_Charlottenburger(props),
        {
            width: "1.7%",
            height: "2.7%",
            left: "80.8%",
            top: "66.4%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "9.63%",
            top: "28.15%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "12.82%",
            top: "29.77%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "15.98%",
            top: "31.39%",
            id: 3,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 3}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "19.16%",
            top: "33.04%",
            id: 4,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 4}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "22.33%",
            top: "34.65%",
            id: 5,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 5}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "25.51%",
            top: "36.28%",
            id: 6,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 6}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "28.69%",
            top: "37.91%",
            id: 7,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 7}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        }
    ]
}
const config_4Heizkreise_8 = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltipsForPloetzenseer_Charlottenburger(props),
        {
            width: "1.7%",
            height: "2.7%",
            left: "80.8%",
            top: "66.4%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "6.45%",
            top: "26.54%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "9.63%",
            top: "28.15%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "12.82%",
            top: "29.77%",
            id: 3,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 3}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "15.98%",
            top: "31.39%",
            id: 4,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 4}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "19.16%",
            top: "33.04%",
            id: 5,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 5}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "22.33%",
            top: "34.65%",
            id: 6,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 6}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "25.51%",
            top: "36.28%",
            id: 7,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 7}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "28.69%",
            top: "37.91%",
            id: 8,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 8}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        }
    ]
}
const config_Oberhutte_7 = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltipsForOberhutte(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "8.46%",
            top: "27.52%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "11.65%",
            top: "29.2%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "14.83%",
            top: "30.81%",
            id: 3,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 3}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "18.01%",
            top: "32.44%",
            id: 4,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 4}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "21.18%",
            top: "34.08%",
            id: 5,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 5}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "24.37%",
            top: "35.7%",
            id: 6,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 6}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "27.53%",
            top: "37.32%",
            id: 7,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 7}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        }
    ]
}
const config_Oberhutte_8 = props => {
    const { styles, renderElement, name } = props;
    return [
        ...defaultTooltipsForOberhutte(props),
        {
            width: "2.1%",
            height: "6.7%",
            left: "5.33%",
            top: "25.92%",
            id: 1,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "8.46%",
            top: "27.52%",
            id: 2,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "11.65%",
            top: "29.2%",
            id: 3,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 3}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "14.83%",
            top: "30.81%",
            id: 4,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 4}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "18.01%",
            top: "32.44%",
            id: 5,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 5}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "21.18%",
            top: "34.08%",
            id: 6,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 6}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "24.37%",
            top: "35.7%",
            id: 7,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 7}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        },
        {
            width: "2.1%",
            height: "6.7%",
            left: "27.53%",
            top: "37.32%",
            id: 8,
            type: "valve",
            tooltip_text: [`${name}.smart_valve`, {n: 8}],
            trackers_positions: [
                {top: 85, left: -60},
                {top: 85, left: -60},
            ],
            className: `${styles.elements} ${styles.cursor} ${styles.valve_style}`,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            render: renderElement,
        }
    ]
}

export const PerformanceTypeConfigs = props => {
    const { type, ...p } = props
    switch (type.toString()) {
        case '1_filter': return config_1(p)
        case '1_nofilter': return config_1_nofilter(p)
        case '2_filter': return config_2(p)
        case '2_nofilter': return config_2_nofilter(p)
        case '3_filter': return config_3(p)
        case '3_nofilter': return config_3_nofilter(p)
        case '4_filter': return config_4(p)
        case '4_nofilter': return config_4_nofilter(p)
        case '5_filter': return config_5(p)
        case '5_nofilter': return config_5_nofilter(p)
        case '6_filter': return config_6(p)
        case '6_nofilter': return config_6_nofilter(p)
        case '7_filter': return config_7(p)
        case '7_nofilter': return config_7_nofilter(p)
        case '8_filter': return config_8(p)
        case '8_nofilter': return config_8_nofilter(p)
        case '12': return config_12(p)
        case '12_prototype': return config_12_prototype(p)
        case '18': return config_18(p)
        case '8_NiehlerGurtel': return config_8_NiehlerGurtel(p)
        case '2_Brusseler': return config_Brusseler_2(p)
        case '8_Brusseler': return config_Brusseler_8(p)
        case '5_Derfflinger': return config_Derfflinger_5(p)
        case '8_Derfflinger': return config_Derfflinger_8(p)
        case '7_AmBlomkenberg': return config_AmBlomkenberg_7(p)
        case '8_AmBlomkenberg': return config_AmBlomkenberg_8(p)
        case '6_Heizkeise_3': return config_3Heizkreise_6(p)
        case '8_Heizkeise_3': return config_3Heizkreise_8(p)
        case '7_Heizkeise_4': return config_4Heizkreise_7(p)
        case '8_Heizkeise_4': return config_4Heizkreise_8(p)
        case '7_Oberhutte': return config_Oberhutte_7(p)
        case '8_Oberhutte': return config_Oberhutte_8(p)
        default: return [
            ...defaultTooltips(p)
        ];
    }
}