import sensor_1_webp from "./5/webp/DSC08975.webp";
import sensor_1_v2_webp from "./5/webp/DSC08975@2x.webp";
import sensor_1_v3_webp from "./5/webp/DSC08975@3x.webp";
import sensor_2_webp from "./5/webp/DSC08910.webp";
import sensor_2_v2_webp from "./5/webp/DSC08910@2x.webp";
import sensor_2_v3_webp from "./5/webp/DSC08910@3x.webp";
import sensor_3_webp from "./5/webp/DSC08903.webp";
import sensor_3_v2_webp from "./5/webp/DSC08903@2x.webp";
import sensor_3_v3_webp from "./5/webp/DSC08903@3x.webp";
import sensor_4_webp from "./5/webp/DSC08876.webp";
import sensor_4_v2_webp from "./5/webp/DSC08876@2x.webp";
import sensor_4_v3_webp from "./5/webp/DSC08876@3x.webp";
import sensor_5_webp from "./7/webp/DSC09000.webp";
import sensor_5_v2_webp from "./7/webp/DSC09000@2x.webp";
import sensor_5_v3_webp from "./7/webp/DSC09000@3x.webp";
import sensor_6_webp from "./5/webp/DSC08904.webp";
import sensor_6_v2_webp from "./5/webp/DSC08904@2x.webp";
import sensor_6_v3_webp from "./5/webp/DSC08904@3x.webp";
import sensor_7_webp from "./5/webp/DSC08962.webp";
import sensor_7_v2_webp from "./5/webp/DSC08962@2x.webp";
import sensor_7_v3_webp from "./5/webp/DSC08962@3x.webp";
import sensor_8_webp from "./5/webp/DSC08965.webp";
import sensor_8_v2_webp from "./5/webp/DSC08965@2x.webp";
import sensor_8_v3_webp from "./5/webp/DSC08965@3x.webp";
import sensor_9_webp from "./7/webp/DSC08997.webp";
import sensor_9_v2_webp from "./7/webp/DSC08997@2x.webp";
import sensor_9_v3_webp from "./7/webp/DSC08997@3x.webp";

import sensor_1_png from "./5/png/DSC08975.png";
import sensor_1_v2_png from "./5/png/DSC08975@2x.png";
import sensor_1_v3_png from "./5/png/DSC08975@3x.png";
import sensor_2_png from "./5/png/DSC08910.png";
import sensor_2_v2_png from "./5/png/DSC08910@2x.png";
import sensor_2_v3_png from "./5/png/DSC08910@3x.png";
import sensor_3_png from "./5/png/DSC08903.png";
import sensor_3_v2_png from "./5/png/DSC08903@2x.png";
import sensor_3_v3_png from "./5/png/DSC08903@3x.png";
import sensor_4_png from "./5/png/DSC08876.png";
import sensor_4_v2_png from "./5/png/DSC08876@2x.png";
import sensor_4_v3_png from "./5/png/DSC08876@3x.png";
import sensor_5_png from "./7/png/DSC09000.png";
import sensor_5_v2_png from "./7/png/DSC09000@2x.png";
import sensor_5_v3_png from "./7/png/DSC09000@3x.png";
import sensor_6_png from "./5/png/DSC08904.png";
import sensor_6_v2_png from "./5/png/DSC08904@2x.png";
import sensor_6_v3_png from "./5/png/DSC08904@3x.png";
import sensor_7_png from "./5/png/DSC08962.png";
import sensor_7_v2_png from "./5/png/DSC08962@2x.png";
import sensor_7_v3_png from "./5/png/DSC08962@3x.png";
import sensor_8_png from "./5/png/DSC08965.png";
import sensor_8_v2_png from "./5/png/DSC08965@2x.png";
import sensor_8_v3_png from "./5/png/DSC08965@3x.png";
import sensor_9_png from "./7/png/DSC08997.png";
import sensor_9_v2_png from "./7/png/DSC08997@2x.png";
import sensor_9_v3_png from "./7/png/DSC08997@3x.png";

const s5 = {
    webp: {
        s_1: [sensor_1_webp, sensor_1_v2_webp, sensor_1_v3_webp],
        s_2: [sensor_2_webp, sensor_2_v2_webp, sensor_2_v3_webp],
        s_3: [sensor_3_webp, sensor_3_v2_webp, sensor_3_v3_webp],
        s_4: [sensor_4_webp, sensor_4_v2_webp, sensor_4_v3_webp],
        s_6: [sensor_6_webp, sensor_6_v2_webp, sensor_6_v3_webp],
        s_7: [sensor_7_webp, sensor_7_v2_webp, sensor_7_v3_webp],
        s_8: [sensor_8_webp, sensor_8_v2_webp, sensor_8_v3_webp],
    },
    png: {
        s_1: [sensor_1_png, sensor_1_v2_png, sensor_1_v3_png],
        s_2: [sensor_2_png, sensor_2_v2_png, sensor_2_v3_png],
        s_3: [sensor_3_png, sensor_3_v2_png, sensor_3_v3_png],
        s_4: [sensor_4_png, sensor_4_v2_png, sensor_4_v3_png],
        s_6: [sensor_6_png, sensor_6_v2_png, sensor_6_v3_png],
        s_7: [sensor_7_png, sensor_7_v2_png, sensor_7_v3_png],
        s_8: [sensor_8_png, sensor_8_v2_png, sensor_8_v3_png],
    }
}
const s7 = {
    webp: {
        s_5: [sensor_5_webp, sensor_5_v2_webp, sensor_5_v3_webp],
        s_9: [sensor_9_webp, sensor_9_v2_webp, sensor_9_v3_webp],
    },
    png: {
        s_5: [sensor_5_png, sensor_5_v2_png, sensor_5_v3_png],
        s_9: [sensor_9_png, sensor_9_v2_png, sensor_9_v3_png],
    }
}

export { s5, s7 };