import React, {useEffect, useState} from 'react';
import {Container, Form} from "react-bootstrap";
import styles from '../../styles/components/Profile/Profile.module.css';
import {BackButton} from "../Inputs";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import ProfileInfo from './ProfileInfo';
import BottomInfo from "./BottomInfo";

import { useDispatch, useSelector } from 'react-redux';
import {GetProfileInfo} from "../../redux/actions";
import {getProfileInfo} from "../../redux/selectors";
import {Footer} from "../index";

const config = [
    {
        name: 'email',
        title: 'Profile.email',
        value: '',
        placeholder: 'Profile.email',
        readOnly: true 
    },
    {
        name: 'phone_number',
        title: 'Profile.phoneNumber',
        value: '',
        placeholder: 'Profile.phoneNumber',
        readOnly: false,
        withoutMargin: true
    },
    {
        name: 'password',
        title: 'Profile.password',
        value: '',
        placeholder: 'Profile.password',
        readOnly: true,
        withoutMargin: true
    },
    {
        name: 'title',
        title: 'Profile.title',
        value: '',
        placeholder: 'Profile.title',
        readOnly: false
    },
    {
        name: 'first_name',
        title: 'Profile.firstName',
        value: '',
        placeholder: 'Profile.firstName',
        readOnly: false,
        multiple: true
    },
    {
        name: 'last_name',
        title: 'Profile.lastName',
        value: '',
        placeholder: 'Profile.lastName',
        readOnly: false,
        multiple: true
    },
    {
        name: 'birthdate',
        title: 'Profile.date',
        value: '',
        placeholder: 'Profile.date',
        readOnly: false
    },
    {
        name: 'address',
        title: 'Profile.address',
        value: '',
        placeholder: 'Profile.address',
        readOnly: false
    },
    {
        name: 'postcode',
        title: 'Profile.postcode',
        value: '',
        placeholder: 'Profile.postcode',
        readOnly: false,
        multiple: true
    },
    {
        name: 'city',
        title: 'Profile.city',
        value: '',
        placeholder: 'Profile.city',
        readOnly: false,
        multiple: true
    },
    {
        name: 'department',
        title: 'Profile.department',
        value: '',
        placeholder: 'Profile.department',
        readOnly: false,
        multiple: true,
        withoutMargin: true
    },
    {
        name: 'position',
        title: 'Profile.position',
        value: '',
        placeholder: 'Profile.position',
        readOnly: false,
        multiple: true,
        withoutMargin: true
    },
    {
        name: 'company_name',
        title: 'Profile.companyName',
        value: '',
        placeholder: 'Profile.companyName',
        readOnly: false,
    },
    {
        name: 'company_title',
        title: 'Profile.title',
        value: '',
        placeholder: 'Profile.title',
        readOnly: false
    },
    {
        name: 'company_last_name',
        title: 'Profile.lastName',
        value: '',
        placeholder: 'Profile.lastName',
        readOnly: false,
        multiple: true
    },
    {
        name: 'company_first_name',
        title: 'Profile.firstName',
        value: '',
        placeholder: 'Profile.firstName',
        readOnly: false,
        multiple: true
    },
    {
        name: 'company_birthdate',
        title: 'Profile.date',
        value: '',
        placeholder: 'Profile.date',
        readOnly: false
    },
    {
        name: 'company_address',
        title: 'Profile.address',
        value: '',
        placeholder: 'Profile.address',
        readOnly: false
    },
    {
        name: 'company_postcode',
        title: 'Profile.postcode',
        value: '',
        placeholder: 'Profile.postcode',
        readOnly: false,
        multiple: true
    },
    {
        name: 'company_city',
        title: 'Profile.city',
        value: '',
        placeholder: 'Profile.city',
        readOnly: false,
        multiple: true
    },
    {
        name: 'company_country',
        title: 'Profile.companyCountry',
        value: '',
        placeholder: 'Profile.companyCountry',
        readOnly: false,
        withoutMargin: true
    }
]

const Profile = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [profileData, setProfileData] = useState([]);
    const [personalData, setPersonalData] = useState([]);
    const [companyData, setCompanyData] = useState([]);
    const profileInfo = useSelector(getProfileInfo);
    
    useEffect(() => {
        config.forEach(field => {
            if(profileInfo[field.name] && field.name !== 'password') {
                field.value = profileInfo[field.name]
            }
        })

        setProfileData(config.slice(0, 3));
        setPersonalData(config.slice(3, 12));
        setCompanyData(config.slice(12));

    }, [profileInfo])

    const goBack = () => {
        navigate(-1);
    }

    useEffect(() => {
        dispatch(GetProfileInfo())
    }, [])

    return (
        <div className={styles.wrap}>
            <Container fluid="lg" className={styles.container}>
                <BackButton onClick={goBack} text={t(`ControlImg360.BackButton`)} isButton isDark />
            </Container>
            <Container fluid="md" className={styles.container_md}>
                <h1>{t("Profile.header")}</h1>
                <div className={styles.header_divider}></div>
                <Form>
                    <ProfileInfo profileInfo={profileData} />
                    <BottomInfo personalInfo={personalData} companyInfo={companyData} />
                </Form>
                <Footer />
            </Container>
        </div>
    );
};

export default Profile;