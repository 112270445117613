import img_1_v2_Hauswasserzahler_webp from "./01_Hauswasserzahler/webp/Hauswasserzahler@2x.webp";
import img_1_v3_Hauswasserzahler_webp from "./01_Hauswasserzahler/webp/Hauswasserzahler@3x.webp";

import img_1_v2_Heizkessel_webp from "./02_Heizkessel/webp/Heizkessel@2x.webp";
import img_1_v3_Heizkessel_webp from "./02_Heizkessel/webp/Heizkessel@3x.webp";
import img_2_v2_Heizkessel_webp from "./02_Heizkessel/webp/Heizkessel_1@2x.webp";
import img_2_v3_Heizkessel_webp from "./02_Heizkessel/webp/Heizkessel_1@3x.webp";
import img_3_v2_Heizkessel_webp from "./02_Heizkessel/webp/Heizkessel_2@2x.webp";
import img_3_v3_Heizkessel_webp from "./02_Heizkessel/webp/Heizkessel_2@3x.webp";

import img_1_v2_HydraulischeWeiche_webp from "./03_HydraulischeWeiche/webp/Hydraulische_weiche@2x.webp";
import img_1_v3_HydraulischeWeiche_webp from "./03_HydraulischeWeiche/webp/Hydraulische_weiche@3x.webp";

import img_1_v2_Ruckspulfilter_1_webp from "./04_Ruckspulfilter/webp/Ruckspulfilter1@2x.webp";
import img_1_v3_Ruckspulfilter_1_webp from "./04_Ruckspulfilter/webp/Ruckspulfilter1@3x.webp";
import img_2_v2_Ruckspulfilter_1_webp from "./04_Ruckspulfilter/webp/Ruckspulfilter1_1@2x.webp";
import img_2_v3_Ruckspulfilter_1_webp from "./04_Ruckspulfilter/webp/Ruckspulfilter1_1@3x.webp";
import img_1_v2_Ruckspulfilter_2_webp from "./04_Ruckspulfilter/webp/Ruckspulfilter2@2x.webp";
import img_1_v3_Ruckspulfilter_2_webp from "./04_Ruckspulfilter/webp/Ruckspulfilter2@3x.webp";
import img_2_v2_Ruckspulfilter_2_webp from "./04_Ruckspulfilter/webp/Ruckspulfilter2_1@2x.webp";
import img_2_v3_Ruckspulfilter_2_webp from "./04_Ruckspulfilter/webp/Ruckspulfilter2_1@3x.webp";

import img_1_v2_Speicherladepumpe_webp from "./05_Speicherladepumpe/webp/Speicherladepumpe@2x.webp";
import img_1_v3_Speicherladepumpe_webp from "./05_Speicherladepumpe/webp/Speicherladepumpe@3x.webp";
import img_2_v2_Speicherladepumpe_webp from "./05_Speicherladepumpe/webp/Speicherladepumpe_1@2x.webp";
import img_2_v3_Speicherladepumpe_webp from "./05_Speicherladepumpe/webp/Speicherladepumpe_1@3x.webp";

import img_1_v2_Zirkulationspumpe_webp from "./06_Zirkulationspumpe/webp/Zirkulationspumpe@2x.webp";
import img_1_v3_Zirkulationspumpe_webp from "./06_Zirkulationspumpe/webp/Zirkulationspumpe@3x.webp";

import img_1_v2_HeatingCircuitPump_1_webp from "./07_HeatingCircuitPump/webp/Heating_circuit_pump1@2x.webp";
import img_1_v3_HeatingCircuitPump_1_webp from "./07_HeatingCircuitPump/webp/Heating_circuit_pump1@3x.webp";
import img_2_v2_HeatingCircuitPump_1_webp from "./07_HeatingCircuitPump/webp/Heating_circuit_pump1_1@2x.webp";
import img_2_v3_HeatingCircuitPump_1_webp from "./07_HeatingCircuitPump/webp/Heating_circuit_pump1_1@3x.webp";
import img_1_v2_HeatingCircuitPump_2_webp from "./07_HeatingCircuitPump/webp/Heating_circuit_pump2@2x.webp";
import img_1_v3_HeatingCircuitPump_2_webp from "./07_HeatingCircuitPump/webp/Heating_circuit_pump2@3x.webp";
import img_1_v2_HeatingCircuitPump_3_webp from "./07_HeatingCircuitPump/webp/Heating_circuit_pump3@2x.webp";
import img_1_v3_HeatingCircuitPump_3_webp from "./07_HeatingCircuitPump/webp/Heating_circuit_pump3@3x.webp";

import img_1_v2_Warmetauscher_webp from "./08_Warmetauscher/webp/Warmetauscher@2x.webp";
import img_1_v3_Warmetauscher_webp from "./08_Warmetauscher/webp/Warmetauscher@3x.webp";

import img_1_v2_Warmwasserspeicher_1_webp from "./09_Warmwasserspeicher/webp/Warmwasserspeicher1@2x.webp";
import img_1_v3_Warmwasserspeicher_1_webp from "./09_Warmwasserspeicher/webp/Warmwasserspeicher1@3x.webp";
import img_2_v2_Warmwasserspeicher_1_webp from "./09_Warmwasserspeicher/webp/Warmwasserspeicher1_1@2x.webp";
import img_2_v3_Warmwasserspeicher_1_webp from "./09_Warmwasserspeicher/webp/Warmwasserspeicher1_1@3x.webp";
import img_1_v2_Warmwasserspeicher_2_webp from "./09_Warmwasserspeicher/webp/Warmwasserspeicher2@2x.webp";
import img_1_v3_Warmwasserspeicher_2_webp from "./09_Warmwasserspeicher/webp/Warmwasserspeicher2@3x.webp";

import img_1_v2_Hauswasserzahler_jpg from "./01_Hauswasserzahler/jpg/Hauswasserzahler@2x.jpg";
import img_1_v3_Hauswasserzahler_jpg from "./01_Hauswasserzahler/jpg/Hauswasserzahler@3x.jpg";

import img_1_v2_Heizkessel_jpg from "./02_Heizkessel/jpg/Heizkessel@2x.jpg";
import img_1_v3_Heizkessel_jpg from "./02_Heizkessel/jpg/Heizkessel@3x.jpg";
import img_2_v2_Heizkessel_jpg from "./02_Heizkessel/jpg/Heizkessel_1@2x.jpg";
import img_2_v3_Heizkessel_jpg from "./02_Heizkessel/jpg/Heizkessel_1@3x.jpg";
import img_3_v2_Heizkessel_jpg from "./02_Heizkessel/jpg/Heizkessel_2@2x.jpg";
import img_3_v3_Heizkessel_jpg from "./02_Heizkessel/jpg/Heizkessel_2@3x.jpg";

import img_1_v2_HydraulischeWeiche_jpg from "./03_HydraulischeWeiche/jpg/Hydraulische_weiche@2x.jpg";
import img_1_v3_HydraulischeWeiche_jpg from "./03_HydraulischeWeiche/jpg/Hydraulische_weiche@3x.jpg";

import img_1_v2_Ruckspulfilter_1_jpg from "./04_Ruckspulfilter/jpg/Ruckspulfilter1@2x.jpg";
import img_1_v3_Ruckspulfilter_1_jpg from "./04_Ruckspulfilter/jpg/Ruckspulfilter1@3x.jpg";
import img_2_v2_Ruckspulfilter_1_jpg from "./04_Ruckspulfilter/jpg/Ruckspulfilter1_1@2x.jpg";
import img_2_v3_Ruckspulfilter_1_jpg from "./04_Ruckspulfilter/jpg/Ruckspulfilter1_1@3x.jpg";
import img_1_v2_Ruckspulfilter_2_jpg from "./04_Ruckspulfilter/jpg/Ruckspulfilter2@2x.jpg";
import img_1_v3_Ruckspulfilter_2_jpg from "./04_Ruckspulfilter/jpg/Ruckspulfilter2@3x.jpg";
import img_2_v2_Ruckspulfilter_2_jpg from "./04_Ruckspulfilter/jpg/Ruckspulfilter2_1@2x.jpg";
import img_2_v3_Ruckspulfilter_2_jpg from "./04_Ruckspulfilter/jpg/Ruckspulfilter2_1@3x.jpg";

import img_1_v2_Speicherladepumpe_jpg from "./05_Speicherladepumpe/jpg/Speicherladepumpe@2x.jpg";
import img_1_v3_Speicherladepumpe_jpg from "./05_Speicherladepumpe/jpg/Speicherladepumpe@3x.jpg";
import img_2_v2_Speicherladepumpe_jpg from "./05_Speicherladepumpe/jpg/Speicherladepumpe_1@2x.jpg";
import img_2_v3_Speicherladepumpe_jpg from "./05_Speicherladepumpe/jpg/Speicherladepumpe_1@3x.jpg";

import img_1_v2_Zirkulationspumpe_jpg from "./06_Zirkulationspumpe/jpg/Zirkulationspumpe@2x.jpg";
import img_1_v3_Zirkulationspumpe_jpg from "./06_Zirkulationspumpe/jpg/Zirkulationspumpe@3x.jpg";

import img_1_v2_HeatingCircuitPump_1_jpg from "./07_HeatingCircuitPump/jpg/Heating_circuit_pump1@2x.jpg";
import img_1_v3_HeatingCircuitPump_1_jpg from "./07_HeatingCircuitPump/jpg/Heating_circuit_pump1@3x.jpg";
import img_2_v2_HeatingCircuitPump_1_jpg from "./07_HeatingCircuitPump/jpg/Heating_circuit_pump1_1@2x.jpg";
import img_2_v3_HeatingCircuitPump_1_jpg from "./07_HeatingCircuitPump/jpg/Heating_circuit_pump1_1@3x.jpg";
import img_1_v2_HeatingCircuitPump_2_jpg from "./07_HeatingCircuitPump/jpg/Heating_circuit_pump2@2x.jpg";
import img_1_v3_HeatingCircuitPump_2_jpg from "./07_HeatingCircuitPump/jpg/Heating_circuit_pump2@3x.jpg";
import img_1_v2_HeatingCircuitPump_3_jpg from "./07_HeatingCircuitPump/jpg/Heating_circuit_pump3@2x.jpg";
import img_1_v3_HeatingCircuitPump_3_jpg from "./07_HeatingCircuitPump/jpg/Heating_circuit_pump3@3x.jpg";

import img_1_v2_Warmetauscher_jpg from "./08_Warmetauscher/jpg/Warmetauscher@2x.jpg";
import img_1_v3_Warmetauscher_jpg from "./08_Warmetauscher/jpg/Warmetauscher@3x.jpg";

import img_1_v2_Warmwasserspeicher_1_jpg from "./09_Warmwasserspeicher/jpg/Warmwasserspeicher1@2x.jpg";
import img_1_v3_Warmwasserspeicher_1_jpg from "./09_Warmwasserspeicher/jpg/Warmwasserspeicher1@3x.jpg";
import img_2_v2_Warmwasserspeicher_1_jpg from "./09_Warmwasserspeicher/jpg/Warmwasserspeicher1_1@2x.jpg";
import img_2_v3_Warmwasserspeicher_1_jpg from "./09_Warmwasserspeicher/jpg/Warmwasserspeicher1_1@3x.jpg";
import img_1_v2_Warmwasserspeicher_2_jpg from "./09_Warmwasserspeicher/jpg/Warmwasserspeicher2@2x.jpg";
import img_1_v3_Warmwasserspeicher_2_jpg from "./09_Warmwasserspeicher/jpg/Warmwasserspeicher2@3x.jpg";

const images_Oberhutte_Albau_37 = {
    'Hauswasserzahler': {
        webp: {
            img_1: [img_1_v2_Hauswasserzahler_webp, img_1_v3_Hauswasserzahler_webp],
        },
        jpg: {
            img_1: [img_1_v2_Hauswasserzahler_jpg, img_1_v3_Hauswasserzahler_jpg],
        }
    },
    'Heizkessel': {
        webp: {
            img_1: [img_1_v2_Heizkessel_webp, img_1_v3_Heizkessel_webp],
            img_2: [img_2_v2_Heizkessel_webp, img_2_v3_Heizkessel_webp],
            img_3: [img_3_v2_Heizkessel_webp, img_3_v3_Heizkessel_webp],
        },
        jpg: {
            img_1: [img_1_v2_Heizkessel_jpg, img_1_v3_Heizkessel_jpg],
            img_2: [img_2_v2_Heizkessel_jpg, img_2_v3_Heizkessel_jpg],
            img_3: [img_3_v2_Heizkessel_jpg, img_3_v3_Heizkessel_jpg],
        }
    },
    'HydraulischeWeiche': {
        webp: {
            img_1: [img_1_v2_HydraulischeWeiche_webp, img_1_v3_HydraulischeWeiche_webp]
        },
        jpg: {
            img_1: [img_1_v2_HydraulischeWeiche_jpg, img_1_v3_HydraulischeWeiche_jpg]
        }
    },
    'Ruckspulfilter': {
        'Ruckspulfilter_1': {
            webp: {
                img_1: [img_1_v2_Ruckspulfilter_1_webp, img_1_v3_Ruckspulfilter_1_webp],
                img_2: [img_2_v2_Ruckspulfilter_1_webp, img_2_v3_Ruckspulfilter_1_webp]
            },
            jpg: {
                img_1: [img_1_v2_Ruckspulfilter_1_jpg, img_1_v3_Ruckspulfilter_1_jpg],
                img_2: [img_2_v2_Ruckspulfilter_1_jpg, img_2_v3_Ruckspulfilter_1_jpg],
            }
        },
        'Ruckspulfilter_2': {
            webp: {
                img_1: [img_1_v2_Ruckspulfilter_2_webp, img_1_v3_Ruckspulfilter_2_webp],
                img_2: [img_2_v2_Ruckspulfilter_2_webp, img_2_v3_Ruckspulfilter_2_webp]
            },
            jpg: {
                img_1: [img_1_v2_Ruckspulfilter_2_jpg, img_1_v3_Ruckspulfilter_2_jpg],
                img_2: [img_2_v2_Ruckspulfilter_2_jpg, img_2_v3_Ruckspulfilter_2_jpg],
            }
        },
        
    },
    'Speicherladepumpe': {
        webp: {
            img_1: [img_1_v2_Speicherladepumpe_webp, img_1_v3_Speicherladepumpe_webp],
            img_2: [img_2_v2_Speicherladepumpe_webp, img_2_v3_Speicherladepumpe_webp]
        },
        jpg: {
            img_1: [img_1_v2_Speicherladepumpe_jpg, img_1_v3_Speicherladepumpe_jpg],
            img_2: [img_2_v2_Speicherladepumpe_jpg, img_2_v3_Speicherladepumpe_jpg]
        }
    },
    'Zirkulationpumpe': {
        webp: {
            img_1: [img_1_v2_Zirkulationspumpe_webp, img_1_v3_Zirkulationspumpe_webp]
        },
        jpg: {
            img_1: [img_1_v2_Zirkulationspumpe_jpg, img_1_v3_Zirkulationspumpe_jpg]
        }
    },
    'HeatingCircuitPump': {
        'HeatingCircuitPump_1': {
            webp: {
                img_1: [img_1_v2_HeatingCircuitPump_1_webp, img_1_v3_HeatingCircuitPump_1_webp],
                img_2: [img_2_v2_HeatingCircuitPump_1_webp, img_2_v3_HeatingCircuitPump_1_webp]
            },
            jpg: {
                img_1: [img_1_v2_HeatingCircuitPump_1_jpg, img_1_v3_HeatingCircuitPump_1_jpg],
                img_2: [img_2_v2_HeatingCircuitPump_1_jpg, img_2_v3_HeatingCircuitPump_1_jpg]
            }
        },
        'HeatingCircuitPump_2': {
            webp: {
                img_1: [img_1_v2_HeatingCircuitPump_2_webp, img_1_v3_HeatingCircuitPump_2_webp]
            },
            jpg: {
                img_1: [img_1_v2_HeatingCircuitPump_2_jpg, img_1_v3_HeatingCircuitPump_2_jpg]
            }
        },
        'HeatingCircuitPump_3': {
            webp: {
                img_1: [img_1_v2_HeatingCircuitPump_3_webp, img_1_v3_HeatingCircuitPump_3_webp]
            },
            jpg: {
                img_1: [img_1_v2_HeatingCircuitPump_3_jpg, img_1_v3_HeatingCircuitPump_3_jpg]
            }
        },
        
    },
    'Warmetauscher': {
        webp: {
            img_1: [img_1_v2_Warmetauscher_webp, img_1_v3_Warmetauscher_webp],
        },
        jpg: {
            img_1: [img_1_v2_Warmetauscher_jpg, img_1_v3_Warmetauscher_jpg],
        }
    },
    'Warmwasserspeicher': {
        'Warmwasserspeicher_1': {
            webp: {
                img_1: [img_1_v2_Warmwasserspeicher_1_webp, img_1_v3_Warmwasserspeicher_1_webp],
                img_2: [img_2_v2_Warmwasserspeicher_1_webp, img_2_v3_Warmwasserspeicher_1_webp]
            },
            jpg: {
                img_1: [img_1_v2_Warmwasserspeicher_1_jpg, img_1_v3_Warmwasserspeicher_1_jpg],
                img_2: [img_2_v2_Warmwasserspeicher_1_jpg, img_2_v3_Warmwasserspeicher_1_jpg],
            }
        },
        'Warmwasserspeicher_2': {
            webp: {
                img_1: [img_1_v2_Warmwasserspeicher_2_webp, img_1_v3_Warmwasserspeicher_2_webp]
            },
            jpg: {
                img_1: [img_1_v2_Warmwasserspeicher_2_jpg, img_1_v3_Warmwasserspeicher_2_jpg]
            }
        },
        
    },
}

export { images_Oberhutte_Albau_37 };