import React, {useCallback, useEffect, useState} from 'react';
import {SensorLayoutGrid, SensorList} from "../index";
import {useSelector} from "react-redux";
import {getDataProperty, getFilters, getActiveBuilding} from "../../redux/selectors";
import { filterRule } from '../../utils/constants';
import {isEmpty} from "../../utils/functions";
import styles from "../../styles/components/Sencors/Sensors.module.css";
import {Tab} from "bootstrap";
import {Tabs} from "react-bootstrap";
import { useParams } from 'react-router';

const combineCard = (c, sensors) => {
    if ( c.modbus === 101) {
        const card22 = sensors.find(c => c.modbus === 22);
        return {...c, sensors: [...c.sensors, ...(card22 ? card22.sensors : [])]}
    } return c;
}

const SensorTabs = props => {
  const { nameForm, typeDisplay } = props;
  const { id: _id } = useParams();
  const id = _id.split('&')[0];
  const [cards, setCards] = useState([]);
  const [currentCards, setCurrentCards] = useState([]);
  const filters = useSelector(getFilters);
  const activeBuilding = useSelector(getActiveBuilding);
  const filter = filters[nameForm];
  const filterConfig = filterRule[id];
  const data = useSelector(getDataProperty);
  const { sensors = [] } = data;

  const getFilterModbusList = name => filterConfig.find(f => f.name === name).modbus;
  const getHasSensor = (name, sensor) => getFilterModbusList(name).includes(sensor.modbus); 

  const filterCards = useCallback(() => {
    const { display_warnings, chilled_water, hot_water, heater, zirculation } = filter || {};

    return currentCards.map(s => combineCard(s, currentCards)).filter(sensor => {
      if (sensor.modbus === 22) return false;
      if (display_warnings) {

        return !!(!sensor.status && (
            isEmpty(filter)
            || (!chilled_water && !hot_water && !heater && !zirculation)
            || (chilled_water && getHasSensor('chilled_water', sensor))
            || (hot_water && getHasSensor('hot_water', sensor))
            || (heater && getHasSensor('heater', sensor))
            || (zirculation && getHasSensor('zirculation', sensor))
        ));
      } else return !!(isEmpty(filter)
        || (display_warnings && !sensor.status)
        || (chilled_water && getHasSensor('chilled_water', sensor))
        || (hot_water && getHasSensor('hot_water', sensor))
        || (heater && getHasSensor('heater', sensor))
        || (zirculation && getHasSensor('zirculation', sensor)));
    })
  }, [filter, currentCards]);

  useEffect(() => {
    setCards(filterCards());
  }, [currentCards, filter, filterCards]);

  useEffect(() => {
    if(activeBuilding) {
      setCurrentCards([])
      const activeCards = activeBuilding.devices.map(device => {
        const findSensor = sensors.find(card => device.modbus === card.modbus);
        const findCard = findSensor ? findSensor.sensors.find(sensor =>  sensor.device_uid === device.device_uid) : null;
        return findCard
              ? {sensors: [findCard], modbus: findCard?.modbus, status: findCard?.status?.status, params: {name: device.name, house: device.house}}
              : null;
      })
      setCurrentCards(activeCards.filter(card => card !== null))
    } else {
      setCurrentCards(sensors)
    }
  }, [activeBuilding, sensors])

  return (
    <Tabs
        transition={false}
        id="controlled-tab-example"
        activeKey={typeDisplay}
        className={[styles.tabs+ ' mb-1']}
    >
        <Tab eventKey="layout-grid">
            <SensorLayoutGrid cards={cards} filter={filter} />
        </Tab>
        <Tab eventKey="list">
            <SensorList cards={cards} filter={filter} />
        </Tab>
    </Tabs>
  )
};

export default SensorTabs;
