import * as types from '../types/properties';

export const getProperties = (payload, cb) => ({ type: types.GET_PROPERTIES, payload, cb });
export const getProperty = (payload, cb) => ({ type: types.GET_PROPERTY, payload, cb });

export const setProperties = (payload) => ({ type: types.SET_PROPERTIES, payload });
export const setProperty = (payload) => ({ type: types.SET_PROPERTY, payload });


export const setActiveProperty = (payload) => ({ type: types.SET_ACTIVE_PROPERTY, payload });

export const setSensor = (payload) => ({ type: types.SET_SENSOR, payload });
export const setActiveSensor = (payload) => ({ type: types.SET_ACTIVE_SENSOR, payload });
export const addActiveSensor = (payload) => ({ type: types.ADD_ACTIVE_SENSOR, payload });
export const removeActiveSensor = () => ({ type: types.REMOVE_ACTIVE_SENSOR });

export const choiceUpdateFunc = (payload, func) => (func(payload));


export const setFilters = (payload) => ({ type: types.SET_FILTERS, payload });

export const getChartData = (payload) => ({ type: types.GET_CHART_DATA, payload });
export const setActiveChartData = (payload) => ({ type: types.SET_ACTIVE_CHART_DATA, payload });

export const resetAllProperties = () => ({ type: types.RESET_ALL_PROPERTIES });


