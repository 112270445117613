import img_18_webp from './webp/master_bg_02.webp';
import ChilledWaterImg_18_webp from './webp/Kaltwasser.webp';
import heatingReturnFlowImg_18_webp from './webp/Heizung_Rücklauf.webp';
import heatingLeadTimeImg_18_webp from './webp/Heizung_Vorlauf.webp';
import hotWaterImg_18_webp from './webp/Warmwasser.webp';
import circulationImg_18_webp from './webp/Zirkulation.webp';

import img_18_png from './png/master_bg_02.png';
import ChilledWaterImg_18_png from './png/Kaltwasser.png';
import heatingReturnFlowImg_18_png from './png/Heizung_Rücklauf.png';
import heatingLeadTimeImg_18_png from './png/Heizung_Vorlauf.png';
import hotWaterImg_18_png from './png/Warmwasser.png';
import circulationImg_18_png from './png/Zirkulation.png';

const performanceImgType18 = {
    webp: [img_18_webp, ChilledWaterImg_18_webp, heatingReturnFlowImg_18_webp, heatingLeadTimeImg_18_webp, hotWaterImg_18_webp, circulationImg_18_webp],
    png: [img_18_png, ChilledWaterImg_18_png, heatingReturnFlowImg_18_png, heatingLeadTimeImg_18_png, hotWaterImg_18_png, circulationImg_18_png]
}

export { performanceImgType18 }