import img_1_v2_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/Kaltwassereingang@2x.webp";
import img_1_v3_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/Kaltwassereingang@3x.webp";
import img_2_v2_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/Kaltwassereingang_1@2x.webp";
import img_2_v3_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/Kaltwassereingang_1@3x.webp";

import img_1_v2_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/Kaltwassereingang@2x.jpg";
import img_1_v3_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/Kaltwassereingang@3x.jpg";
import img_2_v2_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/Kaltwassereingang_1@2x.jpg";
import img_2_v3_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/Kaltwassereingang_1@3x.jpg";

import img_1_v2_rukspulfilter_webp from "./02_Rukspulfilter/webp/Ruckspulfilter@2x.webp";
import img_1_v3_rukspulfilter_webp from "./02_Rukspulfilter/webp/Ruckspulfilter@3x.webp";

import img_1_v2_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/Ruckspulfilter@2x.jpg";
import img_1_v3_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/Ruckspulfilter@3x.jpg";

const images_Unter_den_Eschen_14_15 = {
	'Kaltwassereingang': {
        webp: {
            img_1: [img_1_v2_kaltwassereingang_webp, img_1_v3_kaltwassereingang_webp],
            img_2: [img_2_v2_kaltwassereingang_webp, img_2_v3_kaltwassereingang_webp]
        },
        jpg: {
            img_1: [img_1_v2_kaltwassereingang_jpg, img_1_v3_kaltwassereingang_jpg],
            img_2: [img_2_v2_kaltwassereingang_jpg, img_2_v3_kaltwassereingang_jpg]
        }
    },
    'Rukspulfilter': {
        webp: {
            img_1: [img_1_v2_rukspulfilter_webp, img_1_v3_rukspulfilter_webp]
        },
        jpg: {
            img_1: [img_1_v2_rukspulfilter_jpg, img_1_v3_rukspulfilter_jpg]
        }
    }
}

export { images_Unter_den_Eschen_14_15 };