import React from 'react';
import styles from "../../styles/components/Houses/SensorCardForHouseList.module.css";
import TemperatureBox from "./TemperatureBox";

const TabContent = props => {
    const { type, modbus } = props;
    return (
        typeof modbus === "object"
            ? (<div className="flex-row flex-top-center w-100 flex-evenly text-start w-100">
                <TemperatureBox modbus={modbus.sensor_in} type={'sensor_in'} tabType={type} />
                <TemperatureBox modbus={modbus.sensor_out} type={'sensor_out'} tabType={type} />
            </div>)
            : (<div className={styles.one_temp_box}>
                <TemperatureBox modbus={+modbus} tabType={type} />
            </div>)
    );
}

const TemperatureTabs = props => {
    const { card } = props;
    const { modbus = null } = card;

    return modbus !== null
        ? <div className={`flex-center flex-column ${styles.wrap_temperature_box}`}>
            <TabContent modbus={modbus} type='PAUL' />
        </div>
        : null
};

export default TemperatureTabs;