import img_1_v2_Hauswasserzahler_webp from "./details/01_Hauswasserzahler/webp/Hauswasserzahler@2x.webp";
import img_1_v3_Hauswasserzahler_webp from "./details/01_Hauswasserzahler/webp/Hauswasserzahler@3x.webp";
import img_2_v2_Hauswasserzahler_webp from "./details/01_Hauswasserzahler/webp/Hauswasserzahler_1@2x.webp";
import img_2_v3_Hauswasserzahler_webp from "./details/01_Hauswasserzahler/webp/Hauswasserzahler_1@3x.webp";

import img_1_v2_Ruckspulfilter_webp from "./details/02_Ruckspulfilter/webp/Ruckspulfilter@2x.webp";
import img_1_v3_Ruckspulfilter_webp from "./details/02_Ruckspulfilter/webp/Ruckspulfilter@3x.webp";
import img_2_v2_Ruckspulfilter_webp from "./details/02_Ruckspulfilter/webp/Ruckspulfilter_1@2x.webp";
import img_2_v3_Ruckspulfilter_webp from "./details/02_Ruckspulfilter/webp/Ruckspulfilter_1@3x.webp";

import img_1_v2_Zirkulationspumpe_webp from "./details/03_Zirkulationspumpe/webp/Zirkulationspumpe@2x.webp";
import img_1_v3_Zirkulationspumpe_webp from "./details/03_Zirkulationspumpe/webp/Zirkulationspumpe@3x.webp";
import img_2_v2_Zirkulationspumpe_webp from "./details/03_Zirkulationspumpe/webp/Zirkulationspumpe_1@2x.webp";
import img_2_v3_Zirkulationspumpe_webp from "./details/03_Zirkulationspumpe/webp/Zirkulationspumpe_1@3x.webp";

import img_1_v2_Warmwasserspeicher_webp from "./details/04_Warmwasserspeicher/webp/Warmwasserspeicher@2x.webp";
import img_1_v3_Warmwasserspeicher_webp from "./details/04_Warmwasserspeicher/webp/Warmwasserspeicher@3x.webp";
import img_2_v2_Warmwasserspeicher_webp from "./details/04_Warmwasserspeicher/webp/Warmwasserspeicher_1@2x.webp";
import img_2_v3_Warmwasserspeicher_webp from "./details/04_Warmwasserspeicher/webp/Warmwasserspeicher_1@3x.webp";
import img_3_v2_Warmwasserspeicher_webp from "./details/04_Warmwasserspeicher/webp/Warmwasserspeicher_2@2x.webp";
import img_3_v3_Warmwasserspeicher_webp from "./details/04_Warmwasserspeicher/webp/Warmwasserspeicher_2@3x.webp";
import img_4_v2_Warmwasserspeicher_webp from "./details/04_Warmwasserspeicher/webp/Warmwasserspeicher_3@2x.webp";
import img_4_v3_Warmwasserspeicher_webp from "./details/04_Warmwasserspeicher/webp/Warmwasserspeicher_3@3x.webp";

import img_1_v2_Heizkreise_webp from "./details/05_Heizkreise/webp/Heizkreise@2x.webp";
import img_1_v3_Heizkreise_webp from "./details/05_Heizkreise/webp/Heizkreise@3x.webp";
import img_2_v2_Heizkreise_webp from "./details/05_Heizkreise/webp/Heizkreise_1@2x.webp";
import img_2_v3_Heizkreise_webp from "./details/05_Heizkreise/webp/Heizkreise_1@3x.webp";

import img_1_v2_Heizkreispumpe_webp from "./details/06_Heizkreispumpe/webp/Heizkreispumpe@2x.webp";
import img_1_v3_Heizkreispumpe_webp from "./details/06_Heizkreispumpe/webp/Heizkreispumpe@3x.webp";
import img_2_v2_Heizkreispumpe_webp from "./details/06_Heizkreispumpe/webp/Heizkreispumpe_1@2x.webp";
import img_2_v3_Heizkreispumpe_webp from "./details/06_Heizkreispumpe/webp/Heizkreispumpe_1@3x.webp";

import img_1_v2_Heizkessel_webp from "./details/07_Heizkessel/webp/Heizkessel@2x.webp";
import img_1_v3_Heizkessel_webp from "./details/07_Heizkessel/webp/Heizkessel@3x.webp";
import img_2_v2_Heizkessel_webp from "./details/07_Heizkessel/webp/Heizkessel_1@2x.webp";
import img_2_v3_Heizkessel_webp from "./details/07_Heizkessel/webp/Heizkessel_1@3x.webp";
import img_3_v2_Heizkessel_webp from "./details/07_Heizkessel/webp/Heizkessel_2@2x.webp";
import img_3_v3_Heizkessel_webp from "./details/07_Heizkessel/webp/Heizkessel_2@3x.webp";
import img_4_v2_Heizkessel_webp from "./details/07_Heizkessel/webp/Heizkessel_3@2x.webp";
import img_4_v3_Heizkessel_webp from "./details/07_Heizkessel/webp/Heizkessel_3@3x.webp";
import img_5_v2_Heizkessel_webp from "./details/07_Heizkessel/webp/Heizkessel_4@2x.webp";
import img_5_v3_Heizkessel_webp from "./details/07_Heizkessel/webp/Heizkessel_4@3x.webp";

import img_1_v2_Speicherladepumpe_webp from "./details/08_Speicherladepumpe/webp/Speicherladepumpe@2x.webp";
import img_1_v3_Speicherladepumpe_webp from "./details/08_Speicherladepumpe/webp/Speicherladepumpe@3x.webp";
import img_2_v2_Speicherladepumpe_webp from "./details/08_Speicherladepumpe/webp/Speicherladepumpe_1@2x.webp";
import img_2_v3_Speicherladepumpe_webp from "./details/08_Speicherladepumpe/webp/Speicherladepumpe_1@3x.webp";

import img_1_v2_Warmetauscher_webp from "./details/09_Warmetauscher/webp/Warmetauscher@2x.webp";
import img_1_v3_Warmetauscher_webp from "./details/09_Warmetauscher/webp/Warmetauscher@3x.webp";
import img_2_v2_Warmetauscher_webp from "./details/09_Warmetauscher/webp/Warmetauscher_1@2x.webp";
import img_2_v3_Warmetauscher_webp from "./details/09_Warmetauscher/webp/Warmetauscher_1@3x.webp";

import img_1_v2_Hauswasserzahler_jpg from "./details/01_Hauswasserzahler/jpg/Hauswasserzahler@2x.jpg";
import img_1_v3_Hauswasserzahler_jpg from "./details/01_Hauswasserzahler/jpg/Hauswasserzahler@3x.jpg";
import img_2_v2_Hauswasserzahler_jpg from "./details/01_Hauswasserzahler/jpg/Hauswasserzahler_1@2x.jpg";
import img_2_v3_Hauswasserzahler_jpg from "./details/01_Hauswasserzahler/jpg/Hauswasserzahler_1@3x.jpg";

import img_1_v2_Ruckspulfilter_jpg from "./details/02_Ruckspulfilter/jpg/Ruckspulfilter@2x.jpg";
import img_1_v3_Ruckspulfilter_jpg from "./details/02_Ruckspulfilter/jpg/Ruckspulfilter@3x.jpg";
import img_2_v2_Ruckspulfilter_jpg from "./details/02_Ruckspulfilter/jpg/Ruckspulfilter_1@2x.jpg";
import img_2_v3_Ruckspulfilter_jpg from "./details/02_Ruckspulfilter/jpg/Ruckspulfilter_1@3x.jpg";

import img_1_v2_Zirkulationspumpe_jpg from "./details/03_Zirkulationspumpe/jpg/Zirkulationspumpe@2x.jpg";
import img_1_v3_Zirkulationspumpe_jpg from "./details/03_Zirkulationspumpe/jpg/Zirkulationspumpe@3x.jpg";
import img_2_v2_Zirkulationspumpe_jpg from "./details/03_Zirkulationspumpe/jpg/Zirkulationspumpe_1@2x.jpg";
import img_2_v3_Zirkulationspumpe_jpg from "./details/03_Zirkulationspumpe/jpg/Zirkulationspumpe_1@3x.jpg";

import img_1_v2_Warmwasserspeicher_jpg from "./details/04_Warmwasserspeicher/jpg/Warmwasserspeicher@2x.jpg";
import img_1_v3_Warmwasserspeicher_jpg from "./details/04_Warmwasserspeicher/jpg/Warmwasserspeicher@3x.jpg";
import img_2_v2_Warmwasserspeicher_jpg from "./details/04_Warmwasserspeicher/jpg/Warmwasserspeicher_1@2x.jpg";
import img_2_v3_Warmwasserspeicher_jpg from "./details/04_Warmwasserspeicher/jpg/Warmwasserspeicher_1@3x.jpg";
import img_3_v2_Warmwasserspeicher_jpg from "./details/04_Warmwasserspeicher/jpg/Warmwasserspeicher_2@2x.jpg";
import img_3_v3_Warmwasserspeicher_jpg from "./details/04_Warmwasserspeicher/jpg/Warmwasserspeicher_2@3x.jpg";
import img_4_v2_Warmwasserspeicher_jpg from "./details/04_Warmwasserspeicher/jpg/Warmwasserspeicher_3@2x.jpg";
import img_4_v3_Warmwasserspeicher_jpg from "./details/04_Warmwasserspeicher/jpg/Warmwasserspeicher_3@3x.jpg";

import img_1_v2_Heizkreise_jpg from "./details/05_Heizkreise/jpg/Heizkreise@2x.jpg";
import img_1_v3_Heizkreise_jpg from "./details/05_Heizkreise/jpg/Heizkreise@3x.jpg";
import img_2_v2_Heizkreise_jpg from "./details/05_Heizkreise/jpg/Heizkreise_1@2x.jpg";
import img_2_v3_Heizkreise_jpg from "./details/05_Heizkreise/jpg/Heizkreise_1@3x.jpg";

import img_1_v2_Heizkreispumpe_jpg from "./details/06_Heizkreispumpe/jpg/Heizkreispumpe@2x.jpg";
import img_1_v3_Heizkreispumpe_jpg from "./details/06_Heizkreispumpe/jpg/Heizkreispumpe@3x.jpg";
import img_2_v2_Heizkreispumpe_jpg from "./details/06_Heizkreispumpe/jpg/Heizkreispumpe_1@2x.jpg";
import img_2_v3_Heizkreispumpe_jpg from "./details/06_Heizkreispumpe/jpg/Heizkreispumpe_1@3x.jpg";

import img_1_v2_Heizkessel_jpg from "./details/07_Heizkessel/jpg/Heizkessel@2x.jpg";
import img_1_v3_Heizkessel_jpg from "./details/07_Heizkessel/jpg/Heizkessel@3x.jpg";
import img_2_v2_Heizkessel_jpg from "./details/07_Heizkessel/jpg/Heizkessel_1@2x.jpg";
import img_2_v3_Heizkessel_jpg from "./details/07_Heizkessel/jpg/Heizkessel_1@3x.jpg";
import img_3_v2_Heizkessel_jpg from "./details/07_Heizkessel/jpg/Heizkessel_2@2x.jpg";
import img_3_v3_Heizkessel_jpg from "./details/07_Heizkessel/jpg/Heizkessel_2@3x.jpg";
import img_4_v2_Heizkessel_jpg from "./details/07_Heizkessel/jpg/Heizkessel_3@2x.jpg";
import img_4_v3_Heizkessel_jpg from "./details/07_Heizkessel/jpg/Heizkessel_3@3x.jpg";
import img_5_v2_Heizkessel_jpg from "./details/07_Heizkessel/jpg/Heizkessel_4@2x.jpg";
import img_5_v3_Heizkessel_jpg from "./details/07_Heizkessel/jpg/Heizkessel_4@3x.jpg";

import img_1_v2_Speicherladepumpe_jpg from "./details/08_Speicherladepumpe/jpg/Speicherladepumpe@2x.jpg";
import img_1_v3_Speicherladepumpe_jpg from "./details/08_Speicherladepumpe/jpg/Speicherladepumpe@3x.jpg";
import img_2_v2_Speicherladepumpe_jpg from "./details/08_Speicherladepumpe/jpg/Speicherladepumpe_1@2x.jpg";
import img_2_v3_Speicherladepumpe_jpg from "./details/08_Speicherladepumpe/jpg/Speicherladepumpe_1@3x.jpg";

import img_1_v2_Warmetauscher_jpg from "./details/09_Warmetauscher/jpg/Warmetauscher@2x.jpg";
import img_1_v3_Warmetauscher_jpg from "./details/09_Warmetauscher/jpg/Warmetauscher@3x.jpg";
import img_2_v2_Warmetauscher_jpg from "./details/09_Warmetauscher/jpg/Warmetauscher_1@2x.jpg";
import img_2_v3_Warmetauscher_jpg from "./details/09_Warmetauscher/jpg/Warmetauscher_1@3x.jpg";

const detailsDerfflinger = {
    'Hauswasserzahler': {
        webp: {
            img_1: [img_1_v2_Hauswasserzahler_webp, img_1_v3_Hauswasserzahler_webp],
            img_2: [img_2_v2_Hauswasserzahler_webp, img_2_v3_Hauswasserzahler_webp]
        },
        jpg: {
            img_1: [img_1_v2_Hauswasserzahler_jpg, img_1_v3_Hauswasserzahler_jpg],
            img_2: [img_2_v2_Hauswasserzahler_jpg, img_2_v3_Hauswasserzahler_jpg]
        }
    },
    'Ruckspulfilter': {
        webp: {
            img_1: [img_1_v2_Ruckspulfilter_webp, img_1_v3_Ruckspulfilter_webp],
            img_2: [img_2_v2_Ruckspulfilter_webp, img_2_v3_Ruckspulfilter_webp]
        },
        jpg: {
            img_1: [img_1_v2_Ruckspulfilter_jpg, img_1_v3_Ruckspulfilter_jpg],
            img_2: [img_2_v2_Ruckspulfilter_jpg, img_2_v3_Ruckspulfilter_jpg]
        }
    },
    'Zirkulationpumpe': {
        webp: {
            img_1: [img_1_v2_Zirkulationspumpe_webp, img_1_v3_Zirkulationspumpe_webp],
            img_2: [img_2_v2_Zirkulationspumpe_webp, img_2_v3_Zirkulationspumpe_webp]
        },
        jpg: {
            img_1: [img_1_v2_Zirkulationspumpe_jpg, img_1_v3_Zirkulationspumpe_jpg],
            img_2: [img_2_v2_Zirkulationspumpe_jpg, img_2_v3_Zirkulationspumpe_jpg]
        }
    },
    'Warmwasserspeicher': {
        webp: {
            img_1: [img_1_v2_Warmwasserspeicher_webp, img_1_v3_Warmwasserspeicher_webp],
            img_2: [img_2_v2_Warmwasserspeicher_webp, img_2_v3_Warmwasserspeicher_webp],
            img_3: [img_3_v2_Warmwasserspeicher_webp, img_3_v3_Warmwasserspeicher_webp],
            img_4: [img_4_v2_Warmwasserspeicher_webp, img_4_v3_Warmwasserspeicher_webp]
        },
        jpg: {
            img_1: [img_1_v2_Warmwasserspeicher_jpg, img_1_v3_Warmwasserspeicher_jpg],
            img_2: [img_2_v2_Warmwasserspeicher_jpg, img_2_v3_Warmwasserspeicher_jpg],
            img_3: [img_3_v2_Warmwasserspeicher_jpg, img_3_v3_Warmwasserspeicher_jpg],
            img_4: [img_4_v2_Warmwasserspeicher_jpg, img_4_v3_Warmwasserspeicher_jpg]
        }
    },
    'Heizkreise': {
        webp: {
            img_1: [img_1_v2_Heizkreise_webp, img_1_v3_Heizkreise_webp],
            img_2: [img_2_v2_Heizkreise_webp, img_2_v3_Heizkreise_webp]
        },
        jpg: {
            img_1: [img_1_v2_Heizkreise_jpg, img_1_v3_Heizkreise_jpg],
            img_2: [img_2_v2_Heizkreise_jpg, img_2_v3_Heizkreise_jpg]
        }
    },
    'Heizkreispumpe': {
        webp: {
            img_1: [img_1_v2_Heizkreispumpe_webp, img_1_v3_Heizkreispumpe_webp],
            img_2: [img_2_v2_Heizkreispumpe_webp, img_2_v3_Heizkreispumpe_webp]
        },
        jpg: {
            img_1: [img_1_v2_Heizkreispumpe_jpg, img_1_v3_Heizkreispumpe_jpg],
            img_2: [img_2_v2_Heizkreispumpe_jpg, img_2_v3_Heizkreispumpe_jpg]
        }
    },
    'Speicherladepumpe': {
        webp: {
            img_1: [img_1_v2_Speicherladepumpe_webp, img_1_v3_Speicherladepumpe_webp],
            img_2: [img_2_v2_Speicherladepumpe_webp, img_2_v3_Speicherladepumpe_webp]
        },
        jpg: {
            img_1: [img_1_v2_Speicherladepumpe_jpg, img_1_v3_Speicherladepumpe_jpg],
            img_2: [img_2_v2_Speicherladepumpe_jpg, img_2_v3_Speicherladepumpe_jpg]
        }
    },
    'Heizkessel': {
        webp: {
            img_1: [img_1_v2_Heizkessel_webp, img_1_v3_Heizkessel_webp],
            img_2: [img_2_v2_Heizkessel_webp, img_2_v3_Heizkessel_webp],
            img_3: [img_3_v2_Heizkessel_webp, img_3_v3_Heizkessel_webp],
            img_4: [img_4_v2_Heizkessel_webp, img_4_v3_Heizkessel_webp],
            img_5: [img_5_v2_Heizkessel_webp, img_5_v3_Heizkessel_webp]
        },
        jpg: {
            img_1: [img_1_v2_Heizkessel_jpg, img_1_v3_Heizkessel_jpg],
            img_2: [img_2_v2_Heizkessel_jpg, img_2_v3_Heizkessel_jpg],
            img_3: [img_3_v2_Heizkessel_jpg, img_3_v3_Heizkessel_jpg],
            img_4: [img_4_v2_Heizkessel_jpg, img_4_v3_Heizkessel_jpg],
            img_5: [img_5_v2_Heizkessel_jpg, img_5_v3_Heizkessel_jpg]
        }
    },
    'Warmetauscher': {
        webp: {
            img_1: [img_1_v2_Warmetauscher_webp, img_1_v3_Warmetauscher_webp],
            img_2: [img_2_v2_Warmetauscher_webp, img_2_v3_Warmetauscher_webp]
        },
        jpg: {
            img_1: [img_1_v2_Warmetauscher_jpg, img_1_v3_Warmetauscher_jpg],
            img_2: [img_2_v2_Warmetauscher_jpg, img_2_v3_Warmetauscher_jpg]
        }
    }
}

export { detailsDerfflinger };