import React, { useEffect, useCallback, useState} from "react";
import fscreen from 'fscreen';
import logo from "../../images/Logo_text.svg";
import {useSelector, useDispatch} from "react-redux";
import {getActive} from "../../redux/selectors";
import {setActive, setShowHotspotImage} from "../../redux/actions";

import styles from "../../styles/components/Img360/ControlImg360.module.css";
import { BackButton } from "../Inputs";
import {useTranslation} from "react-i18next";
import { Card360 } from "..";

const ControlImg360 = props => {
    const name = "ControlImg360";
    const active = useSelector(getActive);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {imgSupport, onClick, openFull} = props;

    const enterFullscreen = useCallback(() => {
      onClick(true);
    },[]);

    const exitFullscreen = useCallback(() => {
      onClick(false);
    }, []);

    useEffect(() => {
      if(openFull) {
        setTimeout(() => {
          setShow(openFull);
        }, 100)
      } else {
        setShow(openFull);
      }
    }, [openFull])

    return (
      show ? (
            <>
              <img className={styles.logo} src={logo} alt="PAUL"/>
              <BackButton className={styles.btnWrapper} onClick={exitFullscreen} text={t(`${name}.BackButton`)} isButton />
              <button className={styles.btnSmallScreen } type="button" onClick={exitFullscreen} />
              <Card360 imgSupport={imgSupport}/>
            </>
          ) : (
            <>
              <button className={styles.btnFullScreen} type="button" onClick={enterFullscreen}/>
              <span className={styles.btn360} onClick={enterFullscreen}>360°</span>
            </>
        )
    )
};

export default ControlImg360;
