import img_prototype_webp from './webp/master.webp';
import chilledWaterImg_prototype_webp from '../12/webp/Kaltwasser.webp';
import heatingReturnFlowImg_prototype_webp from './webp/Heizung_Rücklauf.webp';
import heatingLeadTimeImg_prototype_webp from './webp/Heizung_Vorlauf.webp';
import hotWaterImg_prototype_webp from '../12/webp/Warmwasser.webp';
import circulationImg_prototype_webp from '../12/webp/Zirkulation.webp';

import img_prototype_png from './png/master.png';
import chilledWaterImg_prototype_png from '../12/png/Kaltwasser.png';
import heatingReturnFlowImg_prototype_png from './png/Heizung_Rücklauf.png';
import heatingLeadTimeImg_prototype_png from './png/Heizung_Vorlauf.png';
import hotWaterImg_prototype_png from '../12/png/Warmwasser.png';
import circulationImg_prototype_png from '../12/png/Zirkulation.png';

const performanceImgTypePrototype = {
    webp: [img_prototype_webp, chilledWaterImg_prototype_webp, heatingReturnFlowImg_prototype_webp, heatingLeadTimeImg_prototype_webp, hotWaterImg_prototype_webp, circulationImg_prototype_webp ],
    png: [img_prototype_png, chilledWaterImg_prototype_png, heatingReturnFlowImg_prototype_png, heatingLeadTimeImg_prototype_png, hotWaterImg_prototype_png, circulationImg_prototype_png]
}

export { performanceImgTypePrototype }