import sensor_74_webp from "./5_7/webp/Kaltwassereingang.webp";
import sensor_74_v2_webp from "./5_7/webp/Kaltwassereingang@2x.webp";
import sensor_74_v3_webp from "./5_7/webp/Kaltwassereingang@3x.webp";

import sensor_74_jpg from "./5_7/jpg/Kaltwassereingang.jpg";
import sensor_74_v2_jpg from "./5_7/jpg/Kaltwassereingang@2x.jpg";
import sensor_74_v3_jpg from "./5_7/jpg/Kaltwassereingang@3x.jpg";

import sensor_77_webp from "./14_16/webp/Kaltwassereingang.webp";
import sensor_77_v2_webp from "./14_16/webp/Kaltwassereingang@2x.webp";
import sensor_77_v3_webp from "./14_16/webp/Kaltwassereingang@3x.webp";

import sensor_77_jpg from "./14_16/jpg/Kaltwassereingang.jpg";
import sensor_77_v2_jpg from "./14_16/jpg/Kaltwassereingang@2x.jpg";
import sensor_77_v3_jpg from "./14_16/jpg/Kaltwassereingang@3x.jpg";

import sensor_78_webp from "./14_16/webp/Ruckspulfilter.webp";
import sensor_78_v2_webp from "./14_16/webp/Ruckspulfilter@2x.webp";
import sensor_78_v3_webp from "./14_16/webp/Ruckspulfilter@3x.webp";

import sensor_78_jpg from "./14_16/jpg/Ruckspulfilter.jpg";
import sensor_78_v2_jpg from "./14_16/jpg/Ruckspulfilter@2x.jpg";
import sensor_78_v3_jpg from "./14_16/jpg/Ruckspulfilter@3x.jpg";

import sensor_79_webp from "./17_18/webp/Kaltwassereingang.webp";
import sensor_79_v2_webp from "./17_18/webp/Kaltwassereingang@2x.webp";
import sensor_79_v3_webp from "./17_18/webp/Kaltwassereingang@3x.webp";

import sensor_79_jpg from "./17_18/jpg/Kaltwassereingang.jpg";
import sensor_79_v2_jpg from "./17_18/jpg/Kaltwassereingang@2x.jpg";
import sensor_79_v3_jpg from "./17_18/jpg/Kaltwassereingang@3x.jpg";

import sensor_80_webp from "./17_18/webp/Ruckspulfilter.webp";
import sensor_80_v2_webp from "./17_18/webp/Ruckspulfilter@2x.webp";
import sensor_80_v3_webp from "./17_18/webp/Ruckspulfilter@3x.webp";

import sensor_80_jpg from "./17_18/jpg/Ruckspulfilter.jpg";
import sensor_80_v2_jpg from "./17_18/jpg/Ruckspulfilter@2x.jpg";
import sensor_80_v3_jpg from "./17_18/jpg/Ruckspulfilter@3x.jpg";

const s5_7 = {
    webp: {
        s_74: [sensor_74_webp, sensor_74_v2_webp, sensor_74_v3_webp]
    },
    jpg: {
        s_74: [sensor_74_jpg, sensor_74_v2_jpg, sensor_74_v3_jpg]
    }
}

const s14_16 = {
    webp: {
        s_77: [sensor_77_webp, sensor_77_v2_webp, sensor_77_v3_webp],
        s_78: [sensor_78_webp, sensor_78_v2_webp, sensor_78_v3_webp]
    },
    jpg: {
        s_77: [sensor_77_jpg, sensor_77_v2_jpg, sensor_77_v3_jpg],
        s_78: [sensor_78_jpg, sensor_78_v2_jpg, sensor_78_v3_jpg]
    }
}

const s17_18 = {
    webp: {
        s_79: [sensor_79_webp, sensor_79_v2_webp, sensor_79_v3_webp],
        s_80: [sensor_80_webp, sensor_80_v2_webp, sensor_80_v3_webp]
    },
    jpg: {
        s_79: [sensor_79_jpg, sensor_79_v2_jpg, sensor_79_v3_jpg],
        s_80: [sensor_80_jpg, sensor_80_v2_jpg, sensor_80_v3_jpg]
    }
}

export { s5_7, s14_16, s17_18 };