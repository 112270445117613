import React, {useCallback, useEffect, useRef, useState} from 'react';
import "lightgallery/css/lightgallery-bundle.min.css";
import '../styles/components/ImageSlider.css'

import LightGallery from 'lightgallery/react';
import lgPager from 'lightgallery/plugins/pager';
import {useSelector} from "react-redux";
import {getImgSupport} from "../redux/selectors";
import {houseDetailsRule} from "../utils/constants";

const ImageSlider = props => {
    const lightGallery = useRef(null);
    const [container, setContainer] = useState(null);
    const { card, openFullSize, disableFullScreen = false } = props;
    const { id = null } = card;
    const imgSupport = useSelector(getImgSupport);
    const [items, setItems] = useState([]);

    const lgAfterOpen = useCallback(() => {
        const lg = document.querySelector('.lg-container.lg-show');
        const callback = (mutationsList) => {
            mutationsList.forEach(mutation => {
                if (mutation.attributeName === 'class'
                    && (
                        (mutation.oldValue.includes('lg-inline') && !mutation.target.className.includes('lg-inline'))
                        || (!mutation.oldValue.includes('lg-inline') && mutation.target.className.includes('lg-inline'))
                    )
                ) {
                    const modal = document.querySelector('#details_card_modal');
                    if (modal) {
                        modal.classList.toggle('fullscreen')
                        const isFull = modal.className.includes('fullscreen') || false
                        // setIsFullSize(isFull)
                        if (openFullSize) openFullSize(isFull);
                    }
                }
            })
        }
        const mutationObserver = new MutationObserver(callback)
        if (lg) mutationObserver.observe(lg, { attributes: true, attributeOldValue: true })
    }, [openFullSize]);

    useEffect(() => {
        if (id) {
            const rule = houseDetailsRule[id];
            const imgType = imgSupport ? 'imgWebp' : 'imgPng';
            if (rule) {
                setItems(Object.values(rule[imgType]).map((img, i) => ({ id: i, src: img[1], thumb: img[0] })))
            }
        }
    }, [card, id, imgSupport])


    const onInit = useCallback((detail) => {
        if (detail && lightGallery) {
            lightGallery.current = detail.instance;
            lightGallery.current.openGallery();
        }
    }, [lightGallery]);

    const setContainerRef = useCallback((node) => {
        if (node !== null) setContainer(node);
    }, []);


    const getLgComponent = () => {
        if (container !== null) {
            return (
                <LightGallery
                    // plugins={[FullScreen, lgPager]}
                    plugins={[lgPager]}
                    dynamicEl={items}
                    dynamic
                    download={false}
                    counter={false}
                    closable={false}
                    loop={false}
                    escKey
                    showMaximizeIcon = {!disableFullScreen}
                    onInit={onInit}
                    hideControlOnEnd
                    container={container}
                    onAfterOpen={lgAfterOpen}
                />
            );
        }
        return null;
    };

    return (
        <div className={'lg_slider_wrap'}>
            <div style={{ width: '100%', height: '100%' }} ref={setContainerRef} />
            {getLgComponent()}
        </div>
    );
}
export default ImageSlider;