import sensor_92_webp from "./webp/Hauswasserzahler.webp";
import sensor_92_v2_webp from "./webp/Hauswasserzahler@2x.webp";
import sensor_92_v3_webp from "./webp/Hauswasserzahler@3x.webp";
import sensor_93_webp from "./webp/Ruckspulfilter1.webp";
import sensor_93_v2_webp from "./webp/Ruckspulfilter1@2x.webp";
import sensor_93_v3_webp from "./webp/Ruckspulfilter1@3x.webp";
import sensor_94_webp from "./webp/Ruckspulfilter2.webp";
import sensor_94_v2_webp from "./webp/Ruckspulfilter2@2x.webp";
import sensor_94_v3_webp from "./webp/Ruckspulfilter2@3x.webp";
import sensor_95_webp from "./webp/Heizkessel.webp";
import sensor_95_v2_webp from "./webp/Heizkessel@2x.webp";
import sensor_95_v3_webp from "./webp/Heizkessel@3x.webp";
import sensor_96_webp from "./webp/Speicherladepumpe.webp";
import sensor_96_v2_webp from "./webp/Speicherladepumpe@2x.webp";
import sensor_96_v3_webp from "./webp/Speicherladepumpe@3x.webp";
import sensor_97_webp from "./webp/Zirkulationspumpe.webp";
import sensor_97_v2_webp from "./webp/Zirkulationspumpe@2x.webp";
import sensor_97_v3_webp from "./webp/Zirkulationspumpe@3x.webp";
import sensor_98_webp from "./webp/Hydraulische_weiche.webp";
import sensor_98_v2_webp from "./webp/Hydraulische_weiche@2x.webp";
import sensor_98_v3_webp from "./webp/Hydraulische_weiche@3x.webp";
import sensor_99_webp from "./webp/Heating_circuit_pump1.webp";
import sensor_99_v2_webp from "./webp/Heating_circuit_pump1@2x.webp";
import sensor_99_v3_webp from "./webp/Heating_circuit_pump1@3x.webp";
import sensor_100_webp from "./webp/Heating_circuit_pump2.webp";
import sensor_100_v2_webp from "./webp/Heating_circuit_pump2@2x.webp";
import sensor_100_v3_webp from "./webp/Heating_circuit_pump2@3x.webp";
import sensor_101_webp from "./webp/Heating_circuit_pump3.webp";
import sensor_101_v2_webp from "./webp/Heating_circuit_pump3@2x.webp";
import sensor_101_v3_webp from "./webp/Heating_circuit_pump3@3x.webp";
import sensor_102_webp from "./webp/Warmetauscher.webp";
import sensor_102_v2_webp from "./webp/Warmetauscher@2x.webp";
import sensor_102_v3_webp from "./webp/Warmetauscher@3x.webp";
import sensor_103_webp from "./webp/Warmwasserspeicher1.webp";
import sensor_103_v2_webp from "./webp/Warmwasserspeicher1@2x.webp";
import sensor_103_v3_webp from "./webp/Warmwasserspeicher1@3x.webp";
import sensor_104_webp from "./webp/Warmwasserspeicher2.webp";
import sensor_104_v2_webp from "./webp/Warmwasserspeicher2@2x.webp";
import sensor_104_v3_webp from "./webp/Warmwasserspeicher2@3x.webp";

import sensor_92_jpg from "./jpg/Hauswasserzahler.jpg";
import sensor_92_v2_jpg from "./jpg/Hauswasserzahler@2x.jpg";
import sensor_92_v3_jpg from "./jpg/Hauswasserzahler@3x.jpg";
import sensor_93_jpg from "./jpg/Ruckspulfilter1.jpg";
import sensor_93_v2_jpg from "./jpg/Ruckspulfilter1@2x.jpg";
import sensor_93_v3_jpg from "./jpg/Ruckspulfilter1@3x.jpg";
import sensor_94_jpg from "./jpg/Ruckspulfilter2.jpg";
import sensor_94_v2_jpg from "./jpg/Ruckspulfilter2@2x.jpg";
import sensor_94_v3_jpg from "./jpg/Ruckspulfilter2@3x.jpg";
import sensor_95_jpg from "./jpg/Heizkessel.jpg";
import sensor_95_v2_jpg from "./jpg/Heizkessel@2x.jpg";
import sensor_95_v3_jpg from "./jpg/Heizkessel@3x.jpg";
import sensor_96_jpg from "./jpg/Speicherladepumpe.jpg";
import sensor_96_v2_jpg from "./jpg/Speicherladepumpe@2x.jpg";
import sensor_96_v3_jpg from "./jpg/Speicherladepumpe@3x.jpg";
import sensor_97_jpg from "./jpg/Zirkulationspumpe.jpg";
import sensor_97_v2_jpg from "./jpg/Zirkulationspumpe@2x.jpg";
import sensor_97_v3_jpg from "./jpg/Zirkulationspumpe@3x.jpg";
import sensor_98_jpg from "./jpg/Hydraulische_weiche.jpg";
import sensor_98_v2_jpg from "./jpg/Hydraulische_weiche@2x.jpg";
import sensor_98_v3_jpg from "./jpg/Hydraulische_weiche@3x.jpg";
import sensor_99_jpg from "./jpg/Heating_circuit_pump1.jpg";
import sensor_99_v2_jpg from "./jpg/Heating_circuit_pump1@2x.jpg";
import sensor_99_v3_jpg from "./jpg/Heating_circuit_pump1@3x.jpg";
import sensor_100_jpg from "./jpg/Heating_circuit_pump2.jpg";
import sensor_100_v2_jpg from "./jpg/Heating_circuit_pump2@2x.jpg";
import sensor_100_v3_jpg from "./jpg/Heating_circuit_pump2@3x.jpg";
import sensor_101_jpg from "./jpg/Heating_circuit_pump3.jpg";
import sensor_101_v2_jpg from "./jpg/Heating_circuit_pump3@2x.jpg";
import sensor_101_v3_jpg from "./jpg/Heating_circuit_pump3@3x.jpg";
import sensor_102_jpg from "./jpg/Warmetauscher.jpg";
import sensor_102_v2_jpg from "./jpg/Warmetauscher@2x.jpg";
import sensor_102_v3_jpg from "./jpg/Warmetauscher@3x.jpg";
import sensor_103_jpg from "./jpg/Warmwasserspeicher1.jpg";
import sensor_103_v2_jpg from "./jpg/Warmwasserspeicher1@2x.jpg";
import sensor_103_v3_jpg from "./jpg/Warmwasserspeicher1@3x.jpg";
import sensor_104_jpg from "./jpg/Warmwasserspeicher2.jpg";
import sensor_104_v2_jpg from "./jpg/Warmwasserspeicher2@2x.jpg";
import sensor_104_v3_jpg from "./jpg/Warmwasserspeicher2@3x.jpg";


const s37 = {
    webp: {
        s_92: [sensor_92_webp, sensor_92_v2_webp, sensor_92_v3_webp],
        s_93: [sensor_93_webp, sensor_93_v2_webp, sensor_93_v3_webp],
        s_94: [sensor_94_webp, sensor_94_v2_webp, sensor_94_v3_webp],
        s_95: [sensor_95_webp, sensor_95_v2_webp, sensor_95_v3_webp],
        s_96: [sensor_96_webp, sensor_96_v2_webp, sensor_96_v3_webp],
        s_97: [sensor_97_webp, sensor_97_v2_webp, sensor_97_v3_webp],
        s_98: [sensor_98_webp, sensor_98_v2_webp, sensor_98_v3_webp],
        s_99: [sensor_99_webp, sensor_99_v2_webp, sensor_99_v3_webp],
        s_100: [sensor_100_webp, sensor_100_v2_webp, sensor_100_v3_webp],
        s_101: [sensor_101_webp, sensor_101_v2_webp, sensor_101_v3_webp],
        s_102: [sensor_102_webp, sensor_102_v2_webp, sensor_102_v3_webp],
        s_103: [sensor_103_webp, sensor_103_v2_webp, sensor_103_v3_webp],
        s_104: [sensor_104_webp, sensor_104_v2_webp, sensor_104_v3_webp]
    },
    jpg: {
        s_92: [sensor_92_jpg, sensor_92_v2_jpg, sensor_92_v3_jpg],
        s_93: [sensor_93_jpg, sensor_93_v2_jpg, sensor_93_v3_jpg],
        s_94: [sensor_94_jpg, sensor_94_v2_jpg, sensor_94_v3_jpg],
        s_95: [sensor_95_jpg, sensor_95_v2_jpg, sensor_95_v3_jpg],
        s_96: [sensor_96_jpg, sensor_96_v2_jpg, sensor_96_v3_jpg],
        s_97: [sensor_97_jpg, sensor_97_v2_jpg, sensor_97_v3_jpg],
        s_98: [sensor_98_jpg, sensor_98_v2_jpg, sensor_98_v3_jpg],
        s_99: [sensor_99_jpg, sensor_99_v2_jpg, sensor_99_v3_jpg],
        s_100: [sensor_100_jpg, sensor_100_v2_jpg, sensor_100_v3_jpg],
        s_101: [sensor_101_jpg, sensor_101_v2_jpg, sensor_101_v3_jpg],
        s_102: [sensor_102_jpg, sensor_102_v2_jpg, sensor_102_v3_jpg],
        s_103: [sensor_103_jpg, sensor_103_v2_jpg, sensor_103_v3_jpg],
        s_104: [sensor_104_jpg, sensor_104_v2_jpg, sensor_104_v3_jpg]
    }
}

export { s37 };