import React from 'react';
import styles from '../../styles/components/Layouts/UnAuthLayout.module.css';
import logo from '../../images/Logo_text.svg'
import {Container, Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";

const UnAuthLayout = ({children}) => {
  return(
    <>
      <Navbar expand="lg" className={`py-60 ${styles.nav_light}`}>
        <Container className={styles.container}>
          <Navbar.Brand>
            <Link to="/" >
              <img src={logo} alt="PAUL"/>
            </Link>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <div className="">
        <main>
        {children}
        </main>
        <div className="footer">

        </div>
      </div>
    </>
  )
};

export default UnAuthLayout;
