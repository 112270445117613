export const PROFILE_IMAGE = 'PROFILE_IMAGE';

export const GET_PROFILE_INFO = 'GET_PROFILE_INFO';
export const GET_PROFILE_INFO_SUCCEEDED = 'GET_PROFILE_INFO_SUCCEEDED';
export const GET_PROFILE_INFO_FAILED = 'GET_PROFILE_INFO_FAILED';

export const UPDATE_PROFILE_INFO = 'UPDATE_PROFILE_INFO';
export const UPDATE_PROFILE_INFO_SUCCEEDED = 'UPDATE_PROFILE_INFO_SUCCEEDED';
export const UPDATE_PROFILE_INFO_FAILED = 'UPDATE_PROFILE_INFO_FAILED';

export const RESET_ALL_PROFILE = 'RESET_ALL_PROFILE';