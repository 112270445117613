import sensor_38_webp from "./12/webp/Kaltwasser.webp";
import sensor_38_v2_webp from "./12/webp/Kaltwasser@2x.webp";
import sensor_38_v3_webp from "./12/webp/Kaltwasser@3x.webp";

import sensor_38_jpg from "./12/jpg/Kaltwasser.jpg";
import sensor_38_v2_jpg from "./12/jpg/Kaltwasser@2x.jpg";
import sensor_38_v3_jpg from "./12/jpg/Kaltwasser@3x.jpg";

import sensor_39_webp from "./12/webp/Ruckspulfilter.webp";
import sensor_39_v2_webp from "./12/webp/Ruckspulfilter@2x.webp";
import sensor_39_v3_webp from "./12/webp/Ruckspulfilter@3x.webp";

import sensor_39_jpg from "./12/jpg/Ruckspulfilter.jpg";
import sensor_39_v2_jpg from "./12/jpg/Ruckspulfilter@2x.jpg";
import sensor_39_v3_jpg from "./12/jpg/Ruckspulfilter@3x.jpg";

import sensor_40_webp from "./12/webp/Speicherladekreis.webp";
import sensor_40_v2_webp from "./12/webp/Speicherladekreis@2x.webp";
import sensor_40_v3_webp from "./12/webp/Speicherladekreis@3x.webp";

import sensor_40_jpg from "./12/jpg/Speicherladekreis.jpg";
import sensor_40_v2_jpg from "./12/jpg/Speicherladekreis@2x.jpg";
import sensor_40_v3_jpg from "./12/jpg/Speicherladekreis@3x.jpg";

import sensor_41_webp from "./12/webp/Fernwarme.webp";
import sensor_41_v2_webp from "./12/webp/Fernwarme@2x.webp";
import sensor_41_v3_webp from "./12/webp/Fernwarme@3x.webp";

import sensor_41_jpg from "./12/jpg/Fernwarme.jpg";
import sensor_41_v2_jpg from "./12/jpg/Fernwarme@2x.jpg";
import sensor_41_v3_jpg from "./12/jpg/Fernwarme@3x.jpg";

import sensor_42_webp from "./12/webp/Heizkreis_1.webp";
import sensor_42_v2_webp from "./12/webp/Heizkreis_1@2x.webp";
import sensor_42_v3_webp from "./12/webp/Heizkreis_1@3x.webp";

import sensor_42_jpg from "./12/jpg/Heizkreis_1.jpg";
import sensor_42_v2_jpg from "./12/jpg/Heizkreis_1@2x.jpg";
import sensor_42_v3_jpg from "./12/jpg/Heizkreis_1@3x.jpg";

import sensor_43_webp from "./12/webp/Heizkreis_2.webp";
import sensor_43_v2_webp from "./12/webp/Heizkreis_2@2x.webp";
import sensor_43_v3_webp from "./12/webp/Heizkreis_2@3x.webp";

import sensor_43_jpg from "./12/jpg/Heizkreis_2.jpg";
import sensor_43_v2_jpg from "./12/jpg/Heizkreis_2@2x.jpg";
import sensor_43_v3_jpg from "./12/jpg/Heizkreis_2@3x.jpg";

import sensor_44_webp from "./12/webp/Heizkreis_3.webp";
import sensor_44_v2_webp from "./12/webp/Heizkreis_3@2x.webp";
import sensor_44_v3_webp from "./12/webp/Heizkreis_3@3x.webp";

import sensor_44_jpg from "./12/jpg/Heizkreis_3.jpg";
import sensor_44_v2_jpg from "./12/jpg/Heizkreis_3@2x.jpg";
import sensor_44_v3_jpg from "./12/jpg/Heizkreis_3@3x.jpg";

import sensor_45_webp from "./12/webp/Heizkreispumpe_1.webp";
import sensor_45_v2_webp from "./12/webp/Heizkreispumpe_1@2x.webp";
import sensor_45_v3_webp from "./12/webp/Heizkreispumpe_1@3x.webp";

import sensor_45_jpg from "./12/jpg/Heizkreispumpe1.jpg";
import sensor_45_v2_jpg from "./12/jpg/Heizkreispumpe1@2x.jpg";
import sensor_45_v3_jpg from "./12/jpg/Heizkreispumpe1@3x.jpg";

import sensor_46_webp from "./12/webp/Heizkreispumpe_2.webp";
import sensor_46_v2_webp from "./12/webp/Heizkreispumpe_2@2x.webp";
import sensor_46_v3_webp from "./12/webp/Heizkreispumpe_2@3x.webp";

import sensor_46_jpg from "./12/jpg/Heizkreispumpe2.jpg";
import sensor_46_v2_jpg from "./12/jpg/Heizkreispumpe2@2x.jpg";
import sensor_46_v3_jpg from "./12/jpg/Heizkreispumpe2@3x.jpg";

import sensor_47_webp from "./12/webp/Heizkreispumpe_3.webp";
import sensor_47_v2_webp from "./12/webp/Heizkreispumpe_3@2x.webp";
import sensor_47_v3_webp from "./12/webp/Heizkreispumpe_3@3x.webp";

import sensor_47_jpg from "./12/jpg/Heizkreispumpe3.jpg";
import sensor_47_v2_jpg from "./12/jpg/Heizkreispumpe3@2x.jpg";
import sensor_47_v3_jpg from "./12/jpg/Heizkreispumpe3@3x.jpg";

import sensor_48_webp from "./12/webp/Warmwasserbereitung.webp";
import sensor_48_v2_webp from "./12/webp/Warmwasserbereitung@2x.webp";
import sensor_48_v3_webp from "./12/webp/Warmwasserbereitung@3x.webp";

import sensor_48_jpg from "./12/jpg/Warmwasserbereitung.jpg";
import sensor_48_v2_jpg from "./12/jpg/Warmwasserbereitung@2x.jpg";
import sensor_48_v3_jpg from "./12/jpg/Warmwasserbereitung@3x.jpg";

import sensor_49_webp from "./12/webp/Zirkulationspumpe.webp";
import sensor_49_v2_webp from "./12/webp/Zirkulationspumpe@2x.webp";
import sensor_49_v3_webp from "./12/webp/Zirkulationspumpe@3x.webp";

import sensor_49_jpg from "./12/jpg/Zirkulationspumpe.jpg";
import sensor_49_v2_jpg from "./12/jpg/Zirkulationspumpe@2x.jpg";
import sensor_49_v3_jpg from "./12/jpg/Zirkulationspumpe@3x.jpg";

import sensor_50_webp from "./12/webp/Abwasser.webp";
import sensor_50_v2_webp from "./12/webp/Abwasser@2x.webp";
import sensor_50_v3_webp from "./12/webp/Abwasser@3x.webp";

import sensor_50_jpg from "./12/jpg/Abwasser.jpg";
import sensor_50_v2_jpg from "./12/jpg/Abwasser@2x.jpg";
import sensor_50_v3_jpg from "./12/jpg/Abwasser@3x.jpg";

import sensor_66_webp from "./8_9/webp/Kaltwasser.webp";
import sensor_66_v2_webp from "./8_9/webp/Kaltwasser@2x.webp";
import sensor_66_v3_webp from "./8_9/webp/Kaltwasser@3x.webp";

import sensor_66_jpg from "./8_9/jpg/Kaltwasser.jpg";
import sensor_66_v2_jpg from "./8_9/jpg/Kaltwasser@2x.jpg";
import sensor_66_v3_jpg from "./8_9/jpg/Kaltwasser@3x.jpg";

import sensor_68_webp from "./10_11/webp/Ruckspulfilter.webp";
import sensor_68_v2_webp from "./10_11/webp/Ruckspulfilter@2x.webp";
import sensor_68_v3_webp from "./10_11/webp/Ruckspulfilter@3x.webp";

import sensor_68_jpg from "./10_11/jpg/Ruckspulfilter.jpg";
import sensor_68_v2_jpg from "./10_11/jpg/Ruckspulfilter@2x.jpg";
import sensor_68_v3_jpg from "./10_11/jpg/Ruckspulfilter@3x.jpg";

import sensor_69_webp from "./12_14/webp/Kaltwasser.webp";
import sensor_69_v2_webp from "./12_14/webp/Kaltwasser@2x.webp";
import sensor_69_v3_webp from "./12_14/webp/Kaltwasser@3x.webp";

import sensor_69_jpg from "./12_14/jpg/Kaltwasser.jpg";
import sensor_69_v2_jpg from "./12_14/jpg/Kaltwasser@2x.jpg";
import sensor_69_v3_jpg from "./12_14/jpg/Kaltwasser@3x.jpg";

const s8_9 = {
    webp: {
        s_66: [sensor_66_webp, sensor_66_v2_webp, sensor_66_v3_webp]
    },
    jpg: {
        s_66: [sensor_66_jpg, sensor_66_v2_jpg, sensor_66_v3_jpg]
    }
}

const s10_11 = {
    webp: {
        s_68: [sensor_68_webp, sensor_68_v2_webp, sensor_68_v3_webp]
    },
    jpg: {
        s_68: [sensor_68_jpg, sensor_68_v2_jpg, sensor_68_v3_jpg]
    }
}

const s12 = {
    webp: {
        s_38: [sensor_38_webp, sensor_38_v2_webp, sensor_38_v3_webp],
        s_39: [sensor_39_webp, sensor_39_v2_webp, sensor_39_v3_webp],
        s_40: [sensor_40_webp, sensor_40_v2_webp, sensor_40_v3_webp],
        s_41: [sensor_41_webp, sensor_41_v2_webp, sensor_41_v3_webp],
        s_42: [sensor_42_webp, sensor_42_v2_webp, sensor_42_v3_webp],
        s_43: [sensor_43_webp, sensor_43_v2_webp, sensor_43_v3_webp],
        s_44: [sensor_44_webp, sensor_44_v2_webp, sensor_44_v3_webp],
        s_45: [sensor_45_webp, sensor_45_v2_webp, sensor_45_v3_webp],
        s_46: [sensor_46_webp, sensor_46_v2_webp, sensor_46_v3_webp],
        s_47: [sensor_47_webp, sensor_47_v2_webp, sensor_47_v3_webp],
        s_48: [sensor_48_webp, sensor_48_v2_webp, sensor_48_v3_webp],
        s_49: [sensor_49_webp, sensor_49_v2_webp, sensor_49_v3_webp],
        s_50: [sensor_50_webp, sensor_50_v2_webp, sensor_50_v3_webp]
    },
    jpg: {
        s_38: [sensor_38_jpg, sensor_38_v2_jpg, sensor_38_v3_jpg],
        s_39: [sensor_39_jpg, sensor_39_v2_jpg, sensor_39_v3_jpg],
        s_40: [sensor_40_jpg, sensor_40_v2_jpg, sensor_40_v3_jpg],
        s_41: [sensor_41_jpg, sensor_41_v2_jpg, sensor_41_v3_jpg],
        s_42: [sensor_42_jpg, sensor_42_v2_jpg, sensor_42_v3_jpg],
        s_43: [sensor_43_jpg, sensor_43_v2_jpg, sensor_43_v3_jpg],
        s_44: [sensor_44_jpg, sensor_44_v2_jpg, sensor_44_v3_jpg],
        s_45: [sensor_45_jpg, sensor_45_v2_jpg, sensor_45_v3_jpg],
        s_46: [sensor_46_jpg, sensor_46_v2_jpg, sensor_46_v3_jpg],
        s_47: [sensor_47_jpg, sensor_47_v2_jpg, sensor_47_v3_jpg],
        s_48: [sensor_48_jpg, sensor_48_v2_jpg, sensor_48_v3_jpg],
        s_49: [sensor_49_jpg, sensor_49_v2_jpg, sensor_49_v3_jpg],
        s_50: [sensor_50_jpg, sensor_50_v2_jpg, sensor_50_v3_jpg]
        
    }
}

const s12_14 = {
    webp: {
        s_69: [sensor_69_webp, sensor_69_v2_webp, sensor_69_v3_webp]
    },
    jpg: {
        s_69: [sensor_69_jpg, sensor_69_v2_jpg, sensor_69_v3_jpg]
    }
}

export { s8_9, s10_11, s12, s12_14 };