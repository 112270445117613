import React, {useEffect, useState} from 'react';
import {Popover, TextField, IconButton, RadioGroup, FormControl, Paper} from "@mui/material";
import ArrowIconSVG from '../../icons/arrow_white.svg';
import SearchIconSVG from '../../icons/search.svg';
import styles from "../../styles/components/Inputs/SearchFilter.module.css";
import {RadioItem} from "../Inputs/index";
import ClearIcon from '@mui/icons-material/Clear';
import {useTranslation} from "react-i18next";

import "../../styles/components/Inputs/BuildingSelector.css";

const SearchFilter = props => {
    const { type, groups, activeGroup, updateGroup } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const name = "SelectorName";
    const open = Boolean(anchorEl);
    const handleClick = e => setAnchorEl(e.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const [searchedValue, setSearchedValue] = useState();
    const [originalItems, setOriginalItems] = useState([]);
    const [radioValue, setRadioValue] = useState('');
    const [radioItems, setRadioItems] = useState([]);
    
    const {t} = useTranslation();

    useEffect(() => {
        setRadioValue(activeGroup);
    },[activeGroup]);

    useEffect(() => {
        if(groups) {
            setRadioItems(groups);
            setOriginalItems(groups);
        }
    }, [groups]);

    const handleChangeRadio = (e, newValue) => {
        updateGroup(type, newValue);
        setRadioValue(newValue);
    };

    const requestSearch = (searchedVal) => {
        setSearchedValue(searchedVal);
        const filteredItems = originalItems.filter((item) => {
            return item.toLowerCase().startsWith((searchedVal.toLowerCase()));
        });
        setRadioItems(filteredItems);
    };

    const cancelSearch = () => {
        setSearchedValue('');
        requestSearch('');
    };

    return (
        <div className={styles.control_wrapper} >
            <div className={`flex-row ${styles.control_container}`} aria-describedby={'Device-table-filter'} style={{width: type === 'buildingGroup' ? '165px' : '187px'}} onClick={handleClick}>
                <div className={`${type === 'buildingGroup' ? 'flex-row' : 'flex-column'} ${styles.title}`}>
                    <span>{t(`${name}.${type}`)}:</span>
                    <span className={`${styles.selected_value} ${type === 'buildingGroup' ? styles.selected_building : ''}`}>{radioValue}</span>
                </div>
                <img src={ArrowIconSVG} alt="" className={`${styles.arrow} ${open ? styles.active_arrow : ''}`}/>
            </div>
            <Popover
                id={'Device-table-filter'}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    }
                }}
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: '0 !important', 
                        marginTop: '8px', 
                        marginLeft: '0 !important', 
                        overflowY: 'hidden'
                    }
                }}
            >
                <div className={`${styles.popover_container} ${type === 'buildingGroup' ? styles.one_line_container : styles.two_line_container}`}>
                    <TextField
                        id='outlined-search'
                        variant='outlined'
                        placeholder={'Search...'}
                        type='search'
                        fullWidth
                        className='textField'
                        onChange={e => requestSearch(e.target.value)}
                        value={searchedValue}
                        sx={{
                            '& legend': {display: 'none'},
                            '& fieldset': {top: 0},
                            '& .MuiOutlinedInput-root': {
                                borderRadius: 0,
                                background: '#FFFFFF',
                                padding: 0,
                                '& fieldset': {
                                    border: '1px solid #C0C0CF',
                                },
                                '&:hover fieldset': {
                                    border: '1px solid #C0C0CF',
                                },
                                '&.Mui-focused fieldset': {
                                    border: '1px solid #C0C0CF',
                                },
                            },
                            padding: '8px'
                        }}
                        InputProps={{
                            endAdornment: <IconButton
                                onClick={cancelSearch}
                                disabled={!searchedValue}
                            >
                                {
                                    searchedValue ?
                                        <ClearIcon/> : <img src={SearchIconSVG} alt=""/>
                                }
                            </IconButton>
                        }}
                    />
                    <Paper elevation={0} style={{maxHeight: 214, overflowY: 'auto', backgroundColor: '#F6F6F9', marginTop: 0}}>
                        <FormControl className={`w-100 ${styles.radio_group_container}`}>
                            <RadioGroup
                                value={radioValue}
                                onChange={handleChangeRadio}
                            >
                                {radioItems.map(radio => (
                                    <RadioItem value={radio} labelPlacement="end" label={radio} style={{backgroundColor: '#F6F6F9;'}}
                                               type={'RadioListItem'} key={radio + 'RadioListItem'}
                                    />))}
                            </RadioGroup>
                        </FormControl>
                    </Paper>
                </div>
            </Popover>
        </div>

    );
};

export default SearchFilter;