import React, {useState} from 'react';
import styles from '../../styles/components/Inputs/Checbox.module.css'

const Checkbox = props => {
  const {
    label = '',
    name = '',
    disabled = false,
    checked = false,
    onChange,
    required = false,
    positionCheckbox = 'left_checkbox_position'  // right_checkbox_position
  } = props;
  const [input_id] = useState(Math.random());
  return (
    <div className={styles.checkbox}>
      <input
        id={input_id}
        disabled={disabled}
        type="checkbox"
        checked={checked}
        className={styles.input_style}
        onChange={e => onChange(e.target.checked)}
        name={name}
        required={required}
      />
      {label ? (
        <label className={[styles.label_style + ' ' + styles[positionCheckbox]]} htmlFor={input_id}>{ label }</label>
      ) : null}
    </div>
  )
};
export default Checkbox;
