import * as types from '../types/user';
import { getFromLocalStorage } from "../../utils/functions";

const initialState = {
  user: {
    token: getFromLocalStorage('token'),
    error: [],
    prototypeMode: getFromLocalStorage('prototypeMode'),
    socket: {
      isConnected: false,
      id: "df237erfxf97xc"
    }
  },
};

export const user = (state = initialState.user, { type, payload }) => {
  switch (type) {
    case types.SIGN_IN_SUCCEEDED:
      return {
        ...state,
        token: payload.token,
        prototypeMode: payload.isPrototypeMode,
      };
    case types.SIGN_IN_FAILED:
      return {...state, error: [...state.error, { method: 'SignIn', ...payload }]};
    case types.REMOVE_AUTH:
      return {...state, token: null};
    case types.SOCKET_CONNECTION:
      return {...state, socket: {...state.socket, isConnected: payload}};
    case types.RESET_ALL_USER:
      return initialState.user;
    default:
      return state;
  }
};
