import * as types from '../types/img360';

const initialState = {
  img360: {
    currentProperty: {},
    activeImage: {},
    active: false,
    show: false
  },
};

export const img360 = (state = initialState.img360, action) => {
  switch (action.type) {
    case types.CURRENT_PROPERTY:
      return {...state, currentProperty: action.payload};
    case types.ACTIVE_IMAGE:
      return {...state, activeImage: action.payload};
    case types.ACTIVE:
      return {...state, active: action.payload};
    case types.SHOW_HOTSPOT_IMAGE:
      return {...state, show: action.payload};
    case types.RESET_ALL_IMAGE360:
      return initialState.img360;
    default:
      return state;
  }
};
