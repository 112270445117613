import { call, put } from 'redux-saga/effects'
import {API} from '../../utils/API';

const requestSuccess = (type, payload) => ({ type: `${type}_SUCCEEDED`, payload });
const requestError = (type, payload) => ({ type: `${type}_FAILED`, payload });

export function* GetProfileInfo({ type }){
  try {
    const resp = yield call(API.get, 'admins');
    yield put(requestSuccess(type, { ...resp }));
  } catch (error) {
    yield put(requestError(type, { message: error.formError } ));
  }
}

export function* UpdateProfileInfo({ type, payload }){
    try {
      const resp = yield call(API.post, 'admins', payload);
      yield put(requestSuccess(type, { ...resp }));
    } catch (error) {
      yield put(requestError(type, { message: error.formError } ));
    }
  }


