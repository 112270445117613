import React, { useState, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {PickersDay} from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Box } from '@mui/material';
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import CalendarSVG from '../../icons/calendar.svg';
import moment from "moment";

import "../../styles/components/Profile/Calendar.css"
import styles from '../../styles/components/Profile/DateField.module.css';

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== "selected"
  })(({ theme, selected }) => ({
    ...(selected && {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.dark
      },
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%"
    })
}));

const DateField = props => {
    const { date, fieldName, onChange } = props;
    const [value, setValue] = useState(moment(date));
    const { i18n } = useTranslation();

    const updateDate = () => {
        const newValue = { [fieldName]: moment(value) }
        if (onChange) onChange(newValue)
    }

    useEffect(() => {
        if(value) {
            updateDate()
        }
    }, [value])

    useEffect(() => {
        setValue(date);
    }, [date])

    const handleKeyDown = (event) => event.key === 'Enter' && event.target.blur();

    const renderPickerDay = (d, selectedDates, pickersDayProps) => {
        return (
          <CustomPickersDay
            {...pickersDayProps}
            disableMargin
            isSelectDay={date}
          />
        );
    };

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterMoment} locale={i18n.resolvedLanguage}>
                <DatePicker
                    inputFormat={i18n.resolvedLanguage === "de" ? "DD.MM.YYYY" : "MM/DD/YYYY"}
                    mask={i18n.resolvedLanguage === "de" ? "__.__.____" : "__/__/____"}
                    value={value}
                    calendarStartDay={1}
                    views={['year', 'month', 'day']}
                    onChange={(newValue) => {
                        setValue(newValue);
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <input ref={inputRef} {...inputProps} className={styles.input} onKeyDown={handleKeyDown}/>
                        <img 
                            className={`${styles.calendarIcon} cursor-pointer`}
                            src={CalendarSVG} alt="Calendar" 
                            onClick={() => {
                                const { onClick } = InputProps?.endAdornment.props.children.props;
                                if (onClick) onClick(true)
                            }} 
                        />
                    </Box>
                    )}
                    maxDate={moment()}
                    renderDay={renderPickerDay}
                />
            </LocalizationProvider>
        </div>
    );
};

export default DateField;