import * as types from '../types/imgSupport';

const initialState = {
  imgSupport: {
    support: true
  },
};

export const imgSupport = (state = initialState.imgSupport, action) => {
  switch (action.type) {
    case types.IMAGE_FORMAT_SUPPORT:
      return {support: action.payload};
    default:
      return state;
  }
};