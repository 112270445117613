import React, {useCallback, useMemo} from 'react';
import {FormControlLabel, Radio, useRadioGroup} from "@mui/material";
import { styled } from '@mui/material/styles';

const RadioItem = props => {
    const {
        value = null,
        label = '',
        disabled = false,
        labelPlacement = 'end',
        type = '', // RadioListItem
        radioType = 'MainRadio', // MainRadio
    } = props;

    const RadioListItem = styled(FormControlLabel)(() => ({
        width: '100%',
        margin: 0,
        borderBottom: '1px solid #EAEAEF',
        justifyContent: labelPlacement === 'end' ? '' : 'space-between',
        '&.Mui-disabled': {
            opacity: 0.2
        },
        '.MuiFormControlLabel-label': {
            // color: theme.palette.primary.main,
            width: labelPlacement === 'end' ? '100%' : 100,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: '-webkit-box',
            'WebkitBoxOrient': 'vertical',
            'WebkitLineClamp': '2',
        },
    }));

    const MainRadio = styled(Radio)(({ checked }) => ({
        '& > span:not(.MuiTouchRipple-root):before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: checked ? 'transparent' : '#F6F6F9',
            borderRadius: '50%',
            transform: 'scale(.7)',
            transition: 'all .2s',
        },
        '& .MuiSvgIcon-root': {
            width: '24px',
            height: '24px',
            margin: '-6px',
            fontSize: 16,
            transition: 'all .2s',
            color: checked ? '#0000FF' : '#C0C0CF',
            // backgroundColor: checked ? 'transparent' : '#F6F6F9',
            '&:last-child': {
                transform: checked ? 'scale(1.146)' : 'scale(0)'
            }
        },
    }));

    const getStyledRadioForm = useCallback(() => {
        switch (type) {
            case 'RadioListItem': return RadioListItem;
            default: return FormControlLabel;
        }
    }, [type, RadioListItem])

    const getStyledRadio = useCallback(() => {
        switch (radioType) {
            case 'MainRadio': return MainRadio;
            default: return Radio;
        }
    }, [radioType, MainRadio])

    const StyledFormControlLabel = p => {
        const radioGroup = useRadioGroup();

        let checked = false;
        if (radioGroup) checked = radioGroup.value === p.value;

        const StyledFormControlLabel = getStyledRadioForm()
        const StyledRadio = getStyledRadio()

        return <StyledFormControlLabel checked={checked} control={<StyledRadio />} {...p} />;
    }

    return useMemo(() => (
        <StyledFormControlLabel
            value={value}
            labelPlacement={labelPlacement}
            label={label}
            disabled={disabled}
        />
    ), [value, labelPlacement, label, disabled])
}

export default RadioItem