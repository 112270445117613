import React, {useCallback, useEffect, useState} from 'react';
import {Tab} from "bootstrap";
import {Tabs} from "react-bootstrap";
import { Calendar } from "../index";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {
    getFromDay,
    getFrom7Day,
    getFromMonth,
    getFromYear,
    getFromMax, getFromStartYear, isSameCurrentDateByDay, getFrom16Day,
} from "../../utils/functions";

const CalendarWithTabsWrap = props => {
    const name = 'DetailsCharts';
    const {
        dateType = 'day',
        setDateType,
        setRangeType,
        from:propFrom,
        to:propTo,
        setFrom: setPropFrom,
        setTo: setPropTo
    } = props;
    const [isTabChange, setIsTabChange] = useState(false);
    const [from, setFrom] = useState(propFrom || getFromDay(moment()));
    const [to, setTo] = useState(propTo || moment());
    const { t } = useTranslation();

    useEffect(() => setPropFrom(from), [from, setPropFrom])
    useEffect(() => setPropTo(to), [to, setPropTo])

    const updateDate = useCallback(() => {
        switch (dateType) {
            case 'day':
                setRangeType(1);
                if (isTabChange) {
                    setTo(moment());
                    setFrom(getFromDay());
                }
                break;
            case 'week':
                setRangeType(1);
                if (isTabChange) {
                    setTo(moment());
                    setFrom(getFrom7Day());
                }
                break;
            case 'month':
                setRangeType(2);
                if (isTabChange) {
                    setTo(moment());
                    setFrom(isSameCurrentDateByDay(to) ? getFrom16Day() : getFromMonth());
                }
                break;
            case 'year':
                setRangeType(2);
                if (isTabChange) {
                    setTo(moment());
                    setFrom(getFromStartYear());
                }
                break;
            case 'max':
                setRangeType(2);
                if (isTabChange) {
                    setTo(moment());
                    setFrom(getFromMax());
                }
                break;
            default: break;
        }
        if (isTabChange) setIsTabChange(false);
    }, [dateType, to, isTabChange, setRangeType]);

    useEffect(() => {
        if (dateType) updateDate();
    }, [dateType, updateDate])

    return (
        <div>
            <Tabs
                transition={false}
                activeKey={dateType}
                onSelect={(k) => {
                    setIsTabChange(true)
                    setDateType(k)
                }}
                className="tabs detail_card mb-3"
            >
                <Tab eventKey="day" title={t(`${name}.dateType.day`)} className="tab" />
                <Tab eventKey="week" title={t(`${name}.dateType.week`)} className="tab"/>
                <Tab eventKey="month" title={t(`${name}.dateType.month`)} className="tab"/>
                <Tab eventKey="year" title={t(`${name}.dateType.year`)} className="tab"/>
                {/*<Tab eventKey="max" title={'MAX'} className="tab"/>*/}
            </Tabs>
            <Calendar
                minDate={moment('03.01.2022')}
                className={'flex-center'}
                openTo={dateType}
                viewChange={setDateType}
                dateFrom={from}
                dateTo={to}
                setFrom={setFrom}
                setTo={setTo}
            />
        </div>
    )
}

export default CalendarWithTabsWrap;