import sensor_1_webp from "./webp/Point_of_entry.webp";
import sensor_1_full_webp from "./webp/Full_Point_of_entry.webp";
import sensor_1_v2_webp from "./webp/Point_of_entry@2x.webp";
import sensor_1_v3_webp from "./webp/Point_of_entry@3x.webp";

import sensor_1_png from "./png/Point_of_entry.png";
import sensor_1_full_png from "./png/Full_Point_of_entry.png";
import sensor_1_v2_png from "./png/Point_of_entry@2x.png";
import sensor_1_v3_png from "./png/Point_of_entry@3x.png";

import sensor_2_webp from "./webp/Backwashfilter.webp";
import sensor_2_full_webp from "./webp/Full_Backwashfilter.webp";
import sensor_2_v2_webp from "./webp/Backwashfilter@2x.webp";
import sensor_2_v3_webp from "./webp/Backwashfilter@3x.webp";

import sensor_2_png from "./png/Backwashfilter.png";
import sensor_2_full_png from "./png/Full_Backwashfilter.png";
import sensor_2_v2_png from "./png/Backwashfilter@2x.png";
import sensor_2_v3_png from "./png/Backwashfilter@3x.png";

import sensor_3_webp from "./webp/Heizkessel.webp";
import sensor_3_full_webp from "./webp/Full_Heizkessel.webp";
import sensor_3_v2_webp from "./webp/Heizkessel@2x.webp";
import sensor_3_v3_webp from "./webp/Heizkessel@3x.webp";

import sensor_3_png from "./png/Heizkessel.png";
import sensor_3_full_png from "./png/Full_Heizkessel.png";
import sensor_3_v2_png from "./png/Heizkessel@2x.png";
import sensor_3_v3_png from "./png/Heizkessel@3x.png";

import sensor_4_webp from "./webp/Pump.webp";
import sensor_4_full_webp from "./webp/Full_Pump.webp";
import sensor_4_v2_webp from "./webp/Pump@2x.webp";
import sensor_4_v3_webp from "./webp/Pump@3x.webp";

import sensor_4_png from "./png/Pump.png";
import sensor_4_full_png from "./png/Full_Pump.png";
import sensor_4_v2_png from "./png/Pump@2x.png";
import sensor_4_v3_png from "./png/Pump@3x.png";

import sensor_5_webp from "./webp/Warmwasserbereiter.webp";
import sensor_5_full_webp from "./webp/Full_Warmwasserbereiter.webp";
import sensor_5_v2_webp from "./webp/Warmwasserbereiter@2x.webp";
import sensor_5_v3_webp from "./webp/Warmwasserbereiter@3x.webp";

import sensor_5_png from "./png/Warmwasserbereiter.png";
import sensor_5_full_png from "./png/Full_Warmwasserbereiter.png";
import sensor_5_v2_png from "./png/Warmwasserbereiter@2x.png";
import sensor_5_v3_png from "./png/Warmwasserbereiter@3x.png";

import sensor_6_webp from "./webp/DistrictHeating.webp";
import sensor_6_full_webp from "./webp/Full_DistrictHeating.webp";
import sensor_6_v2_webp from "./webp/DistrictHeating@2x.webp";
import sensor_6_v3_webp from "./webp/DistrictHeating@3x.webp";

import sensor_6_png from "./png/DistrictHeating.png";
import sensor_6_full_png from "./png/Full_DistrictHeating.png";
import sensor_6_v2_png from "./png/DistrictHeating@2x.png";
import sensor_6_v3_png from "./png/DistrictHeating@3x.png";

import sensor_7_webp from "./webp/Warmetauscher.webp";
import sensor_7_full_webp from "./webp/Full_Warmetauscher.webp";
import sensor_7_v2_webp from "./webp/Warmetauscher@2x.webp";
import sensor_7_v3_webp from "./webp/Warmetauscher@3x.webp";

import sensor_7_png from "./png/Warmetauscher.png";
import sensor_7_full_png from "./png/Full_Warmetauscher.png";
import sensor_7_v2_png from "./png/Warmetauscher@2x.png";
import sensor_7_v3_png from "./png/Warmetauscher@3x.png";



const sDefault = {
    webp: {
        pointOfEntry: [sensor_1_webp, sensor_1_v2_webp, sensor_1_v3_webp],
        fullPointOfEntry: [sensor_1_full_webp, sensor_1_v2_webp, sensor_1_v3_webp],
        backwashfilter: [sensor_2_webp, sensor_2_v2_webp, sensor_2_v3_webp],
        fullBackwashfilter: [sensor_2_full_webp, sensor_2_v2_webp, sensor_2_v3_webp],
        heizkessel: [sensor_3_webp, sensor_3_v2_webp, sensor_3_v3_webp],
        fullHeizkessel: [sensor_3_full_webp, sensor_3_v2_webp, sensor_3_v3_webp],
        pump: [sensor_4_webp, sensor_4_v2_webp, sensor_4_v3_webp],
        fullPump: [sensor_4_full_webp, sensor_4_v2_webp, sensor_4_v3_webp],
        warmwasserbereiter: [sensor_5_webp, sensor_5_v2_webp, sensor_5_v3_webp],
        fullWarmwasserbereiter: [sensor_5_full_webp, sensor_5_v2_webp, sensor_5_v3_webp],
        fernwarme: [sensor_6_webp, sensor_6_v2_webp, sensor_6_v3_webp],
        fullfernwarme: [sensor_6_full_webp, sensor_6_v2_webp, sensor_6_v3_webp],
        warmetauscher: [sensor_7_webp, sensor_7_v2_webp, sensor_7_v3_webp],
        fullwarmetauscher: [sensor_7_full_webp, sensor_7_v2_webp, sensor_7_v3_webp]
    },
    png: {
        pointOfEntry: [sensor_1_png, sensor_1_v2_png, sensor_1_v3_png],
        fullPointOfEntry: [sensor_1_full_png, sensor_1_v2_png, sensor_1_v3_png],
        backwashfilter: [sensor_2_png, sensor_2_v2_png, sensor_2_v3_png],
        fullBackwashfilter: [sensor_2_full_png, sensor_2_v2_png, sensor_2_v3_png],
        heizkessel: [sensor_3_png, sensor_3_v2_png, sensor_3_v3_png],
        fullHeizkessel: [sensor_3_full_png, sensor_3_v2_png, sensor_3_v3_png],
        pump: [sensor_4_png, sensor_4_v2_png, sensor_4_v3_png],
        fullPump: [sensor_4_full_png, sensor_4_v2_png, sensor_4_v3_png],
        warmwasserbereiter: [sensor_5_png, sensor_5_v2_png, sensor_5_v3_png],
        fullWarmwasserbereiter: [sensor_5_full_png, sensor_5_v2_png, sensor_5_v3_png],
        fernwarme: [sensor_6_png, sensor_6_v2_png, sensor_6_v3_png],
        fullfernwarme: [sensor_6_full_png, sensor_6_v2_png, sensor_6_v3_png],
        warmetauscher: [sensor_7_png, sensor_7_v2_png, sensor_7_v3_png],
        fullwarmetauscher: [sensor_7_full_png, sensor_7_v2_png, sensor_7_v3_png]
    }
}

export { sDefault };