import * as types from '../types/user';

export const SignIn = (payload, cb) => ({ type: types.SIGN_IN, payload, cb });
export const SignOut = (payload, cb) => ({ type: types.SIGN_OUT, payload, cb });
export const RemoveAuth = (payload) => ({ type: types.REMOVE_AUTH, payload});

export const checkConnection = (payload) => ({ type: types.CHECK_CONNECTION, payload });

export const setSocketConnection = (payload) => ({ type: types.SOCKET_CONNECTION, payload });

export const resetAllUser = (payload) => ({ type: types.RESET_ALL_USER, payload });


