import sensor_114_webp from "./webp/Hauswasserzahler.webp";
import sensor_114_v2_webp from "./webp/Hauswasserzahler@2x.webp";
import sensor_114_v3_webp from "./webp/Hauswasserzahler@3x.webp";
import sensor_115_webp from "./webp/Ruckspulfilter.webp";
import sensor_115_v2_webp from "./webp/Ruckspulfilter@2x.webp";
import sensor_115_v3_webp from "./webp/Ruckspulfilter@3x.webp";
import sensor_116_webp from "./webp/Zirkulationspumpe.webp";
import sensor_116_v2_webp from "./webp/Zirkulationspumpe@2x.webp";
import sensor_116_v3_webp from "./webp/Zirkulationspumpe@3x.webp";
import sensor_117_webp from "./webp/Warmwasserspeicher.webp";
import sensor_117_v2_webp from "./webp/Warmwasserspeicher@2x.webp";
import sensor_117_v3_webp from "./webp/Warmwasserspeicher@3x.webp";
import sensor_118_webp from "./webp/Heizkreise.webp";
import sensor_118_v2_webp from "./webp/Heizkreise@2x.webp";
import sensor_118_v3_webp from "./webp/Heizkreise@3x.webp";
import sensor_119_webp from "./webp/Heizkreispumpe.webp";
import sensor_119_v2_webp from "./webp/Heizkreispumpe@2x.webp";
import sensor_119_v3_webp from "./webp/Heizkreispumpe@3x.webp";
import sensor_120_webp from "./webp/Speicherladepumpe.webp";
import sensor_120_v2_webp from "./webp/Speicherladepumpe@2x.webp";
import sensor_120_v3_webp from "./webp/Speicherladepumpe@3x.webp";
import sensor_121_webp from "./webp/Heizkessel.webp";
import sensor_121_v2_webp from "./webp/Heizkessel@2x.webp";
import sensor_121_v3_webp from "./webp/Heizkessel@3x.webp";
import sensor_122_webp from "./webp/Warmetauscher.webp";
import sensor_122_v2_webp from "./webp/Warmetauscher@2x.webp";
import sensor_122_v3_webp from "./webp/Warmetauscher@3x.webp";

import sensor_114_jpg from "./jpg/Hauswasserzahler.jpg";
import sensor_114_v2_jpg from "./jpg/Hauswasserzahler@2x.jpg";
import sensor_114_v3_jpg from "./jpg/Hauswasserzahler@3x.jpg";
import sensor_115_jpg from "./jpg/Ruckspulfilter.jpg";
import sensor_115_v2_jpg from "./jpg/Ruckspulfilter@2x.jpg";
import sensor_115_v3_jpg from "./jpg/Ruckspulfilter@3x.jpg";
import sensor_116_jpg from "./jpg/Zirkulationspumpe.jpg";
import sensor_116_v2_jpg from "./jpg/Zirkulationspumpe@2x.jpg";
import sensor_116_v3_jpg from "./jpg/Zirkulationspumpe@3x.jpg";
import sensor_117_jpg from "./jpg/Warmwasserspeicher.jpg";
import sensor_117_v2_jpg from "./jpg/Warmwasserspeicher@2x.jpg";
import sensor_117_v3_jpg from "./jpg/Warmwasserspeicher@3x.jpg";
import sensor_118_jpg from "./jpg/Heizkreise.jpg";
import sensor_118_v2_jpg from "./jpg/Heizkreise@2x.jpg";
import sensor_118_v3_jpg from "./jpg/Heizkreise@3x.jpg";
import sensor_119_jpg from "./jpg/Heizkreispumpe.jpg";
import sensor_119_v2_jpg from "./jpg/Heizkreispumpe@2x.jpg";
import sensor_119_v3_jpg from "./jpg/Heizkreispumpe@3x.jpg";
import sensor_120_jpg from "./jpg/Speicherladepumpe.jpg";
import sensor_120_v2_jpg from "./jpg/Speicherladepumpe@2x.jpg";
import sensor_120_v3_jpg from "./jpg/Speicherladepumpe@3x.jpg";
import sensor_121_jpg from "./jpg/Heizkessel.jpg";
import sensor_121_v2_jpg from "./jpg/Heizkessel@2x.jpg";
import sensor_121_v3_jpg from "./jpg/Heizkessel@3x.jpg";
import sensor_122_jpg from "./jpg/Warmetauscher.jpg";
import sensor_122_v2_jpg from "./jpg/Warmetauscher@2x.jpg";
import sensor_122_v3_jpg from "./jpg/Warmetauscher@3x.jpg";


const sDerfflinger = {
    webp: {
        s_114: [sensor_114_webp, sensor_114_v2_webp, sensor_114_v3_webp],
        s_115: [sensor_115_webp, sensor_115_v2_webp, sensor_115_v3_webp],
        s_116: [sensor_116_webp, sensor_116_v2_webp, sensor_116_v3_webp],
        s_117: [sensor_117_webp, sensor_117_v2_webp, sensor_117_v3_webp],
        s_118: [sensor_118_webp, sensor_118_v2_webp, sensor_118_v3_webp],
        s_119: [sensor_119_webp, sensor_119_v2_webp, sensor_119_v3_webp],
        s_120: [sensor_120_webp, sensor_120_v2_webp, sensor_120_v3_webp],
        s_121: [sensor_121_webp, sensor_121_v2_webp, sensor_121_v3_webp],
        s_122: [sensor_122_webp, sensor_122_v2_webp, sensor_122_v3_webp]
    },
    jpg: {
        s_114: [sensor_114_jpg, sensor_114_v2_jpg, sensor_114_v3_jpg],
        s_115: [sensor_115_jpg, sensor_115_v2_jpg, sensor_115_v3_jpg],
        s_116: [sensor_116_jpg, sensor_116_v2_jpg, sensor_116_v3_jpg],
        s_117: [sensor_117_jpg, sensor_117_v2_jpg, sensor_117_v3_jpg],
        s_118: [sensor_118_jpg, sensor_118_v2_jpg, sensor_118_v3_jpg],
        s_119: [sensor_119_jpg, sensor_119_v2_jpg, sensor_119_v3_jpg],
        s_120: [sensor_120_jpg, sensor_120_v2_jpg, sensor_120_v3_jpg],
        s_121: [sensor_121_jpg, sensor_121_v2_jpg, sensor_121_v3_jpg],
        s_122: [sensor_122_jpg, sensor_122_v2_jpg, sensor_122_v3_jpg]
    }
}

export { sDerfflinger };