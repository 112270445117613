import img_1_v2_Hauswasserzahler_webp from "./details/01_Hauswasserzahler/webp/Hauswasserzahler@2x.webp";
import img_1_v3_Hauswasserzahler_webp from "./details/01_Hauswasserzahler/webp/Hauswasserzahler@3x.webp";
import img_2_v2_Hauswasserzahler_webp from "./details/01_Hauswasserzahler/webp/Hauswasserzahler_1@2x.webp";
import img_2_v3_Hauswasserzahler_webp from "./details/01_Hauswasserzahler/webp/Hauswasserzahler_1@3x.webp";
import img_3_v2_Hauswasserzahler_webp from "./details/01_Hauswasserzahler/webp/Hauswasserzahler_2@2x.webp";
import img_3_v3_Hauswasserzahler_webp from "./details/01_Hauswasserzahler/webp/Hauswasserzahler_2@3x.webp";

import img_1_v2_Zirkulationspumpe_webp from "./details/02_Zirculationspumpe/webp/Zirculationspumpe@2x.webp";
import img_1_v3_Zirkulationspumpe_webp from "./details/02_Zirculationspumpe/webp/Zirculationspumpe@3x.webp";
import img_2_v2_Zirkulationspumpe_webp from "./details/02_Zirculationspumpe/webp/Zirculationspumpe_1@2x.webp";
import img_2_v3_Zirkulationspumpe_webp from "./details/02_Zirculationspumpe/webp/Zirculationspumpe_1@3x.webp";

import img_1_v2_Warmwasserspeicher_webp from "./details/03_Warmwasserspeicher/webp/Warmwasserspeicher@2x.webp";
import img_1_v3_Warmwasserspeicher_webp from "./details/03_Warmwasserspeicher/webp/Warmwasserspeicher@3x.webp";
import img_2_v2_Warmwasserspeicher_webp from "./details/03_Warmwasserspeicher/webp/Warmwasserspeicher_1@2x.webp";
import img_2_v3_Warmwasserspeicher_webp from "./details/03_Warmwasserspeicher/webp/Warmwasserspeicher_1@3x.webp";

import img_1_v2_Heizkreispumpe_1_webp from "./details/04_Heizkreispumpe/webp/Heizkreispumpe1@2x.webp";
import img_1_v3_Heizkreispumpe_1_webp from "./details/04_Heizkreispumpe/webp/Heizkreispumpe1@3x.webp";
import img_2_v2_Heizkreispumpe_1_webp from "./details/04_Heizkreispumpe/webp/Heizkreispumpe1_1@2x.webp";
import img_2_v3_Heizkreispumpe_1_webp from "./details/04_Heizkreispumpe/webp/Heizkreispumpe1_1@3x.webp";
import img_1_v2_Heizkreispumpe_2_webp from "./details/04_Heizkreispumpe/webp/Heizkreispumpe2@2x.webp";
import img_1_v3_Heizkreispumpe_2_webp from "./details/04_Heizkreispumpe/webp/Heizkreispumpe2@3x.webp";
import img_2_v3_Heizkreispumpe_2_webp from "./details/04_Heizkreispumpe/webp/Heizkreispumpe2_1@3x.webp";
import img_2_v2_Heizkreispumpe_2_webp from "./details/04_Heizkreispumpe/webp/Heizkreispumpe2_1@2x.webp";
import img_1_v2_Heizkreispumpe_3_webp from "./details/04_Heizkreispumpe/webp/Heizkreispumpe3@2x.webp";
import img_1_v3_Heizkreispumpe_3_webp from "./details/04_Heizkreispumpe/webp/Heizkreispumpe3@3x.webp";
import img_2_v2_Heizkreispumpe_3_webp from "./details/04_Heizkreispumpe/webp/Heizkreispumpe3_1@2x.webp";
import img_2_v3_Heizkreispumpe_3_webp from "./details/04_Heizkreispumpe/webp/Heizkreispumpe3_1@3x.webp";

import img_1_v2_Speicherladepumpe_webp from "./details/05_Speicherladepumpe/webp/Speicherladepumpe@2x.webp";
import img_1_v3_Speicherladepumpe_webp from "./details/05_Speicherladepumpe/webp/Speicherladepumpe@3x.webp";
import img_2_v2_Speicherladepumpe_webp from "./details/05_Speicherladepumpe/webp/Speicherladepumpe_1@2x.webp";
import img_2_v3_Speicherladepumpe_webp from "./details/05_Speicherladepumpe/webp/Speicherladepumpe_1@3x.webp";

import img_1_v2_Frischwasserstation_webp from "./details/06_Frischwasserstation/webp/Frischwasserstation@2x.webp";
import img_1_v3_Frischwasserstation_webp from "./details/06_Frischwasserstation/webp/Frischwasserstation@3x.webp";
import img_2_v2_Frischwasserstation_webp from "./details/06_Frischwasserstation/webp/Frischwasserstation_1@2x.webp";
import img_2_v3_Frischwasserstation_webp from "./details/06_Frischwasserstation/webp/Frischwasserstation_1@3x.webp";
import img_3_v2_Frischwasserstation_webp from "./details/06_Frischwasserstation/webp/Frischwasserstation_2@2x.webp";
import img_3_v3_Frischwasserstation_webp from "./details/06_Frischwasserstation/webp/Frischwasserstation_2@3x.webp";
import img_4_v2_Frischwasserstation_webp from "./details/06_Frischwasserstation/webp/Frischwasserstation_3@2x.webp";
import img_4_v3_Frischwasserstation_webp from "./details/06_Frischwasserstation/webp/Frischwasserstation_3@3x.webp";
import img_5_v2_Frischwasserstation_webp from "./details/06_Frischwasserstation/webp/Frischwasserstation_4@2x.webp";
import img_5_v3_Frischwasserstation_webp from "./details/06_Frischwasserstation/webp/Frischwasserstation_4@3x.webp";

import img_1_v2_Hauswasserzahler_jpg from "./details/01_Hauswasserzahler/jpg/Hauswasserzahler@2x.jpg";
import img_1_v3_Hauswasserzahler_jpg from "./details/01_Hauswasserzahler/jpg/Hauswasserzahler@3x.jpg";
import img_2_v2_Hauswasserzahler_jpg from "./details/01_Hauswasserzahler/jpg/Hauswasserzahler_1@2x.jpg";
import img_2_v3_Hauswasserzahler_jpg from "./details/01_Hauswasserzahler/jpg/Hauswasserzahler_1@3x.jpg";
import img_3_v2_Hauswasserzahler_jpg from "./details/01_Hauswasserzahler/jpg/Hauswasserzahler_2@2x.jpg";
import img_3_v3_Hauswasserzahler_jpg from "./details/01_Hauswasserzahler/jpg/Hauswasserzahler_2@3x.jpg";

import img_1_v2_Zirkulationspumpe_jpg from "./details/02_Zirculationspumpe/jpg/Zirculationspumpe@2x.jpg";
import img_1_v3_Zirkulationspumpe_jpg from "./details/02_Zirculationspumpe/jpg/Zirculationspumpe@3x.jpg";
import img_2_v2_Zirkulationspumpe_jpg from "./details/02_Zirculationspumpe/jpg/Zirculationspumpe_1@2x.jpg";
import img_2_v3_Zirkulationspumpe_jpg from "./details/02_Zirculationspumpe/jpg/Zirculationspumpe_1@3x.jpg";

import img_1_v2_Warmwasserspeicher_jpg from "./details/03_Warmwasserspeicher/jpg/Warmwasserspeicher@2x.jpg";
import img_1_v3_Warmwasserspeicher_jpg from "./details/03_Warmwasserspeicher/jpg/Warmwasserspeicher@3x.jpg";
import img_2_v2_Warmwasserspeicher_jpg from "./details/03_Warmwasserspeicher/jpg/Warmwasserspeicher_1@2x.jpg";
import img_2_v3_Warmwasserspeicher_jpg from "./details/03_Warmwasserspeicher/jpg/Warmwasserspeicher_1@3x.jpg";

import img_1_v2_Heizkreispumpe_1_jpg from "./details/04_Heizkreispumpe/jpg/Heizkreispumpe1@2x.jpg";
import img_1_v3_Heizkreispumpe_1_jpg from "./details/04_Heizkreispumpe/jpg/Heizkreispumpe1@3x.jpg";
import img_2_v2_Heizkreispumpe_1_jpg from "./details/04_Heizkreispumpe/jpg/Heizkreispumpe1_1@2x.jpg";
import img_2_v3_Heizkreispumpe_1_jpg from "./details/04_Heizkreispumpe/jpg/Heizkreispumpe1_1@3x.jpg";
import img_1_v2_Heizkreispumpe_2_jpg from "./details/04_Heizkreispumpe/jpg/Heizkreispumpe2@2x.jpg";
import img_1_v3_Heizkreispumpe_2_jpg from "./details/04_Heizkreispumpe/jpg/Heizkreispumpe2@3x.jpg";
import img_2_v3_Heizkreispumpe_2_jpg from "./details/04_Heizkreispumpe/jpg/Heizkreispumpe2_1@3x.jpg";
import img_2_v2_Heizkreispumpe_2_jpg from "./details/04_Heizkreispumpe/jpg/Heizkreispumpe2_1@2x.jpg";
import img_1_v2_Heizkreispumpe_3_jpg from "./details/04_Heizkreispumpe/jpg/Heizkreispumpe3@2x.jpg";
import img_1_v3_Heizkreispumpe_3_jpg from "./details/04_Heizkreispumpe/jpg/Heizkreispumpe3@3x.jpg";
import img_2_v2_Heizkreispumpe_3_jpg from "./details/04_Heizkreispumpe/jpg/Heizkreispumpe3_1@2x.jpg";
import img_2_v3_Heizkreispumpe_3_jpg from "./details/04_Heizkreispumpe/jpg/Heizkreispumpe3_1@3x.jpg";

import img_1_v2_Speicherladepumpe_jpg from "./details/05_Speicherladepumpe/jpg/Speicherladepumpe@2x.jpg";
import img_1_v3_Speicherladepumpe_jpg from "./details/05_Speicherladepumpe/jpg/Speicherladepumpe@3x.jpg";
import img_2_v2_Speicherladepumpe_jpg from "./details/05_Speicherladepumpe/jpg/Speicherladepumpe_1@2x.jpg";
import img_2_v3_Speicherladepumpe_jpg from "./details/05_Speicherladepumpe/jpg/Speicherladepumpe_1@3x.jpg";

import img_1_v2_Frischwasserstation_jpg from "./details/06_Frischwasserstation/jpg/Frischwasserstation@2x.jpg";
import img_1_v3_Frischwasserstation_jpg from "./details/06_Frischwasserstation/jpg/Frischwasserstation@3x.jpg";
import img_2_v2_Frischwasserstation_jpg from "./details/06_Frischwasserstation/jpg/Frischwasserstation_1@2x.jpg";
import img_2_v3_Frischwasserstation_jpg from "./details/06_Frischwasserstation/jpg/Frischwasserstation_1@3x.jpg";
import img_3_v2_Frischwasserstation_jpg from "./details/06_Frischwasserstation/jpg/Frischwasserstation_2@2x.jpg";
import img_3_v3_Frischwasserstation_jpg from "./details/06_Frischwasserstation/jpg/Frischwasserstation_2@3x.jpg";
import img_4_v2_Frischwasserstation_jpg from "./details/06_Frischwasserstation/jpg/Frischwasserstation_3@2x.jpg";
import img_4_v3_Frischwasserstation_jpg from "./details/06_Frischwasserstation/jpg/Frischwasserstation_3@3x.jpg";
import img_5_v2_Frischwasserstation_jpg from "./details/06_Frischwasserstation/jpg/Frischwasserstation_4@2x.jpg";
import img_5_v3_Frischwasserstation_jpg from "./details/06_Frischwasserstation/jpg/Frischwasserstation_4@3x.jpg";

const detailsBrusseler = {
    'Hauswasserzahler': {
        webp: {
            img_1: [img_1_v2_Hauswasserzahler_webp, img_1_v3_Hauswasserzahler_webp],
            img_2: [img_2_v2_Hauswasserzahler_webp, img_2_v3_Hauswasserzahler_webp],
            img_3: [img_3_v2_Hauswasserzahler_webp, img_3_v3_Hauswasserzahler_webp]
        },
        jpg: {
            img_1: [img_1_v2_Hauswasserzahler_jpg, img_1_v3_Hauswasserzahler_jpg],
            img_2: [img_2_v2_Hauswasserzahler_jpg, img_2_v3_Hauswasserzahler_jpg],
            img_3: [img_3_v2_Hauswasserzahler_jpg, img_3_v3_Hauswasserzahler_jpg]
        }
    },
    'Frischwasserstation': {
        webp: {
            img_1: [img_1_v2_Frischwasserstation_webp, img_1_v3_Frischwasserstation_webp],
            img_2: [img_2_v2_Frischwasserstation_webp, img_2_v3_Frischwasserstation_webp],
            img_3: [img_3_v2_Frischwasserstation_webp, img_3_v3_Frischwasserstation_webp],
            img_4: [img_4_v2_Frischwasserstation_webp, img_4_v3_Frischwasserstation_webp],
            img_5: [img_5_v2_Frischwasserstation_webp, img_5_v3_Frischwasserstation_webp]
        },
        jpg: {
            img_1: [img_1_v2_Frischwasserstation_jpg, img_1_v3_Frischwasserstation_jpg],
            img_2: [img_2_v2_Frischwasserstation_jpg, img_2_v3_Frischwasserstation_jpg],
            img_3: [img_3_v2_Frischwasserstation_jpg, img_3_v3_Frischwasserstation_jpg],
            img_4: [img_4_v2_Frischwasserstation_jpg, img_4_v3_Frischwasserstation_jpg],
            img_5: [img_5_v2_Frischwasserstation_jpg, img_5_v3_Frischwasserstation_jpg]
        }
    },
    'Speicherladepumpe': {
        webp: {
            img_1: [img_1_v2_Speicherladepumpe_webp, img_1_v3_Speicherladepumpe_webp],
            img_2: [img_2_v2_Speicherladepumpe_webp, img_2_v3_Speicherladepumpe_webp]
        },
        jpg: {
            img_1: [img_1_v2_Speicherladepumpe_jpg, img_1_v3_Speicherladepumpe_jpg],
            img_2: [img_2_v2_Speicherladepumpe_jpg, img_2_v3_Speicherladepumpe_jpg]
        }
    },
    'Zirkulationpumpe': {
        webp: {
            img_1: [img_1_v2_Zirkulationspumpe_webp, img_1_v3_Zirkulationspumpe_webp],
            img_2: [img_2_v2_Zirkulationspumpe_webp, img_2_v3_Zirkulationspumpe_webp]
        },
        jpg: {
            img_1: [img_1_v2_Zirkulationspumpe_jpg, img_1_v3_Zirkulationspumpe_jpg],
            img_2: [img_2_v2_Zirkulationspumpe_jpg, img_2_v3_Zirkulationspumpe_jpg]
        }
    },
    'Heizkreispumpe': {
        'Heizkreispumpe_1': {
            webp: {
                img_1: [img_1_v2_Heizkreispumpe_1_webp, img_1_v3_Heizkreispumpe_1_webp],
                img_2: [img_2_v2_Heizkreispumpe_1_webp, img_2_v3_Heizkreispumpe_1_webp]
            },
            jpg: {
                img_1: [img_1_v2_Heizkreispumpe_1_jpg, img_1_v3_Heizkreispumpe_1_jpg],
                img_2: [img_2_v2_Heizkreispumpe_1_jpg, img_2_v3_Heizkreispumpe_1_jpg]
            }
        },
        'Heizkreispumpe_2': {
            webp: {
                img_1: [img_1_v2_Heizkreispumpe_2_webp, img_1_v3_Heizkreispumpe_2_webp],
                img_2: [img_2_v2_Heizkreispumpe_2_webp, img_2_v3_Heizkreispumpe_2_webp]
            },
            jpg: {
                img_1: [img_1_v2_Heizkreispumpe_2_jpg, img_1_v3_Heizkreispumpe_2_jpg],
                img_2: [img_2_v2_Heizkreispumpe_2_jpg, img_2_v3_Heizkreispumpe_2_jpg]
            }
        },
        'Heizkreispumpe_3': {
            webp: {
                img_1: [img_1_v2_Heizkreispumpe_3_webp, img_1_v3_Heizkreispumpe_3_webp],
                img_2: [img_2_v2_Heizkreispumpe_3_webp, img_2_v3_Heizkreispumpe_3_webp]
            },
            jpg: {
                img_1: [img_1_v2_Heizkreispumpe_3_jpg, img_1_v3_Heizkreispumpe_3_jpg],
                img_2: [img_2_v2_Heizkreispumpe_3_jpg, img_2_v3_Heizkreispumpe_3_jpg]
            }
        }
    },
    'Warmwasserspeicher': {
        webp: {
            img_1: [img_1_v2_Warmwasserspeicher_webp, img_1_v3_Warmwasserspeicher_webp],
            img_2: [img_2_v2_Warmwasserspeicher_webp, img_2_v3_Warmwasserspeicher_webp]
        },
        jpg: {
            img_1: [img_1_v2_Warmwasserspeicher_jpg, img_1_v3_Warmwasserspeicher_jpg],
            img_2: [img_2_v2_Warmwasserspeicher_jpg, img_2_v3_Warmwasserspeicher_jpg]
        }
    }
}

export { detailsBrusseler };