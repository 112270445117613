import img_1_v2_kaltwassereingang_webp from "./01_Katlwassereingang/webp/Kaltwassereingang@2x.webp";
import img_1_v3_kaltwassereingang_webp from "./01_Katlwassereingang/webp/Kaltwassereingang@3x.webp";
import img_2_v2_kaltwassereingang_webp from "./01_Katlwassereingang/webp/Kaltwassereingang_1@2x.webp";
import img_2_v3_kaltwassereingang_webp from "./01_Katlwassereingang/webp/Kaltwassereingang_1@3x.webp";

import img_1_v2_kaltwassereingang_jpg from "./01_Katlwassereingang/jpg/Kaltwassereingang@2x.jpg";
import img_1_v3_kaltwassereingang_jpg from "./01_Katlwassereingang/jpg/Kaltwassereingang@3x.jpg";
import img_2_v2_kaltwassereingang_jpg from "./01_Katlwassereingang/jpg/Kaltwassereingang_1@2x.jpg";
import img_2_v3_kaltwassereingang_jpg from "./01_Katlwassereingang/jpg/Kaltwassereingang_1@3x.jpg";

import img_1_v2_ruckspulfilter_webp from "./02_Ruckspulfilter/webp/Ruckspulfilter@2x.webp";
import img_1_v3_ruckspulfilter_webp from "./02_Ruckspulfilter/webp/Ruckspulfilter@3x.webp";
import img_2_v2_ruckspulfilter_webp from "./02_Ruckspulfilter/webp/Ruckspulfilter_1@2x.webp";
import img_2_v3_ruckspulfilter_webp from "./02_Ruckspulfilter/webp/Ruckspulfilter_1@3x.webp";

import img_1_v2_ruckspulfilter_jpg from "./02_Ruckspulfilter/jpg/Ruckspulfilter@2x.jpg";
import img_1_v3_ruckspulfilter_jpg from "./02_Ruckspulfilter/jpg/Ruckspulfilter@3x.jpg";
import img_2_v2_ruckspulfilter_jpg from "./02_Ruckspulfilter/jpg/Ruckspulfilter_1@2x.jpg";
import img_2_v3_ruckspulfilter_jpg from "./02_Ruckspulfilter/jpg/Ruckspulfilter_1@3x.jpg";

import img_1_v2_fernwarme_webp from "./03_Fernwarme/webp/Fernwarme@2x.webp";
import img_1_v3_fernwarme_webp from "./03_Fernwarme/webp/Fernwarme@3x.webp";

import img_1_v2_fernwarme_jpg from "./03_Fernwarme/jpg/Fernwarme@2x.jpg";
import img_1_v3_fernwarme_jpg from "./03_Fernwarme/jpg/Fernwarme@3x.jpg";

import img_1_v2_warmetauscher_webp from "./04_Warmetauscher/webp/Warmetauscher@2x.webp";
import img_1_v3_warmetauscher_webp from "./04_Warmetauscher/webp/Warmetauscher@3x.webp";
import img_2_v2_warmetauscher_webp from "./04_Warmetauscher/webp/Warmetauscher_1@2x.webp";
import img_2_v3_warmetauscher_webp from "./04_Warmetauscher/webp/Warmetauscher_1@3x.webp";
import img_3_v2_warmetauscher_webp from "./04_Warmetauscher/webp/Warmetauscher_2@2x.webp";
import img_3_v3_warmetauscher_webp from "./04_Warmetauscher/webp/Warmetauscher_2@3x.webp";

import img_1_v2_warmetauscher_jpg from "./04_Warmetauscher/jpg/Warmetauscher@2x.jpg";
import img_1_v3_warmetauscher_jpg from "./04_Warmetauscher/jpg/Warmetauscher@3x.jpg";
import img_2_v2_warmetauscher_jpg from "./04_Warmetauscher/jpg/Warmetauscher_1@2x.jpg";
import img_2_v3_warmetauscher_jpg from "./04_Warmetauscher/jpg/Warmetauscher_1@3x.jpg";
import img_3_v2_warmetauscher_jpg from "./04_Warmetauscher/jpg/Warmetauscher_2@2x.jpg";
import img_3_v3_warmetauscher_jpg from "./04_Warmetauscher/jpg/Warmetauscher_2@3x.jpg";

import img_1_v2_heizkreispumpe_1_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe1@2x.webp";
import img_1_v3_heizkreispumpe_1_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe1@3x.webp";
import img_2_v2_heizkreispumpe_1_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe1_1@2x.webp";
import img_2_v3_heizkreispumpe_1_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe1_1@3x.webp";
import img_1_v2_heizkreispumpe_2_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe2@2x.webp";
import img_1_v3_heizkreispumpe_2_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe2@3x.webp";
import img_2_v2_heizkreispumpe_2_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe2_1@2x.webp";
import img_2_v3_heizkreispumpe_2_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe2_1@3x.webp";
import img_1_v2_heizkreispumpe_3_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe3@2x.webp";
import img_1_v3_heizkreispumpe_3_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe3@3x.webp";
import img_2_v2_heizkreispumpe_3_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe3_1@2x.webp";
import img_2_v3_heizkreispumpe_3_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe3_1@3x.webp";
import img_1_v2_heizkreispumpe_4_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe4@2x.webp";
import img_1_v3_heizkreispumpe_4_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe4@3x.webp";
import img_2_v2_heizkreispumpe_4_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe4_1@2x.webp";
import img_2_v3_heizkreispumpe_4_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe4_1@3x.webp";

import img_1_v2_heizkreispumpe_1_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe1@2x.jpg";
import img_1_v3_heizkreispumpe_1_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe1@3x.jpg";
import img_2_v2_heizkreispumpe_1_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe1_1@2x.jpg";
import img_2_v3_heizkreispumpe_1_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe1_1@3x.jpg";
import img_1_v2_heizkreispumpe_2_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe2@2x.jpg";
import img_1_v3_heizkreispumpe_2_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe2@3x.jpg";
import img_2_v2_heizkreispumpe_2_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe2_1@2x.jpg";
import img_2_v3_heizkreispumpe_2_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe2_1@3x.jpg";
import img_1_v2_heizkreispumpe_3_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe3@2x.jpg";
import img_1_v3_heizkreispumpe_3_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe3@3x.jpg";
import img_2_v2_heizkreispumpe_3_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe3_1@2x.jpg";
import img_2_v3_heizkreispumpe_3_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe3_1@3x.jpg";
import img_1_v2_heizkreispumpe_4_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe4@2x.jpg";
import img_1_v3_heizkreispumpe_4_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe4@3x.jpg";
import img_2_v2_heizkreispumpe_4_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe4_1@2x.jpg";
import img_2_v3_heizkreispumpe_4_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe4_1@3x.jpg";

import img_1_v2_speicherladepumpe_webp from "./06_Speicherladepumpe/webp/Speicherladepumpe@2x.webp";
import img_1_v3_speicherladepumpe_webp from "./06_Speicherladepumpe/webp/Speicherladepumpe@3x.webp";

import img_1_v2_speicherladepumpe_jpg from "./06_Speicherladepumpe/jpg/Speicherladepumpe@2x.jpg";
import img_1_v3_speicherladepumpe_jpg from "./06_Speicherladepumpe/jpg/Speicherladepumpe@3x.jpg";

import img_1_v2_warmwasserspeicher_webp from "./07_Warmwasserspeicher/webp/Warmwasserspeicher@2x.webp";
import img_1_v3_warmwasserspeicher_webp from "./07_Warmwasserspeicher/webp/Warmwasserspeicher@3x.webp";
import img_2_v2_warmwasserspeicher_webp from "./07_Warmwasserspeicher/webp/Warmwasserspeicher_1@2x.webp";
import img_2_v3_warmwasserspeicher_webp from "./07_Warmwasserspeicher/webp/Warmwasserspeicher_1@3x.webp";

import img_1_v2_warmwasserspeicher_jpg from "./07_Warmwasserspeicher/jpg/Warmwasserspeicher@2x.jpg";
import img_1_v3_warmwasserspeicher_jpg from "./07_Warmwasserspeicher/jpg/Warmwasserspeicher@3x.jpg";
import img_2_v2_warmwasserspeicher_jpg from "./07_Warmwasserspeicher/jpg/Warmwasserspeicher_1@2x.jpg";
import img_2_v3_warmwasserspeicher_jpg from "./07_Warmwasserspeicher/jpg/Warmwasserspeicher_1@3x.jpg";

const images_Charlottenburgerstrabe = {
    'Kaltwassereingang': {
        webp: {
            img_1: [img_1_v2_kaltwassereingang_webp, img_1_v3_kaltwassereingang_webp],
            img_2: [img_2_v2_kaltwassereingang_webp, img_2_v3_kaltwassereingang_webp],
        },
        png: {
            img_1: [img_1_v2_kaltwassereingang_jpg, img_1_v3_kaltwassereingang_jpg],
            img_2: [img_2_v2_kaltwassereingang_jpg, img_2_v3_kaltwassereingang_jpg],
        }
    },
    'Ruckspulfilter': {
        webp: {
            img_1: [img_1_v2_ruckspulfilter_webp, img_1_v3_ruckspulfilter_webp],
            img_2: [img_2_v2_ruckspulfilter_webp, img_2_v3_ruckspulfilter_webp]
        },
        png: {
            img_1: [img_1_v2_ruckspulfilter_jpg, img_1_v3_ruckspulfilter_jpg],
            img_2: [img_2_v2_ruckspulfilter_jpg, img_2_v3_ruckspulfilter_jpg]
        }
    },
    'Fernwarme': {
        webp: {
            img_1: [img_1_v2_fernwarme_webp, img_1_v3_fernwarme_webp]
        },
        png: {
            img_1: [img_1_v2_fernwarme_jpg, img_1_v3_fernwarme_jpg]
        }
    },
    'Warmetauscher': {
        webp: {
            img_1: [img_1_v2_warmetauscher_webp, img_1_v3_warmetauscher_webp],
            img_2: [img_2_v2_warmetauscher_webp, img_2_v3_warmetauscher_webp],
            img_3: [img_3_v2_warmetauscher_webp, img_3_v3_warmetauscher_webp]
        },
        png: {
            img_1: [img_1_v2_warmetauscher_jpg, img_1_v3_warmetauscher_jpg],
            img_2: [img_2_v2_warmetauscher_jpg, img_2_v3_warmetauscher_jpg],
            img_3: [img_3_v2_warmetauscher_jpg, img_3_v3_warmetauscher_jpg]
        }
    },
    'Heizkreispumpe': {
        'Heizkreispumpe_1': {
            webp: {
                img_1: [img_1_v2_heizkreispumpe_1_webp, img_1_v3_heizkreispumpe_1_webp],
                img_2: [img_2_v2_heizkreispumpe_1_webp, img_2_v3_heizkreispumpe_1_webp]
            },
            png: {
                img_1: [img_1_v2_heizkreispumpe_1_jpg, img_1_v3_heizkreispumpe_1_jpg],
                img_2: [img_2_v2_heizkreispumpe_1_jpg, img_2_v3_heizkreispumpe_1_jpg]
            }
        },
        'Heizkreispumpe_2': {
            webp: {
                img_1: [img_1_v2_heizkreispumpe_2_webp, img_1_v3_heizkreispumpe_2_webp],
                img_2: [img_2_v2_heizkreispumpe_2_webp, img_2_v3_heizkreispumpe_2_webp]
            },
            png: {
                img_1: [img_1_v2_heizkreispumpe_2_jpg, img_1_v3_heizkreispumpe_2_jpg],
                img_2: [img_2_v2_heizkreispumpe_2_jpg, img_2_v3_heizkreispumpe_2_jpg]
            }
        },
        'Heizkreispumpe_3': {
            webp: {
                img_1: [img_1_v2_heizkreispumpe_3_webp, img_1_v3_heizkreispumpe_3_webp],
                img_2: [img_2_v2_heizkreispumpe_3_webp, img_2_v3_heizkreispumpe_3_webp]
            },
            png: {
                img_1: [img_1_v2_heizkreispumpe_3_jpg, img_1_v3_heizkreispumpe_3_jpg],
                img_2: [img_2_v2_heizkreispumpe_3_jpg, img_2_v3_heizkreispumpe_3_jpg]
            }
        },
        'Heizkreispumpe_4': {
            webp: {
                img_1: [img_1_v2_heizkreispumpe_4_webp, img_1_v3_heizkreispumpe_4_webp],
                img_2: [img_2_v2_heizkreispumpe_4_webp, img_2_v3_heizkreispumpe_4_webp]
            },
            png: {
                img_1: [img_1_v2_heizkreispumpe_4_jpg, img_1_v3_heizkreispumpe_4_jpg],
                img_2: [img_2_v2_heizkreispumpe_4_jpg, img_2_v3_heizkreispumpe_4_jpg]
            }
        }
    },
    'Speicherladepumpe': {
        webp: {
            img_1: [img_1_v2_speicherladepumpe_webp, img_1_v3_speicherladepumpe_webp]
        },
        png: {
            img_1: [img_1_v2_speicherladepumpe_jpg, img_1_v3_speicherladepumpe_jpg]
        }
    },
    'Warmwasserspeicher': {
        webp: {
            img_1: [img_1_v2_warmwasserspeicher_webp, img_1_v3_warmwasserspeicher_webp],
            img_2: [img_2_v2_warmwasserspeicher_webp, img_2_v3_warmwasserspeicher_webp]
        },
        png: {
            img_1: [img_1_v2_warmwasserspeicher_jpg, img_1_v3_warmwasserspeicher_jpg],
            img_2: [img_2_v2_warmwasserspeicher_jpg, img_2_v3_warmwasserspeicher_jpg]
        }
    },
}

export { images_Charlottenburgerstrabe };