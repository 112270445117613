import React, {useCallback, useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {SignOut, GetProfileInfo, resetAllProfile, resetAllProperties} from "../redux/actions";
import styles from '../styles/components/UserPanel.module.css'
import {useNavigate} from "react-router";
import {Button} from "react-bootstrap";
import ProfileImgSVG from '../icons/profile_image.svg'
import BellSVG from '../icons/bell-ringing.svg'
import WorldSVG from '../icons/world.svg'
import {Button as ButtonMUI, Popover, Avatar, Box} from "@mui/material";
import { useTranslation } from "react-i18next";
import {languages} from "../utils/constants";

import {getProfileImage} from "../redux/selectors";
import { urlBack } from "../utils/API";

const UserPanel = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const handleLogout = useCallback(() => {
    dispatch(SignOut({}, () => navigate('/login') ));
    dispatch(resetAllProfile());
    dispatch(resetAllProperties());
  }, [dispatch, navigate]);

  const handleProfile = useCallback(() => {
      handleCloseUser();
      navigate('/profile');
    }, [navigate]);

  const [anchorElInfo, setAnchorElInfo] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [avatar, setAvatar] = useState(ProfileImgSVG);
  const croppedImage = useSelector(getProfileImage);
  const openInfo = Boolean(anchorElInfo);
  const openUser = Boolean(anchorElUser);
  const handleClickInfo = (event) => setAnchorElInfo(event.currentTarget);
  const handleClickUser = (event) => setAnchorElUser(event.currentTarget);
  const handleCloseInfo = () => setAnchorElInfo(null);
  const handleCloseUser = () => setAnchorElUser(null);
  const changeLang = () => {
      const activeLangIndex = languages.findIndex(l => l === i18n.language);
      const nextLang = activeLangIndex === languages.length - 1 ? languages[0] : languages[activeLangIndex + 1]
      i18n.changeLanguage(nextLang)
  };

  const getSX = right => {
      return {
          position: "relative",
          mt: "9px",
          "&::before": {
              backgroundColor: "white",
              content: '""',
              display: "block",
              position: "absolute",
              width: 14,
              height: 14,
              top: -6,
              transform: "rotate(45deg)",
              right: right,
          }
      }
  }

   useEffect(() => {
    dispatch(GetProfileInfo())
   });

  useEffect(() => {
    if(croppedImage) setAvatar(`${urlBack}${croppedImage}`);
  }, [croppedImage]);

  return (
    <div className={[styles.container + ' flex-row']}>
        <Button aria-describedby={'info-menu'} variant="contained" onClick={handleClickInfo}>
            <img src={BellSVG} alt="" />
        </Button>
        <Popover
            id={'info-menu'}
            open={openInfo}
            anchorEl={anchorElInfo}
            onClose={handleCloseInfo}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            PaperProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    borderRadius: 0
                }
            }}
        >
            <Box sx={getSX("18px")} />
            <div className={`${styles.text_container} ${styles.popover_container}`}>
                <span>{t("userPanel.info")}</span>
            </div>
        </Popover>
        <Button variant="contained" className={styles.language} onClick={changeLang}>
            <img src={WorldSVG} alt="" />
            <span>{i18n.resolvedLanguage || '-'}</span>
        </Button>
        <Button
            id="user-button"
            variant="link"
            aria-controls={openUser ? 'user-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openUser ? 'true' : undefined}
            onClick={handleClickUser}
        >
            <Avatar alt="Avatar" src={avatar} className={styles.avatar} />
            {/* <img src={ProfileImgSVG} alt="" /> */}
        </Button>
        <Popover
            id={'user-menu'}
            open={openUser}
            anchorEl={anchorElUser}
            onClose={handleCloseUser}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            PaperProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    borderRadius: 0
                }
            }}
        >
            <Box sx={getSX("26px")} />
            <div className={styles.popover_container}>
                <div className={styles.link_container}>
                    <ButtonMUI className={styles.link} variant="text" onClick={handleProfile}>
                        {t("userPanel.profile")}
                    </ButtonMUI>
                </div>
                <div className={styles.button_container}>
                    <ButtonMUI className={styles.btn_primary} variant="text" onClick={handleLogout}>
                        {t('userPanel.logout')}
                    </ButtonMUI>
                </div>
            </div>
        </Popover>
    </div>
  )
};

export default UserPanel;
