import React from 'react';
import styles from '../../styles/components/Inputs/BackButton.module.css'
import {Link} from "react-router-dom";
import arrowSVG from '../../icons/ArrowIcon.svg'
import arrowLightSVG from '../../icons/arrow_light.svg'
import {useTranslation} from "react-i18next";

const BackButton = props => {
  const { t } = useTranslation();
  const {to = '', text = t('BackButton.defText'), onClick = null, className = '', isButton = false, isDark = false } = props;
  const content = <>
      <div className={styles.container}>
          <img src={isDark ? arrowLightSVG : arrowSVG} alt='' />
      </div>
      <span className={isDark ? styles.dark : styles.light}>{text}</span>
  </>
  return isButton ? (
      <button className={`${styles.back_btn} ${isDark ? styles.dark : styles.light} ${className}`} onClick={onClick}>{content}</button>
  ) : (
    <Link to={to} className={`${styles.back_btn} ${isDark ? styles.dark : styles.light} ${className}`} onClick={onClick}>{content}</Link>
  )
};
export default BackButton;
