import React from 'react';
import styles from '../../styles/components/DetailsCard/DetailsCharts.module.css'
import moment from "moment";
import {useTranslation} from "react-i18next";
import Moment from "react-moment";
import {getActiveChartData} from "../../redux/selectors";
import {useSelector} from "react-redux";

const InformationTopPanel = props => {
    const { card = null, type = '' } = props;
    const { modbus = {} } = card || {};
    const { t } = useTranslation();
    const activeData = useSelector(getActiveChartData)[type]
    const isSingle = !isNaN(modbus);

    const getActiveDataByType = t => {
        let modbusValue = isSingle ? +modbus : modbus[t];
        return activeData && activeData.data.find(d => +d.serieId === modbusValue)
            ? activeData.data.find(d => +d.serieId === modbusValue).data
            : {}
    }

    return (
            <div className={styles.temp_row}>
                <div>
                    <span>{t(isSingle ? 'DetailsCharts.temperature' : 'DetailsCharts.fastForward')}</span>
                    <div className={styles.temp_box}>
                        <span className={styles.temp_value}>
                            {activeData ? (getActiveDataByType('sensor_in').y || 0).toFixed(0) : '-'}
                        </span>
                        <span>°C</span>
                    </div>
                </div>
                {!isSingle ?
                    (<div>
                        <span>{t('DetailsCharts.Rewind')}</span>
                        <div className={styles.temp_box}>
                        <span className={styles.temp_value}>
                            {activeData ? (getActiveDataByType('sensor_out').y || 0).toFixed(0) : '-'}
                        </span>
                            <span>°C</span>
                        </div>
                    </div>)
                    : null}
                <div className={`flex-column flex-left-center ${styles.time_box}`}>
                    <div>{activeData ? moment(getActiveDataByType('sensor_out').x).format('DD MMM YYYY') : '-'}</div>
                    {activeData && activeData.rangeType === 1 ? (
                        <div className={styles.time}>
                            <Moment format={t('time.format')}>
                                {getActiveDataByType('sensor_out').x}
                            </Moment>{` ${t('time.clock')}`}
                        </div>
                    ) : null}
                </div>
            </div>
    )
}

export default InformationTopPanel;