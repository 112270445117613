import * as types from '../types/performance';

const initialState = {
  performanceImg: {
    filters: {
      hotWater: {
        show: false,
        // modbus: [151, 232, 233, 51]
        // modbus: [100, 243]
      },
      chilledWater: {
        show: false,
        // modbus: [101, 100, 22]
      },
      circulation: {
        show: false,
        // modbus: [152, 160, 161, 162, 163, 164, 165, 166, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 20, 30, 31]
      },
      heatingLeadTime: {
        show: false,
        // modbus: [212, 232, 200]
      },
      heatingReturnFlow: {
        show: false,
        // modbus: [213, 233, 201, 97]
      },
    },
    nasFilters: {
      hotWater: {
        show: false,
        modbus: [100, 243]
      },
      chilledWater: {
        show: false,
        modbus: [245]
      },
      circulation: {
        show: false,
        modbus: [2, 3, 4, 5, 6, 7, 8, 20, 200]
      },
      heatingLeadTime: {
        show: false,
        modbus: [232]
      },
      heatingReturnFlow: {
        show: false,
        modbus: [233]
      },
    },
    activeGroup: 1,
    activeStreet: null,
    activeBuilding: null
  }
};

export const performance = (state = initialState.performanceImg, action) => {
  switch (action.type) {
    case types.SET_FILTER_PERFORMANCE:
      return {...state, filters: action.payload};
    case types.SET_NAS_FILTER_PERFORMANCE:
        return {...state, nasFilters: action.payload};
    case types.RESET_FILTER_PERFORMANCE:
      return {...state, filters: initialState.performanceImg.filters };
    case types.ACTIVE_GROUP:
      return {...state, activeGroup: action.payload};
    case types.ACTIVE_BUILDING:
      return {...state, activeBuilding: action.payload};
    case types.ACTIVE_STREET:
        return {...state, activeStreet: action.payload};
    case types.RESET_ALL_PERFORMANCE_IMAGES:
      return initialState.performanceImg;
    default:
      return state;
  }
};
