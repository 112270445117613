import img_1_v2_rukspulfilter_webp from "./02_Rukspulfilter/webp/Ruckspulfilter@2x.webp";
import img_1_v3_rukspulfilter_webp from "./02_Rukspulfilter/webp/Ruckspulfilter@3x.webp";
import img_2_v2_rukspulfilter_webp from "./02_Rukspulfilter/webp/Ruckspulfilter_1@2x.webp";
import img_2_v3_rukspulfilter_webp from "./02_Rukspulfilter/webp/Ruckspulfilter_1@3x.webp";
import img_3_v2_rukspulfilter_webp from "./02_Rukspulfilter/webp/Ruckspulfilter_2@2x.webp";
import img_3_v3_rukspulfilter_webp from "./02_Rukspulfilter/webp/Ruckspulfilter_2@3x.webp";

import img_1_v2_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/Ruckspulfilter@2x.jpg";
import img_1_v3_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/Ruckspulfilter@3x.jpg";
import img_2_v2_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/Ruckspulfilter_1@2x.jpg";
import img_2_v3_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/Ruckspulfilter_1@3x.jpg";
import img_3_v2_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/Ruckspulfilter_2@2x.jpg";
import img_3_v3_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/Ruckspulfilter_2@3x.jpg";

const images_Heimatring_10_11 = {
    'Rukspulfilter': {
        webp: {
            img_1: [img_1_v2_rukspulfilter_webp, img_1_v3_rukspulfilter_webp],
            img_2: [img_2_v2_rukspulfilter_webp, img_2_v3_rukspulfilter_webp],
            img_3: [img_3_v2_rukspulfilter_webp, img_3_v3_rukspulfilter_webp]
        },
        jpg: {
            img_1: [img_1_v2_rukspulfilter_jpg, img_1_v3_rukspulfilter_jpg],
            img_2: [img_2_v2_rukspulfilter_jpg, img_2_v3_rukspulfilter_jpg],
            img_3: [img_3_v2_rukspulfilter_jpg, img_3_v3_rukspulfilter_jpg]
        }
    }
}
export { images_Heimatring_10_11 };