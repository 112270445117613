import React, { useEffect, useState} from 'react';
import styles from '../../styles/components/DetailsCard/DetailsCharts.module.css'
import {Charts, CalendarWithTabsWrap} from "../index";
import {useSelector} from "react-redux";
import {useParams} from "react-router";
import {selectChartData, getActiveBuilding} from "../../redux/selectors";
import moment from "moment";
import {useTranslation} from "react-i18next";
import PipesInfoPanel from "./PipesInfoPanel";
import {useGetDataForChart} from "../../hooks";
import {modbusName, PipesDetailsSettings} from "../../utils/constants";
import {_lineTheme} from "../Charts";
import {isSameCurrentDateByDay} from "../../utils/functions";

const PipesChartWrap = props => {
    // const name = 'PipesChartWrap';
    const minTemperature = 55;
    const {
        card = null,
        type = '',
        hoverTypeLine,
        checkedLegend,
        setHoverTypeLine,
        setCheckedLegend,

        dateType,
        from,
        to,
        rangeType,
        setDateType,
        setFrom,
        setTo,
        setRangeType,
    } = props;
    const [data, setData] = useState([]);
    const [pipes, setPipes] = useState(0);

    const legend = [
        {text: ['pipeTemperature'], id: 'pipes', icon: { type: 'line', style: { backgroundColor: '#6479B7'}} },
        {text: ['hotWaterSupplyTemp'], id: 'returnFlow', icon: { type: 'line', style: { backgroundColor: '#FF797E'} }},
        {text: ['circulationTemperature'], id: 'storageTank', icon: { type: 'gusty_line'}},
        {text: ['minimumTargetTemperature', minTemperature], id: 'minTemperature', icon: { type: 'line', style: { backgroundColor: '#1CE783'}}},
        {text: ['anomalies'], id: 'anomalies', icon: { type: 'square', style: { backgroundColor: '#FF797E'}}},
    ]

    const { modbus } = card || {};
    const chartData = useSelector(selectChartData);
    const activeBuilding = useSelector(getActiveBuilding);
    const { t } = useTranslation();
    const { id: _propertyId } = useParams();
    const propertyId = _propertyId.split('&')[0];
    const chartId = 'PipesChart';

    useGetDataForChart({ rangeType, from, to, modbus, type, chartId, dateType });

    const isLastDate = (d, item, i) => i === item.length - 1 && moment(d.date).minutes();

    useEffect(() => {
        const modbusList = (chartData[type][chartId] || [chartId])
            .reduce((acc, item) => [...acc, ...Object.keys(item.modbuses || {}).map(modbus =>{ return { uid: item.uid, modbus: modbus} })], []);

        setPipes(modbusList.filter(pipe => modbus.pipes.find(p => p === +pipe.modbus)).length);
        
        const dataByType = chartData[type][chartId]
            ? chartData[type][chartId].reduce((acc, item) => [...acc, ...Object.values(item.modbuses || {})], [])
            : [];

        setData(dataByType.reduce((acc, item, i) => {
            if (!item || modbusList[i] === 'undefined') return acc;
            const iFirstData = Object.values(item).findIndex(v => v.temperature)
            const modbusId = +modbusList[i].modbus;
            const sensorUid = modbusList[i].uid;
            const typeModbus = Object.keys(PipesDetailsSettings[propertyId].modbus).find(typeM => PipesDetailsSettings[propertyId].modbus[typeM].includes(modbusId));
            const lineStyles = PipesDetailsSettings[propertyId].styles[typeModbus] || {};
            // const title = propertyId === '62f9f4293f62cee2df80a3ec'  ? '' : (propertyId === '62e90c753b1685117c8fe1e2' ?  modbusName[modbusId]?.componentType : modbusName[propertyId][modbusId]?.componentType) || '';
            // const titleValue = propertyId === '62f9f4293f62cee2df80a3ec' ? 0 : modbusName[propertyId][modbusId]?.value;
            let title = '';
            let titleValue = 0;
            if (activeBuilding) {
                const currentDevice = activeBuilding.devices.find(device => device.modbus === modbusId && device.device_uid === sensorUid);
                title = currentDevice?.name ? currentDevice.name[0].split('.')[2] : modbusName[propertyId][modbusId]?.componentType;
                titleValue = currentDevice?.name ? currentDevice.name[1].n : modbusName[propertyId][modbusId]?.value;
            } else {
                title = propertyId === '62e90c753b1685117c8fe1e2' ?  modbusName[modbusId]?.componentType || '' : modbusName[propertyId][modbusId]?.componentType || '';
                titleValue = modbusName[propertyId][modbusId]?.value;
            }
            // const title = activeBuilding ? activeBuilding.devices.find(device => { return device.modbus === modbusId && device.device_uid === sensorUid ? device.name[0].split('.')[2] : '' }) : (propertyId === '62e90c753b1685117c8fe1e2' ?  modbusName[modbusId]?.componentType : modbusName[propertyId][modbusId]?.componentType) || '';
            // const titleValue = propertyId === '62f9f4293f62cee2df80a3ec' ? 0 : modbusName[propertyId][modbusId]?.value;

            const { color, strokeDasharray } = lineStyles;
            if (!checkedLegend.includes(typeModbus)) return acc
            return [...acc, {
                id: i+1,
                color: !!hoverTypeLine && hoverTypeLine !== typeModbus ? _lineTheme.disabledLine : color,
                strokeDasharray,
                title: titleValue ? t(`Sensors.component.${title}`, {n: titleValue}) : t(`Sensors.component.${title}`),
                data: Object.values(item).reduce(
                    (a, d, index) => (
                        iFirstData && index < iFirstData
                            ? a
                            : [...a, {
                                x: (isLastDate(d, item, index) ? moment(d.date).add(1, rangeType === 1 ? 'hours' : 'day') : moment(d.date)).format('YYYY-MM-DD HH:mm').toString(),
                                y: +d.temperature?.toFixed(2) || null
                            }]
                    ), [])
            }]
        }, []))
    }, [chartData, modbus, rangeType, t, type, checkedLegend, hoverTypeLine])

    const isPrediction = dateType === 'month' && isSameCurrentDateByDay(to);
    return (
        <div>
            <div className={styles.chart_box}>
                <div className={`${ styles.chart} line-chart h-500`}>
                    <Charts.LineChartPipes
                        data={data}
                        rangeType={rangeType}
                        dateType={dateType}
                        minTemperature={minTemperature}
                        checkedLegend={checkedLegend}
                        hoverTypeLine={hoverTypeLine}
                        isPrediction={isPrediction}
                        t={t}
                    />
                </div>
                <CalendarWithTabsWrap
                    dateType={dateType}
                    setRangeType={setRangeType}
                    setDateType={setDateType}
                    setFrom={setFrom}
                    setTo={setTo}
                    from={from}
                    to={to}
                />
            </div>
            <hr className="mb-35"/>
            <PipesInfoPanel
                checked={checkedLegend}
                setChecked={setCheckedLegend}
                setHoverTypeLine={setHoverTypeLine}
                legend={legend}
                pipes={pipes}
            />
        </div>
    )
}

export default PipesChartWrap;