import React from 'react';
import styles from "../../styles/components/Layouts/Layout.module.css";
import logo from "../../images/Logo_text.svg";
import {Container, Navbar} from "react-bootstrap";
import {Link} from 'react-router-dom';
import { UserPanel } from "../index";

const Layout = ({children}) => (
  <>
    <Navbar expand="lg" className={`py-40 ${styles.navbar}`}>
      <Container className={styles.container}>
        <Navbar.Brand>
          <Link to="/" >
            <img src={logo} alt="PAUL"/>
          </Link>
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <UserPanel />
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <div className="">
      <main>
        {children}
      </main>
      <div className="footer" />
    </div>
  </>
);

export default Layout;
