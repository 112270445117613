export const REMOVE_AUTH = 'REMOVE_AUTH';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCEEDED = 'SIGN_IN_SUCCEEDED';
export const SIGN_IN_FAILED = 'SIGN_IN_FAILED';

export const SIGN_OUT = 'SIGN_OUT';

export const CHECK_CONNECTION = 'CHECK_CONNECTION';

export const SOCKET_CONNECTION = 'SOCKET_CONNECTION';
export const SOCKET_ID = 'SOCKET_ID';
export const RESET_ALL_USER = 'RESET_ALL_USER';


