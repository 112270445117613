import React from 'react';
import styles from '../../styles/components/DetailsCard/InformationDetails.module.css'

import ImageSlider from "../ImageSlider";

const ImagesDetails = props => {
    const { card, openFullSize } = props;

    return (
        <div className={`${styles.container} ${styles.just_image}`}>
            <div className={`${styles.img_wrap}`} >
                <ImageSlider card={card} openFullSize={openFullSize} disableFullScreen/>
            </div>
        </div>
    )
}

export default ImagesDetails;