import sensor_146_webp from "./webp/Kaltwassereingang.webp";
import sensor_146_v2_webp from "./webp/Kaltwassereingang@2x.webp";
import sensor_146_v3_webp from "./webp/Kaltwassereingang@3x.webp";
import sensor_147_webp from "./webp/Ruckspulfilter.webp";
import sensor_147_v2_webp from "./webp/Ruckspulfilter@2x.webp";
import sensor_147_v3_webp from "./webp/Ruckspulfilter@3x.webp";
import sensor_148_webp from "./webp/Warmwasserbereitung.webp";
import sensor_148_v2_webp from "./webp/Warmwasserbereitung@2x.webp";
import sensor_148_v3_webp from "./webp/Warmwasserbereitung@3x.webp";
import sensor_149_webp from "./webp/Heizkessel.webp";
import sensor_149_v2_webp from "./webp/Heizkessel@2x.webp";
import sensor_149_v3_webp from "./webp/Heizkessel@3x.webp";
import sensor_150_webp from "./webp/BHKW.webp";
import sensor_150_v2_webp from "./webp/BHKW@2x.webp";
import sensor_150_v3_webp from "./webp/BHKW@3x.webp";
import sensor_151_webp from "./webp/Pufferspeicher.webp";
import sensor_151_v2_webp from "./webp/Pufferspeicher@2x.webp";
import sensor_151_v3_webp from "./webp/Pufferspeicher@3x.webp";
import sensor_152_webp from "./webp/Speicherladepumpe.webp";
import sensor_152_v2_webp from "./webp/Speicherladepumpe@2x.webp";
import sensor_152_v3_webp from "./webp/Speicherladepumpe@3x.webp";
import sensor_153_webp from "./webp/Fresh_water_station.webp";
import sensor_153_v2_webp from "./webp/Fresh_water_station@2x.webp";
import sensor_153_v3_webp from "./webp/Fresh_water_station@3x.webp";
import sensor_154_webp from "./webp/Heizkreis.webp";
import sensor_154_v2_webp from "./webp/Heizkreis@2x.webp";
import sensor_154_v3_webp from "./webp/Heizkreis@3x.webp";

import sensor_146_jpg from "./jpg/Kaltwassereingang.jpg";
import sensor_146_v2_jpg from "./jpg/Kaltwassereingang@2x.jpg";
import sensor_146_v3_jpg from "./jpg/Kaltwassereingang@3x.jpg";
import sensor_147_jpg from "./jpg/Ruckspulfilter.jpg";
import sensor_147_v2_jpg from "./jpg/Ruckspulfilter@2x.jpg";
import sensor_147_v3_jpg from "./jpg/Ruckspulfilter@3x.jpg";
import sensor_148_jpg from "./jpg/Warmwasserbereitung.jpg";
import sensor_148_v2_jpg from "./jpg/Warmwasserbereitung@2x.jpg";
import sensor_148_v3_jpg from "./jpg/Warmwasserbereitung@3x.jpg";
import sensor_149_jpg from "./jpg/Heizkessel.jpg";
import sensor_149_v2_jpg from "./jpg/Heizkessel@2x.jpg";
import sensor_149_v3_jpg from "./jpg/Heizkessel@3x.jpg";
import sensor_150_jpg from "./jpg/BHKW.jpg";
import sensor_150_v2_jpg from "./jpg/BHKW@2x.jpg";
import sensor_150_v3_jpg from "./jpg/BHKW@3x.jpg";
import sensor_151_jpg from "./jpg/Pufferspeicher.jpg";
import sensor_151_v2_jpg from "./jpg/Pufferspeicher@2x.jpg";
import sensor_151_v3_jpg from "./jpg/Pufferspeicher@3x.jpg";
import sensor_152_jpg from "./jpg/Speicherladepumpe.jpg";
import sensor_152_v2_jpg from "./jpg/Speicherladepumpe@2x.jpg";
import sensor_152_v3_jpg from "./jpg/Speicherladepumpe@3x.jpg";
import sensor_153_jpg from "./jpg/Fresh_water_station.jpg";
import sensor_153_v2_jpg from "./jpg/Fresh_water_station@2x.jpg";
import sensor_153_v3_jpg from "./jpg/Fresh_water_station@3x.jpg";
import sensor_154_jpg from "./jpg/Heizkreis.jpg";
import sensor_154_v2_jpg from "./jpg/Heizkreis@2x.jpg";
import sensor_154_v3_jpg from "./jpg/Heizkreis@3x.jpg";

const sAmBlomkenberg = {
    webp: {
        s_146: [sensor_146_webp, sensor_146_v2_webp, sensor_146_v3_webp],
        s_147: [sensor_147_webp, sensor_147_v2_webp, sensor_147_v3_webp],
        s_148: [sensor_148_webp, sensor_148_v2_webp, sensor_148_v3_webp],
        s_149: [sensor_149_webp, sensor_149_v2_webp, sensor_149_v3_webp],
        s_150: [sensor_150_webp, sensor_150_v2_webp, sensor_150_v3_webp],
        s_151: [sensor_151_webp, sensor_151_v2_webp, sensor_151_v3_webp],
        s_152: [sensor_152_webp, sensor_152_v2_webp, sensor_152_v3_webp],
        s_153: [sensor_153_webp, sensor_153_v2_webp, sensor_153_v3_webp],
        s_154: [sensor_154_webp, sensor_154_v2_webp, sensor_154_v3_webp]
    },
    jpg: {
        s_146: [sensor_146_jpg, sensor_146_v2_jpg, sensor_146_v3_jpg],
        s_147: [sensor_147_jpg, sensor_147_v2_jpg, sensor_147_v3_jpg],
        s_148: [sensor_148_jpg, sensor_148_v2_jpg, sensor_148_v3_jpg],
        s_149: [sensor_149_jpg, sensor_149_v2_jpg, sensor_149_v3_jpg],
        s_150: [sensor_150_jpg, sensor_150_v2_jpg, sensor_150_v3_jpg],
        s_151: [sensor_151_jpg, sensor_151_v2_jpg, sensor_151_v3_jpg],
        s_152: [sensor_152_jpg, sensor_152_v2_jpg, sensor_152_v3_jpg],
        s_153: [sensor_153_jpg, sensor_153_v2_jpg, sensor_153_v3_jpg],
        s_154: [sensor_154_jpg, sensor_154_v2_jpg, sensor_154_v3_jpg]
    }
}

export { sAmBlomkenberg };