import React, {useCallback, useEffect, useState} from 'react';
import {Cluster, Marker, RotationControl, ZoomControl} from "react-mapbox-gl";
import styles from "../../styles/components/MapView.module.css";
import markerSVG from "../../icons/marker.svg";
import {setActiveProperty, setZoom} from "../../redux/actions";
import BuildingCard from "../MapPanel/BuildingCard";
import {useDispatch, useSelector} from "react-redux";
import {getActiveProperty, getDataProperties, getFilters, getZoom} from "../../redux/selectors";


const MapContent = props => {
    const { nameForm, defaultPointers, map } = props;
    const dispatch = useDispatch();
    const properties = useSelector(getDataProperties) || [];
    const activeProperty = useSelector(getActiveProperty);
    const [pointers, setPoints] = useState(defaultPointers);
    const filters = useSelector(getFilters);
    const isAutoZoom = useSelector(getZoom);
    const filter = filters[nameForm];

    const filteredProperties = useCallback(() => {
        const { display_warnings, city, zip } = filter || {};
        return properties ? properties.filter(card => {
            return !((display_warnings && (card.sensors.find(s => !s.status) === null))
                || (city && card.city !== city)
                || (zip && card.zip !== zip));
        }) : []
    }, [properties, filter]);

    useEffect(() => {
        const filterProperties = filteredProperties();
        setPoints(defaultPointers.filter(point => filterProperties.find(p => p._id === point.id)));
    }, [properties, filter, defaultPointers, filteredProperties]);

    const hasDiff12 = (a1 = [], a2 = []) => a1.find(i1 => !a2.find(i2 => i2.toFixed(12) === i1.toFixed(12)))

    const getIndex = point => properties.findIndex(card => card._id === point.id);
    const getFindProperty = point => properties.length ? properties.find(p => p._id === point.id) || {} : {};
    const getProperty = point => properties[getIndex(point)] || {};
    const goToPoint = (id = null) => {
        const mapCenter = Object.values(map.transform.center);
        const pointPosition = defaultPointers.find(point  => point.id === (id || activeProperty));
        if (pointPosition && hasDiff12(pointPosition.coordinates, mapCenter)) map.flyTo({ center: pointPosition.coordinates, essential: true });
    }
    const clickOnMarker = point => {
        if (activeProperty !== getProperty(point)._id) {
            goToPoint(point.id);
            dispatch(setActiveProperty(activeProperty === point.id ? null : point.id));
        } else goToPoint();
    }
    let timeout;
    const openProperty = card => {
        const mapCenter = Object.values(map.transform.center);
        const pointPosition = defaultPointers.find(point  => point.id === card._id);
        const hasDiff = pointPosition ? hasDiff12(pointPosition.coordinates, mapCenter) : false;
        if (hasDiff) {
            map.flyTo({ center: pointPosition.coordinates, essential: true })
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => dispatch(setZoom(!isAutoZoom)), 800)
        } else dispatch(setZoom(!isAutoZoom))

    }

    return (
        <>
            <Cluster
                zoomOnClickPadding={300}
                zoomOnClick={true}
                ClusterMarkerFactory={(coordinates, pointCount) => (
                  <Marker coordinates={coordinates} className={styles.clusterMarker} key={coordinates} >
                    <p className={styles.clusterPoint}>{pointCount}</p>
                  </Marker>
                )}
            >
                {
                    pointers.map((point, key) =>
                        <Marker
                            key={key}
                            coordinates={point.coordinates}
                            anchor="bottom"
                            className={styles.marker}
                            pitchAlignment='map'
                        >
                            {
                                activeProperty === getProperty(point)._id ? null : (
                                    <p>{properties.length ? getFindProperty(point).name || '' : ''}</p>
                                )
                            }
                            <img src={markerSVG} className={styles.marker_image} alt='marker' onClick={() => clickOnMarker(point)} />
                            {properties.length ? (
                                <BuildingCard
                                    key={point.id}
                                    card={getProperty(point)}
                                    i={getIndex(point)}
                                    isForMap
                                    show={activeProperty === getProperty(point)._id}
                                    goTo={openProperty}
                                />
                            ) : null}
                        </Marker>
                    )
                }
            </Cluster>
            <div className={styles.navigation_control}>
                <ZoomControl style={{boxShadow: 'none'}} className={styles.zoom_control}/>
                <RotationControl className={styles.rotation_control} style={{boxShadow: 'none', backgroundColor: 'rgb(249, 249, 249)'}}/>
            </div>
        </>
    )
};

export default MapContent;