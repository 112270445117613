import img_1_v2_kaltwasser_webp from "./details/Point_of_entry/webp/Point_of_entry@2x.webp";
import img_1_v3_kaltwasser_webp from "./details/Point_of_entry/webp/Point_of_entry@3x.webp";

import img_1_v2_kaltwasser_png from "./details/Point_of_entry/png/Point_of_entry@2x.png";
import img_1_v3_kaltwasser_png from "./details/Point_of_entry/png/Point_of_entry@3x.png";

import img_1_v2_rukspulfilter_webp from "./details/Backwashfilter/webp/Backwashfilter@2x.webp";
import img_1_v3_rukspulfilter_webp from "./details/Backwashfilter/webp/Backwashfilter@3x.webp";

import img_1_v2_rukspulfilter_png from "./details/Backwashfilter/png/Backwashfilter@2x.png";
import img_1_v3_rukspulfilter_png from "./details/Backwashfilter/png/Backwashfilter@3x.png";

import img_1_v2_pumpe_webp from "./details/Pump/webp/Pump@2x.webp";
import img_1_v3_pumpe_webp from "./details/Pump/webp/Pump@3x.webp";

import img_1_v2_pumpe_png from "./details/Pump/png/Pump@2x.png";
import img_1_v3_pumpe_png from "./details/Pump/png/Pump@3x.png";

import img_1_v2_heizkessel_webp from "./details/Heizkessel/webp/Heizkessel@2x.webp";
import img_1_v3_heizkessel_webp from "./details/Heizkessel/webp/Heizkessel@3x.webp";

import img_1_v2_heizkessel_png from "./details/Heizkessel/png/Heizkessel@2x.png";
import img_1_v3_heizkessel_png from "./details/Heizkessel/png/Heizkessel@3x.png";

import img_1_v2_warmwasserspeicher_webp from "./details/Warmwasserbereiter/webp/Warmwasserbereiter@2x.webp";
import img_1_v3_warmwasserspeicher_webp from "./details/Warmwasserbereiter/webp/Warmwasserbereiter@3x.webp";

import img_1_v2_warmwasserspeicher_png from "./details/Warmwasserbereiter/png/Warmwasserbereiter@2x.png";
import img_1_v3_warmwasserspeicher_png from "./details/Warmwasserbereiter/png/Warmwasserbereiter@3x.png";

import img_1_v2_fernwarme_webp from "./details/District_heating/webp/DistrictHeating@2x.webp";
import img_1_v3_fernwarme_webp from "./details/District_heating/webp/DistrictHeating@3x.webp";

import img_1_v2_fernwarme_png from "./details/District_heating/png/DistrictHeating@2x.png";
import img_1_v3_fernwarme_png from "./details/District_heating/png/DistrictHeating@3x.png";

import img_1_v2_warmetauscher_webp from "./details/Warmetauscher/webp/Warmetauscher@2x.webp";
import img_1_v3_warmetauscher_webp from "./details/Warmetauscher/webp/Warmetauscher@3x.webp";

import img_1_v2_warmetauscher_png from "./details/Warmetauscher/png/Warmetauscher@2x.png";
import img_1_v3_warmetauscher_png from "./details/Warmetauscher/png/Warmetauscher@3x.png";

const default_images = {
    'Heizkessel': {
        webp: {
            img_1: [img_1_v2_heizkessel_webp, img_1_v3_heizkessel_webp]
        },
        png: {
            img_1: [img_1_v2_heizkessel_png, img_1_v3_heizkessel_png]
        }
    },
    'Warmwasserspeicher': {
        webp: {
            img_1: [img_1_v2_warmwasserspeicher_webp, img_1_v3_warmwasserspeicher_webp]
        },
        png: {
            img_1: [img_1_v2_warmwasserspeicher_png, img_1_v3_warmwasserspeicher_png]
        }
    },
    'Rukspulfilter': {
        webp: {
            img_1: [img_1_v2_rukspulfilter_webp, img_1_v3_rukspulfilter_webp]
        },
        png: {
            img_1: [img_1_v2_rukspulfilter_png, img_1_v3_rukspulfilter_png]
        }
    },
    'Kaltwassereingang': {
        webp: {
            img_1: [img_1_v2_kaltwasser_webp, img_1_v3_kaltwasser_webp]
        },
        png: {
            img_1: [img_1_v2_kaltwasser_png, img_1_v3_kaltwasser_png]
        }
    },
    'Pumpe': {
        webp: {
            img_1: [img_1_v2_pumpe_webp, img_1_v3_pumpe_webp]
        },
        png: {
            img_1: [img_1_v2_pumpe_png, img_1_v3_pumpe_png]
        }
    },
    'Fernwarme': {
        webp: {
            img_1: [img_1_v2_fernwarme_webp, img_1_v3_fernwarme_webp]
        },
        png: {
            img_1: [img_1_v2_fernwarme_png, img_1_v3_fernwarme_png]
        }
    },
    'Warmetauscher': {
        webp: {
            img_1: [img_1_v2_warmetauscher_webp, img_1_v3_warmetauscher_webp]
        },
        png: {
            img_1: [img_1_v2_warmetauscher_png, img_1_v3_warmetauscher_png]
        }
    }
}

export { default_images };