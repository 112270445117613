import React, {useState} from 'react';
import styles from '../../styles/components/Houses/SensorCardForHouseList.module.css';
import {LazyLoadImage} from "react-lazy-load-image-component";
import {useSelector} from "react-redux";
import {getImgSupport} from "../../redux/selectors";
import DetailsCard from "../DetailsCard/DetailsCard";
import {useTranslation} from "react-i18next";
import TemperatureTabs from "./_TemperatureTabs";

const SensorCardForHouseList = props => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const imgSupport = useSelector(getImgSupport);
  const { card } = props;
  const { id = '', textType = '', imgWebp = [], imgPng = [], hasDetails = false } = card;

  const handleOpenDetails = () => { if (hasDetails) setOpen(true) }
  const handleCloseDetails = () => setOpen(false);

  return (
    <div key={id} className={`${styles.card} flex-between flex-column`}>
      <div className={styles.header}>{`${t(`houseCards.${textType}`)} ${card.componentNumber ? card.componentNumber : ''}`} <span className={styles.headerBuilding}>{card.building ? ` | ${card.building}` : ''}</span></div>
      <div className={`${styles.img_wrap} ${hasDetails ? 'cursor-share' : ''}`} onClick={handleOpenDetails}>
        <LazyLoadImage alt="gebäude bild" effect="blur" src={imgSupport ? imgWebp[0] : imgPng[0]} />
      </div>
      <TemperatureTabs card={card} />
      {hasDetails && <DetailsCard handleCloseDetails={handleCloseDetails} open={open} card={card} />}
    </div>
  )
};

export default SensorCardForHouseList;
