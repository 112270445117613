import * as types from '../types/profile';

const initialState = {
  profile: {
    image: '',
    error: [],
  },
};

export const profile = (state = initialState.profile, { type, payload }) => {
  switch (type) {
    case types.PROFILE_IMAGE:
      return {...state, image: payload};
    case types.GET_PROFILE_INFO_SUCCEEDED:
      return {...state, ...payload};
    case types.GET_PROFILE_INFO_FAILED:
      return {...state, error: [...state.error, { method: 'GetProfileInfo', ...payload }]};
    case types.UPDATE_PROFILE_INFO_SUCCEEDED:
      return {...state, ...payload};
    case types.UPDATE_PROFILE_INFO_FAILED:
        return {...state, error: [...state.error, { method: 'UpdateProfileInfo', ...payload }]};
    case types.RESET_ALL_PROFILE:
      return initialState.profile;
    default:
      return state;
  }
};