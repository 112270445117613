import { images_Unter_den_Eschen_3_4 } from "./3_4/details";
import { images_Unter_den_Eschen_5_7 } from "./5_7/details";
import { images_Unter_den_Eschen_8_10 } from "./8_10/details";
import { images_Unter_den_Eschen_11_13 } from "./11_13/details";
import { images_Unter_den_Eschen_14_15 } from "./14_15/details";

const detailsUnter_den_Eschen = {
    '3_4': images_Unter_den_Eschen_3_4,
    '5_7': images_Unter_den_Eschen_5_7,
    '8_10': images_Unter_den_Eschen_8_10,
    '11_13': images_Unter_den_Eschen_11_13,
    '14_15': images_Unter_den_Eschen_14_15
}

export { detailsUnter_den_Eschen };