import * as types from '../types/performance';

export const setFilterPerformance = (payload) => ({ type: types.SET_FILTER_PERFORMANCE, payload});
export const setNasFilterPerformance = (payload) => ({ type: types.SET_NAS_FILTER_PERFORMANCE, payload});
export const resetFilterPerformance = (payload) => ({ type: types.RESET_FILTER_PERFORMANCE, payload});
export const setActiveGroup = (payload) => ({ type: types.ACTIVE_GROUP, payload});
export const setActiveBuilding = (payload) => ({ type: types.ACTIVE_BUILDING, payload});
export const setActiveStreet = (payload) => ({ type: types.ACTIVE_STREET, payload});

export const resetAllPerformanceImages = (payload) => ({ type: types.RESET_ALL_PERFORMANCE_IMAGES, payload});


