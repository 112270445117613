import React from 'react';

const animateCircleGreySVG = (
  <svg width="161" height="161" viewBox="0 0 161 161" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="80" cy="80" r="65" fill="#EAEAEF"/>
    <g filter="url(#filter0_d_1397_3836)">
      <circle cx="80" cy="80" r="60" fill="white"/>
    </g>
    <defs>
      <filter id="filter0_d_1397_3836" x="14" y="18" width="132" height="132" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="3"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1397_3836"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1397_3836" result="shape"/>
      </filter>
    </defs>
  </svg>
);
export default animateCircleGreySVG;
