import img_1_v2_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/DSC08997@2x.webp";
import img_1_v3_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/DSC08997@3x.webp";
import img_2_v2_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/DSC09005@2x.webp";
import img_2_v3_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/DSC09005@3x.webp";

import img_1_v2_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/DSC08997@2x.jpg";
import img_1_v3_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/DSC08997@3x.jpg";
import img_2_v2_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/DSC09005@2x.jpg";
import img_2_v3_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/DSC09005@3x.jpg";

import img_1_v2_rukspulfilter_webp from "./02_Rukspulfilter/webp/DSC09000@2x.webp";
import img_1_v3_rukspulfilter_webp from "./02_Rukspulfilter/webp/DSC09000@3x.webp";
import img_2_v2_rukspulfilter_webp from "./02_Rukspulfilter/webp/DSC09013@2x.webp";
import img_2_v3_rukspulfilter_webp from "./02_Rukspulfilter/webp/DSC09013@3x.webp";

import img_1_v2_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/DSC09000@2x.jpg";
import img_1_v3_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/DSC09000@3x.jpg";
import img_2_v2_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/DSC09013@2x.jpg";
import img_2_v3_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/DSC09013@3x.jpg";

const images_7 = {
    'Rukspulfilter': {
        webp: {
            img_1: [img_1_v2_rukspulfilter_webp, img_1_v3_rukspulfilter_webp],
            img_2: [img_2_v2_rukspulfilter_webp, img_2_v3_rukspulfilter_webp],
        },
        jpg: {
            img_1: [img_1_v2_rukspulfilter_jpg, img_1_v3_rukspulfilter_jpg],
            img_2: [img_2_v2_rukspulfilter_jpg, img_2_v3_rukspulfilter_jpg],
        }
    },
    'Kaltwassereingang': {
        webp: {
            img_1: [img_1_v2_kaltwassereingang_webp, img_1_v3_kaltwassereingang_webp],
            img_2: [img_2_v2_kaltwassereingang_webp, img_2_v3_kaltwassereingang_webp],
        },
        jpg: {
            img_1: [img_1_v2_kaltwassereingang_jpg, img_1_v3_kaltwassereingang_jpg],
            img_2: [img_2_v2_kaltwassereingang_jpg, img_2_v3_kaltwassereingang_jpg],
        }
    }
}

export {images_7} ;