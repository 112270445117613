import React from 'react';
import styles from '../../styles/components/Profile/ProfileInfo.module.css';
import ProfileImage from './ProfileImage';
import {InputField} from '../Inputs';
import {Grid} from "@mui/material";

const ProfileInfo = props => {
    const { profileInfo } = props;

    return (
        <div className={styles.container}>
            <Grid container>
                <Grid item xs="auto">
                    <Grid container className={styles.grid}>
                        <Grid item align="center" xs={6}>
                            <ProfileImage/>
                        </Grid>
                        <Grid item xs={6} className={styles.grid_item}>
                            {
                                profileInfo.slice(0, 2).map(field => <InputField field={field} key={field.name}/>)
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs="auto">
                    <Grid container className={`${styles.grid} ${styles.grid_container}`}>
                        <Grid item xs={6} className={styles.grid_item}>
                            {
                                profileInfo.slice(2, 3).map(field => <InputField field={field} key={field.name}/>)
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default ProfileInfo;