import React from 'react';
import styles from '../styles/components/TooltipWrap.module.css'
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const TooltipWrap = ({children, id, date}) => (
    <OverlayTrigger
        key={id}
        placement="top"
        overlay={
            <Tooltip id={`tooltip-${id}`} className={styles.tooltip}>
                {date || '- : -'}
            </Tooltip>
        }
    >
        {children}
    </OverlayTrigger>
);

export default TooltipWrap;