import React from 'react';
import {FreeMode, Navigation} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { SensorCard } from "../index";

const SensorLayoutGrid = props => {
  const { cards = [], filter = {} } = props;

  return (
    <Swiper className='sensorLayoutGrid' freeMode modules={[FreeMode, Navigation]} slidesPerView={'auto'} spaceBetween={40} navigation grabCursor style={{display: 'flex'}}>
      {cards.length
        ? cards.map(c => <SwiperSlide key={c.sensors[0]._id}><SensorCard card={c} params={c.params || {}} warningFilter={filter.display_warnings}/></SwiperSlide>)
        // : <EmptyCard />
        : null
      }
    </Swiper>
  )
};

export default SensorLayoutGrid;
