import img_1_v2_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/DSC08876@2x.webp";
import img_1_v3_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/DSC08876@3x.webp";
import img_2_v2_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/DSC08879@2x.webp";
import img_2_v3_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/DSC08879@3x.webp";
import img_3_v2_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/DSC08974@2x.webp";
import img_3_v3_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/DSC08974@3x.webp";

import img_1_v2_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/DSC08876@2x.jpg";
import img_1_v3_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/DSC08876@3x.jpg";
import img_2_v2_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/DSC08879@2x.jpg";
import img_2_v3_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/DSC08879@3x.jpg";
import img_3_v2_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/DSC08974@2x.jpg";
import img_3_v3_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/DSC08974@3x.jpg";

import img_1_v2_rukspulfilter_webp from "./02_Rukspulfilter/webp/DSC08883@2x.webp";
import img_1_v3_rukspulfilter_webp from "./02_Rukspulfilter/webp/DSC08883@3x.webp";
import img_2_v2_rukspulfilter_webp from "./02_Rukspulfilter/webp/DSC08975@2x.webp";
import img_2_v3_rukspulfilter_webp from "./02_Rukspulfilter/webp/DSC08975@3x.webp";
import img_3_v2_rukspulfilter_webp from "./02_Rukspulfilter/webp/DSC08979@2x.webp";
import img_3_v3_rukspulfilter_webp from "./02_Rukspulfilter/webp/DSC08979@3x.webp";
import img_4_v2_rukspulfilter_webp from "./02_Rukspulfilter/webp/DSC08981@2x.webp";
import img_4_v3_rukspulfilter_webp from "./02_Rukspulfilter/webp/DSC08981@3x.webp";

import img_1_v2_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/DSC08883@2x.jpg";
import img_1_v3_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/DSC08883@3x.jpg";
import img_2_v2_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/DSC08975@2x.jpg";
import img_2_v3_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/DSC08975@3x.jpg";
import img_3_v2_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/DSC08979@2x.jpg";
import img_3_v3_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/DSC08979@3x.jpg";
import img_4_v2_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/DSC08981@2x.jpg";
import img_4_v3_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/DSC08981@3x.jpg";

import img_1_v2_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/DSC08961@2x.webp";
import img_1_v3_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/DSC08961@3x.webp";
import img_2_v2_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/DSC08962@2x.webp";
import img_2_v3_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/DSC08962@3x.webp";
import img_3_v2_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/IMG_0265@2x.webp";
import img_3_v3_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/IMG_0265@3x.webp";

import img_1_v2_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/DSC08961@2x.jpg";
import img_1_v3_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/DSC08961@3x.jpg";
import img_2_v2_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/DSC08962@2x.jpg";
import img_2_v3_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/DSC08962@3x.jpg";
import img_3_v2_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/IMG_0265@2x.jpg";
import img_3_v3_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/IMG_0265@3x.jpg";

import img_1_v2_heizkessel_webp from "./04_Heizkessel/webp/DSC08910@2x.webp";
import img_1_v3_heizkessel_webp from "./04_Heizkessel/webp/DSC08910@3x.webp";
import img_2_v2_heizkessel_webp from "./04_Heizkessel/webp/DSC08907@2x.webp";
import img_2_v3_heizkessel_webp from "./04_Heizkessel/webp/DSC08907@3x.webp";
import img_3_v2_heizkessel_webp from "./04_Heizkessel/webp/DSC08911@2x.webp";
import img_3_v3_heizkessel_webp from "./04_Heizkessel/webp/DSC08911@3x.webp";
import img_4_v2_heizkessel_webp from "./04_Heizkessel/webp/IMG_0263@2x.webp";
import img_4_v3_heizkessel_webp from "./04_Heizkessel/webp/IMG_0263@3x.webp";

import img_1_v2_heizkessel_png from "./04_Heizkessel/png/DSC08910@2x.png";
import img_1_v3_heizkessel_png from "./04_Heizkessel/png/DSC08910@3x.png";
import img_2_v2_heizkessel_png from "./04_Heizkessel/png/DSC08907@2x.png";
import img_2_v3_heizkessel_png from "./04_Heizkessel/png/DSC08907@3x.png";
import img_3_v2_heizkessel_png from "./04_Heizkessel/png/DSC08911@2x.png";
import img_3_v3_heizkessel_png from "./04_Heizkessel/png/DSC08911@3x.png";
import img_4_v2_heizkessel_png from "./04_Heizkessel/png/IMG_0263@2x.png";
import img_4_v3_heizkessel_png from "./04_Heizkessel/png/IMG_0263@3x.png";

import img_1_v2_speicherladepumpe_webp from "./05_Speicherladepumpe/webp/DSC08900@2x.webp";
import img_1_v3_speicherladepumpe_webp from "./05_Speicherladepumpe/webp/DSC08900@3x.webp";
import img_2_v2_speicherladepumpe_webp from "./05_Speicherladepumpe/webp/DSC08904@2x.webp";
import img_2_v3_speicherladepumpe_webp from "./05_Speicherladepumpe/webp/DSC08904@3x.webp";
import img_3_v2_speicherladepumpe_webp from "./05_Speicherladepumpe/webp/DSC08940@2x.webp";
import img_3_v3_speicherladepumpe_webp from "./05_Speicherladepumpe/webp/DSC08940@3x.webp";
import img_4_v2_speicherladepumpe_webp from "./05_Speicherladepumpe/webp/IMG_0256@2x.webp";
import img_4_v3_speicherladepumpe_webp from "./05_Speicherladepumpe/webp/IMG_0256@3x.webp";

import img_1_v2_speicherladepumpe_jpg from "./05_Speicherladepumpe/jpg/DSC08900@2x.jpg";
import img_1_v3_speicherladepumpe_jpg from "./05_Speicherladepumpe/jpg/DSC08900@3x.jpg";
import img_2_v2_speicherladepumpe_jpg from "./05_Speicherladepumpe/jpg/DSC08904@2x.jpg";
import img_2_v3_speicherladepumpe_jpg from "./05_Speicherladepumpe/jpg/DSC08904@3x.jpg";
import img_3_v2_speicherladepumpe_jpg from "./05_Speicherladepumpe/jpg/DSC08940@2x.jpg";
import img_3_v3_speicherladepumpe_jpg from "./05_Speicherladepumpe/jpg/DSC08940@3x.jpg";
import img_4_v2_speicherladepumpe_jpg from "./05_Speicherladepumpe/jpg/IMG_0256@2x.jpg";
import img_4_v3_speicherladepumpe_jpg from "./05_Speicherladepumpe/jpg/IMG_0256@3x.jpg";

import img_1_v2_heizkreispumpe_webp from "./06_Heizkreispumpe/webp/DSC08898@2x.webp";
import img_1_v3_heizkreispumpe_webp from "./06_Heizkreispumpe/webp/DSC08898@3x.webp";
import img_2_v2_heizkreispumpe_webp from "./06_Heizkreispumpe/webp/DSC08903@2x.webp";
import img_2_v3_heizkreispumpe_webp from "./06_Heizkreispumpe/webp/DSC08903@3x.webp";
import img_3_v2_heizkreispumpe_webp from "./06_Heizkreispumpe/webp/IMG_0254@2x.webp";
import img_3_v3_heizkreispumpe_webp from "./06_Heizkreispumpe/webp/IMG_0254@3x.webp";

import img_1_v2_heizkreispumpe_jpg from "./06_Heizkreispumpe/jpg/DSC08898@2x.jpg";
import img_1_v3_heizkreispumpe_jpg from "./06_Heizkreispumpe/jpg/DSC08898@3x.jpg";
import img_2_v2_heizkreispumpe_jpg from "./06_Heizkreispumpe/jpg/DSC08903@2x.jpg";
import img_2_v3_heizkreispumpe_jpg from "./06_Heizkreispumpe/jpg/DSC08903@3x.jpg";
import img_3_v2_heizkreispumpe_jpg from "./06_Heizkreispumpe/jpg/IMG_0254@2x.jpg";
import img_3_v3_heizkreispumpe_jpg from "./06_Heizkreispumpe/jpg/IMG_0254@3x.jpg";

import img_1_v2_zirkulationspumpe_webp from "./07_Zirkulationspumpe/webp/DSC08918@2x.webp";
import img_1_v3_zirkulationspumpe_webp from "./07_Zirkulationspumpe/webp/DSC08918@3x.webp";
import img_2_v2_zirkulationspumpe_webp from "./07_Zirkulationspumpe/webp/DSC08965@2x.webp";
import img_2_v3_zirkulationspumpe_webp from "./07_Zirkulationspumpe/webp/DSC08965@3x.webp";

import img_1_v2_zirkulationspumpe_jpg from "./07_Zirkulationspumpe/jpg/DSC08918@2x.jpg";
import img_1_v3_zirkulationspumpe_jpg from "./07_Zirkulationspumpe/jpg/DSC08918@3x.jpg";
import img_2_v2_zirkulationspumpe_jpg from "./07_Zirkulationspumpe/jpg/DSC08965@2x.jpg";
import img_2_v3_zirkulationspumpe_jpg from "./07_Zirkulationspumpe/jpg/DSC08965@3x.jpg";

const images_5 = {
    'Heizkessel': {
        webp: {
            img_1: [img_1_v2_heizkessel_webp, img_1_v3_heizkessel_webp],
            img_2: [img_2_v2_heizkessel_webp, img_2_v3_heizkessel_webp],
            img_3: [img_3_v2_heizkessel_webp, img_3_v3_heizkessel_webp],
            img_4: [img_4_v2_heizkessel_webp, img_4_v3_heizkessel_webp],
        },
        png: {
            img_1: [img_1_v2_heizkessel_png, img_1_v3_heizkessel_png],
            img_2: [img_2_v2_heizkessel_png, img_2_v3_heizkessel_png],
            img_3: [img_3_v2_heizkessel_png, img_3_v3_heizkessel_png],
            img_4: [img_4_v2_heizkessel_png, img_4_v3_heizkessel_png],
        }
    },
    'Warmwasserspeicher': {
        webp: {
            img_1: [img_1_v2_warmwasserspeicher_webp, img_1_v3_warmwasserspeicher_webp],
            img_2: [img_2_v2_warmwasserspeicher_webp, img_2_v3_warmwasserspeicher_webp],
            img_3: [img_3_v2_warmwasserspeicher_webp, img_3_v3_warmwasserspeicher_webp],
        },
        jpg: {
            img_1: [img_1_v2_warmwasserspeicher_jpg, img_1_v3_warmwasserspeicher_jpg],
            img_2: [img_2_v2_warmwasserspeicher_jpg, img_2_v3_warmwasserspeicher_jpg],
            img_3: [img_3_v2_warmwasserspeicher_jpg, img_3_v3_warmwasserspeicher_jpg],
        }
    },
    'Rukspulfilter': {
        webp: {
            img_1: [img_2_v2_rukspulfilter_webp, img_2_v3_rukspulfilter_webp],
            img_2: [img_1_v2_rukspulfilter_webp, img_1_v3_rukspulfilter_webp],
            img_3: [img_3_v2_rukspulfilter_webp, img_3_v3_rukspulfilter_webp],
            img_4: [img_4_v2_rukspulfilter_webp, img_4_v3_rukspulfilter_webp],
        },
        jpg: {
            img_1: [img_2_v2_rukspulfilter_jpg, img_2_v3_rukspulfilter_jpg],
            img_2: [img_1_v2_rukspulfilter_jpg, img_1_v3_rukspulfilter_jpg],
            img_3: [img_3_v2_rukspulfilter_jpg, img_3_v3_rukspulfilter_jpg],
            img_4: [img_4_v2_rukspulfilter_jpg, img_4_v3_rukspulfilter_jpg],
        }
    },
    'Kaltwassereingang': {
        webp: {
            img_1: [img_1_v2_kaltwassereingang_webp, img_1_v3_kaltwassereingang_webp],
            img_2: [img_2_v2_kaltwassereingang_webp, img_2_v3_kaltwassereingang_webp],
            img_3: [img_3_v2_kaltwassereingang_webp, img_3_v3_kaltwassereingang_webp],
        },
        jpg: {
            img_1: [img_1_v2_kaltwassereingang_jpg, img_1_v3_kaltwassereingang_jpg],
            img_2: [img_2_v2_kaltwassereingang_jpg, img_2_v3_kaltwassereingang_jpg],
            img_3: [img_3_v2_kaltwassereingang_jpg, img_3_v3_kaltwassereingang_jpg],
        }
    },
    'Speicherladepumpe': {
        webp: {
            img_1: [img_1_v2_speicherladepumpe_webp, img_1_v3_speicherladepumpe_webp],
            img_2: [img_2_v2_speicherladepumpe_webp, img_2_v3_speicherladepumpe_webp],
            img_3: [img_3_v2_speicherladepumpe_webp, img_3_v3_speicherladepumpe_webp],
            img_4: [img_4_v2_speicherladepumpe_webp, img_4_v3_speicherladepumpe_webp],
        },
        jpg: {
            img_1: [img_1_v2_speicherladepumpe_jpg, img_1_v3_speicherladepumpe_jpg],
            img_2: [img_2_v2_speicherladepumpe_jpg, img_2_v3_speicherladepumpe_jpg],
            img_3: [img_3_v2_speicherladepumpe_jpg, img_3_v3_speicherladepumpe_jpg],
            img_4: [img_4_v2_speicherladepumpe_jpg, img_4_v3_speicherladepumpe_jpg],
        }
    },
    'Heizkreispumpe': {
        webp: {
            img_1: [img_2_v2_heizkreispumpe_webp, img_2_v3_heizkreispumpe_webp],
            img_2: [img_1_v2_heizkreispumpe_webp, img_1_v3_heizkreispumpe_webp],
            img_3: [img_3_v2_heizkreispumpe_webp, img_3_v3_heizkreispumpe_webp],
        },
        jpg: {
            img_1: [img_2_v2_heizkreispumpe_jpg, img_2_v3_heizkreispumpe_jpg],
            img_2: [img_1_v2_heizkreispumpe_jpg, img_1_v3_heizkreispumpe_jpg],
            img_3: [img_3_v2_heizkreispumpe_jpg, img_3_v3_heizkreispumpe_jpg],
        }
    },
    'Zirkulationspumpe': {
        webp: {
            img_1: [img_2_v2_zirkulationspumpe_webp, img_2_v3_zirkulationspumpe_webp],
            img_2: [img_1_v2_zirkulationspumpe_webp, img_1_v3_zirkulationspumpe_webp],
        },
        jpg: {
            img_1: [img_2_v2_zirkulationspumpe_jpg, img_2_v3_zirkulationspumpe_jpg],
            img_2: [img_1_v2_zirkulationspumpe_jpg, img_1_v3_zirkulationspumpe_jpg],
        }
    },
}

export {images_5} ;