import {createContext} from "react";
import socketio from "socket.io-client";

export let SocketListContext = new createContext({});

export const socketEmit = (method, payload, socketId) =>  SocketListContext._currentValue[socketId]?.emit(method, payload);
export const socketOn = (method, cb, socketId) => SocketListContext._currentValue[socketId]?.on(method, cb);

const _startSocket = ({ urlBack, token, socketId}) => {
  console.log('Connecting socket...');
  SocketListContext._currentValue[socketId] = socketio.connect(urlBack, {
    transports: ["polling"],
    auth: {token}
  });
}

export const connectSocket = props => {
  const { socketId } = props || {};
  _startSocket(props);
  const socket = SocketListContext._currentValue[socketId]
  socket?.on('connect', () => {
    console.log('Connected.');
  });
  socket?.on('reconnect', () => {
    console.log('Trying reconnect..');
  });
  socket?.on('disconnect', () => {
    console.log('Disconnected. Will attempt reconnect in 2 seconds');
    setTimeout(() => _startSocket(props), 2000);
  })
};

export const disconnectSocket = ({ socketId }) => {
  console.log('Disconnecting socket...');
  const socket = SocketListContext._currentValue[socketId]
  socket?.disconnect();
};