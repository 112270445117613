import React, {useEffect} from 'react';
import styles from '../styles/components/PropertyOverview.module.css'
import { MapView, MapPanelCards } from "./index";
import {useDispatch, useSelector} from "react-redux";
import {getAuth, getImgSupport, getSocketConnection, getSocketId} from "../redux/selectors";
import {choiceUpdateFunc, getProperties} from "../redux/actions";

const PropertyOverview = () => {
  const dispatch = useDispatch();
  const imgSupport = useSelector(getImgSupport);
  const isConnected = useSelector(getSocketConnection);
  const socketId = useSelector(getSocketId);
  const token = useSelector(getAuth);
  
  useEffect(() => {
    if (isConnected && socketId) {
      dispatch(getProperties({ token }, (resp, func) => dispatch(choiceUpdateFunc(resp, func))));
    }
  }, [dispatch, token, isConnected, socketId]);
  const nameForm = 'properties';

  return (
    <div className={styles.wrap}>
      <MapView nameForm={nameForm} imgSupport={imgSupport}/>
      <MapPanelCards nameForm={nameForm} imgSupport={imgSupport}/>
    </div>
  )
};

export default PropertyOverview;
