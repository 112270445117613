import sensor_81_webp from "./8_10/webp/Kaltwassereingang10.webp";
import sensor_81_v2_webp from "./8_10/webp/Kaltwassereingang10@2x.webp";
import sensor_81_v3_webp from "./8_10/webp/Kaltwassereingang10@3x.webp";

import sensor_81_jpg from "./8_10/jpg/Kaltwassereingang10.jpg";
import sensor_81_v2_jpg from "./8_10/jpg/Kaltwassereingang10@2x.jpg";
import sensor_81_v3_jpg from "./8_10/jpg/Kaltwassereingang10@3x.jpg";

import sensor_82_webp from "./3_4/webp/Kaltwassereingang.webp";
import sensor_82_v2_webp from "./3_4/webp/Kaltwassereingang@2x.webp";
import sensor_82_v3_webp from "./3_4/webp/Kaltwassereingang@3x.webp";

import sensor_82_jpg from "./3_4/jpg/Kaltwassereingang.jpg";
import sensor_82_v2_jpg from "./3_4/jpg/Kaltwassereingang@2x.jpg";
import sensor_82_v3_jpg from "./3_4/jpg/Kaltwassereingang@3x.jpg";

import sensor_83_webp from "./5_7/webp/Kaltwassereingang.webp";
import sensor_83_v2_webp from "./5_7/webp/Kaltwassereingang@2x.webp";
import sensor_83_v3_webp from "./5_7/webp/Kaltwassereingang@3x.webp";

import sensor_83_jpg from "./5_7/jpg/Kaltwassereingang.jpg";
import sensor_83_v2_jpg from "./5_7/jpg/Kaltwassereingang@2x.jpg";
import sensor_83_v3_jpg from "./5_7/jpg/Kaltwassereingang@3x.jpg";

import sensor_84_webp from "./8_10/webp/Kaltwassereingang8.webp";
import sensor_84_v2_webp from "./8_10/webp/Kaltwassereingang8@2x.webp";
import sensor_84_v3_webp from "./8_10/webp/Kaltwassereingang8@3x.webp";

import sensor_84_jpg from "./8_10/jpg/Kaltwassereingang8.jpg";
import sensor_84_v2_jpg from "./8_10/jpg/Kaltwassereingang8@2x.jpg";
import sensor_84_v3_jpg from "./8_10/jpg/Kaltwassereingang8@3x.jpg";

import sensor_85_webp from "./8_10/webp/Ruckspulfilter8.webp";
import sensor_85_v2_webp from "./8_10/webp/Ruckspulfilter8@2x.webp";
import sensor_85_v3_webp from "./8_10/webp/Ruckspulfilter8@3x.webp";

import sensor_85_jpg from "./8_10/jpg/Ruckspulfilter8.jpg";
import sensor_85_v2_jpg from "./8_10/jpg/Ruckspulfilter8@2x.jpg";
import sensor_85_v3_jpg from "./8_10/jpg/Ruckspulfilter8@3x.jpg";

import sensor_86_webp from "./11_13/webp/Kaltwassereingang.webp";
import sensor_86_v2_webp from "./11_13/webp/Kaltwassereingang@2x.webp";
import sensor_86_v3_webp from "./11_13/webp/Kaltwassereingang@3x.webp";

import sensor_86_jpg from "./11_13/jpg/Kaltwassereingang.jpg";
import sensor_86_v2_jpg from "./11_13/jpg/Kaltwassereingang@2x.jpg";
import sensor_86_v3_jpg from "./11_13/jpg/Kaltwassereingang@3x.jpg";

import sensor_87_webp from "./14_15/webp/Kaltwassereingang.webp";
import sensor_87_v2_webp from "./14_15/webp/Kaltwassereingang@2x.webp";
import sensor_87_v3_webp from "./14_15/webp/Kaltwassereingang@3x.webp";

import sensor_87_jpg from "./14_15/jpg/Kaltwassereingang.jpg";
import sensor_87_v2_jpg from "./14_15/jpg/Kaltwassereingang@2x.jpg";
import sensor_87_v3_jpg from "./14_15/jpg/Kaltwassereingang@3x.jpg";

import sensor_88_webp from "./14_15/webp/Rukspulfilter.webp";
import sensor_88_v2_webp from "./14_15/webp/Ruckspulfilter@2x.webp";
import sensor_88_v3_webp from "./14_15/webp/Ruckspulfilter@3x.webp";

import sensor_88_jpg from "./14_15/jpg/Rukspulfilter.jpg";
import sensor_88_v2_jpg from "./14_15/jpg/Ruckspulfilter@2x.jpg";
import sensor_88_v3_jpg from "./14_15/jpg/Ruckspulfilter@3x.jpg";

const s3_4 = {
    webp: {
        s_82: [sensor_82_webp, sensor_82_v2_webp, sensor_82_v3_webp]
    },
    jpg: {
        s_82: [sensor_82_jpg, sensor_82_v2_jpg, sensor_82_v3_jpg]
    }
}

const s5_7 = {
    webp: {
        s_83: [sensor_83_webp, sensor_83_v2_webp, sensor_83_v3_webp]
    },
    jpg: {
        s_83: [sensor_83_jpg, sensor_83_v2_jpg, sensor_83_v3_jpg]
    }
}

const s8_10 = {
    webp: {
        s_81: [sensor_81_webp, sensor_81_v2_webp, sensor_81_v3_webp],
        s_84: [sensor_84_webp, sensor_84_v2_webp, sensor_84_v3_webp],
        s_85: [sensor_85_webp, sensor_85_v2_webp, sensor_85_v3_webp]
    },
    jpg: {
        s_81: [sensor_81_jpg, sensor_81_v2_jpg, sensor_81_v3_jpg],
        s_84: [sensor_84_jpg, sensor_84_v2_jpg, sensor_84_v3_jpg],
        s_85: [sensor_85_jpg, sensor_85_v2_jpg, sensor_85_v3_jpg]
    }
}

const s11_13 = {
    webp: {
        s_86: [sensor_86_webp, sensor_86_v2_webp, sensor_86_v3_webp]
    },
    jpg: {
        s_86: [sensor_86_jpg, sensor_86_v2_jpg, sensor_86_v3_jpg]
    }
}

const s14_15 = {
    webp: {
        s_87: [sensor_87_webp, sensor_87_v2_webp, sensor_87_v3_webp],
        s_88: [sensor_88_webp, sensor_88_v2_webp, sensor_88_v3_webp]
    },
    jpg: {
        s_87: [sensor_87_jpg, sensor_87_v2_jpg, sensor_87_v3_jpg],
        s_88: [sensor_88_jpg, sensor_88_v2_jpg, sensor_88_v3_jpg]
    }
}

export { s3_4, s5_7, s8_10, s11_13, s14_15 };
