import React from 'react';
import styles from '../styles/components/BuildingInform.module.css'
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";
import {useSelector} from "react-redux";
import {getDataProperty, getImgSupport, getActiveBuilding} from "../redux/selectors";
import {propertyImgRule, disabledTabsProperties} from "../utils/constants";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { buildings } from "../images/buildings";


const BuildingInform = () => {
  const location = useLocation();
  const data = useSelector(getDataProperty);
  const activeBuilding = useSelector(getActiveBuilding);
  const imgSupport = useSelector(getImgSupport);
  const { name = '', zip = '', city = '', country = '', _id = ''} = data;

  return (
    <div className={`${styles.wrap} ${disabledTabsProperties.includes(_id) ? styles.big_3D : styles.small_3D}`}>
      <Row className={[styles.briefInfo + ' flex-row ' + (!data ? styles.hidden : '')]}>
        <Link to='/property' >{country}</Link>{'/'}
        <span>{zip}{' '}{city}</span>{'/'}
        <Link to={location.pathname} className={styles.bold} >{`${country}_${zip}_${name}`}</Link>
      </Row>
      <Row className={[styles.container + ' flex-top-center']}>
        <Col xs={6} md={4} className={styles.image_wrap}>
          <LazyLoadImage
            wrapperClassName={styles.lazy_load_image}
            alt="gebäude bild"
            effect="blur"
            src={_id
                ? (propertyImgRule[_id]
                    ? (imgSupport ? propertyImgRule[_id].imgWebp : propertyImgRule[_id].imgPng)
                    : (imgSupport ? buildings.webp.building_def_webp : buildings.png.building_def_png))
                : ''}
            width={251}
          />
        </Col>
        <Col xs={12} md={8}>
          <h2 className={styles.title}>{ activeBuilding?.circulationGroup ? activeBuilding.street :  name}</h2>
          <h2 className={'fw-normal'}>{zip}{' '}{city}</h2>
        </Col>
      </Row>
    </div>
  )
};

export default BuildingInform;
