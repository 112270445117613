import sensor_52_webp from "./3_4/webp/Kaltwassereingang.webp";
import sensor_52_v2_webp from "./3_4/webp/Kaltwassereingang@2x.webp";
import sensor_52_v3_webp from "./3_4/webp/Kaltwassereingang@3x.webp";

import sensor_52_jpg from "./3_4/jpg/Kaltwassereingang.jpg";
import sensor_52_v2_jpg from "./3_4/jpg/Kaltwassereingang@2x.jpg";
import sensor_52_v3_jpg from "./3_4/jpg/Kaltwassereingang@3x.jpg";

import sensor_54_webp from "./7_9/webp/Kaltwassereingang.webp";
import sensor_54_v2_webp from "./7_9/webp/Kaltwassereingang@2x.webp";
import sensor_54_v3_webp from "./7_9/webp/Kaltwassereingang@3x.webp";

import sensor_54_jpg from "./7_9/jpg/Kaltwassereingang.jpg";
import sensor_54_v2_jpg from "./7_9/jpg/Kaltwassereingang@2x.jpg";
import sensor_54_v3_jpg from "./7_9/jpg/Kaltwassereingang@3x.jpg";

import sensor_55_webp from "./10_12/webp/Kaltwassereingang.webp";
import sensor_55_v2_webp from "./10_12/webp/Kaltwassereingang@2x.webp";
import sensor_55_v3_webp from "./10_12/webp/Kaltwassereingang@3x.webp";

import sensor_55_jpg from "./10_12/jpg/Kaltwassereingang.jpg";
import sensor_55_v2_jpg from "./10_12/jpg/Kaltwassereingang@2x.jpg";
import sensor_55_v3_jpg from "./10_12/jpg/Kaltwassereingang@3x.jpg";

import sensor_56_webp from "./10_12/webp/Rukspulfilter.webp";
import sensor_56_v2_webp from "./10_12/webp/Rukspulfilter@2x.webp";
import sensor_56_v3_webp from "./10_12/webp/Rukspulfilter@3x.webp";

import sensor_56_jpg from "./10_12/jpg/Rukspulfilter.jpg";
import sensor_56_v2_jpg from "./10_12/jpg/Rukspulfilter@2x.jpg";
import sensor_56_v3_jpg from "./10_12/jpg/Rukspulfilter@3x.jpg";

const s3_4 = {
    webp: {
        s_52: [sensor_52_webp, sensor_52_v2_webp, sensor_52_v3_webp]
    },
    jpg: {
        s_52: [sensor_52_jpg, sensor_52_v2_jpg, sensor_52_v3_jpg]
    }
}

const s7_9 = {
    webp: {
        s_54: [sensor_54_webp, sensor_54_v2_webp, sensor_54_v3_webp]
    },
    jpg: {
        s_54: [sensor_54_jpg, sensor_54_v2_jpg, sensor_54_v3_jpg]
    }
}

const s10_12 = {
    webp: {
        s_55: [sensor_55_webp, sensor_55_v2_webp, sensor_55_v3_webp],
        s_56: [sensor_56_webp, sensor_56_v2_webp, sensor_56_v3_webp]
    },
    jpg: {
        s_55: [sensor_55_jpg, sensor_55_v2_jpg, sensor_55_v3_jpg],
        s_56: [sensor_56_jpg, sensor_56_v2_jpg, sensor_56_v3_jpg]
    }
}

export { s3_4, s7_9, s10_12 };