import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getActiveSensor, getDataProperty, getFilterPerformance, getImgSupport} from "../../redux/selectors/index";
import PerformanceTooltip from "./PerformanceTooltip";
import PerformanceImg from "./PerformanceImg";
import * as animationData from '../../images/animation/pulse.json';
import Lottie from 'lottie-react';
import {performanceImgTypePrototype} from "../../images/performance/prototype/index";

import styles from "../../styles/components/Performance/PerformanceImg.module.css";
import PerformanceTooltipPrototype from "./PerformanceTooltipPrototype";
import {SensorCard, TooltipWrap} from "../index";
import SensorCardPrototype from "../Sensors/SensorCardPrototype";
import {removeActiveSensor} from "../../redux/actions";

const PerformanceImgTypePrototype = () => {
    const dispatch = useDispatch();
    const imgSupport = useSelector(getImgSupport);
    const filters = useSelector(getFilterPerformance);
    const {heatingLeadTime, heatingReturnFlow} = filters;
    const modbusActiveFilters = Object.values(filters).reduce((acc, n) => n.show ? [...acc, ...n.modbus] : acc, []);
    const name = 'PerformanceTooltip';
    const data = useSelector(getDataProperty);
    const active_sensors = useSelector(getActiveSensor);
    const {sensors = []} = data;

    const deleteActiveCard = () => dispatch(removeActiveSensor());

    const onClick = (area) => {
        if (!area.id.toString().includes('m') && active_sensors.includes(area.id)) {
            deleteActiveCard();
        }
    };

    const renderElement = (area) => {
        return (
            <>
                <TooltipWrap id={area.id} date={`${area.tooltip_text}`}>
                    <div className={styles.performance_mouse_hover} onClick={() => onClick(area)}/>
                </TooltipWrap>

                {
                    !area.id.toString().includes('m') ? (
                        <>
                            <div
                                className={`${styles.renderElement_wrap} ${!modbusActiveFilters.includes(area.id) ? styles.hidden_tooltip : ''}`}>
                                <PerformanceTooltip area={area} index={1}/>
                            </div>
                            {area.status !== "true" || area.status === null ? (
                                <div className={styles.animation}>
                                    <Lottie animationData={animationData} width={40} height={40}/>
                                </div>
                            ) : null}
                        </>
                    ) : null
                }
                {sensors && active_sensors.map((modbus) => {
                    if (modbus === area.id) {
                        const card = sensors.find(s => modbus === s.modbus)
                        return (
                            <div key={area.id}
                                 className={`${styles.card_wrap} ${card ? styles.show : ''} sensor-card-wrap`}>
                                <SensorCard card={card} isModal={true}/>
                            </div>
                        )
                    } else
                        return null;
                })}
            </>
        )
    };

    const renderElementPrototype = (area) => {
        return (
            <>
                <TooltipWrap id={area.id} date={`${area.tooltip_text}`}>
                    <div className={styles.performance_mouse_hover} onClick={() => onClick(area)}/>
                </TooltipWrap>
                <div>
                    <div
                        className={`${styles.red_line + ' ' + styles.render_lines} ${!heatingLeadTime.show || !heatingReturnFlow.show ? styles.hidden_lines : ''}`}/>
                    <div
                        className={`${styles.red_line_2 + ' ' + styles.render_lines} ${!heatingLeadTime.show || !heatingReturnFlow.show ? styles.hidden_lines : ''}`}/>
                    <div
                        className={`${styles.renderElement_wrap} ${!heatingLeadTime.show ? styles.hidden_tooltip : ''}`}>
                        <PerformanceTooltipPrototype area={area} name={'T'} value={83} status={true} date={new Date()}
                                                     index={0}/>
                    </div>
                    <div
                        className={`${styles.renderElement_wrap} ${!heatingLeadTime.show || !heatingReturnFlow.show ? styles.hidden_tooltip : ''}`}>
                        <PerformanceTooltipPrototype area={area} name={'△'} delta value={8} status={false} index={1}
                                                     date={new Date()}/>
                    </div>
                    <div
                        className={`${styles.renderElement_wrap} ${!heatingReturnFlow.show ? styles.hidden_tooltip : ''}`}>
                        <PerformanceTooltipPrototype area={area} name={'T'} value={75} status={true} date={new Date()}
                                                     index={2}/>
                    </div>
                </div>
                <div
                    className={`${styles.prototype_sensor} ${active_sensors.includes(area.id) ? styles.show : ''} sensor-card-wrap`}>
                    <SensorCardPrototype isModal/>
                </div>
            </>
        )
    };

    const mapArea = [
        {
            width: "8.08%",
            height: "19%",
            left: "67.6%",
            top: "66.34%",
            id: 'm101',
            tooltip_text: [`${name}.boiler`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.78%",
            height: "2.93%",
            left: "66.25%",
            top: "55.3%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.92%",
            height: "3.5%",
            left: "60.77%",
            top: "64%",
            id: 'm103',
            tooltip_text: [`${name}.storage_charging_pump`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "5.34%",
            height: "20%",
            left: "52%",
            top: "58.5%",
            id: 'm100',
            tooltip_text: [`${name}.storage_water_heater`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.92%",
            height: "3.3%",
            left: "48.5%",
            top: "58%",
            id: 'm104',
            tooltip_text: [`${name}.circulation_pump`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2.6%",
            height: "8.5%",
            left: "37.76%",
            top: "58.78%",
            id: 'm105',
            tooltip_text: [`${name}.backwash_filters`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4.5%",
            height: "4%",
            left: "30%",
            top: "55%",
            id: 'm108',
            tooltip_text: [`${name}.domestic_water_meters`],
            className: `${styles.elements}`,
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "37.62%",
            top: "49.86%",
            id: 160,
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            tooltip_text: [`${name}.pipe`, {n: 1}],
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "40.58%",
            top: "51.51%",
            id: 161,
            tooltip_text: [`${name}.pipe`, {n: 2}],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "43.53%",
            top: "53.11%",
            id: 162,
            tooltip_text: [`${name}.pipe`, {n: 3}],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "50.7%",
            top: "55.58%",
            id: 151,
            tooltip_text: [`${name}.hot_water`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style}`,
            trackers_positions: [
                {top: -25, left: 35},
                {top: 0, left: 35},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "50.47%",
            top: "58.58%",
            id: 152,
            tooltip_text: [`${name}.circulation_flow`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "59.29%",
            top: "62.9%",
            id: 232,
            tooltip_text: [`${name}.hot_water_storage_tank`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "66.34%",
            top: "51.88%",
            id: 212,
            tooltip_text: [`${name}.heating_circuit_flow`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "67.85%",
            top: "52.05%",
            id: 213,
            tooltip_text: [`${name}.heating_circuit_return`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style}`,
            trackers_positions: [
                {top: 20, left: 35},
                {top: 45, left: 35},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "58.6%",
            top: "70.99%",
            id: 233,
            tooltip_text: [`${name}.hot_tank_flow`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "35.25%",
            top: "58.05%",
            id: 100,
            tooltip_text: [`${name}.cold_water_inlet`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "4%",
            height: "4%",
            left: "62.3%",
            top: "73%",
            id: 97,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            tooltip_text: [`${name}.energy_valve`],
            className: `image-map__map ${styles.elements} ${styles.cursor}`,
            trackers_positions: [
                {top: -30, left: 75},
                {top: 15, left: -10},
                {top: 80, left: -10},
            ],
            render: renderElementPrototype,
        },

    ];

    return (
        <PerformanceImg mapArea={mapArea} images={imgSupport ? performanceImgTypePrototype.webp : performanceImgTypePrototype.png}/>
    )
}

export default PerformanceImgTypePrototype;
