import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {getDataProperty} from "../../redux/selectors";
import styles from '../../styles/components/Houses/TemperatureBox.module.css'
import {useTranslation} from "react-i18next";

const TemperatureBox = props => {
  const { modbus, type, tabType } = props;
  const property = useSelector(getDataProperty);
  const { sensors = [] } = property;
  const [sensor, setSensor] = useState({});
  const [index, setIndex] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    setSensor(sensors.find(s => s.modbus === modbus) || {})
  }, [property, sensors, modbus]);

  useEffect(() => {
    if (sensor.sensors) {
      setIndex(sensor.sensors.findIndex(s => s.type === tabType))
    }
  }, [sensor, tabType]);

  const text = type === 'sensor_in' ? 'VL' : 'RL';
  return (
    <div>
      <div className={styles.temperature_wrap}>
        <span>{sensor.sensors && sensor.sensors[index] ? sensor.sensors[index].temperature.toFixed(1) : '- -'}</span>
        <span>°C</span>
      </div>
      <span>{type ? `${text} ` : ''}{t('HousesList.temperature')}</span>
    </div>
  )
};

export default TemperatureBox;
