import React from 'react';

const EmptyCard = () => {
  return (
    <div className={` sensor-card`}>
      Is empty
    </div>
  )
};

export default EmptyCard;
