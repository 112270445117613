import React from 'react';
import {useTranslation} from "react-i18next";
import styles from "../../styles/components/Profile/PersonalInfo.module.css";
import {InputField} from "../Inputs";
import {Grid} from "@mui/material";

const PersonalInfo = props => {
    const {personalInfo} = props;
    const {t} = useTranslation();

    return (
        <div className={styles.container}>
            <h5>{t("Profile.personalTitle")}</h5>
            <Grid container className={styles.field_container}>
                {
                    personalInfo.map(field =>
                        <Grid item xs={field.multiple ? 6 : 12} key={field.name}>
                            <InputField field={field} key={field.name}/>
                        </Grid>
                    )
                }
            </Grid>
        </div>
    );
};

export default PersonalInfo;