import { call, put } from 'redux-saga/effects'
import {API} from '../../utils/API';
import {RemoveAuth} from "../actions";
import {getFromLocalStorage, setToLocalStorage} from "../../utils/functions";
import {socketEmit, socketOn} from '../../utils/socket-config';


const requestSuccess = (type, payload) => ({ type: `${type}_SUCCEEDED`, payload });
const requestError = (type, payload) => ({ type: `${type}_FAILED`, payload });

export function* SignIn({ type, payload, cb }){
  try {
    const resp = yield call(API.post, 'admins/login', payload);
    const isPrototypeMode = payload.username === 'Customer';
    yield put(requestSuccess(type, { token: resp.token, isPrototypeMode }));
    setToLocalStorage('token', resp.token);
    if (isPrototypeMode) setToLocalStorage('prototypeMode', true);
    if (cb) cb();
    switch (payload.username) {
      case 'Nassauische_Heimstätte@paul.tech':
        setToLocalStorage('userType', 'Nassauische_Heimstätte@paul.tech');
        break;
      case 'GCP@paul.tech':
        setToLocalStorage('userType', 'GCP@paul.tech');
        break;
      case 'LEG@paul.tech':
        setToLocalStorage('userType', 'LEG@paul.tech');
        break;
      case 'ambelin@paul.tech':
        setToLocalStorage('userType', 'ambelin@paul.tech');
        break;
      case 'drk@paul.tech':
        setToLocalStorage('userType', 'drk@paul.tech');
        break;
      default:
        setToLocalStorage('userType', 'all');
        break;
    }
  } catch (error) {
    yield put(requestError(type, { message: error.formError} ));
  }
}

export function* SignOut({ cb }){
  yield put(RemoveAuth());
  setToLocalStorage('token', null);
  setToLocalStorage('prototypeMode', false);
  setToLocalStorage('userType', 'all');
  if (cb) cb();
}

export function* checkConnection() {
  const token = getFromLocalStorage('token');
  yield socketEmit('ping', { token });
  yield socketOn('pong', () => {});
}
