import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from '../../styles/components/Sencors/TemperatureCircle.module.css'
import animateCircleGrey from '../../icons/animateCircleGrey'
import Moment from 'react-moment';
import Lottie from 'lottie-react';
import * as animationDataRed from '../../images/animation/fluid_lottie_red.json';
import * as animationDataBlue from '../../images/animation/fluid_lottie_blue.json';
import {getStatusSensor} from "../../utils/functions";
import { useParams } from 'react-router';
import {useTranslation} from "react-i18next";
import moment from "moment";

const TemperatureCircle = props => {
  const { sensors, type, params = {} } = props;
  const { device_uid = null } = params
  const { id: _id } = useParams();
  const { t } = useTranslation();
  const id = _id.split('&')[0];
  const [sensor, setSensor] = useState({});
  const [statusObj, setStatusObj] = useState({});
  const [transition, setTransition] = useState();
  const [firstCircle, setFirstCircle] = useState();
  const [secondCircle, setSecondCircle] = useState();
  const { temperature, updated_at } = sensor || {};

  useEffect(() => {
    setSensor(sensors.find(s => (device_uid ? s.device_uid === device_uid : true) && s.type === type))
  }, [sensors, type, device_uid]);

  useEffect(() => {
    setStatusObj(getStatusSensor(sensor, id));
  }, [sensor, id]);

  const setCircleUpdate = useCallback(() => {
    if (firstCircle === null) {
      setFirstCircle(statusObj.status ? animationDataBlue : animationDataRed);
      setTransition('second');
    } else {
      setSecondCircle(statusObj.status ? animationDataBlue : animationDataRed );
      setTransition('first');
    }
  }, [firstCircle, statusObj]);

  useEffect(() => {
    if (statusObj) setCircleUpdate();
  }, [statusObj, setCircleUpdate]);

  const timeout = useRef(null);

  useEffect(() => {
    if (transition) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        if (firstCircle && secondCircle) {
          if (transition === 'first') {
            setFirstCircle(null);
          } else {
            setSecondCircle(null)
          }
        }
      }, 3100);
    }
  },[transition, firstCircle, secondCircle]);

  // useEffect(() => {
  //   return () => clearTimeout(timeout);
  // } , [type])

  useEffect(() => {
    if (!(firstCircle || secondCircle) && statusObj) setCircleUpdate();
  }, [firstCircle, secondCircle, setCircleUpdate, statusObj])

  const oldData = ((moment.duration(moment().diff(updated_at))).asHours() >= 1)

  return (
    <>
      <div className={[styles.container + ' flex-center']}>
        {sensor && oldData
        ? animateCircleGrey :
        temperature && temperature > 0 && temperature < 100 && (firstCircle || secondCircle) ? (
          <>
            { firstCircle ? (
              <div className={[styles.svg + ' '+ (transition === 'first' ? styles.svg_off : styles.svg_on)]}>
                <Lottie animationData={firstCircle} loop={true}/>
              </div>
            ) : null }
            { secondCircle  ? (
              <div className={[styles.svg + ' '+ (transition === 'second' ? styles.svg_off : styles.svg_on)]}>
                <Lottie animationData={secondCircle} loop={true}/>
              </div>
            ) : null}
          </>
        ) : animateCircleGrey}
        <div className={styles.temperature}>
          <div className={styles.temperature_box}>
            <span>{temperature && temperature > 0 && temperature < 100 ? temperature.toFixed(1) : '- -'}</span>
            <span>°C</span>
          </div>
          <div className={[styles.time_box+ ' time-box']}>
            <Moment format={t('time.format')}>
              {updated_at}
            </Moment>
            {t('time.clock')}
          </div>
        </div>
      </div>
      <div className={styles.sub_box}>
        <div className={styles.subtitle}>
          { oldData
              ? t('status.oldData')
              : temperature && temperature > 0 && temperature < 100
                ? (statusObj.statusText ? t(`status.${statusObj.statusText}`) : null)
                : t('status.noData')}
        </div>
      </div>
    </>
  )
};

export default TemperatureCircle;
