import img_1_v2_kaltwassereingang_13_webp from "./01_Kaltwassereingang/webp/Kaltwassereingang13@2x.webp";
import img_1_v3_kaltwassereingang_13_webp from "./01_Kaltwassereingang/webp/Kaltwassereingang13@3x.webp";
import img_2_v2_kaltwassereingang_13_webp from "./01_Kaltwassereingang/webp/Kaltwassereingang13_1@2x.webp";
import img_2_v3_kaltwassereingang_13_webp from "./01_Kaltwassereingang/webp/Kaltwassereingang13_1@3x.webp";

import img_1_v2_kaltwassereingang_16_webp from "./01_Kaltwassereingang/webp/Kaltwassereingang16@2x.webp";
import img_1_v3_kaltwassereingang_16_webp from "./01_Kaltwassereingang/webp/Kaltwassereingang16@3x.webp";
import img_2_v2_kaltwassereingang_16_webp from "./01_Kaltwassereingang/webp/Kaltwassereingang16_1@2x.webp";
import img_2_v3_kaltwassereingang_16_webp from "./01_Kaltwassereingang/webp/Kaltwassereingang16_1@3x.webp";

import img_1_v2_kaltwassereingang_13_jpg from "./01_Kaltwassereingang/jpg/Kaltwassereingang13@2x.jpg";
import img_1_v3_kaltwassereingang_13_jpg from "./01_Kaltwassereingang/jpg/Kaltwassereingang13@3x.jpg";
import img_2_v2_kaltwassereingang_13_jpg from "./01_Kaltwassereingang/jpg/Kaltwassereingang13_1@2x.jpg";
import img_2_v3_kaltwassereingang_13_jpg from "./01_Kaltwassereingang/jpg/Kaltwassereingang13_1@3x.jpg";

import img_1_v2_kaltwassereingang_16_jpg from "./01_Kaltwassereingang/jpg/Kaltwassereingang16@2x.jpg";
import img_1_v3_kaltwassereingang_16_jpg from "./01_Kaltwassereingang/jpg/Kaltwassereingang16@3x.jpg";
import img_2_v2_kaltwassereingang_16_jpg from "./01_Kaltwassereingang/jpg/Kaltwassereingang16_1@2x.jpg";
import img_2_v3_kaltwassereingang_16_jpg from "./01_Kaltwassereingang/jpg/Kaltwassereingang16_1@3x.jpg";

import img_1_v2_rukspulfilter_webp from "./02_Rukspulfilter/webp/Rukspulfilter16@2x.webp";
import img_1_v3_rukspulfilter_webp from "./02_Rukspulfilter/webp/Rukspulfilter16@3x.webp";
import img_2_v2_rukspulfilter_webp from "./02_Rukspulfilter/webp/Rukspulfilter16_1@2x.webp";
import img_2_v3_rukspulfilter_webp from "./02_Rukspulfilter/webp/Rukspulfilter16_1@3x.webp";

import img_1_v2_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/Rukspulfilter16@2x.jpg";
import img_1_v3_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/Rukspulfilter16@3x.jpg";
import img_2_v2_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/Rukspulfilter16_1@2x.jpg";
import img_2_v3_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/Rukspulfilter16_1@3x.jpg";

const images_Unter_den_Platanen_13_16 = {
    'Kaltwassereingang': {
        'Kaltwassereingang_13': {
            webp: {
                img_1: [img_1_v2_kaltwassereingang_13_webp, img_1_v3_kaltwassereingang_13_webp],
                img_2: [img_2_v2_kaltwassereingang_13_webp, img_2_v3_kaltwassereingang_13_webp]
            },
            jpg: {
                img_1: [img_1_v2_kaltwassereingang_13_jpg, img_1_v3_kaltwassereingang_13_jpg],
                img_2: [img_2_v2_kaltwassereingang_13_jpg, img_2_v3_kaltwassereingang_13_jpg]
            }
        },
        'Kaltwassereingang_16': {
            webp: {
                img_1: [img_1_v2_kaltwassereingang_16_webp, img_1_v3_kaltwassereingang_16_webp],
                img_2: [img_2_v2_kaltwassereingang_16_webp, img_2_v3_kaltwassereingang_16_webp]
            },
            jpg: {
                img_1: [img_1_v2_kaltwassereingang_16_jpg, img_1_v3_kaltwassereingang_16_jpg],
                img_2: [img_2_v2_kaltwassereingang_16_jpg, img_2_v3_kaltwassereingang_16_jpg]
            }
        }
    },
    'Rukspulfilter': {
        webp: {
            img_1: [img_1_v2_rukspulfilter_webp, img_1_v3_rukspulfilter_webp],
            img_2: [img_2_v2_rukspulfilter_webp, img_2_v3_rukspulfilter_webp]
        },
        jpg: {
            img_1: [img_1_v2_rukspulfilter_jpg, img_1_v3_rukspulfilter_jpg],
            img_2: [img_2_v2_rukspulfilter_jpg, img_2_v3_rukspulfilter_jpg]
        }
    }
}

export { images_Unter_den_Platanen_13_16 };