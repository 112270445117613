import React, {useState ,useContext, useEffect} from 'react';
import {
  Img360View,
  DynamicPerformanceImg,
  FilterPerformanceImg,
  Sensors,
  HousesList,
  PipesDetails,
  Footer
} from './index'
import {Col, Container, Row} from "react-bootstrap";
import styles from '../styles/components/DetailView.module.css'
import BuildingInform from "./BuildingInform";
import {useDispatch, useSelector} from "react-redux";
import {
  choiceUpdateFunc,
  getProperty,
  setActiveProperty,
  setZoom,
  setPitch,
  resetFilterPerformance
} from "../redux/actions";
import {getAuth, getDataProperty, getPrototypeMode, getSocketConnection, getSocketId} from "../redux/selectors";
import {SocketListContext} from "../utils/socket-config";
import {useParams} from "react-router";
import {useDocumentTitle} from "../hooks";
import { BackButton } from "./Inputs";
import { PipesDetailsSettings, disabledTabsProperties } from "../utils/constants";

const DetailView = () => {
  const { id: _id } = useParams();
  const id = _id.split('&')[0];
  const dispatch = useDispatch();
  const [pipesDetails, setPipesDetails] = useState(PipesDetailsSettings[id]);
  const token = useSelector(getAuth);
  const isConnected = useSelector(getSocketConnection);
  const socketId = useSelector(getSocketId);
  const prototypeMode = useSelector(getPrototypeMode);
  // const socketId = useSelector(getSocketId);
  // const socketId = '2edsds4fkgn793';
  const socketList = useContext(SocketListContext);
  const socket = socketList[socketId] || null;
  const data = useSelector(getDataProperty) || {};
  useDocumentTitle(`Paul ${data.name ? `- ${data.name}` : ''}`);

  useEffect(() => {
    dispatch(setZoom(false));
    dispatch(setPitch(false));
    return () => dispatch(resetFilterPerformance());
  }, [dispatch]);

  useEffect(() => {
    if(id && isConnected && socketId) {
      dispatch(setActiveProperty(id));
      dispatch(getProperty({ token, id }, (resp, func) => {
        dispatch(choiceUpdateFunc(resp, func))
      }))
    }
  }, [isConnected, socketId, id, token, dispatch]);

  const performance3DImageController = () => {
    switch(id) {
      case '62061f10452e21129b8ca241': 
        // return <PerformanceImgType18 />
        return <DynamicPerformanceImg type={'18'} />
      case '62061f10452e21129b8ca242':
        // return prototypeMode ? <PerformanceImgTypePrototype /> : <PerformanceImgType12 />
        return <DynamicPerformanceImg type={prototypeMode ? '12_prototype' : '12'} />
      case '62e90c3e3b1685117c8fe1e1':
        // return <PerformanceImgType25 />
        return <DynamicPerformanceImg type={'8_NiehlerGurtel'}/>
      case '62e90c753b1685117c8fe1e2':
        return <DynamicPerformanceImg type={'8_Heizkeise_4'}/>
      case '62f9f4293f62cee2df80a3ec':
        return <DynamicPerformanceImg />
      case '631993e9580e73164e7b12b2':
        return <DynamicPerformanceImg type={'8_Brusseler'}/>
      case '6319942f580e73164e7b12b3':
        return <DynamicPerformanceImg type={'8_Derfflinger'}/>
      case '631f0690b6c762f01414a1da':
        return <DynamicPerformanceImg type={'8_AmBlomkenberg'}/>
      case '63402b5b68662770736f5c69':
        return <DynamicPerformanceImg type={'8_Oberhutte'}/>
      default:
        // return <PerformanceImgType25 />
        return <DynamicPerformanceImg type={'8_NiehlerGurtel'}/>
    }
  }

  return (
    <div className={styles.detail_view_wrap}>
      <Container fluid="lg" className={styles.container}>
        <Row>
          {performance3DImageController()}
          <Col className="flex-row">
            <BackButton to='/'/>
            {
              disabledTabsProperties.includes(id) ? null : (
                <Img360View />
              )
            }
          </Col>
        </Row>
        <Row>
          <Col>
            <FilterPerformanceImg />
          </Col>
        </Row>
      </Container>
      <Container fluid="md" className={styles.container_md}>
        <Row>
          <Col>
            <BuildingInform />
          </Col>
        </Row>
        <Row>
          <Col>
            <Sensors />
          </Col>
        </Row>
        <Row>
          <Col>
            <HousesList />
          </Col>
        </Row>
        <Row>
          <Col>
            {
              (id !== "63402b5b68662770736f5c69")  && (
                <PipesDetails card={pipesDetails} />
              ) 
            }
            {/* <PipesDetails card={PipesDetailsSettings} /> */}
          </Col>
        </Row>
        <Row>
          <Col>
            <Footer />
          </Col>
        </Row>
      </Container>
    </div>
  )
};
export default DetailView;
