import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import styles from '../../styles/components/Houses/HousesList.module.css'
import { useParams } from 'react-router';
import {housesRule} from "../../utils/constants";
import SensorCardForHouseList from "./SensorCardForHouseList";
import {getActiveBuilding} from "../../redux/selectors";
import {useTranslation} from "react-i18next";

const HousesList = () => {
  const name = 'HousesList';
  const { t } = useTranslation();
  const { id: _id } = useParams();
  const id = _id.split('&')[0];
  const [houseCards, setHouseCards] = useState([]);
  const activeBuildingGroup = useSelector(getActiveBuilding);
  const houseList = houseCards && !activeBuildingGroup?.components ? Object.keys(houseCards) : [];

  useEffect(() => {
    if (activeBuildingGroup) {
      setHouseCards(activeBuildingGroup?.components ? activeBuildingGroup.components : housesRule[id] || [])
    } else {
      setHouseCards(housesRule[id] || [])
    }
  }, [id, activeBuildingGroup]);

  return (
    <>
      { activeBuildingGroup?.components ? (
        houseCards.map(component => (
          <div key={component.id} className={styles.house_wrap}>
            <h2 className="mb-50">{`${t(`${name}.${component.type}`)} ${component.building ? `| ${component.building}` : ''}`}</h2>
            <div className={styles.house_list}>
              {component.cards.map(card => <SensorCardForHouseList card={card} key={card.id} />)}
            </div>
          </div>
        ))
        ) : (
          houseList.map(number => (
            <div key={number} className={styles.house_wrap}>
              <h2 className="mb-50">{`${t(`${name}.components`)}${ number !== "null" ? t(`${name}.house`, {number}) : ''}`}</h2>
              <div className={styles.house_list}>
                {houseCards[number].map(card => <SensorCardForHouseList card={card} key={card.id} />)}
              </div>
            </div>
        )))
      }
    </>
  )
};

export default HousesList;
