import React, {useState, useEffect, useCallback, useRef} from "react";
import { imagesRole } from "../../utils/constants";
import { useParams } from 'react-router';
import { ControlImg360 } from "../index";
import { Box }from '@mui/material';
import fscreen from 'fscreen';
import {useSelector, useDispatch} from "react-redux";
import {getCurrentProperty, getActiveImage, getImgSupport, getShowHotspotImage, getActive} from "../../redux/selectors";
import {setCurrentProperty, setActiveImage, setActive, setShowHotspotImage} from "../../redux/actions";

import "../../styles/components/Img360/CustomHotspot.css";
import styles from "../../styles/components/Img360/Img360View.module.css";
import PannellumWrap from "./PannellumWrap";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "70%",
  height: "80%",
  padding: "8px", 
  borderRadius: "8px", 
  backgroundColor: "white", 
  zIndex: 10
};

const Img360View = () => {
  const { id: _id } = useParams();
  const id = _id.split('&')[0];
  const [show, setShow] = useState(false);
  const [imageHotspot, setImageHotspot] = useState();
  const dispatch = useDispatch();
  const [openFull, setOpenFull] = useState(false);
  const currentProperty = useSelector(getCurrentProperty);
  const activeImage     = useSelector(getActiveImage) || {};
  const isFullScreen    = useSelector(getActive);
  const imgSupport      = useSelector(getImgSupport);
  const open            = useSelector(getShowHotspotImage);
  const fullscreenRef = useRef(null);
  const {images = []} = currentProperty || {};

  useEffect(() => {
    dispatch(setCurrentProperty(imagesRole.find(property => property.device_id === id)))
  }, [id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setActiveImage({}));
      dispatch(setCurrentProperty({}));
    }
  }, [dispatch]);

  useEffect(() => {
    if (images) dispatch(setActiveImage(images[0]));
  }, [images, dispatch]);

  useEffect(() => {
    dispatch(setActive(openFull));
  }, [openFull, dispatch]);

  const closeModal = () => dispatch(setShowHotspotImage(false));

  const enterFullscreen = useCallback(() => {
    setOpenFull(true);
  }, []);

  const enterFull =(data) => {
    setOpenFull(data);
  }

  const keyboardPress = (e) => {
    if(document.fullscreenElement === null) {
      setOpenFull(false);
    }
  }

  useEffect(() => {
      window.addEventListener('fullscreenchange', keyboardPress, false)
  }, [window])

  const handleClick = useCallback(sceneId => {
    dispatch(setActiveImage(images.find(image => image.id === sceneId)))
  }, [images, dispatch]);

  const onClickHotspot = useCallback(spot => {
    if (spot.name) {
      setImageHotspot(spot);
      dispatch(setShowHotspotImage(true));
    } else handleClick(spot.sceneId);
  }, [setImageHotspot, handleClick])

  return (
    <div className={styles.img360container}>
      <div ref={fullscreenRef} className={`${styles.img360wrap} ${(show ? styles.show : '')} ${openFull ? 'fullScreen' : 'isNotFullScreen'}`}>
        <PannellumWrap
            scenes={images}
            activeSceneId={activeImage.id}
            autoLoad
            isFullScreen={isFullScreen}
            fullscreenRef={fullscreenRef}
            openFull={openFull}
            onClickHotspot={onClickHotspot}
        >
          <ControlImg360 fullscreenRef={fullscreenRef} imgSupport={imgSupport} onClick={enterFull} openFull={openFull}/>
          {
            open && (
              <>
                <Box component="span" sx={style}>
                  <img src={imgSupport ? imageHotspot.imgWebp : imageHotspot.imgPng} alt="" className={styles.imageHotspot}/>
                </Box>
                <div className={styles.modalContainer} onClick={closeModal} />
              </>
            )
          }
        </PannellumWrap>
      </div>
      <span className={styles.btn360} onClick={enterFullscreen}>360°</span>
    </div>
  );
};

export default Img360View;
