import * as types from '../types/map';

const initialState = {
  map: {
    zoom: false,
    pitch: false
  },
};

export const map = (state = initialState.map, action) => {
  switch (action.type) {
    case types.SHOW_3D:
      return {...state, zoom: action.payload};
    case types.PITCH:
        return {...state, pitch: action.payload};
    case types.RESET_ALL_MAP:
      return initialState.map;
    default:
      return state;
  }
};