import img_12_webp from './webp/fn_master_bg_01.webp';
import ChilledWaterImg_12_webp from './webp/Kaltwasser.webp';
import heatingReturnFlowImg_12_webp from './webp/Heizung_Rücklauf.webp';
import heatingLeadTimeImg_12_webp from './webp/Heizung_Vorlauf.webp';
import hotWaterImg_12_webp from './webp/Warmwasser.webp';
import circulationImg_12_webp from './webp/Zirkulation.webp';

import img_12_png from './png/fn_master_bg_01.png';
import ChilledWaterImg_12_png from './png/Kaltwasser.png';
import heatingReturnFlowImg_12_png from './png/Heizung_Rücklauf.png';
import heatingLeadTimeImg_12_png from './png/Heizung_Vorlauf.png';
import hotWaterImg_12_png from './png/Warmwasser.png';
import circulationImg_12_png from './png/Zirkulation.png';

const performanceImgType12 = {
    webp: [img_12_webp, ChilledWaterImg_12_webp, heatingReturnFlowImg_12_webp, heatingLeadTimeImg_12_webp, hotWaterImg_12_webp, circulationImg_12_webp],
    png: [img_12_png, ChilledWaterImg_12_png, heatingReturnFlowImg_12_png, heatingLeadTimeImg_12_png, hotWaterImg_12_png, circulationImg_12_png]
}

export { performanceImgType12 }