import img_2_v2_kaltwassereingang_webp from "./details/01_Hauswasserzahler/webp/Hauswasserzahler_1@2x.webp";
import img_2_v3_kaltwassereingang_webp from "./details/01_Hauswasserzahler/webp/Hauswasserzahler_1@2x.webp";
import img_3_v2_kaltwassereingang_webp from "./details/01_Hauswasserzahler/webp/Hauswasserzahler_2@2x.webp";
import img_3_v3_kaltwassereingang_webp from "./details/01_Hauswasserzahler/webp/Hauswasserzahler_2@2x.webp";
import img_4_v2_kaltwassereingang_webp from "./details/01_Hauswasserzahler/webp/Hauswasserzahler_3@2x.webp";
import img_4_v3_kaltwassereingang_webp from "./details/01_Hauswasserzahler/webp/Hauswasserzahler_3@2x.webp";
import img_5_v2_kaltwassereingang_webp from "./details/01_Hauswasserzahler/webp/Hauswasserzahler_4@2x.webp";
import img_5_v3_kaltwassereingang_webp from "./details/01_Hauswasserzahler/webp/Hauswasserzahler_4@2x.webp";

import img_2_v2_kaltwassereingang_jpg from "./details/01_Hauswasserzahler/jpg/Hauswasserzahler_1@2x.jpg";
import img_2_v3_kaltwassereingang_jpg from "./details/01_Hauswasserzahler/jpg/Hauswasserzahler_1@2x.jpg";
import img_3_v2_kaltwassereingang_jpg from "./details/01_Hauswasserzahler/jpg/Hauswasserzahler_2@2x.jpg";
import img_3_v3_kaltwassereingang_jpg from "./details/01_Hauswasserzahler/jpg/Hauswasserzahler_2@2x.jpg";
import img_4_v2_kaltwassereingang_jpg from "./details/01_Hauswasserzahler/jpg/Hauswasserzahler_3@2x.jpg";
import img_4_v3_kaltwassereingang_jpg from "./details/01_Hauswasserzahler/jpg/Hauswasserzahler_3@2x.jpg";
import img_5_v2_kaltwassereingang_jpg from "./details/01_Hauswasserzahler/jpg/Hauswasserzahler_4@2x.jpg";
import img_5_v3_kaltwassereingang_jpg from "./details/01_Hauswasserzahler/jpg/Hauswasserzahler_4@2x.jpg";

import img_2_v2_ruckspulfilter_webp from "./details/02_Ruckspulfilter/webp/Rukspulfilter_1@2x.webp";
import img_2_v3_ruckspulfilter_webp from "./details/02_Ruckspulfilter/webp/Rukspulfilter_1@2x.webp";
import img_3_v2_ruckspulfilter_webp from "./details/02_Ruckspulfilter/webp/Rukspulfilter_2@2x.webp";
import img_3_v3_ruckspulfilter_webp from "./details/02_Ruckspulfilter/webp/Rukspulfilter_2@2x.webp";

import img_2_v2_ruckspulfilter_jpg from "./details/02_Ruckspulfilter/jpg/Rukspulfilter_1@2x.jpg";
import img_2_v3_ruckspulfilter_jpg from "./details/02_Ruckspulfilter/jpg/Rukspulfilter_1@2x.jpg";
import img_3_v2_ruckspulfilter_jpg from "./details/02_Ruckspulfilter/jpg/Rukspulfilter_2@2x.jpg";
import img_3_v3_ruckspulfilter_jpg from "./details/02_Ruckspulfilter/jpg/Rukspulfilter_2@2x.jpg";


import img_2_v2_warmwasserspeicher_1_webp from "./details/03_Warmwasserspeicher/webp/Warmwasserspeicher1_1@2x.webp";
import img_2_v3_warmwasserspeicher_1_webp from "./details/03_Warmwasserspeicher/webp/Warmwasserspeicher1_1@2x.webp";
import img_3_v2_warmwasserspeicher_1_webp from "./details/03_Warmwasserspeicher/webp/Warmwasserspeicher1_2@2x.webp";
import img_3_v3_warmwasserspeicher_1_webp from "./details/03_Warmwasserspeicher/webp/Warmwasserspeicher1_2@2x.webp";

import img_2_v2_warmwasserspeicher_2_webp from "./details/03_Warmwasserspeicher/webp/Warmwasserspeicher2_1@2x.webp";
import img_2_v3_warmwasserspeicher_2_webp from "./details/03_Warmwasserspeicher/webp/Warmwasserspeicher2_1@2x.webp";
import img_3_v2_warmwasserspeicher_2_webp from "./details/03_Warmwasserspeicher/webp/Warmwasserspeicher2_2@2x.webp";
import img_3_v3_warmwasserspeicher_2_webp from "./details/03_Warmwasserspeicher/webp/Warmwasserspeicher2_2@2x.webp";
import img_4_v2_warmwasserspeicher_2_webp from "./details/03_Warmwasserspeicher/webp/Warmwasserspeicher2_3@2x.webp";
import img_4_v3_warmwasserspeicher_2_webp from "./details/03_Warmwasserspeicher/webp/Warmwasserspeicher2_3@2x.webp";

import img_2_v2_warmwasserspeicher_1_jpg from "./details/03_Warmwasserspeicher/jpg/Warmwasserspeicher1_1@2x.jpg";
import img_2_v3_warmwasserspeicher_1_jpg from "./details/03_Warmwasserspeicher/jpg/Warmwasserspeicher1_1@2x.jpg";
import img_3_v2_warmwasserspeicher_1_jpg from "./details/03_Warmwasserspeicher/jpg/Warmwasserspeicher1_2@2x.jpg";
import img_3_v3_warmwasserspeicher_1_jpg from "./details/03_Warmwasserspeicher/jpg/Warmwasserspeicher1_2@2x.jpg";

import img_2_v2_warmwasserspeicher_2_jpg from "./details/03_Warmwasserspeicher/jpg/Warmwasserspeicher2_1@2x.jpg";
import img_2_v3_warmwasserspeicher_2_jpg from "./details/03_Warmwasserspeicher/jpg/Warmwasserspeicher2_1@2x.jpg";
import img_3_v2_warmwasserspeicher_2_jpg from "./details/03_Warmwasserspeicher/jpg/Warmwasserspeicher2_2@2x.jpg";
import img_3_v3_warmwasserspeicher_2_jpg from "./details/03_Warmwasserspeicher/jpg/Warmwasserspeicher2_2@2x.jpg";
import img_4_v2_warmwasserspeicher_2_jpg from "./details/03_Warmwasserspeicher/jpg/Warmwasserspeicher2_3@2x.jpg";
import img_4_v3_warmwasserspeicher_2_jpg from "./details/03_Warmwasserspeicher/jpg/Warmwasserspeicher2_3@2x.jpg";


import img_2_v2_speicherladepumpe_webp from "./details/04_Speicherladepumpe/webp/Speicherladepumpe2_1@2x.webp";
import img_2_v3_speicherladepumpe_webp from "./details/04_Speicherladepumpe/webp/Speicherladepumpe2_1@2x.webp";


import img_2_v2_speicherladepumpe_jpg from "./details/04_Speicherladepumpe/jpg/Speicherladepumpe2_1@2x.jpg";
import img_2_v3_speicherladepumpe_jpg from "./details/04_Speicherladepumpe/jpg/Speicherladepumpe2_1@2x.jpg";

import img_2_v2_speicherladesystem_1_webp from "./details/11_Speicherladesystem/webp/Speicherladesystem1_1@2x.webp";
import img_2_v3_speicherladesystem_1_webp from "./details/11_Speicherladesystem/webp/Speicherladesystem1_1@2x.webp";
import img_3_v2_speicherladesystem_1_webp from "./details/11_Speicherladesystem/webp/Speicherladesystem1_2@2x.webp";
import img_3_v3_speicherladesystem_1_webp from "./details/11_Speicherladesystem/webp/Speicherladesystem1_2@2x.webp";
import img_4_v2_speicherladesystem_1_webp from "./details/11_Speicherladesystem/webp/Speicherladesystem1_3@2x.webp";
import img_4_v3_speicherladesystem_1_webp from "./details/11_Speicherladesystem/webp/Speicherladesystem1_3@2x.webp";
import img_5_v2_speicherladesystem_1_webp from "./details/11_Speicherladesystem/webp/Speicherladesystem1_4@2x.webp";
import img_5_v3_speicherladesystem_1_webp from "./details/11_Speicherladesystem/webp/Speicherladesystem1_4@2x.webp";
import img_6_v2_speicherladesystem_1_webp from "./details/11_Speicherladesystem/webp/Speicherladesystem1_5@2x.webp";
import img_6_v3_speicherladesystem_1_webp from "./details/11_Speicherladesystem/webp/Speicherladesystem1_5@2x.webp";
import img_7_v2_speicherladesystem_1_webp from "./details/11_Speicherladesystem/webp/Speicherladesystem1_6@2x.webp";
import img_7_v3_speicherladesystem_1_webp from "./details/11_Speicherladesystem/webp/Speicherladesystem1_6@2x.webp";

import img_2_v2_speicherladesystem_1_jpg from "./details/11_Speicherladesystem/jpg/Speicherladesystem1_1@2x.jpg";
import img_2_v3_speicherladesystem_1_jpg from "./details/11_Speicherladesystem/jpg/Speicherladesystem1_1@2x.jpg";
import img_3_v2_speicherladesystem_1_jpg from "./details/11_Speicherladesystem/jpg/Speicherladesystem1_2@2x.jpg";
import img_3_v3_speicherladesystem_1_jpg from "./details/11_Speicherladesystem/jpg/Speicherladesystem1_2@2x.jpg";
import img_4_v2_speicherladesystem_1_jpg from "./details/11_Speicherladesystem/jpg/Speicherladesystem1_3@2x.jpg";
import img_4_v3_speicherladesystem_1_jpg from "./details/11_Speicherladesystem/jpg/Speicherladesystem1_3@2x.jpg";
import img_5_v2_speicherladesystem_1_jpg from "./details/11_Speicherladesystem/jpg/Speicherladesystem1_4@2x.jpg";
import img_5_v3_speicherladesystem_1_jpg from "./details/11_Speicherladesystem/jpg/Speicherladesystem1_4@2x.jpg";
import img_6_v2_speicherladesystem_1_jpg from "./details/11_Speicherladesystem/jpg/Speicherladesystem1_5@2x.jpg";
import img_6_v3_speicherladesystem_1_jpg from "./details/11_Speicherladesystem/jpg/Speicherladesystem1_5@2x.jpg";
import img_7_v2_speicherladesystem_1_jpg from "./details/11_Speicherladesystem/jpg/Speicherladesystem1_6@2x.jpg";
import img_7_v3_speicherladesystem_1_jpg from "./details/11_Speicherladesystem/jpg/Speicherladesystem1_6@2x.jpg";

import img_2_v2_speicherladesystem_2_webp from "./details/11_Speicherladesystem/webp/Speicherladesystem2_1@2x.webp";
import img_2_v3_speicherladesystem_2_webp from "./details/11_Speicherladesystem/webp/Speicherladesystem2_1@2x.webp";
import img_3_v2_speicherladesystem_2_webp from "./details/11_Speicherladesystem/webp/Speicherladesystem2_2@2x.webp";
import img_3_v3_speicherladesystem_2_webp from "./details/11_Speicherladesystem/webp/Speicherladesystem2_2@2x.webp";
import img_4_v2_speicherladesystem_2_webp from "./details/11_Speicherladesystem/webp/Speicherladesystem2_3@2x.webp";
import img_4_v3_speicherladesystem_2_webp from "./details/11_Speicherladesystem/webp/Speicherladesystem2_3@2x.webp";
import img_5_v2_speicherladesystem_2_webp from "./details/11_Speicherladesystem/webp/Speicherladesystem2_4@2x.webp";
import img_5_v3_speicherladesystem_2_webp from "./details/11_Speicherladesystem/webp/Speicherladesystem2_4@2x.webp";
import img_6_v2_speicherladesystem_2_webp from "./details/11_Speicherladesystem/webp/Speicherladesystem2_5@2x.webp";
import img_6_v3_speicherladesystem_2_webp from "./details/11_Speicherladesystem/webp/Speicherladesystem2_5@2x.webp";
import img_7_v2_speicherladesystem_2_webp from "./details/11_Speicherladesystem/webp/Speicherladesystem2_6@2x.webp";
import img_7_v3_speicherladesystem_2_webp from "./details/11_Speicherladesystem/webp/Speicherladesystem2_6@2x.webp";

import img_2_v2_speicherladesystem_2_jpg from "./details/11_Speicherladesystem/jpg/Speicherladesystem2_1@2x.jpg";
import img_2_v3_speicherladesystem_2_jpg from "./details/11_Speicherladesystem/jpg/Speicherladesystem2_1@2x.jpg";
import img_3_v2_speicherladesystem_2_jpg from "./details/11_Speicherladesystem/jpg/Speicherladesystem2_2@2x.jpg";
import img_3_v3_speicherladesystem_2_jpg from "./details/11_Speicherladesystem/jpg/Speicherladesystem2_2@2x.jpg";
import img_4_v2_speicherladesystem_2_jpg from "./details/11_Speicherladesystem/jpg/Speicherladesystem2_3@2x.jpg";
import img_4_v3_speicherladesystem_2_jpg from "./details/11_Speicherladesystem/jpg/Speicherladesystem2_3@2x.jpg";
import img_5_v2_speicherladesystem_2_jpg from "./details/11_Speicherladesystem/jpg/Speicherladesystem2_4@2x.jpg";
import img_5_v3_speicherladesystem_2_jpg from "./details/11_Speicherladesystem/jpg/Speicherladesystem2_4@2x.jpg";
import img_6_v2_speicherladesystem_2_jpg from "./details/11_Speicherladesystem/jpg/Speicherladesystem2_5@2x.jpg";
import img_6_v3_speicherladesystem_2_jpg from "./details/11_Speicherladesystem/jpg/Speicherladesystem2_5@2x.jpg";
import img_7_v2_speicherladesystem_2_jpg from "./details/11_Speicherladesystem/jpg/Speicherladesystem2_6@2x.jpg";
import img_7_v3_speicherladesystem_2_jpg from "./details/11_Speicherladesystem/jpg/Speicherladesystem2_6@2x.jpg";

import img_2_v2_heizkreispumpe_1_webp from "./details/05_Heizkreispumpe/webp/Heizkreispumpe1_1@2x.webp";
import img_2_v3_heizkreispumpe_1_webp from "./details/05_Heizkreispumpe/webp/Heizkreispumpe1_1@2x.webp";
import img_3_v2_heizkreispumpe_1_webp from "./details/05_Heizkreispumpe/webp/Heizkreispumpe1_2@2x.webp";
import img_3_v3_heizkreispumpe_1_webp from "./details/05_Heizkreispumpe/webp/Heizkreispumpe1_2@2x.webp";
import img_4_v2_heizkreispumpe_1_webp from "./details/05_Heizkreispumpe/webp/Heizkreispumpe1_3@2x.webp";
import img_4_v3_heizkreispumpe_1_webp from "./details/05_Heizkreispumpe/webp/Heizkreispumpe1_3@2x.webp";
import img_2_v2_heizkreispumpe_2_webp from "./details/05_Heizkreispumpe/webp/Heizkreispumpe2_1@2x.webp";
import img_2_v3_heizkreispumpe_2_webp from "./details/05_Heizkreispumpe/webp/Heizkreispumpe2_1@2x.webp";
import img_3_v2_heizkreispumpe_2_webp from "./details/05_Heizkreispumpe/webp/Heizkreispumpe2_2@2x.webp";
import img_3_v3_heizkreispumpe_2_webp from "./details/05_Heizkreispumpe/webp/Heizkreispumpe2_2@2x.webp";
import img_4_v2_heizkreispumpe_2_webp from "./details/05_Heizkreispumpe/webp/Heizkreispumpe2_3@2x.webp";
import img_4_v3_heizkreispumpe_2_webp from "./details/05_Heizkreispumpe/webp/Heizkreispumpe2_3@2x.webp";
import img_2_v2_heizkreispumpe_3_webp from "./details/05_Heizkreispumpe/webp/Heizkreispumpe3_1@2x.webp";
import img_2_v3_heizkreispumpe_3_webp from "./details/05_Heizkreispumpe/webp/Heizkreispumpe3_1@2x.webp";
import img_3_v2_heizkreispumpe_3_webp from "./details/05_Heizkreispumpe/webp/Heizkreispumpe3_2@2x.webp";
import img_3_v3_heizkreispumpe_3_webp from "./details/05_Heizkreispumpe/webp/Heizkreispumpe3_2@2x.webp";
import img_4_v2_heizkreispumpe_3_webp from "./details/05_Heizkreispumpe/webp/Heizkreispumpe3_3@2x.webp";
import img_4_v3_heizkreispumpe_3_webp from "./details/05_Heizkreispumpe/webp/Heizkreispumpe3_3@2x.webp";
import img_2_v2_heizkreispumpe_4_webp from "./details/05_Heizkreispumpe/webp/Heizkreispumpe4_1@2x.webp";
import img_2_v3_heizkreispumpe_4_webp from "./details/05_Heizkreispumpe/webp/Heizkreispumpe4_1@2x.webp";
import img_3_v2_heizkreispumpe_4_webp from "./details/05_Heizkreispumpe/webp/Heizkreispumpe4_2@2x.webp";
import img_3_v3_heizkreispumpe_4_webp from "./details/05_Heizkreispumpe/webp/Heizkreispumpe4_2@2x.webp";

import img_2_v2_heizkreispumpe_1_jpg from "./details/05_Heizkreispumpe/jpg/Heizkreispumpe1_1@2x.jpg";
import img_2_v3_heizkreispumpe_1_jpg from "./details/05_Heizkreispumpe/jpg/Heizkreispumpe1_1@2x.jpg";
import img_3_v2_heizkreispumpe_1_jpg from "./details/05_Heizkreispumpe/jpg/Heizkreispumpe1_2@2x.jpg";
import img_3_v3_heizkreispumpe_1_jpg from "./details/05_Heizkreispumpe/jpg/Heizkreispumpe1_2@2x.jpg";
import img_4_v2_heizkreispumpe_1_jpg from "./details/05_Heizkreispumpe/jpg/Heizkreispumpe1_3@2x.jpg";
import img_4_v3_heizkreispumpe_1_jpg from "./details/05_Heizkreispumpe/jpg/Heizkreispumpe1_3@2x.jpg";

import img_2_v2_heizkreispumpe_2_jpg from "./details/05_Heizkreispumpe/jpg/Heizkreispumpe2_1@2x.jpg";
import img_2_v3_heizkreispumpe_2_jpg from "./details/05_Heizkreispumpe/jpg/Heizkreispumpe2_1@2x.jpg";
import img_3_v2_heizkreispumpe_2_jpg from "./details/05_Heizkreispumpe/jpg/Heizkreispumpe2_2@2x.jpg";
import img_3_v3_heizkreispumpe_2_jpg from "./details/05_Heizkreispumpe/jpg/Heizkreispumpe2_2@2x.jpg";
import img_4_v2_heizkreispumpe_2_jpg from "./details/05_Heizkreispumpe/jpg/Heizkreispumpe2_3@2x.jpg";
import img_4_v3_heizkreispumpe_2_jpg from "./details/05_Heizkreispumpe/jpg/Heizkreispumpe2_3@2x.jpg";

import img_2_v2_heizkreispumpe_3_jpg from "./details/05_Heizkreispumpe/jpg/Heizkreispumpe3_1@2x.jpg";
import img_2_v3_heizkreispumpe_3_jpg from "./details/05_Heizkreispumpe/jpg/Heizkreispumpe3_1@2x.jpg";
import img_3_v2_heizkreispumpe_3_jpg from "./details/05_Heizkreispumpe/jpg/Heizkreispumpe3_2@2x.jpg";
import img_3_v3_heizkreispumpe_3_jpg from "./details/05_Heizkreispumpe/jpg/Heizkreispumpe3_2@2x.jpg";
import img_4_v2_heizkreispumpe_3_jpg from "./details/05_Heizkreispumpe/jpg/Heizkreispumpe3_3@2x.jpg";
import img_4_v3_heizkreispumpe_3_jpg from "./details/05_Heizkreispumpe/jpg/Heizkreispumpe3_3@2x.jpg";

import img_2_v2_heizkreispumpe_4_jpg from "./details/05_Heizkreispumpe/jpg/Heizkreispumpe4_1@2x.jpg";
import img_2_v3_heizkreispumpe_4_jpg from "./details/05_Heizkreispumpe/jpg/Heizkreispumpe4_1@2x.jpg";
import img_3_v2_heizkreispumpe_4_jpg from "./details/05_Heizkreispumpe/jpg/Heizkreispumpe4_2@2x.jpg";
import img_3_v3_heizkreispumpe_4_jpg from "./details/05_Heizkreispumpe/jpg/Heizkreispumpe4_2@2x.jpg";


import img_2_v2_zirkulationspumpe_1_webp from "./details/06_Zirkulationspumpe/webp/Zirkulationspumpe1_1@2x.webp";
import img_2_v3_zirkulationspumpe_1_webp from "./details/06_Zirkulationspumpe/webp/Zirkulationspumpe1_1@2x.webp";
import img_3_v2_zirkulationspumpe_1_webp from "./details/06_Zirkulationspumpe/webp/Zirkulationspumpe1_2@2x.webp";
import img_3_v3_zirkulationspumpe_1_webp from "./details/06_Zirkulationspumpe/webp/Zirkulationspumpe1_2@2x.webp";

import img_2_v2_zirkulationspumpe_2_webp from "./details/06_Zirkulationspumpe/webp/Zirkulationspumpe2_1@2x.webp";
import img_2_v3_zirkulationspumpe_2_webp from "./details/06_Zirkulationspumpe/webp/Zirkulationspumpe2_1@2x.webp";
import img_3_v2_zirkulationspumpe_2_webp from "./details/06_Zirkulationspumpe/webp/Zirkulationspumpe2_2@2x.webp";
import img_3_v3_zirkulationspumpe_2_webp from "./details/06_Zirkulationspumpe/webp/Zirkulationspumpe2_2@2x.webp";


import img_2_v2_zirkulationspumpe_1_jpg from "./details/06_Zirkulationspumpe/jpg/Zirkulationspumpe1_1@2x.jpg";
import img_2_v3_zirkulationspumpe_1_jpg from "./details/06_Zirkulationspumpe/jpg/Zirkulationspumpe1_1@2x.jpg";
import img_3_v2_zirkulationspumpe_1_jpg from "./details/06_Zirkulationspumpe/jpg/Zirkulationspumpe1_2@2x.jpg";
import img_3_v3_zirkulationspumpe_1_jpg from "./details/06_Zirkulationspumpe/jpg/Zirkulationspumpe1_2@2x.jpg";

import img_2_v2_zirkulationspumpe_2_jpg from "./details/06_Zirkulationspumpe/jpg/Zirkulationspumpe2_1@2x.jpg";
import img_2_v3_zirkulationspumpe_2_jpg from "./details/06_Zirkulationspumpe/jpg/Zirkulationspumpe2_1@2x.jpg";
import img_3_v2_zirkulationspumpe_2_jpg from "./details/06_Zirkulationspumpe/jpg/Zirkulationspumpe2_2@2x.jpg";
import img_3_v3_zirkulationspumpe_2_jpg from "./details/06_Zirkulationspumpe/jpg/Zirkulationspumpe2_2@2x.jpg";


import img_2_v2_warmetauscher_1_webp from "./details/07_Warmetauscher/webp/Warmetauscher1_1@2x.webp";
import img_2_v3_warmetauscher_1_webp from "./details/07_Warmetauscher/webp/Warmetauscher1_1@2x.webp";
import img_3_v2_warmetauscher_1_webp from "./details/07_Warmetauscher/webp/Warmetauscher1_2@2x.webp";
import img_3_v3_warmetauscher_1_webp from "./details/07_Warmetauscher/webp/Warmetauscher1_2@2x.webp";
import img_4_v2_warmetauscher_1_webp from "./details/07_Warmetauscher/webp/Warmetauscher1_3@2x.webp";
import img_4_v3_warmetauscher_1_webp from "./details/07_Warmetauscher/webp/Warmetauscher1_3@2x.webp";

import img_2_v2_warmetauscher_2_webp from "./details/07_Warmetauscher/webp/Warmetauscher2_1@2x.webp";
import img_2_v3_warmetauscher_2_webp from "./details/07_Warmetauscher/webp/Warmetauscher2_1@2x.webp";
import img_3_v2_warmetauscher_2_webp from "./details/07_Warmetauscher/webp/Warmetauscher2_2@2x.webp";
import img_3_v3_warmetauscher_2_webp from "./details/07_Warmetauscher/webp/Warmetauscher2_2@2x.webp";
import img_4_v2_warmetauscher_2_webp from "./details/07_Warmetauscher/webp/Warmetauscher2_3@2x.webp";
import img_4_v3_warmetauscher_2_webp from "./details/07_Warmetauscher/webp/Warmetauscher2_3@2x.webp";


import img_2_v2_warmetauscher_1_jpg from "./details/07_Warmetauscher/jpg/Warmetauscher1_1@2x.jpg";
import img_2_v3_warmetauscher_1_jpg from "./details/07_Warmetauscher/jpg/Warmetauscher1_1@2x.jpg";
import img_3_v2_warmetauscher_1_jpg from "./details/07_Warmetauscher/jpg/Warmetauscher1_2@2x.jpg";
import img_3_v3_warmetauscher_1_jpg from "./details/07_Warmetauscher/jpg/Warmetauscher1_2@2x.jpg";
import img_4_v2_warmetauscher_1_jpg from "./details/07_Warmetauscher/jpg/Warmetauscher1_3@2x.jpg";
import img_4_v3_warmetauscher_1_jpg from "./details/07_Warmetauscher/jpg/Warmetauscher1_3@2x.jpg";

import img_2_v2_warmetauscher_2_jpg from "./details/07_Warmetauscher/jpg/Warmetauscher2_1@2x.jpg";
import img_2_v3_warmetauscher_2_jpg from "./details/07_Warmetauscher/jpg/Warmetauscher2_1@2x.jpg";
import img_3_v2_warmetauscher_2_jpg from "./details/07_Warmetauscher/jpg/Warmetauscher2_2@2x.jpg";
import img_3_v3_warmetauscher_2_jpg from "./details/07_Warmetauscher/jpg/Warmetauscher2_2@2x.jpg";
import img_4_v2_warmetauscher_2_jpg from "./details/07_Warmetauscher/jpg/Warmetauscher2_3@2x.jpg";
import img_4_v3_warmetauscher_2_jpg from "./details/07_Warmetauscher/jpg/Warmetauscher2_3@2x.jpg";


import img_2_v2_vorlagepumpe_webp from "./details/08_Vorlagepumpe/webp/Vorlagepumpe_1@2x.webp";
import img_2_v3_vorlagepumpe_webp from "./details/08_Vorlagepumpe/webp/Vorlagepumpe_1@2x.webp";
import img_3_v2_vorlagepumpe_webp from "./details/08_Vorlagepumpe/webp/Vorlagepumpe_2@2x.webp";
import img_3_v3_vorlagepumpe_webp from "./details/08_Vorlagepumpe/webp/Vorlagepumpe_2@2x.webp";
import img_4_v2_vorlagepumpe_webp from "./details/08_Vorlagepumpe/webp/Vorlagepumpe_3@2x.webp";
import img_4_v3_vorlagepumpe_webp from "./details/08_Vorlagepumpe/webp/Vorlagepumpe_3@2x.webp";
import img_5_v2_vorlagepumpe_webp from "./details/08_Vorlagepumpe/webp/Vorlagepumpe_4@2x.webp";
import img_5_v3_vorlagepumpe_webp from "./details/08_Vorlagepumpe/webp/Vorlagepumpe_4@2x.webp";


import img_2_v2_vorlagepumpe_jpg from "./details/08_Vorlagepumpe/jpg/Vorlagepumpe_1@2x.jpg";
import img_2_v3_vorlagepumpe_jpg from "./details/08_Vorlagepumpe/jpg/Vorlagepumpe_1@2x.jpg";
import img_3_v2_vorlagepumpe_jpg from "./details/08_Vorlagepumpe/jpg/Vorlagepumpe_2@2x.jpg";
import img_3_v3_vorlagepumpe_jpg from "./details/08_Vorlagepumpe/jpg/Vorlagepumpe_2@2x.jpg";
import img_4_v2_vorlagepumpe_jpg from "./details/08_Vorlagepumpe/jpg/Vorlagepumpe_3@2x.jpg";
import img_4_v3_vorlagepumpe_jpg from "./details/08_Vorlagepumpe/jpg/Vorlagepumpe_3@2x.jpg";
import img_5_v2_vorlagepumpe_jpg from "./details/08_Vorlagepumpe/jpg/Vorlagepumpe_4@2x.jpg";
import img_5_v3_vorlagepumpe_jpg from "./details/08_Vorlagepumpe/jpg/Vorlagepumpe_4@2x.jpg";


import img_2_v2_kesselpumpe_1_webp from "./details/09_Kesselpumpe/webp/Kesselpumpe1_1@2x.webp";
import img_2_v3_kesselpumpe_1_webp from "./details/09_Kesselpumpe/webp/Kesselpumpe1_1@2x.webp";
import img_3_v2_kesselpumpe_1_webp from "./details/09_Kesselpumpe/webp/Kesselpumpe1_2@2x.webp";
import img_3_v3_kesselpumpe_1_webp from "./details/09_Kesselpumpe/webp/Kesselpumpe1_2@2x.webp";

import img_2_v2_kesselpumpe_2_webp from "./details/09_Kesselpumpe/webp/Kesselpumpe2_1@2x.webp";
import img_2_v3_kesselpumpe_2_webp from "./details/09_Kesselpumpe/webp/Kesselpumpe2_1@2x.webp";


import img_2_v2_kesselpumpe_1_jpg from "./details/09_Kesselpumpe/jpg/Kesselpumpe1_1@2x.jpg";
import img_2_v3_kesselpumpe_1_jpg from "./details/09_Kesselpumpe/jpg/Kesselpumpe1_1@2x.jpg";
import img_3_v2_kesselpumpe_1_jpg from "./details/09_Kesselpumpe/jpg/Kesselpumpe1_2@2x.jpg";
import img_3_v3_kesselpumpe_1_jpg from "./details/09_Kesselpumpe/jpg/Kesselpumpe1_2@2x.jpg";

import img_2_v2_kesselpumpe_2_jpg from "./details/09_Kesselpumpe/jpg/Kesselpumpe2_1@2x.jpg";
import img_2_v3_kesselpumpe_2_jpg from "./details/09_Kesselpumpe/jpg/Kesselpumpe2_1@2x.jpg";


import img_2_v2_heizkessel_1_webp from "./details/10_Heizkessel/webp/Heizkessel1_1@2x.webp";
import img_2_v3_heizkessel_1_webp from "./details/10_Heizkessel/webp/Heizkessel1_1@2x.webp";
import img_3_v2_heizkessel_1_webp from "./details/10_Heizkessel/webp/Heizkessel1_2@2x.webp";
import img_3_v3_heizkessel_1_webp from "./details/10_Heizkessel/webp/Heizkessel1_2@2x.webp";
import img_4_v2_heizkessel_1_webp from "./details/10_Heizkessel/webp/Heizkessel1_3@2x.webp";
import img_4_v3_heizkessel_1_webp from "./details/10_Heizkessel/webp/Heizkessel1_3@2x.webp";
import img_5_v2_heizkessel_1_webp from "./details/10_Heizkessel/webp/Heizkessel1_4@2x.webp";
import img_5_v3_heizkessel_1_webp from "./details/10_Heizkessel/webp/Heizkessel1_4@2x.webp";
import img_6_v2_heizkessel_1_webp from "./details/10_Heizkessel/webp/Heizkessel1_5@2x.webp";
import img_6_v3_heizkessel_1_webp from "./details/10_Heizkessel/webp/Heizkessel1_5@2x.webp";

import img_2_v2_heizkessel_2_webp from "./details/10_Heizkessel/webp/Heizkessel2_1@2x.webp";
import img_2_v3_heizkessel_2_webp from "./details/10_Heizkessel/webp/Heizkessel2_1@2x.webp";
import img_3_v2_heizkessel_2_webp from "./details/10_Heizkessel/webp/Heizkessel2_2@2x.webp";
import img_3_v3_heizkessel_2_webp from "./details/10_Heizkessel/webp/Heizkessel2_2@2x.webp";
import img_4_v2_heizkessel_2_webp from "./details/10_Heizkessel/webp/Heizkessel2_3@2x.webp";
import img_4_v3_heizkessel_2_webp from "./details/10_Heizkessel/webp/Heizkessel2_3@2x.webp";
import img_5_v2_heizkessel_2_webp from "./details/10_Heizkessel/webp/Heizkessel2_4@2x.webp";
import img_5_v3_heizkessel_2_webp from "./details/10_Heizkessel/webp/Heizkessel2_4@2x.webp";


import img_2_v2_heizkessel_1_jpg from "./details/10_Heizkessel/jpg/Heizkessel1_1@2x.jpg";
import img_2_v3_heizkessel_1_jpg from "./details/10_Heizkessel/jpg/Heizkessel1_1@2x.jpg";
import img_3_v2_heizkessel_1_jpg from "./details/10_Heizkessel/jpg/Heizkessel1_2@2x.jpg";
import img_3_v3_heizkessel_1_jpg from "./details/10_Heizkessel/jpg/Heizkessel1_2@2x.jpg";
import img_4_v2_heizkessel_1_jpg from "./details/10_Heizkessel/jpg/Heizkessel1_3@2x.jpg";
import img_4_v3_heizkessel_1_jpg from "./details/10_Heizkessel/jpg/Heizkessel1_3@2x.jpg";
import img_5_v2_heizkessel_1_jpg from "./details/10_Heizkessel/jpg/Heizkessel1_4@2x.jpg";
import img_5_v3_heizkessel_1_jpg from "./details/10_Heizkessel/jpg/Heizkessel1_4@2x.jpg";
import img_6_v2_heizkessel_1_jpg from "./details/10_Heizkessel/jpg/Heizkessel1_5@2x.jpg";
import img_6_v3_heizkessel_1_jpg from "./details/10_Heizkessel/jpg/Heizkessel1_5@2x.jpg";

import img_2_v2_heizkessel_2_jpg from "./details/10_Heizkessel/jpg/Heizkessel2_1@2x.jpg";
import img_2_v3_heizkessel_2_jpg from "./details/10_Heizkessel/jpg/Heizkessel2_1@2x.jpg";
import img_3_v2_heizkessel_2_jpg from "./details/10_Heizkessel/jpg/Heizkessel2_2@2x.jpg";
import img_3_v3_heizkessel_2_jpg from "./details/10_Heizkessel/jpg/Heizkessel2_2@2x.jpg";
import img_4_v2_heizkessel_2_jpg from "./details/10_Heizkessel/jpg/Heizkessel2_3@2x.jpg";
import img_4_v3_heizkessel_2_jpg from "./details/10_Heizkessel/jpg/Heizkessel2_3@2x.jpg";
import img_5_v2_heizkessel_2_jpg from "./details/10_Heizkessel/jpg/Heizkessel2_4@2x.jpg";
import img_5_v3_heizkessel_2_jpg from "./details/10_Heizkessel/jpg/Heizkessel2_4@2x.jpg";

const images_NiehlerGurtel = {
    'Kaltwassereingang': {
        webp: {
            img_2: [img_2_v2_kaltwassereingang_webp, img_2_v3_kaltwassereingang_webp],
            img_3: [img_3_v2_kaltwassereingang_webp, img_3_v3_kaltwassereingang_webp],
            img_4: [img_4_v2_kaltwassereingang_webp, img_4_v3_kaltwassereingang_webp],
            img_5: [img_5_v2_kaltwassereingang_webp, img_5_v3_kaltwassereingang_webp]
        },
        jpg: {

            img_2: [img_2_v2_kaltwassereingang_jpg, img_2_v3_kaltwassereingang_jpg],
            img_3: [img_3_v2_kaltwassereingang_jpg, img_3_v3_kaltwassereingang_jpg],
            img_4: [img_4_v2_kaltwassereingang_jpg, img_4_v3_kaltwassereingang_jpg],
            img_5: [img_5_v2_kaltwassereingang_jpg, img_5_v3_kaltwassereingang_jpg]
        }
    },
    'Rukspulfilter': {
        webp: {
            //img_1: [img_1_v2_ruckspulfilter_webp, img_1_v3_ruckspulfilter_webp],
            img_2: [img_2_v2_ruckspulfilter_webp, img_2_v3_ruckspulfilter_webp],
            img_3: [img_3_v2_ruckspulfilter_webp, img_3_v3_ruckspulfilter_webp]
        },
        jpg: {
            //img_1: [img_1_v2_ruckspulfilter_jpg, img_1_v3_ruckspulfilter_jpg],
            img_2: [img_2_v2_ruckspulfilter_jpg, img_2_v3_ruckspulfilter_jpg],
            img_3: [img_3_v2_ruckspulfilter_jpg, img_3_v3_ruckspulfilter_jpg]
        }
    },
    'Warmwasserspeicher': {
        'Warmwasserspeicher_1': {
            webp: {
                img_2: [img_2_v2_warmwasserspeicher_1_webp, img_2_v3_warmwasserspeicher_1_webp],
                img_3: [img_3_v2_warmwasserspeicher_1_webp, img_3_v3_warmwasserspeicher_1_webp]
            },
            jpg: {
                //img_1: [img_1_v2_warmwasserspeicher_1_jpg, img_1_v3_warmwasserspeicher_1_jpg],
                img_2: [img_2_v2_warmwasserspeicher_1_jpg, img_2_v3_warmwasserspeicher_1_jpg],
                img_3: [img_3_v2_warmwasserspeicher_1_jpg, img_3_v3_warmwasserspeicher_1_jpg]
            }
        },
        'Warmwasserspeicher_2': {
            webp: {
                //img_1: [img_1_v2_warmwasserspeicher_2_webp, img_1_v3_warmwasserspeicher_2_webp],
                img_2: [img_2_v2_warmwasserspeicher_2_webp, img_2_v3_warmwasserspeicher_2_webp],
                img_3: [img_3_v2_warmwasserspeicher_2_webp, img_3_v3_warmwasserspeicher_2_webp],
                img_4: [img_4_v2_warmwasserspeicher_2_webp, img_4_v3_warmwasserspeicher_2_webp]
            },
            jpg: {
                //img_1: [img_1_v2_warmwasserspeicher_2_jpg, img_1_v3_warmwasserspeicher_2_jpg],
                img_2: [img_2_v2_warmwasserspeicher_2_jpg, img_2_v3_warmwasserspeicher_2_jpg],
                img_3: [img_3_v2_warmwasserspeicher_2_jpg, img_3_v3_warmwasserspeicher_2_jpg],
                img_4: [img_4_v2_warmwasserspeicher_2_jpg, img_4_v3_warmwasserspeicher_2_jpg]
            }
        }
    },
    'Speicherladepumpe': {
        webp: {
            //img_1: [img_1_v2_speicherladepumpe_webp, img_1_v3_speicherladepumpe_webp],
            img_2: [img_2_v2_speicherladepumpe_webp, img_2_v3_speicherladepumpe_webp]
        },
        jpg: {
            //img_1: [img_1_v2_speicherladepumpe_jpg, img_1_v3_speicherladepumpe_jpg],
            img_2: [img_2_v2_speicherladepumpe_jpg, img_2_v3_speicherladepumpe_jpg]
        }
    },
    'Speicherladesystem': {
        'Speicherladesystem_1': {
            webp: {
                //img_1: [img_1_v2_speicherladesystem_1_webp, img_1_v3_speicherladesystem_1_webp],
                img_2: [img_2_v2_speicherladesystem_1_webp, img_2_v3_speicherladesystem_1_webp],
                img_3: [img_3_v2_speicherladesystem_1_webp, img_3_v3_speicherladesystem_1_webp],
                img_4: [img_4_v2_speicherladesystem_1_webp, img_4_v3_speicherladesystem_1_webp],
                img_5: [img_5_v2_speicherladesystem_1_webp, img_5_v3_speicherladesystem_1_webp],
                img_6: [img_6_v2_speicherladesystem_1_webp, img_6_v3_speicherladesystem_1_webp],
                img_7: [img_7_v2_speicherladesystem_1_webp, img_7_v3_speicherladesystem_1_webp]
            },
            jpg: {
                //img_1: [img_1_v2_speicherladesystem_1_jpg, img_1_v3_speicherladesystem_1_jpg],
                img_2: [img_2_v2_speicherladesystem_1_jpg, img_2_v3_speicherladesystem_1_jpg],
                img_3: [img_3_v2_speicherladesystem_1_jpg, img_3_v3_speicherladesystem_1_jpg],
                img_4: [img_4_v2_speicherladesystem_1_jpg, img_4_v3_speicherladesystem_1_jpg],
                img_5: [img_5_v2_speicherladesystem_1_jpg, img_5_v3_speicherladesystem_1_jpg],
                img_6: [img_6_v2_speicherladesystem_1_jpg, img_6_v3_speicherladesystem_1_jpg],
                img_7: [img_7_v2_speicherladesystem_1_jpg, img_7_v3_speicherladesystem_1_jpg]
            }
        },
        'Speicherladesystem_2': {
            webp: {
                //img_1: [img_1_v2_speicherladesystem_2_webp, img_1_v3_speicherladesystem_2_webp],
                img_2: [img_2_v2_speicherladesystem_2_webp, img_2_v3_speicherladesystem_2_webp],
                img_3: [img_3_v2_speicherladesystem_2_webp, img_3_v3_speicherladesystem_2_webp],
                img_4: [img_4_v2_speicherladesystem_2_webp, img_4_v3_speicherladesystem_2_webp],
                img_5: [img_5_v2_speicherladesystem_2_webp, img_5_v3_speicherladesystem_2_webp],
                img_6: [img_6_v2_speicherladesystem_2_webp, img_6_v3_speicherladesystem_2_webp],
                img_7: [img_7_v2_speicherladesystem_2_webp, img_7_v3_speicherladesystem_2_webp]
            },
            jpg: {
                //img_1: [img_1_v2_speicherladesystem_2_jpg, img_1_v3_speicherladesystem_2_jpg],
                img_2: [img_2_v2_speicherladesystem_2_jpg, img_2_v3_speicherladesystem_2_jpg],
                img_3: [img_3_v2_speicherladesystem_2_jpg, img_3_v3_speicherladesystem_2_jpg],
                img_4: [img_4_v2_speicherladesystem_2_jpg, img_4_v3_speicherladesystem_2_jpg],
                img_5: [img_5_v2_speicherladesystem_2_jpg, img_5_v3_speicherladesystem_2_jpg],
                img_6: [img_6_v2_speicherladesystem_2_jpg, img_6_v3_speicherladesystem_2_jpg],
                img_7: [img_7_v2_speicherladesystem_2_jpg, img_7_v3_speicherladesystem_2_jpg]
            }
        }
    },
    'Heizkreispumpe': {
        'Heizkreispumpe_1': {
            webp: {
                //img_1: [img_1_v2_heizkreispumpe_1_webp, img_1_v3_heizkreispumpe_1_webp],
                img_2: [img_2_v2_heizkreispumpe_1_webp, img_2_v3_heizkreispumpe_1_webp],
                img_3: [img_3_v2_heizkreispumpe_1_webp, img_3_v3_heizkreispumpe_1_webp],
                img_4: [img_4_v2_heizkreispumpe_1_webp, img_4_v3_heizkreispumpe_1_webp]
            },
            jpg: {
                //img_1: [img_1_v2_heizkreispumpe_1_jpg, img_1_v3_heizkreispumpe_1_jpg],
                img_2: [img_2_v2_heizkreispumpe_1_jpg, img_2_v3_heizkreispumpe_1_jpg],
                img_3: [img_3_v2_heizkreispumpe_1_jpg, img_3_v3_heizkreispumpe_1_jpg],
                img_4: [img_4_v2_heizkreispumpe_1_jpg, img_4_v3_heizkreispumpe_1_jpg]
            }
        },
        'Heizkreispumpe_2': {
            webp: {
                //img_1: [img_1_v2_heizkreispumpe_2_webp, img_1_v3_heizkreispumpe_2_webp],
                img_2: [img_2_v2_heizkreispumpe_2_webp, img_2_v3_heizkreispumpe_2_webp],
                img_3: [img_3_v2_heizkreispumpe_2_webp, img_3_v3_heizkreispumpe_2_webp],
                img_4: [img_4_v2_heizkreispumpe_2_webp, img_4_v3_heizkreispumpe_2_webp]
            },
            jpg: {
                //img_1: [img_1_v2_heizkreispumpe_2_jpg, img_1_v3_heizkreispumpe_2_jpg],
                img_2: [img_2_v2_heizkreispumpe_2_jpg, img_2_v3_heizkreispumpe_2_jpg],
                img_3: [img_3_v2_heizkreispumpe_2_jpg, img_3_v3_heizkreispumpe_2_jpg],
                img_4: [img_4_v2_heizkreispumpe_2_jpg, img_4_v3_heizkreispumpe_2_jpg]
            }
        },
        'Heizkreispumpe_3': {
            webp: {
                //img_1: [img_1_v2_heizkreispumpe_3_webp, img_1_v3_heizkreispumpe_3_webp],
                img_2: [img_2_v2_heizkreispumpe_3_webp, img_2_v3_heizkreispumpe_3_webp],
                img_3: [img_3_v2_heizkreispumpe_3_webp, img_3_v3_heizkreispumpe_3_webp],
                img_4: [img_4_v2_heizkreispumpe_3_webp, img_4_v3_heizkreispumpe_3_webp]
            },
            jpg: {
                //img_1: [img_1_v2_heizkreispumpe_3_jpg, img_1_v3_heizkreispumpe_3_jpg],
                img_2: [img_2_v2_heizkreispumpe_3_jpg, img_2_v3_heizkreispumpe_3_jpg],
                img_3: [img_3_v2_heizkreispumpe_3_jpg, img_3_v3_heizkreispumpe_3_jpg],
                img_4: [img_4_v2_heizkreispumpe_3_jpg, img_4_v3_heizkreispumpe_3_jpg]
            }
        },
        'Heizkreispumpe_4': {
            webp: {
                //img_1: [img_1_v2_heizkreispumpe_4_webp, img_1_v3_heizkreispumpe_4_webp],
                img_2: [img_2_v2_heizkreispumpe_4_webp, img_2_v3_heizkreispumpe_4_webp],
                img_3: [img_3_v2_heizkreispumpe_4_webp, img_3_v3_heizkreispumpe_4_webp]
            },
            jpg: {
                //img_1: [img_1_v2_heizkreispumpe_4_jpg, img_1_v3_heizkreispumpe_4_jpg],
                img_2: [img_2_v2_heizkreispumpe_4_jpg, img_2_v3_heizkreispumpe_4_jpg],
                img_3: [img_3_v2_heizkreispumpe_4_jpg, img_3_v3_heizkreispumpe_4_jpg]
            }
        }
    },
    'Zirkulationspumpe': {
        'Zirkulationspumpe_1': {
            webp: {
                //img_1: [img_1_v2_zirkulationspumpe_1_webp, img_1_v3_zirkulationspumpe_1_webp],
                img_2: [img_2_v2_zirkulationspumpe_1_webp, img_2_v3_zirkulationspumpe_1_webp],
                img_3: [img_3_v2_zirkulationspumpe_1_webp, img_3_v3_zirkulationspumpe_1_webp]

            },
            jpg: {
                //img_1: [img_1_v2_zirkulationspumpe_1_jpg, img_1_v3_zirkulationspumpe_1_jpg],
                img_2: [img_2_v2_zirkulationspumpe_1_jpg, img_2_v3_zirkulationspumpe_1_jpg],
                img_3: [img_3_v2_zirkulationspumpe_1_jpg, img_3_v3_zirkulationspumpe_1_jpg]
            }
        },
        'Zirkulationspumpe_2': {
            webp: {
                //img_1: [img_1_v2_zirkulationspumpe_2_webp, img_1_v3_zirkulationspumpe_2_webp],
                img_2: [img_2_v2_zirkulationspumpe_2_webp, img_2_v3_zirkulationspumpe_2_webp],
                img_3: [img_3_v2_zirkulationspumpe_2_webp, img_3_v3_zirkulationspumpe_2_webp]
            },
            jpg: {
                //img_1: [img_1_v2_zirkulationspumpe_2_jpg, img_1_v3_zirkulationspumpe_2_jpg],
                img_2: [img_2_v2_zirkulationspumpe_2_jpg, img_2_v3_zirkulationspumpe_2_jpg],
                img_3: [img_3_v2_zirkulationspumpe_2_jpg, img_3_v3_zirkulationspumpe_2_jpg]
            }
        }
    },
    'Warmetauscher': {
        'Warmetauscher_1': {
            webp: {
                //img_1: [img_1_v2_warmetauscher_1_webp, img_1_v3_warmetauscher_1_webp],
                img_2: [img_2_v2_warmetauscher_1_webp, img_2_v3_warmetauscher_1_webp],
                img_3: [img_3_v2_warmetauscher_1_webp, img_3_v3_warmetauscher_1_webp],
                img_4: [img_4_v2_warmetauscher_1_webp, img_4_v3_warmetauscher_1_webp],
            },
            jpg: {
                //img_1: [img_1_v2_warmetauscher_1_jpg, img_1_v3_warmetauscher_1_jpg],
                img_2: [img_2_v2_warmetauscher_1_jpg, img_2_v3_warmetauscher_1_jpg],
                img_3: [img_3_v2_warmetauscher_1_jpg, img_3_v3_warmetauscher_1_jpg],
                img_4: [img_4_v2_warmetauscher_1_jpg, img_4_v3_warmetauscher_1_jpg],
            }
        },
        'Warmetauscher_2': {
            webp: {
                //img_1: [img_1_v2_warmetauscher_2_webp, img_1_v3_warmetauscher_2_webp],
                img_2: [img_2_v2_warmetauscher_2_webp, img_2_v3_warmetauscher_2_webp],
                img_3: [img_3_v2_warmetauscher_2_webp, img_3_v3_warmetauscher_2_webp],
                img_4: [img_4_v2_warmetauscher_2_webp, img_4_v3_warmetauscher_2_webp],
            },
            jpg: {
                //img_1: [img_1_v2_warmetauscher_2_jpg, img_1_v3_warmetauscher_2_jpg],
                img_2: [img_2_v2_warmetauscher_2_jpg, img_2_v3_warmetauscher_2_jpg],
                img_3: [img_3_v2_warmetauscher_2_jpg, img_3_v3_warmetauscher_2_jpg],
                img_4: [img_4_v2_warmetauscher_2_jpg, img_4_v3_warmetauscher_2_jpg],
            }
        }
    },
    'Vorlagepumpe': {
        webp: {
            //img_1: [img_1_v2_vorlagepumpe_webp, img_1_v3_vorlagepumpe_webp],
            img_2: [img_2_v2_vorlagepumpe_webp, img_2_v3_vorlagepumpe_webp],
            img_3: [img_3_v2_vorlagepumpe_webp, img_3_v3_vorlagepumpe_webp],
            img_4: [img_4_v2_vorlagepumpe_webp, img_4_v3_vorlagepumpe_webp],
            img_5: [img_5_v2_vorlagepumpe_webp, img_5_v3_vorlagepumpe_webp]
        },
        jpg: {
            //img_1: [img_1_v2_vorlagepumpe_jpg, img_1_v3_vorlagepumpe_jpg],
            img_2: [img_2_v2_vorlagepumpe_jpg, img_2_v3_vorlagepumpe_jpg],
            img_3: [img_3_v2_vorlagepumpe_jpg, img_3_v3_vorlagepumpe_jpg],
            img_4: [img_4_v2_vorlagepumpe_jpg, img_4_v3_vorlagepumpe_jpg],
            img_5: [img_5_v2_vorlagepumpe_jpg, img_5_v3_vorlagepumpe_jpg]
        }
    },
    'Kesselpumpe': {
        'Kesselpumpe_1': {
            webp: {
                //img_1: [img_1_v2_kesselpumpe_1_webp, img_1_v3_kesselpumpe_1_webp],
                img_2: [img_2_v2_kesselpumpe_1_webp, img_2_v3_kesselpumpe_1_webp],
                img_3: [img_3_v2_kesselpumpe_1_webp, img_3_v3_kesselpumpe_1_webp]
            },
            jpg: {
                //img_1: [img_1_v2_kesselpumpe_1_jpg, img_1_v3_kesselpumpe_1_jpg],
                img_2: [img_2_v2_kesselpumpe_1_jpg, img_2_v3_kesselpumpe_1_jpg],
                img_3: [img_3_v2_kesselpumpe_1_jpg, img_3_v3_kesselpumpe_1_jpg]
            }
        },
        'Kesselpumpe_2': {
            webp: {
                //img_1: [img_1_v2_kesselpumpe_2_webp, img_1_v3_kesselpumpe_2_webp],
                img_2: [img_2_v2_kesselpumpe_2_webp, img_2_v3_kesselpumpe_2_webp]
            },
            jpg: {
                ///img_1: [img_1_v2_kesselpumpe_2_jpg, img_1_v3_kesselpumpe_2_jpg],
                img_2: [img_2_v2_kesselpumpe_2_jpg, img_2_v3_kesselpumpe_2_jpg]
            }
        }
    },
    'Heizkessel': {
        'Heizkessel_1': {
            webp: {
                //img_1: [img_1_v2_heizkessel_1_webp, img_1_v3_heizkessel_1_webp],
                img_2: [img_2_v2_heizkessel_1_webp, img_2_v3_heizkessel_1_webp],
                img_3: [img_3_v2_heizkessel_1_webp, img_3_v3_heizkessel_1_webp],
                img_4: [img_4_v2_heizkessel_1_webp, img_4_v3_heizkessel_1_webp],
                img_5: [img_5_v2_heizkessel_1_webp, img_5_v3_heizkessel_1_webp],
                img_6: [img_6_v2_heizkessel_1_webp, img_6_v3_heizkessel_1_webp]
            },
            jpg: {
                //img_1: [img_1_v2_heizkessel_1_jpg, img_1_v3_heizkessel_1_jpg],
                img_2: [img_2_v2_heizkessel_1_jpg, img_2_v3_heizkessel_1_jpg],
                img_3: [img_3_v2_heizkessel_1_jpg, img_3_v3_heizkessel_1_jpg],
                img_4: [img_4_v2_heizkessel_1_jpg, img_4_v3_heizkessel_1_jpg],
                img_5: [img_5_v2_heizkessel_1_jpg, img_5_v3_heizkessel_1_jpg],
                img_6: [img_6_v2_heizkessel_1_jpg, img_6_v3_heizkessel_1_jpg]
            }
        },
        'Heizkessel_2': {
            webp: {
                //img_1: [img_1_v2_heizkessel_2_webp, img_1_v3_heizkessel_2_webp],
                img_2: [img_2_v2_heizkessel_2_webp, img_2_v3_heizkessel_2_webp],
                img_3: [img_3_v2_heizkessel_2_webp, img_3_v3_heizkessel_2_webp],
                img_4: [img_4_v2_heizkessel_2_webp, img_4_v3_heizkessel_2_webp],
                img_5: [img_5_v2_heizkessel_2_webp, img_5_v3_heizkessel_2_webp]
            },
            jpg: {
                //img_1: [img_1_v2_heizkessel_2_jpg, img_1_v3_heizkessel_2_jpg],
                img_2: [img_2_v2_heizkessel_2_jpg, img_2_v3_heizkessel_2_jpg],
                img_3: [img_3_v2_heizkessel_2_jpg, img_3_v3_heizkessel_2_jpg],
                img_4: [img_4_v2_heizkessel_2_jpg, img_4_v3_heizkessel_2_jpg],
                img_5: [img_5_v2_heizkessel_2_jpg, img_5_v3_heizkessel_2_jpg]
            }
        }
    }
}

export {images_NiehlerGurtel} ;
