import React, {useEffect, useState} from 'react';
import styles from '../../styles/components/Sencors/Sensors.module.css'
import Filters from "../Filters";
import { SensorTabs } from "../index";
import {useSelector} from "react-redux";
import {getDataProperty} from "../../redux/selectors";
import {useTranslation} from "react-i18next";

const Sensors = () => {
  const name = "Sensors";
  const property = useSelector(getDataProperty);
  const [hasWarning, setHasWarning] = useState(false);
  const { t } = useTranslation();
  const { sensors } = property;
  useEffect(() => {
    if (sensors) {
      setHasWarning(sensors.find(s => !s.status) !== null);
    }
  }, [sensors]);

  const filterList = [
    { text: t(`${name}.filters.warnings`), value: 'display_warnings', id: 0, showIf: hasWarning },
    { text: t(`${name}.filters.chilledWater`), value: 'chilled_water', id: 1 },
    { text: t(`${name}.filters.hotWater`), value: 'hot_water', id: 2 },
    { text: t(`${name}.filters.circulation`), value: 'zirculation', id: 3 },
    { text: t(`${name}.filters.heater`), value: 'heater', id: 4 },
  ];
  const [typeDisplay, setTypeDisplay] = useState('layout-grid');
  const nameForm = 'sensors';
  return (
    <div className={'mb-120'}>
      <h2 className={`mb-50 ${styles.title}`}>{t(`${name}.label`)}</h2>
      <div className={styles.filters_container}>
        <Filters
          filterList={filterList}
          nameForm={nameForm}
          typeDisplay={typeDisplay}
          setTypeDisplay={setTypeDisplay}
        />
      </div>
      <div>
        <SensorTabs nameForm={nameForm} typeDisplay={typeDisplay} />
      </div>
    </div>
  )
};

export default Sensors;
