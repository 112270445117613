import React, {useContext, useEffect, useCallback} from 'react';
import Routing from './Routing';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/main.css';
import { HashRouter } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setImgSupport, setSocketConnection} from "./redux/actions";
import {connectSocket, disconnectSocket, SocketListContext} from "./utils/socket-config";
import {getAuth, getSocketId} from "./redux/selectors";
import { useThemeDetector } from "./hooks";
import {checkWebPSupport} from "./utils/functions";
import "./utils/i18n";
import 'moment/locale/de'
import {useTranslation} from "react-i18next";
import moment from "moment";
import { urlBack } from "./utils/API";

const App = () => {
  const dispatch = useDispatch();
  const token = useSelector(getAuth);
  const socketId = useSelector(getSocketId);
  const isDarkTheme = useThemeDetector();
  const { i18n } = useTranslation();
  const socketList = useContext(SocketListContext);
  const socket = socketList[socketId] || null;
  moment.locale(i18n.language);
  moment.updateLocale(i18n.language, { week: { dow: 1, doy: 7 }})
  // useEffect(() => {
  //   if (token) {
  //     const interval = setInterval(() => {
  //       dispatch(checkConnection(token))
  //     }, 10000);
  //     return () => clearInterval(interval);
  //   }
  // }, [dispatch, token]);

  const socketControl = useCallback(() => {
    if (token && urlBack && socketId) {
        connectSocket({ urlBack, token, socketId });
        dispatch(setSocketConnection(true));
    } else {
        disconnectSocket({ socketId });
        dispatch(setSocketConnection(false));
    }
  }, [dispatch, token, urlBack, socketId])

  socket?.on('connect', () => dispatch(setSocketConnection(socket.connected)));
  socket?.on('disconnect', () => dispatch(setSocketConnection(socket.connected)));

  useEffect(() => {
    socketControl();
  }, [token, socketId, socketControl]);

  useEffect(() => {
    return () => {
      disconnectSocket({ socketId })
    }
  }, [socketId, dispatch])

  // useEffect(() => {
  //   if (token) {
  //     setIsFirstEntry(true);
  //     dispatch(checkConnection(token));
  //   } else if (isFirstEntry) {
  //     disconnectSocket();
  //   }
  // }, [socket, token, dispatch, isFirstEntry]);

  useEffect(() => {
    if (isDarkTheme) {
      document.querySelector("link[rel='icon shortcut']").href = require('./images/favicons/favicon-32x32-Dark.png');
    } else {
      document.querySelector("link[rel='icon shortcut']").href = require('./images/favicons/favicon-32x32-Light.png');
    }
  }, [isDarkTheme]);

  useEffect(() => {
    checkWebPSupport().then((status) => {dispatch(setImgSupport(status))});
  }, [dispatch]);

  return (
    <HashRouter>
      <Routing />
    </HashRouter>
  )
};

export default App;