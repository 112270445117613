import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getActiveSensor, getDataProperty, getFilterPerformance, getImgSupport, getActiveGroup} from "../../redux/selectors/index";
import PerformanceTooltip from "./PerformanceTooltip";
import ArrowImg from "../../icons/arrow_top.svg";
import PerformanceImg from "./PerformanceImg";
import * as animationData from '../../images/animation/pulse.json';
import Lottie from 'lottie-react';
import {performanceImgType25} from "../../images/performance/25/index";

import styles from "../../styles/components/Performance/PerformanceImg.module.css";
import {SensorCard, TooltipWrap} from "../index";
import {removeActiveSensor, setActiveGroup} from "../../redux/actions";

const PerformanceImgType25 = () => {
    const dispatch = useDispatch();
    const imgSupport = useSelector(getImgSupport);
    const filters = useSelector(getFilterPerformance);
    // const [activeGroup, setActiveGroup] = useState(1);
    const modbusActiveFilters = Object.values(filters).reduce((acc, n) => n.show ? [...acc, ...n.modbus] : acc, []);
    const name = 'PerformanceTooltip';
    const data = useSelector(getDataProperty);
    const active_sensors = useSelector(getActiveSensor);
    const activeGroup = useSelector(getActiveGroup);
    const {sensors = []} = data;
    const valvesId = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 20, 30, 31];

    const deleteActiveCard = () => dispatch(removeActiveSensor());

    const onClick = (area) => {
        if (!area.id.toString().includes('m') && active_sensors.includes(area.id)) {
            deleteActiveCard();
        }
    };

    const changeGroup = (data) => dispatch(setActiveGroup(data));

    const renderElement = (area) => {
        return (
            <>
                {
                    !area.group || area.group === activeGroup ? (
                        <>
                            <TooltipWrap id={area.id} date={`${area.tooltip_text}`}>
                                <div className={styles.performance_mouse_hover} onClick={() => onClick(area)}/>
                            </TooltipWrap>

                            {
                                !area.id.toString().includes('m') ? (
                                    <>
                                        <div
                                            className={[styles.renderElement_wrap + ' ' + (!modbusActiveFilters.includes(area.id) ? styles.hidden_tooltip : '')]}>
                                            <PerformanceTooltip area={area} index={1}/>
                                        </div>
                                        {(area.status !== "true" || area.status === null) && !valvesId.includes(area.id) ? (
                                            <div className={styles.animation}>
                                                <Lottie animationData={animationData} width={40} height={40}/>
                                            </div>
                                        ) : null}
                                    </>
                                ) : null
                            }
                            {sensors && active_sensors.map((modbus) => {
                                if (modbus === area.id) {
                                    const card = sensors.find(s => modbus === s.modbus)
                                    return (
                                        <div key={area.id}
                                                className={`${styles.card_wrap} ${card ? styles.show : ''} sensor-card-wrap`}>
                                            <SensorCard card={card} isModal={true}/>
                                        </div>
                                    )
                                } else
                                    return null;
                            })}
                        </>
                    ) : null
                }
            </>
        )
    };

    const renderArrows = () => {
        return(
            <div className={`flex-column ${styles.arrows_container}`}>
                <button disabled={activeGroup === 2} onClick={() => changeGroup(2)} className={`${styles.arrow_button} ${activeGroup === 2 ? styles.disabled_button : styles.enabled_button}`}>
                    <img src={ArrowImg} alt="" className={`${styles.arrow_icon}`} />
                </button>
                <button disabled={activeGroup === 1} onClick={() => changeGroup(1)} className={`${styles.arrow_button} ${activeGroup === 1 ? styles.disabled_button : styles.enabled_button}`}>
                    <img src={ArrowImg} alt="" className={`${styles.arrow_icon} ${styles.arrow_bottom}`}/>
                </button>
            </div>
        )
    }

    const mapArea = [
        {
            width: "20px",
            height: "40px",
            left: "4.1%",
            top: "38.4%",
            id: 'm100',
            className: `${styles.elements}`,
            render: renderArrows,
        },
        {
            width: "7%",
            height: "16.5%",
            left: "81.7%",
            top: "79.5%",
            id: 'm101',
            tooltip_text: [`${name}.boiler`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "7%",
            height: "16.5%",
            left: "90.2%",
            top: "66.5%",
            id: 'm101',
            tooltip_text: [`${name}.boiler`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "2.5%",
            left: "54.9%",
            top: "54.5%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "2.5%",
            left: "59.7%",
            top: "57%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "2.5%",
            left: "64.6%",
            top: "59.5%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "2.5%",
            left: "69.4%",
            top: "62%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "2.5%",
            left: "88%",
            top: "72.6%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "2.5%",
            left: "79.5%",
            top: "85.7%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "2.5%",
            left: "70.8%",
            top: "77%",
            id: 'm104',
            tooltip_text: [`${name}.feed_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "2.5%",
            left: "41.9%",
            top: "62%",
            id: 'm103',
            tooltip_text: [`${name}.storage_charging_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4%",
            height: "18.5%",
            left: "37.2%",
            top: "53.3%",
            id: 'm100',
            tooltip_text: [`${name}.storage_water_heater`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2.7%",
            height: "7.8%",
            left: "45%",
            top: "63.5%",
            id: 'm100',
            tooltip_text: [`${name}.heat_exchanger`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2.8%",
            height: "7.8%",
            left: "82.5%",
            top: "65.5%",
            id: 'm100',
            tooltip_text: [`${name}.heat_exchanger`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2.8%",
            height: "7.8%",
            left: "74%",
            top: "78.5%",
            id: 'm100',
            tooltip_text: [`${name}.heat_exchanger`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "2.5%",
            left: "29.3%",
            top: "55.5%",
            id: 'm104',
            tooltip_text: [`${name}.circulation_pump`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.8%",
            height: "6.7%",
            left: "13.9%",
            top: "51%",
            id: 'm105',
            tooltip_text: [`${name}.backwash_filters`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4%",
            height: "3.5%",
            left: "8%",
            top: "48.5%",
            id: 'm108',
            tooltip_text: [`${name}.domestic_water_meters`],
            className: `${styles.elements}`,
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2.8%",
            height: "3.5%",
            left: "25.7%",
            top: "52.5%",
            id: 30,
            group: 1,
            tooltip_text: [`${name}.flush_valve`],
            className: `${styles.elements} ${styles.cursor}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            style: { zIndex: activeGroup === 1 ? 3 : 2 },
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2.8%",
            height: "3.5%",
            left: "25.7%",
            top: "52.5%",
            id: 31,
            group: 2,
            tooltip_text: [`${name}.flush_valve`],
            className: `${styles.elements} ${styles.cursor}`,
            style: { zIndex: activeGroup === 2 ? 3 : 2 },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            // style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.3%",
            height: "4.7%",
            left: "6.5%",
            top: "38.5%",
            id: 1,
            group: 1,
            tooltip_text: [`${name}.smart_valve`, {n: 1}],
            style: { zIndex: activeGroup === 1 ? 3 : 2 },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `${styles.elements} ${styles.cursor}`,
            render: renderElement,
        },
        {
            width: "1.3%",
            height: "4.7%",
            left: "6.5%",
            top: "38.5%",
            id: 8,
            group: 2,
            tooltip_text: [`${name}.smart_valve`, {n: 9}],
            style: { zIndex: activeGroup === 2 ? 3 : 2 },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `${styles.elements} ${styles.cursor}`,
            render: renderElement,
        },
        {
            width: "1.3%",
            height: "4.9%",
            left: "9%",
            top: "39.8%",
            id: 16,
            group: 1,
            tooltip_text: [`${name}.smart_valve`, {n: 2}],
            style: { zIndex: activeGroup === 1 ? 3 : 2 },
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `${styles.elements} ${styles.cursor}`,
            render: renderElement,
        },
        {
            width: "1.3%",
            height: "4.9%",
            left: "9%",
            top: "39.8%",
            id: 2,
            group: 2,
            tooltip_text: [`${name}.smart_valve`, {n:10}],
            style: { zIndex: activeGroup === 2 ? 3 : 2 },
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `${styles.elements} ${styles.cursor}`,
            render: renderElement,
        },
        {
            width: "1.3%",
            height: "4.9%",
            left: "11.5%",
            top: "41.1%",
            id: 11,
            group: 1,
            tooltip_text: [`${name}.smart_valve`, {n: 3}],
            style: { zIndex: activeGroup === 1 ? 3 : 2 },
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `${styles.elements} ${styles.cursor}`,
            render: renderElement,
        },
        {
            width: "1.3%",
            height: "4.9%",
            left: "11.5%",
            top: "41.1%",
            id: 3,
            group: 2,
            tooltip_text: [`${name}.smart_valve`, {n: 11}],
            style: { zIndex: activeGroup === 2 ? 3 : 2 },
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `${styles.elements} ${styles.cursor}`,
            render: renderElement,
        },
        {
            width: "1.3%",
            height: "4.9%",
            left: "13.8%",
            top: "42.3%",
            id: 4,
            group: 1,
            tooltip_text: [`${name}.smart_valve`, {n: 4}],
            style: { zIndex: activeGroup === 1 ? 3 : 2 },
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `${styles.elements} ${styles.cursor}`,
            render: renderElement,
        },
        {
            width: "1.3%",
            height: "4.9%",
            left: "13.8%",
            top: "42.3%",
            id: 7,
            group: 2,
            tooltip_text: [`${name}.smart_valve`, {n: 12}],
            style: { zIndex: activeGroup === 2 ? 3 : 2 },
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `${styles.elements} ${styles.cursor}`,
            render: renderElement,
        },
        {
            width: "1.3%",
            height: "4.9%",
            left: "16.2%",
            top: "43.6%",
            id: 10,
            group: 1,
            tooltip_text: [`${name}.smart_valve`, {n: 5}],
            style: { zIndex: activeGroup === 1 ? 3 : 2 },
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `${styles.elements} ${styles.cursor}`,
            render: renderElement,
        },
        {
            width: "1.3%",
            height: "4.9%",
            left: "16.2%",
            top: "43.6%",
            id: 5,
            group: 2,
            tooltip_text: [`${name}.smart_valve`, {n: 13}],
            style: { zIndex: activeGroup === 2 ? 3 : 2 },
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `${styles.elements} ${styles.cursor}`,
            render: renderElement,
        },
        {
            width: "1.3%",
            height: "4.9%",
            left: "18.7%",
            top: "44.9%",
            id: 20,
            group: 1,
            tooltip_text: [`${name}.smart_valve`, {n: 6}],
            style: { zIndex: activeGroup === 1 ? 3 : 2 },
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `${styles.elements} ${styles.cursor}`,
            render: renderElement,
        },
        {
            width: "1.3%",
            height: "4.9%",
            left: "18.7%",
            top: "44.9%",
            id: 6,
            group: 2,
            tooltip_text: [`${name}.smart_valve`, {n: 14}],
            style: { zIndex: activeGroup === 2 ? 3 : 2 },
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `${styles.elements} ${styles.cursor}`,
            render: renderElement,
        },
        {
            width: "1.3%",
            height: "4.9%",
            left: "21.1%",
            top: "46.2%",
            id: 14,
            group: 1,
            tooltip_text: [`${name}.smart_valve`, {n: 7}],
            style: { zIndex: activeGroup === 1 ? 3 : 2 },
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `${styles.elements} ${styles.cursor}`,
            render: renderElement,
        },
        {
            width: "1.3%",
            height: "4.9%",
            left: "21.1%",
            top: "46.2%",
            id: 9,
            group: 2,
            tooltip_text: [`${name}.smart_valve`, {n: 15}],
            style: { zIndex: activeGroup === 2 ? 3 : 2 },
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `${styles.elements} ${styles.cursor}`,
            render: renderElement,
        },
        {
            width: "1.3%",
            height: "4.9%",
            left: "23.5%",
            top: "47.4%",
            id: 13,
            group: 1,
            tooltip_text: [`${name}.smart_valve`, {n: 8}],
            style: { zIndex: activeGroup === 1 ? 3 : 2 },
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `${styles.elements} ${styles.cursor}`,
            render: renderElement,
        },
        {
            width: "1.3%",
            height: "4.9%",
            left: "23.5%",
            top: "47.4%",
            id: 12,
            group: 2,
            tooltip_text: [`${name}.smart_valve`, {n: 16}],
            style: { zIndex: activeGroup === 2 ? 3 : 2 },
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `${styles.elements} ${styles.cursor}`,
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "35.15%",
            top: " 48.9%",
            id: 0,
            tooltip_text: [`${name}.hot_water`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `image-map__map ${styles.sensor_style} ${styles.sensor_style_type25}`,
            render: renderElement,
        },
    ];

    return (
        <PerformanceImg mapArea={mapArea} images={imgSupport ? performanceImgType25.webp : performanceImgType25.png}/>
    )
}

export default PerformanceImgType25;
