import img_1_v2_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/DSC08762@2x.webp";
import img_1_v3_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/DSC08762@3x.webp";
import img_2_v2_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/DSC08767@2x.webp";
import img_2_v3_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/DSC08767@3x.webp";
import img_3_v2_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/DSC08772@2x.webp";
import img_3_v3_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/DSC08772@3x.webp";

import img_1_v2_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/DSC08762@2x.jpg";
import img_1_v3_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/DSC08762@3x.jpg";
import img_2_v2_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/DSC08767@2x.jpg";
import img_2_v3_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/DSC08767@3x.jpg";
import img_3_v2_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/DSC08772@2x.jpg";
import img_3_v3_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/DSC08772@3x.jpg";

import img_1_v2_rukspulfilter_webp from "./02_Rukspulfilter/webp/DSC08758@2x.webp";
import img_1_v3_rukspulfilter_webp from "./02_Rukspulfilter/webp/DSC08758@3x.webp";
import img_2_v2_rukspulfilter_webp from "./02_Rukspulfilter/webp/DSC08836@2x.webp";
import img_2_v3_rukspulfilter_webp from "./02_Rukspulfilter/webp/DSC08836@3x.webp";

import img_1_v2_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/DSC08758@2x.jpg";
import img_1_v3_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/DSC08758@3x.jpg";
import img_2_v2_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/DSC08836@2x.jpg";
import img_2_v3_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/DSC08836@3x.jpg";

import img_1_v2_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/DSC08806@2x.webp";
import img_1_v3_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/DSC08806@3x.webp";
import img_2_v2_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/IMG_0186@2x.webp";
import img_2_v3_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/IMG_0186@3x.webp";

import img_1_v2_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/DSC08806@2x.jpg";
import img_1_v3_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/DSC08806@3x.jpg";
import img_2_v2_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/IMG_0186@2x.jpg";
import img_2_v3_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/IMG_0186@3x.jpg";

import img_1_v2_heizkessel_webp from "./04_Heizkessel/webp/DSC08808@2x.webp";
import img_1_v3_heizkessel_webp from "./04_Heizkessel/webp/DSC08808@3x.webp";
import img_2_v2_heizkessel_webp from "./04_Heizkessel/webp/DSC08809@2x.webp";
import img_2_v3_heizkessel_webp from "./04_Heizkessel/webp/DSC08809@3x.webp";
import img_3_v2_heizkessel_webp from "./04_Heizkessel/webp/IMG_0183@2x.webp";
import img_3_v3_heizkessel_webp from "./04_Heizkessel/webp/IMG_0183@3x.webp";

import img_1_v2_heizkessel_png from "./04_Heizkessel/jpg/DSC08808@2x.jpg";
import img_1_v3_heizkessel_png from "./04_Heizkessel/jpg/DSC08808@3x.jpg";
import img_2_v2_heizkessel_png from "./04_Heizkessel/jpg/DSC08809@2x.jpg";
import img_2_v3_heizkessel_png from "./04_Heizkessel/jpg/DSC08809@3x.jpg";
import img_3_v2_heizkessel_png from "./04_Heizkessel/jpg/IMG_0183@2x.jpg";
import img_3_v3_heizkessel_png from "./04_Heizkessel/jpg/IMG_0183@3x.jpg";

import img_1_v2_speicherladepumpe_webp from "./05_Speicherladepumpe/webp/DSC08795@2x.webp";
import img_1_v3_speicherladepumpe_webp from "./05_Speicherladepumpe/webp/DSC08795@3x.webp";
import img_2_v2_speicherladepumpe_webp from "./05_Speicherladepumpe/webp/DSC08800@2x.webp";
import img_2_v3_speicherladepumpe_webp from "./05_Speicherladepumpe/webp/DSC08800@3x.webp";

import img_1_v2_speicherladepumpe_jpg from "./05_Speicherladepumpe/jpg/DSC08795@2x.jpg";
import img_1_v3_speicherladepumpe_jpg from "./05_Speicherladepumpe/jpg/DSC08795@3x.jpg";
import img_2_v2_speicherladepumpe_jpg from "./05_Speicherladepumpe/jpg/DSC08800@2x.jpg";
import img_2_v3_speicherladepumpe_jpg from "./05_Speicherladepumpe/jpg/DSC08800@3x.jpg";

import img_1_v2_heizkreispumpe_webp from "./06_Heizkreispumpe/webp/DSC08797@2x.webp";
import img_1_v3_heizkreispumpe_webp from "./06_Heizkreispumpe/webp/DSC08797@3x.webp";
import img_2_v2_heizkreispumpe_webp from "./06_Heizkreispumpe/webp/DSC08798@2x.webp";
import img_2_v3_heizkreispumpe_webp from "./06_Heizkreispumpe/webp/DSC08798@3x.webp";
import img_3_v2_heizkreispumpe_webp from "./06_Heizkreispumpe/webp/IMG_0167@2x.webp";
import img_3_v3_heizkreispumpe_webp from "./06_Heizkreispumpe/webp/IMG_0167@3x.webp";

import img_1_v2_heizkreispumpe_jpg from "./06_Heizkreispumpe/jpg/DSC08797@2x.jpg";
import img_1_v3_heizkreispumpe_jpg from "./06_Heizkreispumpe/jpg/DSC08797@3x.jpg";
import img_2_v2_heizkreispumpe_jpg from "./06_Heizkreispumpe/jpg/DSC08798@2x.jpg";
import img_2_v3_heizkreispumpe_jpg from "./06_Heizkreispumpe/jpg/DSC08798@3x.jpg";
import img_3_v2_heizkreispumpe_jpg from "./06_Heizkreispumpe/jpg/IMG_0167@2x.jpg";
import img_3_v3_heizkreispumpe_jpg from "./06_Heizkreispumpe/jpg/IMG_0167@3x.jpg";

import img_1_v2_zirkulationspumpe_webp from "./07_Zirkulationspumpe/webp/DSC08791@2x.webp";
import img_1_v3_zirkulationspumpe_webp from "./07_Zirkulationspumpe/webp/DSC08791@3x.webp";
import img_2_v2_zirkulationspumpe_webp from "./07_Zirkulationspumpe/webp/IMG_0193@2x.webp";
import img_2_v3_zirkulationspumpe_webp from "./07_Zirkulationspumpe/webp/IMG_0193@3x.webp";

import img_1_v2_zirkulationspumpe_jpg from "./07_Zirkulationspumpe/jpg/DSC08791@2x.jpg";
import img_1_v3_zirkulationspumpe_jpg from "./07_Zirkulationspumpe/jpg/DSC08791@3x.jpg";
import img_2_v2_zirkulationspumpe_jpg from "./07_Zirkulationspumpe/jpg/IMG_0193@2x.jpg";
import img_2_v3_zirkulationspumpe_jpg from "./07_Zirkulationspumpe/jpg/IMG_0193@3x.jpg";

const images = {
    'Heizkessel': {
        webp: {
            img_1: [img_1_v2_heizkessel_webp, img_1_v3_heizkessel_webp],
            img_2: [img_2_v2_heizkessel_webp, img_2_v3_heizkessel_webp],
            img_3: [img_3_v2_heizkessel_webp, img_3_v3_heizkessel_webp],
        },
        png: {
            img_1: [img_1_v2_heizkessel_png, img_1_v3_heizkessel_png],
            img_2: [img_2_v2_heizkessel_png, img_2_v3_heizkessel_png],
            img_3: [img_3_v2_heizkessel_png, img_3_v3_heizkessel_png],
        }
    },
    'Warmwasserspeicher': {
        webp: {
            img_1: [img_1_v2_warmwasserspeicher_webp, img_1_v3_warmwasserspeicher_webp],
            img_2: [img_2_v2_warmwasserspeicher_webp, img_2_v3_warmwasserspeicher_webp],
        },
        jpg: {
            img_1: [img_1_v2_warmwasserspeicher_jpg, img_1_v3_warmwasserspeicher_jpg],
            img_2: [img_2_v2_warmwasserspeicher_jpg, img_2_v3_warmwasserspeicher_jpg],
        }
    },
    'Rukspulfilter': {
        webp: {
            img_1: [img_1_v2_rukspulfilter_webp, img_1_v3_rukspulfilter_webp],
            img_2: [img_2_v2_rukspulfilter_webp, img_2_v3_rukspulfilter_webp],
        },
        jpg: {
            img_1: [img_1_v2_rukspulfilter_jpg, img_1_v3_rukspulfilter_jpg],
            img_2: [img_2_v2_rukspulfilter_jpg, img_2_v3_rukspulfilter_jpg],
        }
    },
    'Kaltwassereingang': {
        webp: {
            img_1: [img_1_v2_kaltwassereingang_webp, img_1_v3_kaltwassereingang_webp],
            img_2: [img_2_v2_kaltwassereingang_webp, img_2_v3_kaltwassereingang_webp],
            img_3: [img_3_v2_kaltwassereingang_webp, img_3_v3_kaltwassereingang_webp],
        },
        jpg: {
            img_1: [img_1_v2_kaltwassereingang_jpg, img_1_v3_kaltwassereingang_jpg],
            img_2: [img_2_v2_kaltwassereingang_jpg, img_2_v3_kaltwassereingang_jpg],
            img_3: [img_3_v2_kaltwassereingang_jpg, img_3_v3_kaltwassereingang_jpg],
        }
    },
    'Speicherladepumpe': {
        webp: {
            img_1: [img_2_v2_speicherladepumpe_webp, img_2_v3_speicherladepumpe_webp],
            img_2: [img_1_v2_speicherladepumpe_webp, img_1_v3_speicherladepumpe_webp],
        },
        jpg: {
            img_1: [img_2_v2_speicherladepumpe_jpg, img_2_v3_speicherladepumpe_jpg],
            img_2: [img_1_v2_speicherladepumpe_jpg, img_1_v3_speicherladepumpe_jpg],
        }
    },
    'Heizkreispumpe': {
        webp: {
            img_1: [img_1_v2_heizkreispumpe_webp, img_1_v3_heizkreispumpe_webp],
            img_2: [img_2_v2_heizkreispumpe_webp, img_2_v3_heizkreispumpe_webp],
            img_3: [img_3_v2_heizkreispumpe_webp, img_3_v3_heizkreispumpe_webp],
        },
        jpg: {
            img_1: [img_1_v2_heizkreispumpe_jpg, img_1_v3_heizkreispumpe_jpg],
            img_2: [img_2_v2_heizkreispumpe_jpg, img_2_v3_heizkreispumpe_jpg],
            img_3: [img_3_v2_heizkreispumpe_jpg, img_3_v3_heizkreispumpe_jpg],
        }
    },
    'Zirkulationspumpe': {
        webp: {
            img_1: [img_1_v2_zirkulationspumpe_webp, img_1_v3_zirkulationspumpe_webp],
            img_2: [img_2_v2_zirkulationspumpe_webp, img_2_v3_zirkulationspumpe_webp],
        },
        jpg: {
            img_1: [img_1_v2_zirkulationspumpe_jpg, img_1_v3_zirkulationspumpe_jpg],
            img_2: [img_2_v2_zirkulationspumpe_jpg, img_2_v3_zirkulationspumpe_jpg],
        }
    },
}

export {images} ;