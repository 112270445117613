import sensor_58_webp from "./1/webp/Kaltwasser.webp";
import sensor_58_v2_webp from "./1/webp/Kaltwasser@2x.webp";
import sensor_58_v3_webp from "./1/webp/Kaltwasser@3x.webp";

import sensor_58_jpg from "./1/jpg/Kaltwasser.jpg";
import sensor_58_v2_jpg from "./1/jpg/Kaltwasser@2x.jpg";
import sensor_58_v3_jpg from "./1/jpg/Kaltwasser@3x.jpg";

import sensor_62_webp from "./7_9/webp/Kaltwasser.webp";
import sensor_62_v2_webp from "./7_9/webp/Kaltwasser@2x.webp";
import sensor_62_v3_webp from "./7_9/webp/Kaltwasser@3x.webp";

import sensor_62_jpg from "./7_9/jpg/Kaltwasser.jpg";
import sensor_62_v2_jpg from "./7_9/jpg/Kaltwasser@2x.jpg";
import sensor_62_v3_jpg from "./7_9/jpg/Kaltwasser@3x.jpg";

import sensor_63_webp from "./13_16/webp/Kaltwassereingang16.webp";
import sensor_63_v2_webp from "./13_16/webp/Kaltwassereingang16@2x.webp";
import sensor_63_v3_webp from "./13_16/webp/Kaltwassereingang16@3x.webp";

import sensor_63_jpg from "./13_16/jpg/Kaltwassereingang16.jpg";
import sensor_63_v2_jpg from "./13_16/jpg/Kaltwassereingang16@2x.jpg";
import sensor_63_v3_jpg from "./13_16/jpg/Kaltwassereingang16@3x.jpg";

import sensor_64_webp from "./13_16/webp/Kaltwassereingang13.webp";
import sensor_64_v2_webp from "./13_16/webp/Kaltwassereingang13@2x.webp";
import sensor_64_v3_webp from "./13_16/webp/Kaltwassereingang13@3x.webp";

import sensor_64_jpg from "./13_16/jpg/Kaltwassereingang13.jpg";
import sensor_64_v2_jpg from "./13_16/jpg/Kaltwassereingang13@2x.jpg";
import sensor_64_v3_jpg from "./13_16/jpg/Kaltwassereingang13@3x.jpg";

import sensor_65_webp from "./13_16/webp/Rukspulfilter16.webp";
import sensor_65_v2_webp from "./13_16/webp/Rukspulfilter16@2x.webp";
import sensor_65_v3_webp from "./13_16/webp/Rukspulfilter16@3x.webp";

import sensor_65_jpg from "./13_16/jpg/Rukspulfilter16.jpg";
import sensor_65_v2_jpg from "./13_16/jpg/Rukspulfilter16@2x.jpg";
import sensor_65_v3_jpg from "./13_16/jpg/Rukspulfilter16@3x.jpg";

const s1 = {
    webp: {
        s_58: [sensor_58_webp, sensor_58_v2_webp, sensor_58_v3_webp]
    },
    jpg: {
        s_58: [sensor_58_jpg, sensor_58_v2_jpg, sensor_58_v3_jpg]
    }
}

const s7_9 = {
    webp: {
        s_62: [sensor_62_webp, sensor_62_v2_webp, sensor_62_v3_webp]
    },
    jpg: {
        s_62: [sensor_62_jpg, sensor_62_v2_jpg, sensor_62_v3_jpg]
    }
}

const s13_16 = {
    webp: {
        s_63: [sensor_63_webp, sensor_63_v2_webp, sensor_63_v3_webp],
        s_64: [sensor_64_webp, sensor_64_v2_webp, sensor_64_v3_webp],
        s_65: [sensor_65_webp, sensor_65_v2_webp, sensor_65_v3_webp]
    },
    jpg: {
        s_63: [sensor_63_jpg, sensor_63_v2_jpg, sensor_63_v3_jpg],
        s_64: [sensor_64_jpg, sensor_64_v2_jpg, sensor_64_v3_jpg],
        s_65: [sensor_65_jpg, sensor_65_v2_jpg, sensor_65_v3_jpg]
    }
}

export { s1, s7_9, s13_16 };