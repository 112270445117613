import React from 'react';
import styles from '../../styles/components/Inputs/InputField.module.css';
import DateField from '../Profile/DateField';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import { UpdateProfileInfo } from '../../redux/actions';

const InputField = props => {
  const { field } = props;
  const { name, title, value, placeholder, readOnly, withoutMargin } = field;
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const updateField = e => {
    const text = e.currentTarget.textContent.trim();
    e.currentTarget.textContent = text;
    const newValue = {
      [name]: text
    }
    dispatch(UpdateProfileInfo(newValue));
  }

  const onInput = e => {
    if (e.currentTarget.textContent.trim().length === 0) {
      e.currentTarget.textContent = '';
    }
  }

  const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
          updateField(event);
          event.target.blur();
      }
  }

  return (
    <div className={`${styles.fieldContainer} ${withoutMargin ? styles.withoutMargin : ""}`}>
      <p className={styles.fieldTitle}>{t(title)}</p>
      {
        name !== 'birthdate' && name !== 'company_birthdate' ?
        (
            <div className={!readOnly ? styles.icon : ''}>
              <div className={styles.input} placeholder={`${t(placeholder)} ${t("Profile.change")}`}
                   onBlur={updateField} onInput={onInput}
                   suppressContentEditableWarning={true} contentEditable={!readOnly} onKeyDown={handleKeyDown}>
                  {value}
              </div>
            </div>
        ) : (
          <DateField date={value} fieldName={name}/>
        )
      }
    </div>
  )
};
export default InputField;
