import React from 'react';
import styles from '../styles/components/Footer.module.css'
import logo from "../images/Logo_text.svg";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Footer = () => {
    const name = 'Footer';
    const { t } = useTranslation();
    return (
        <div className={styles.footer}>
            <hr />
            <Link to="/" >
                <img src={logo} alt="PAUL"/>
            </Link>
            <hr className={styles.short_line}/>
            <span>{t(`${name}.description`)}</span>
            <a href="https://paul.tech" target="_blank" rel="noreferrer">paul.tech</a>
        </div>
    )
}

export default Footer;