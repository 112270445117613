import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    getActiveSensor,
    getDataProperty,
    getImgSupport,
    getActiveGroup,
    getFilterPerformance
} from "../../redux/selectors/index";
import PerformanceTooltip from "./PerformanceTooltip";
import PerformanceTooltipPrototype from "./PerformanceTooltipPrototype";
import SensorCardPrototype from "../Sensors/SensorCardPrototype";
import PerformanceImg from "./PerformanceImg";

import {Tabs} from "react-bootstrap";
import {Tab} from "bootstrap";

import {configPerformanceByProperty, filterPerformanceConfig} from "../../utils/constants";

import styles from "../../styles/components/Performance/PerformanceImg.module.css";
import {SensorCard, TooltipWrap} from "../index";
import {removeActiveSensor, setActiveGroup} from "../../redux/actions";
import { SearchFilter } from '../Inputs';

import {getActiveBuilding, getActiveStreet} from "../../redux/selectors";
import {setActiveBuilding, setActiveStreet} from "../../redux/actions";
import {PerformanceTypeConfigs} from "./Config";
import {performanceImgType18} from "../../images/performance/18";
import {useParams} from "react-router";
import {performanceImgType12} from "../../images/performance/12";
import {performanceImgTypePrototype} from "../../images/performance/prototype";
import {performanceImgType25} from "../../images/performance/25";
import ArrowImg from "../../icons/arrow_top.svg";
import {
    performanceImgType1_filter,
    performanceImgType2_filter,
    performanceImgType3_filter,
    performanceImgType4_filter,
    performanceImgType5_filter,
    performanceImgType6_filter,
    performanceImgType7_filter,
    performanceImgType8_filter,

    performanceImgType1_nofilter,
    performanceImgType2_nofilter,
    performanceImgType3_nofilter,
    performanceImgType4_nofilter,
    performanceImgType5_nofilter,
    performanceImgType6_nofilter,
    performanceImgType7_nofilter,
    performanceImgType8_nofilter,

    performanceImgTypeBrusseler_2,
    performanceImgTypeBrusseler_8,

    performanceImgTypeDerfflinger_5,
    performanceImgTypeDerfflinger_8,

    performanceImgType3Heizkreise_6,
    performanceImgType3Heizkreise_8,
    performanceImgType4Heizkreise_7,
    performanceImgType4Heizkreise_8,

    performanceImgTypeAmBlomkenberg_7,
    performanceImgTypeAmBlomkenberg_8,

    performanceImgTypeOberhutter_7,
    performanceImgTypeOberhutter_8
} from "../../images/performance/3d_images";
import { t } from 'i18next';

const DynamicPerformanceImg = props => {
    const { type = '' } = props;
    const {id: _id} = useParams();
    const id = _id.split('&')[0];
    const name = 'PerformanceTooltip';
    const data = useSelector(getDataProperty);
    const active_sensors = useSelector(getActiveSensor);
    const activeGroup = useSelector(getActiveGroup);
    const activeBuildingGroup = useSelector(getActiveBuilding);
    const activeCirculationGroup = useSelector(getActiveStreet);
    const dispatch = useDispatch();
    const [searchList, setSearchList] = useState({});
    const [buidingsList, setBuildingsList] = useState([]);
    const [tabType, setTabType] = useState("Group 1");
    const [type3dImage, setType3dImage] = useState(!type ? '5_nofilter' : type);
    const [maxGroups, setMaxGroups] = useState();
    const imgSupport = useSelector(getImgSupport);
    const filters = useSelector(getFilterPerformance);
    const {heatingLeadTime, heatingReturnFlow} = filters;
    // const _type = activeBuildingGroup?.config3d.type || type;
    const pointsActiveFilters = Object.keys(filters)
        .reduce((acc, key) => filters[key]?.show && (filterPerformanceConfig[type3dImage] || filterPerformanceConfig.default) ? [...acc, ...(filterPerformanceConfig[type3dImage] || filterPerformanceConfig.default)[key]] : acc, []);

    const {sensors = []} = data;

    const deleteActiveCard = () => dispatch(removeActiveSensor());

    const onClick = (area) => {
        if (!area.id.toString().includes('m') && active_sensors.includes(area.id)) {
            deleteActiveCard();
        }
    };

    useEffect(() => {
        if (activeBuildingGroup) {
            setType3dImage(activeBuildingGroup.config3d.type);
        }
    }, [activeBuildingGroup])

    useEffect(() => {
        setSearchList(configPerformanceByProperty[id]?.buildings?.reduce((acc, building) => ({
            ...acc, [building.street] : [...(acc[building.street] || []), building.number]
        }), {}) || {})
    }, [configPerformanceByProperty])

    useEffect(() => {
        return () => {
            dispatch(setActiveBuilding(null));
            dispatch(setActiveGroup(1));
        }
    }, [])

    useEffect(() => {
        if (Object.keys(searchList).length !== 0) {
            setBuildingsList(Object.values(searchList)[0])
            dispatch(setActiveStreet(Object.keys(searchList)[0]));
            dispatch(setActiveBuilding(configPerformanceByProperty[id]?.buildings?.find(building => (
                building.street === Object.keys(searchList)[0] &&
                building.number === Object.values(searchList)[0][0]
            ))));
        }
    }, [searchList, dispatch])

    // const addFadeout = () => {
    //     let image = document.getElementsByClassName('image-map__content');
    //     console.log(image)
    //     image[0].classList.add('fade');
    // }

    const updateGroups = useCallback((t, data) => {
        if (t === 'circulationGroup') {
            dispatch(setActiveStreet(data));
            const findStreetIndex = Object.keys(searchList).findIndex(street => street === data);
            dispatch(setActiveBuilding(configPerformanceByProperty[id]?.buildings?.find(building => (
                building.street === data &&
                building.number === Object.values(searchList)[findStreetIndex][0]
            ))));
            setBuildingsList(Object.values(searchList)[findStreetIndex])
        } else {
            dispatch(setActiveBuilding(configPerformanceByProperty[id]?.buildings?.find(building => (
                building.street === activeCirculationGroup &&  building.number === data
            ))));
        }
        dispatch(setActiveGroup(1));
        // addFadeout();
    })

    // const changeGroup = (data) => dispatch(setActiveGroup(data));

    const renderElement = (area) => {
        return !area.group || area.group === activeGroup ? (
            <>
                <TooltipWrap id={area.id} date={`${area.tooltip_text}`}>
                    <div className={styles.performance_mouse_hover} onClick={() => onClick(area)}/>
                </TooltipWrap>
                {
                    !area.id.toString().includes('m') ? (
                        <>
                            <div className={`${styles.renderElement_wrap} ${!pointsActiveFilters.includes(area.id) ? styles.hidden_tooltip : ''}`}>
                                <PerformanceTooltip area={area} index={1}/>
                            </div>
                        </>
                    ) : null
                }
            </>
        ) : null
    };

    const renderElementPrototype = (area) => {
        return (
            <>
                <TooltipWrap id={area.id} date={`${area.tooltip_text}`}>
                    <div className={styles.performance_mouse_hover} onClick={() => onClick(area)}/>
                </TooltipWrap>
                {type === '12_prototype' ? (
                    <div>
                        <div
                            className={`${styles.red_line + ' ' + styles.render_lines} ${!heatingLeadTime.show || !heatingReturnFlow.show ? styles.hidden_lines : ''}`}/>
                        <div
                            className={`${styles.red_line_2 + ' ' + styles.render_lines} ${!heatingLeadTime.show || !heatingReturnFlow.show ? styles.hidden_lines : ''}`}/>
                        <div
                            className={`${styles.renderElement_wrap} ${!heatingLeadTime.show ? styles.hidden_tooltip : ''}`}>
                            <PerformanceTooltipPrototype area={area} name={'T'} value={83} status={true} date={new Date()}
                                                         index={0}/>
                        </div>
                        <div
                            className={`${styles.renderElement_wrap} ${!heatingLeadTime.show || !heatingReturnFlow.show ? styles.hidden_tooltip : ''}`}>
                            <PerformanceTooltipPrototype area={area} name={'△'} delta value={8} status={false} index={1}
                                                         date={new Date()}/>
                        </div>
                        <div
                            className={`${styles.renderElement_wrap} ${!heatingReturnFlow.show ? styles.hidden_tooltip : ''}`}>
                            <PerformanceTooltipPrototype area={area} name={'T'} value={75} status={true} date={new Date()}
                                                         index={2}/>
                        </div>
                    </div>
                ) : null}
                <div
                    className={`${styles.prototype_sensor} ${active_sensors.includes(area.id) ? styles.show : ''} sensor-card-wrap`}>
                    <SensorCardPrototype isModal/>
                </div>
            </>
        )
    };

    const changeGroup = (data) => {
        if (activeBuildingGroup) {
            if (data !== 1) {
                const type3DImage = activeBuildingGroup?.config3d?.devices.filter(device => !isNaN(device.pointId)).length - ((data - 1) * 8);
                setType3dImage(`${type3DImage > 8 ? 8 : type3DImage}${activeBuildingGroup?.config3d?.type.slice(1)}`);
            } else {
                setType3dImage(activeBuildingGroup?.config3d?.type);
            }
        }
        dispatch(setActiveGroup(data));
    };

    const renderArrows = () => {
        setMaxGroups(Math.ceil(activeBuildingGroup?.config3d?.devices.filter(device => !isNaN(device.pointId)).length / 8) || 2)
        return(
            <div className={`flex-column ${styles.arrows_container}`}>
                <button disabled={activeGroup === maxGroups} onClick={() => changeGroup(activeGroup + 1)} className={`${styles.arrow_button} ${activeGroup === maxGroups ? styles.disabled_button : styles.enabled_button}`}>
                    <img src={ArrowImg} alt="" className={`${styles.arrow_icon}`} />
                </button>
                <button disabled={activeGroup === 1} onClick={() => changeGroup(activeGroup - 1)} className={`${styles.arrow_button} ${activeGroup === 1 ? styles.disabled_button : styles.enabled_button}`}>
                    <img src={ArrowImg} alt="" className={`${styles.arrow_icon} ${styles.arrow_bottom}`}/>
                </button>
            </div>
        )
    }

    const selectorGroups = () => {
        return (
            <div className='flex-row'>
                <SearchFilter type={'circulationGroup'} groups={Object.keys(searchList)} activeGroup={activeCirculationGroup} updateGroup={updateGroups}/>
                <SearchFilter type={'buildingGroup'} groups={buidingsList} activeGroup={activeBuildingGroup?.number} updateGroup={updateGroups}/>
            </div>
        )
    }

    const selectCirculationTabs = (currentTab) => {
        if (currentTab === "Group 2") {
            dispatch(setActiveBuilding(configPerformanceByProperty[id]?.buildings?.find(building => (
                building.circulationGroup === 2
            ))));
        } else {
            dispatch(setActiveBuilding(configPerformanceByProperty[id]?.buildings?.find(building => (
                building.circulationGroup === 1
            ))));
        }
        dispatch(setActiveGroup(1));
        setTabType(currentTab)
    }

    const circulationTabs = () => {
        return (
            <Tabs
                transition={false}
                activeKey={tabType}
                onSelect={(k) => selectCirculationTabs(k)}
                className={`tabs ${styles.circulationTabsContainer}`}
            >
                { configPerformanceByProperty[id].buildings.map(group => (
                    <Tab key={group.circulationGroup} eventKey={`Group ${group.circulationGroup}`} title={t("CirculationTabs.group", { n: group.circulationGroup })} className="tab"/>
                  ))
                }
            </Tabs>
        )
    }

    const getImageByType = t => {
        switch (t) {
            case '1_filter': return  performanceImgType1_filter
            case '2_filter': return  performanceImgType2_filter
            case '3_filter': return  performanceImgType3_filter
            case '4_filter': return  performanceImgType4_filter
            case '5_filter': return  performanceImgType5_filter
            case '6_filter': return  performanceImgType6_filter
            case '7_filter': return  performanceImgType7_filter
            case '8_filter': return  performanceImgType8_filter

            case '1_nofilter': return  performanceImgType1_nofilter
            case '2_nofilter': return  performanceImgType2_nofilter
            case '3_nofilter': return  performanceImgType3_nofilter
            case '4_nofilter': return  performanceImgType4_nofilter
            case '5_nofilter': return  performanceImgType5_nofilter
            case '6_nofilter': return  performanceImgType6_nofilter
            case '7_nofilter': return  performanceImgType7_nofilter
            case '8_nofilter': return  performanceImgType8_nofilter

            case '2_Brusseler': return  performanceImgTypeBrusseler_2
            case '8_Brusseler': return  performanceImgTypeBrusseler_8
            case '5_Derfflinger': return  performanceImgTypeDerfflinger_5
            case '8_Derfflinger': return  performanceImgTypeDerfflinger_8
            case '7_AmBlomkenberg': return  performanceImgTypeAmBlomkenberg_7
            case '8_AmBlomkenberg': return  performanceImgTypeAmBlomkenberg_8

            case '6_Heizkeise_3': return  performanceImgType3Heizkreise_6
            case '8_Heizkeise_3': return  performanceImgType3Heizkreise_8
            case '7_Heizkeise_4': return  performanceImgType4Heizkreise_7
            case '8_Heizkeise_4': return  performanceImgType4Heizkreise_8

            case '7_Oberhutte': return performanceImgTypeOberhutter_7
            case '8_Oberhutte': return performanceImgTypeOberhutter_8

            case '12': return performanceImgType12
            case '12_prototype': return performanceImgTypePrototype
            case '18': return performanceImgType18
            case '8_NiehlerGurtel': return performanceImgType25
            default: return null
        }
    }

    // const activeBuilding = configPerformanceByProperty[id]?.buildings[1];

    return (
        // <PerformanceImg mapArea={mapArea(styles, name, renderElement, renderElementPrototype, selectorGroups)} images={imgSupport ? performanceImgValve5_nofilter.webp : performanceImgValve5_nofilter.png}/>
        <PerformanceImg
            mapArea={PerformanceTypeConfigs({ type: type3dImage, styles, name, renderElement, renderElementPrototype, selectorGroups, renderArrows, circulationTabs, activeGroup, activeBuildingGroup})}
            images={getImageByType(type3dImage)[imgSupport ? 'webp' : 'png']}
            type={type}
            maxGroups={maxGroups}
        />
    )
}

export default DynamicPerformanceImg;
