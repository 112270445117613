import React, {useCallback, useEffect, useState} from 'react';
import { BuildingCard } from "../index";
import {useSelector} from "react-redux";
import {getDataProperties, getFilters} from "../../redux/selectors";
import { FreeMode, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const MapLayoutGrid = props => {
  const { nameForm, imgSupport } = props;
  const [cards, setCards] = useState([]);
  const data = useSelector(getDataProperties);
  const filters = useSelector(getFilters);
  const filter = filters[nameForm];

  const filteredCards = useCallback(() => {
    const { display_warnings, city, zip } = filter || {};
    return data ? data.filter(card => {
      return !((display_warnings && (card.sensors.find(s => !s.status) === null))
        || (city && card.city !== city)
        || (zip && card.zip !== zip));
    }) : []
  }, [data, filter]);

  useEffect(() => {
    setCards(filteredCards());
  }, [data, filter, filteredCards]);

  return (
    <Swiper className='mapLayoutGrid' freeMode modules={[FreeMode, Navigation]} spaceBetween={40} slidesPerView={6} navigation grabCursor style={{display: 'flex'}}>
      {cards.map((card, i) => (<SwiperSlide key={card._id}><BuildingCard card={card} show3D={false} transition={false} i={i} imgSupport={imgSupport}/></SwiperSlide>))}
    </Swiper>
  )
};

export default MapLayoutGrid;
