import sensor_10_webp from "./webp/DSC08798.webp";
import sensor_10_v2_webp from "./webp/DSC08798@2x.webp";
import sensor_10_v3_webp from "./webp/DSC08798@3x.webp";
import sensor_11_webp from "./webp/DSC08762.webp";
import sensor_11_v2_webp from "./webp/DSC08762@2x.webp";
import sensor_11_v3_webp from "./webp/DSC08762@3x.webp";
import sensor_12_webp from "./webp/DSC08758.webp";
import sensor_12_v2_webp from "./webp/DSC08758@2x.webp";
import sensor_12_v3_webp from "./webp/DSC08758@3x.webp";
import sensor_13_webp from "./webp/DSC08791.webp";
import sensor_13_v2_webp from "./webp/DSC08791@2x.webp";
import sensor_13_v3_webp from "./webp/DSC08791@3x.webp";
import sensor_14_webp from "./webp/DSC08808.webp";
import sensor_14_v2_webp from "./webp/DSC08808@2x.webp";
import sensor_14_v3_webp from "./webp/DSC08808@3x.webp";
import sensor_15_webp from "./webp/DSC08800.webp";
import sensor_15_v2_webp from "./webp/DSC08800@2x.webp";
import sensor_15_v3_webp from "./webp/DSC08800@3x.webp";
import sensor_16_webp from "./webp/DSC08806.webp";
import sensor_16_v2_webp from "./webp/DSC08806@2x.webp";
import sensor_16_v3_webp from "./webp/DSC08806@3x.webp";

import sensor_10_png from "./png/DSC08798.png";
import sensor_10_v2_png from "./png/DSC08798@2x.png";
import sensor_10_v3_png from "./png/DSC08798@3x.png";
import sensor_11_png from "./png/DSC08762.png";
import sensor_11_v2_png from "./png/DSC08762@2x.png";
import sensor_11_v3_png from "./png/DSC08762@3x.png";
import sensor_12_png from "./png/DSC08758.png";
import sensor_12_v2_png from "./png/DSC08758@2x.png";
import sensor_12_v3_png from "./png/DSC08758@3x.png";
import sensor_13_png from "./png/DSC08791.png";
import sensor_13_v2_png from "./png/DSC08791@2x.png";
import sensor_13_v3_png from "./png/DSC08791@3x.png";
import sensor_14_png from "./png/DSC08808.png";
import sensor_14_v2_png from "./png/DSC08808@2x.png";
import sensor_14_v3_png from "./png/DSC08808@3x.png";
import sensor_15_png from "./png/DSC08800.png";
import sensor_15_v2_png from "./png/DSC08800@2x.png";
import sensor_15_v3_png from "./png/DSC08800@3x.png";
import sensor_16_png from "./png/DSC08806.png";
import sensor_16_v2_png from "./png/DSC08806@2x.png";
import sensor_16_v3_png from "./png/DSC08806@3x.png";


const sNull = {
    webp: {
        s_10: [sensor_10_webp, sensor_10_v2_webp, sensor_10_v3_webp],
        s_11: [sensor_11_webp, sensor_11_v2_webp, sensor_11_v3_webp],
        s_12: [sensor_12_webp, sensor_12_v2_webp, sensor_12_v3_webp],
        s_13: [sensor_13_webp, sensor_13_v2_webp, sensor_13_v3_webp],
        s_14: [sensor_14_webp, sensor_14_v2_webp, sensor_14_v3_webp],
        s_15: [sensor_15_webp, sensor_15_v2_webp, sensor_15_v3_webp],
        s_16: [sensor_16_webp, sensor_16_v2_webp, sensor_16_v3_webp],
    },
    png: {
        s_10: [sensor_10_png, sensor_10_v2_png, sensor_10_v3_png],
        s_11: [sensor_11_png, sensor_11_v2_png, sensor_11_v3_png],
        s_12: [sensor_12_png, sensor_12_v2_png, sensor_12_v3_png],
        s_13: [sensor_13_png, sensor_13_v2_png, sensor_13_v3_png],
        s_14: [sensor_14_png, sensor_14_v2_png, sensor_14_v3_png],
        s_15: [sensor_15_png, sensor_15_v2_png, sensor_15_v3_png],
        s_16: [sensor_16_png, sensor_16_v2_png, sensor_16_v3_png],
    }
}

export { sNull };