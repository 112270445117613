import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ImageMap} from "@qiuz/react-image-map";
import styles from "../../styles/components/Performance/PerformanceImg.module.css";
import {
    getActiveSensor,
    getDataProperty,
    getFilterPerformance,
    getImgSupport,
    getActiveGroup,
    getActiveBuilding
} from "../../redux/selectors/index";
import {useDispatch, useSelector} from "react-redux";
import {SensorCard} from "../index";
import {addActiveSensor, setActiveSensor, removeActiveSensor} from "../../redux/actions";
import {useParams} from "react-router";
import {getPrototypeMode} from "../../redux/selectors";
import {configPerformanceDefault, disabledTabsProperties} from '../../utils/constants';
import heater_webp from '../../images/performance/prototype/webp/heater_on_2358_1651.webp';
import heater_png from '../../images/performance/prototype/png/heater_on_2358_1651.png';
import {useTranslation} from "react-i18next";

const PerformanceImg = props => {
    const [currentImages, setCurrentImages] = useState([]);
    const [activeElement, setActiveElement] = useState(null);
    const dispatch = useDispatch();
    const {id: _id} = useParams();
    const id = _id.split('&')[0];
    const imgSupport = useSelector(getImgSupport);
    const prototypeMode = useSelector(getPrototypeMode);
    const activeSensor = useSelector(getActiveSensor);
    const activeGroup = useSelector(getActiveGroup);
    const data = useSelector(getDataProperty);
    const {showTooltip, showTooltips, mapArea, filters, images, maxGroups} = props;
    const {hotWater, chilledWater, circulation, heatingLeadTime, heatingReturnFlow} = filters;
    const {sensors = []} = data;
    const imageMapRef = useRef(null);

    const onMapClick = (area) => {
        if ((activeSensor.includes(area.id) || area.id.toString().includes('m')) && !area.className.includes('image-map__map')) {
            deleteActiveCard();
        }
        if (!area.id.toString().includes('m') && !activeSensor.includes(area.id)) {
            dispatch(addActiveSensor(area.id));
            const imageWidth = imageMapRef.current?.children[0]?.clientWidth;
            const imageHeight = imageMapRef.current?.children[0]?.clientHeight;
            setActiveElement({...area, imageWidth, imageHeight});
        }
    };

    useEffect(() => {
        if(activeGroup !== 1 && maxGroups > 2) {
            setTimeout(() => {setCurrentImages(images)}, 500);
        } else {
            setCurrentImages(images);
        }
    }, [images]);

    const additional3DLayers = useCallback(() => {
        switch(id) {
            case '62061f10452e21129b8ca242' && prototypeMode: 
                return (
                    <img src={imgSupport ? heater_webp : heater_png}
                       className={`${styles.img_layer} ${showTooltip ? '' : styles.hidden} ${styles.small_3D_images}`} alt=""
                       id="bg_heizkessel_on"/>
                )
            case '62e90c3e3b1685117c8fe1e1': 
                return (
                    <>
                        <img src={currentImages[6]} alt="" className={`${styles.img_layer} ${styles.valves_layer} ${showTooltip ? '' : styles.hidden} ${activeGroup === 2 ? styles.animation_layer_group1 : ''}`}/>
                        <img src={currentImages[6]} alt="" className={`${styles.img_layer} ${styles.valves_layer} ${showTooltip ? '' : styles.hidden} ${activeGroup === 1 ? styles.animation_layer_group2 : ''}`}/>
                    </>
                )
            case '631f0690b6c762f01414a1da': 
                return (
                    <>
                        <img src={currentImages[6]} alt="" className={`${styles.img_layer} ${styles.valves_layer} ${showTooltip ? '' : styles.hidden} ${activeGroup !== 1 ? styles.animation_layer_group1 : ''}`}/>
                        <img src={currentImages[6]} alt="" className={`${styles.img_layer} ${ activeGroup === 3 ? styles.transition_switching_group : styles.valves_layer} ${showTooltip ? '' : styles.hidden} ${activeGroup !== 2 ? activeGroup === 3 ? styles.hidden_valves_layer : styles.animation_layer_group2 : ''}`}/>
                    </>
                )
            case '63402b5b68662770736f5c69': 
                return (
                    <>
                        <img src={currentImages[6]} alt="" className={`${styles.img_layer} ${styles.valves_layer} ${showTooltip ? '' : styles.hidden} ${activeGroup !== 1 ? styles.animation_layer_group1 : ''}`} />
                        <img src={currentImages[6]} alt="" className={`${styles.img_layer} ${ activeGroup === 3 ? styles.transition_switching_group : styles.valves_layer} ${showTooltip ? '' : styles.hidden} ${activeGroup !== 2 ? activeGroup === 3 ? styles.hidden_valves_layer : styles.animation_layer_group2 : ''}`} />
                    </>
                )
            default:
                return null;
        }
    }, [activeGroup, currentImages]);

    const calculateParameters = (imageParam, elementParam, divisor) => {
        return (imageParam * +elementParam.split('%')[0]) / divisor;
    };

    const deleteActiveCard = () => dispatch(removeActiveSensor());
     const calculateCenteringX =  activeElement ? calculateParameters(activeElement.imageWidth, activeElement.width, 200) : '';
     const calculateOutsideLeft = activeElement ? calculateParameters(activeElement.imageWidth, activeElement.left, 100) + 116.5 : '';
     const calculateOutsideRight = activeElement ? calculateParameters(activeElement.imageWidth, activeElement.left, 100) - 116.5 : '';
     const calculateOutsideTop = activeElement ? calculateParameters(activeElement.imageHeight, activeElement.top, 100) - 386 : '';
     const calculatePointHeight = activeElement ? calculateParameters(activeElement.imageHeight, activeElement.height, 100) : '';
     const calculateOutsideX = activeElement ? +calculateOutsideLeft > activeElement.imageWidth ? activeElement.imageWidth - calculateOutsideLeft : calculateOutsideRight < 0 ? Math.abs(calculateOutsideRight) : calculateCenteringX : '';
     const calculateOutsideY = calculateOutsideTop < 0 ? `calc(${calculatePointHeight}px + 20px)` : 'calc(-100% - 20px)';
     const pointWidthTranslateX = activeElement ? `calc(-50% + ${calculateOutsideX}px)` : '-50%';
    return (
        <div className={`${styles.usage_map_wrap} ${showTooltip ? '' : styles.hidden_tooltips} ${disabledTabsProperties.includes(id) ? styles.big_3D_images_wrap : styles.small_3D_images_wrap}`}>
            <div className={styles.clickable_area} onClick={deleteActiveCard}/>
            <div ref={imageMapRef}>
                <ImageMap className={`${styles.usage_map} ${disabledTabsProperties.includes(id) ? '' : styles.small_3D_images}`} src={currentImages[0]} map={mapArea} onLoad={showTooltips}
                      onMapClick={onMapClick}/>
            </div>
            {additional3DLayers()}
            <img src={currentImages[4]} alt="" className={`${styles.img_layer} ${hotWater.show ? '' : styles.hidden} ${disabledTabsProperties.includes(id) ? '' : styles.small_3D_images}`}/>
            <img src={currentImages[1]} alt=""
                    className={`${styles.img_layer} ${chilledWater.show ? '' : styles.hidden} ${disabledTabsProperties.includes(id) ? '' : styles.small_3D_images}`}/>
            <img src={currentImages[2]} alt=""
                    className={`${styles.img_layer} ${heatingReturnFlow.show ? '' : styles.hidden} ${disabledTabsProperties.includes(id) ? '' : styles.small_3D_images}`}/>
            <img src={currentImages[3]} alt=""
                    className={`${styles.img_layer} ${heatingLeadTime.show ? '' : styles.hidden} ${disabledTabsProperties.includes(id) ? '' : styles.small_3D_images}`}/>
            <img src={currentImages[5]} alt="" className={`${styles.img_layer} ${circulation.show ? '' : styles.hidden} ${disabledTabsProperties.includes(id) ? '' : styles.small_3D_images}`}/>
            <div className={`${styles.usage_map} ${styles.card_wrapper} ${disabledTabsProperties.includes(id) ? '' : styles.small_3D_images}`} >
                {sensors && activeSensor.map((pointId) => {
                    if (pointId === activeElement.id) {
                        const card = sensors.find(s => activeElement.modbus === s.modbus)
                        return (
                            <div key={activeElement.id}
                                 className={`${styles.card_wrap} ${card ? styles.show : ''} sensor-card-wrap`} style={{left: activeElement.left, top: activeElement.top, position: 'absolute', transform: `translate(${pointWidthTranslateX}, ${calculateOutsideY}`}}>
                                <SensorCard card={card} isModal={true} params={{ device_uid: activeElement.device_uid, ...(activeElement.params || {}) }} />
                            </div>
                        )
                    } else
                        return null;
                })}
            </div>
        </div>
    )
};

const PerformanceImgWrap = props => {
    const {mapArea: configArea = [], images, type = '18', maxGroups } = props;
    const {id: _id} = useParams();
    const id = _id.split('&')[0];
    const dispatch = useDispatch();
    const filters = useSelector(getFilterPerformance);
    const activeBuildingGroup = useSelector(getActiveBuilding);
    const [showTooltip, setShowTooltip] = useState(false);
    const [mapArea, setMapArea] = useState([]);
    const data = useSelector(getDataProperty);
    const {t} = useTranslation();
    const active_sensors = useSelector(getActiveSensor);
    const activeGroup = useSelector(getActiveGroup);

    const [pointsData, setPointsData] = useState([]);

    const showTooltips = useCallback(() => {
        setShowTooltip(true);
    }, [setShowTooltip]);

    useEffect(() => {
        dispatch(setActiveSensor([]));
    }, [dispatch]);

    const setMapAreaFunc = useCallback(() => {
        // const activeBuilding = configPerformanceByProperty[id]?.buildings[1];
        setMapArea(configArea.map(point => {
            let deviceFor3d = null;
            let valveNumber = null;
            if(activeGroup && activeGroup !== 1 && activeBuildingGroup) {
                deviceFor3d = (activeBuildingGroup?.config3d.devices || configPerformanceDefault[type] || []).find(d => point.id.toString().includes('d') ? d.pointId === point.id : (d.pointId - ((activeGroup - 1) * 8)) === point.id);
                valveNumber = id !== '6319942f580e73164e7b12b3' ? (activeBuildingGroup?.config3d.devices || configPerformanceDefault[type] || []).find(d => (d.pointId - ((activeGroup - 1) * 8)) === point.id) : null;
            } else {
                deviceFor3d = (activeBuildingGroup?.config3d.devices || configPerformanceDefault[type] || []).find(d => d.pointId === point.id);
            }
            const activeDevice = activeBuildingGroup?.devices?.find(d => deviceFor3d?.device_uid ?  (d.modbus === deviceFor3d?.modbus && d.device_uid === deviceFor3d?.device_uid) : d.modbus === deviceFor3d?.modbus);
            return deviceFor3d
                ? {
                    ...point,
                    modbus: deviceFor3d?.modbus,
                    tooltip_text: valveNumber ? [point.tooltip_text[0], {n: valveNumber.pointId}] : [...point.tooltip_text],
                    device_uid: deviceFor3d?.device_uid || activeDevice?.device_uid,
                    params: { name: activeDevice?.name, house: activeDevice?.house }
                }
                 : point
        }))
    }, [configArea, id, activeBuildingGroup, activeGroup])

    useEffect(() => {
        setMapAreaFunc()
    }, [configArea, id])

    useEffect(() => {
        if (data.sensors) {
            const updateSensors = mapArea.map(point => {
                if (!point.id.toString().includes('m')) {
                    let p = null;
                    if(activeBuildingGroup) {
                        const activeSensors = data.sensors.find(s => s.modbus === point.modbus);
                        const activeSensor = activeSensors ? activeSensors.sensors.find(sensor => sensor.device_uid === point.device_uid): null
                        p = activeSensor ? {
                            sensors: [activeSensor],
                            status: activeSensor.status.status,
                            modbus: activeSensor.modbus
                        } : null
                    } else {
                        p = data.sensors.find(s => s.modbus === point.modbus);
                    }
                    return {
                        ...point,
                        show: point.show ? point.show.toString() : null,
                        tooltip_text: point.tooltip_text ? t(point.tooltip_text[0], point.tooltip_text[1]) : '',
                        className: `${point.className} ${p && p.status ? '' : styles.status_bad}`,
                        // className: `${point.className} ${styles.status_bad}`,
                        status: p ? p.status.toString() : null,
                        sensors: p ? p.sensors : [],
                    };
                } else {
                    return {
                        ...point,
                        tooltip_text: point.tooltip_text ? t(point.tooltip_text[0], point.tooltip_text[1]) : '',
                    };
                }
            });
            setPointsData(updateSensors)
        }
    }, [data, filters, t, active_sensors, mapArea]);

    return (
        <PerformanceImg showTooltip={showTooltip} showTooltips={showTooltips} mapArea={pointsData} filters={filters} images={images} maxGroups={maxGroups}/>
    )
};

export default PerformanceImgWrap;
