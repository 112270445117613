import img_1_v2_kaltwassereingang_8_webp from "./01_Kaltwassereingang/webp/Kaltwassereingang8@2x.webp";
import img_1_v3_kaltwassereingang_8_webp from "./01_Kaltwassereingang/webp/Kaltwassereingang8@3x.webp";
import img_2_v2_kaltwassereingang_8_webp from "./01_Kaltwassereingang/webp/Kaltwassereingang8_1@2x.webp";
import img_2_v3_kaltwassereingang_8_webp from "./01_Kaltwassereingang/webp/Kaltwassereingang8_1@3x.webp";

import img_1_v2_kaltwassereingang_10_webp from "./01_Kaltwassereingang/webp/Kaltwassereingang10@2x.webp";
import img_1_v3_kaltwassereingang_10_webp from "./01_Kaltwassereingang/webp/Kaltwassereingang10@3x.webp";
import img_2_v2_kaltwassereingang_10_webp from "./01_Kaltwassereingang/webp/Kaltwassereingang10_1@2x.webp";
import img_2_v3_kaltwassereingang_10_webp from "./01_Kaltwassereingang/webp/Kaltwassereingang10_1@3x.webp";

import img_1_v2_kaltwassereingang_8_jpg from "./01_Kaltwassereingang/jpg/Kaltwassereingang8@2x.jpg";
import img_1_v3_kaltwassereingang_8_jpg from "./01_Kaltwassereingang/jpg/Kaltwassereingang8@3x.jpg";
import img_2_v2_kaltwassereingang_8_jpg from "./01_Kaltwassereingang/jpg/Kaltwassereingang8_1@2x.jpg";
import img_2_v3_kaltwassereingang_8_jpg from "./01_Kaltwassereingang/jpg/Kaltwassereingang8_1@3x.jpg";

import img_1_v2_kaltwassereingang_10_jpg from "./01_Kaltwassereingang/jpg/Kaltwassereingang10@2x.jpg";
import img_1_v3_kaltwassereingang_10_jpg from "./01_Kaltwassereingang/jpg/Kaltwassereingang10@3x.jpg";
import img_2_v2_kaltwassereingang_10_jpg from "./01_Kaltwassereingang/jpg/Kaltwassereingang10_1@2x.jpg";
import img_2_v3_kaltwassereingang_10_jpg from "./01_Kaltwassereingang/jpg/Kaltwassereingang10_1@3x.jpg";

import img_1_v2_rukspulfilter_webp from "./02_Rukspulfilter/webp/Ruckspulfilter8@2x.webp";
import img_1_v3_rukspulfilter_webp from "./02_Rukspulfilter/webp/Ruckspulfilter8@3x.webp";

import img_1_v2_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/Ruckspulfilter8@2x.jpg";
import img_1_v3_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/Ruckspulfilter8@3x.jpg";

const images_Unter_den_Eschen_8_10 = {
	'Kaltwassereingang': {
        'Kaltwassereingang_8': {
            webp: {
                img_1: [img_1_v2_kaltwassereingang_8_webp, img_1_v3_kaltwassereingang_8_webp],
                img_2: [img_2_v2_kaltwassereingang_8_webp, img_2_v3_kaltwassereingang_8_webp]
            },
            jpg: {
                img_1: [img_1_v2_kaltwassereingang_8_jpg, img_1_v3_kaltwassereingang_8_jpg],
                img_2: [img_2_v2_kaltwassereingang_8_jpg, img_2_v3_kaltwassereingang_8_jpg]
            }
        },
        'Kaltwassereingang_10': {
            webp: {
                img_1: [img_1_v2_kaltwassereingang_10_webp, img_1_v3_kaltwassereingang_10_webp],
                img_2: [img_2_v2_kaltwassereingang_10_webp, img_2_v3_kaltwassereingang_10_webp]
            },
            jpg: {
                img_1: [img_1_v2_kaltwassereingang_10_jpg, img_1_v3_kaltwassereingang_10_jpg],
                img_2: [img_2_v2_kaltwassereingang_10_jpg, img_2_v3_kaltwassereingang_10_jpg]
            }
        }
    },
    'Rukspulfilter': {
        webp: {
            img_1: [img_1_v2_rukspulfilter_webp, img_1_v3_rukspulfilter_webp]
        },
        jpg: {
            img_1: [img_1_v2_rukspulfilter_jpg, img_1_v3_rukspulfilter_jpg]
        }
    }
}

export { images_Unter_den_Eschen_8_10 };