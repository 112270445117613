import { images_Unter_den_Platanen_1 } from "./1/details";
import { images_Unter_den_Platanen_7_9 } from "./7_9/details";
import { images_Unter_den_Platanen_13_16 } from "./13_16/details";

const detailsAnter_den_Platanen = {
    '1': images_Unter_den_Platanen_1,
    '7_9': images_Unter_den_Platanen_7_9,
    '13_16': images_Unter_den_Platanen_13_16
}

export { detailsAnter_den_Platanen };