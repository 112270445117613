import { take, put, call, select } from 'redux-saga/effects';
import {setProperties, setProperty, setSensor} from "../actions";
import {disconnectSocket, socketEmit, socketOn} from "../../utils/socket-config";
import {getTypeSensor, getStatus, getStatusSensor, isEmpty, getFromLocalStorage} from "../../utils/functions";
import {API} from "../../utils/API";
import {eventChannel} from "redux-saga";

const requestSuccess = (type, payload) => ({ type: `${type}_SUCCEEDED`, payload });
// const requestError = (type, payload) => ({ type: `${type}_FAILED`, payload });

function* socketEmitter({emit, on, payload}) {
  const { id } = (yield select()).user.socket;
  return eventChannel(emitter => {
      socketEmit(emit, payload, id);
      socketOn(on, resp => emitter(resp), id);
      return () => disconnectSocket();
  })
}

function handleResponseProperties(type, resp, cb) {
  const user = getFromLocalStorage('userType');
    if (resp.type === "properties" && cb) {
      let updateData = null;
      switch (user) {
        case 'all':
          updateData = normalizeDataProperties(resp.data);
          break;
        case 'Nassauische_Heimstätte@paul.tech':
          updateData = normalizeDataProperties(resp.data.filter(property => property._id === "62f9f4293f62cee2df80a3ec"));
          break;
        case 'GCP@paul.tech':
          updateData = normalizeDataProperties(resp.data.filter(property => property._id === "62e90c3e3b1685117c8fe1e1"));
          break;
        case 'LEG@paul.tech':
          updateData = normalizeDataProperties(resp.data.filter(property => property._id === "62e90c753b1685117c8fe1e2"));
          break;
        case 'ambelin@paul.tech':
          updateData = normalizeDataProperties(resp.data.filter(property => property._id === "631993e9580e73164e7b12b2" || property._id === "6319942f580e73164e7b12b3"));
          break;
        case 'drk@paul.tech':
          updateData = normalizeDataProperties(resp.data.filter(property => property._id === "631f0690b6c762f01414a1da"));
          break;
        default:
          updateData = normalizeDataProperties(resp.data);
          break;
      }
      cb(updateData, setProperties)
    } else if (resp.type === "property" && cb) {
      let updateData = null;
      switch (user) {
        case 'all':
          updateData = normalizeDataProperties(resp.data);
          break;
        case 'Nassauische_Heimstätte@paul.tech':
          updateData = normalizeDataProperties(resp.data.filter(property => property._id === "62f9f4293f62cee2df80a3ec"));
          break;
        case 'GCP@paul.tech':
          updateData = normalizeDataProperties(resp.data.filter(property => property._id === "62e90c3e3b1685117c8fe1e1"));
          break;
        case 'LEG@paul.tech':
          updateData = normalizeDataProperties(resp.data.filter(property => property._id === "62e90c753b1685117c8fe1e2"));
          break;
        case 'ambelin@paul.tech':
          updateData = normalizeDataProperties(resp.data.filter(property => property._id === "631993e9580e73164e7b12b2" || property._id === "6319942f580e73164e7b12b3"));
          break;
        case 'drk@paul.tech':
          updateData = normalizeDataProperties(resp.data.filter(property => property._id === "631f0690b6c762f01414a1da"));
          break;
        default:
          updateData = normalizeDataProperties(resp.data);
          break;
      }
      cb(updateData, setProperty)
    } else if (resp.type === "sensor" && cb) {
      cb(resp.data, setSensor)
    }
}

function* tryRequestProperties(type, socketRequest, cb) {
  try {
      while (true) {
          let resp = yield take(socketRequest)
          yield handleResponseProperties(type, resp, cb)
      }
  } catch (error) {
      // const isExpires = yield sessionExpires(error)
      console.log('catch', error, type);
      // if (isExpires) yield put(setNeedRemoveToken(true));
      // yield put(requestError(type, { message: error.formError} ));
  }
}

function handleResponseProperty(type, resp, cb) {
  if (resp.type === "property" && cb) {
    const updateData = normalizeDataProperties(resp.data);
    cb(updateData, setProperty)
  } else if (resp.type === "sensor" && cb) {
    cb(resp.data, setSensor)
  }
}

function* tryRequestProperty(type, socketRequest, cb) {
  try {
      while (true) {
          let resp = yield take(socketRequest)
          yield handleResponseProperty(type, resp, cb)
      }
  } catch (error) {
      // const isExpires = yield sessionExpires(error)
      console.log('catch', error, type);
      // if (isExpires) yield put(setNeedRemoveToken(true));
      // yield put(requestError(type, { message: error.formError} ));
  }
}


const normalizeDataProperties = data => {
  const sensorsToArray = prop => prop.sensors.reduce((acc, point) => {
    const { _id } = prop;
    const findPointIndex = acc.findIndex(s => s.modbus === point.modbus);
    const findPoint = acc.find(s => s.modbus === point.modbus);
    return findPoint
        ? acc.map((item, i) => i === findPointIndex
            ? {
              sensors: [...findPoint.sensors, { ...point, type: getTypeSensor(point) } ],
              modbus: point.modbus,
              status: getStatus([point, ...findPoint.sensors], _id)
            }
            : item)
        : [...acc, {
          sensors: [{ ...point, type: getTypeSensor(point) }],
          modbus: point.modbus,
          status: getStatus([point], _id)
        }];
  }, []);
  const sensorsAddStatus = prop => prop.sensors.map(point => {
    const { _id } = prop;
    const newPoints = point.sensors.map(s => ({...s, status: getStatusSensor(s, _id) }));
    return { ...point, sensors: newPoints }
  });
  const normalizeData = data.map(prop => ({...prop, sensors: sensorsToArray(prop)}));
  const updateData = normalizeData.map(prop => ({...prop, sensors: sensorsAddStatus(prop)}));
  return updateData
};

export function* getProperties({ type, payload, cb }) {
  const { token } = payload;
  const socketRequest = yield call(socketEmitter, { emit: `properties`, on: 'update', payload: {token} })
  yield tryRequestProperties(type, socketRequest, cb);
}

export function* getProperty({ type, payload, cb }) {
  const { token, id } = payload;
  const socketRequest = yield call(socketEmitter, { emit: `property`, on: 'update', payload: {token, _id: id} })
  yield tryRequestProperty(type, socketRequest, cb);
}

export function* _getChartData({ type, payload }){
  try {
    const resp = yield call(API.post, 'charts', payload.params);
    yield put(requestSuccess(type, { type: payload.type, data: isEmpty(resp) ? [] : resp, chartId: payload.chartId }));
  } catch (error) {
    console.error('getChartData error');
    // yield put(requestError(type, { message: error.formError} ));
  }
}