import img_25_webp from './webp/03_SwitchPlate_0001.webp';
import ChilledWaterImg_25_webp from './webp/23_Kaltwasser_0001.webp';
import heatingReturnFlowImg_25_webp from './webp/21_Heizrucklauf_0001.webp';
import heatingLeadTimeImg_25_webp from './webp/22_Heizvorlauf_0001.webp';
import hotWaterImg_25_webp from './webp/24_Warmwasser_0001.webp';
import circulationImg_25_webp from './webp/25_Zirkulation_0001.webp';
import switchValve_25_webp from './webp/32_SwitchVentile.webp';

import img_25_png from './png/03_SwitchPlate_0001.png';
import ChilledWaterImg_25_png from './png/23_Kaltwasser_0001.png';
import heatingReturnFlowImg_25_png from './png/21_Heizrucklauf_0001.png';
import heatingLeadTimeImg_25_png from './png/22_Heizvorlauf_0001.png';
import hotWaterImg_25_png from './png/24_Warmwasser_0001.png';
import circulationImg_25_png from './png/25_Zirkulation_0001.png';
import switchValve_25_png from './png/32_SwitchVentile.png';

const performanceImgType25 = {
    webp: [img_25_webp, ChilledWaterImg_25_webp, heatingReturnFlowImg_25_webp, heatingLeadTimeImg_25_webp, hotWaterImg_25_webp, circulationImg_25_webp, switchValve_25_webp],
    png: [img_25_png, ChilledWaterImg_25_png, heatingReturnFlowImg_25_png, heatingLeadTimeImg_25_png, hotWaterImg_25_png, circulationImg_25_png, switchValve_25_png]
}

export { performanceImgType25 }