import sensor_123_webp from "./Group_1/webp/Kaltwassereingang.webp";
import sensor_123_v2_webp from "./Group_1/webp/Kaltwassereingang@2x.webp";
import sensor_123_v3_webp from "./Group_1/webp/Kaltwassereingang@3x.webp";

import sensor_123_jpg from "./Group_1/jpg/Kaltwassereingang.jpg";
import sensor_123_v2_jpg from "./Group_1/jpg/Kaltwassereingang@2x.jpg";
import sensor_123_v3_jpg from "./Group_1/jpg/Kaltwassereingang@3x.jpg";

import sensor_124_webp from "./Group_1/webp/Ruckspulfilter.webp";
import sensor_124_v2_webp from "./Group_1/webp/Ruckspulfilter@2x.webp";
import sensor_124_v3_webp from "./Group_1/webp/Ruckspulfilter@3x.webp";

import sensor_124_jpg from "./Group_1/jpg/Ruckspulfilter.jpg";
import sensor_124_v2_jpg from "./Group_1/jpg/Ruckspulfilter@2x.jpg";
import sensor_124_v3_jpg from "./Group_1/jpg/Ruckspulfilter@3x.jpg";

import sensor_125_webp from "./Group_1/webp/Heizkreispumpe1.webp";
import sensor_125_v2_webp from "./Group_1/webp/Heizkreispumpe1@2x.webp";
import sensor_125_v3_webp from "./Group_1/webp/Heizkreispumpe1@3x.webp";

import sensor_125_jpg from "./Group_1/jpg/Heizkreispumpe1.jpg";
import sensor_125_v2_jpg from "./Group_1/jpg/Heizkreispumpe1@2x.jpg";
import sensor_125_v3_jpg from "./Group_1/jpg/Heizkreispumpe1@3x.jpg";

import sensor_126_webp from "./Group_1/webp/Heizkreispumpe2.webp";
import sensor_126_v2_webp from "./Group_1/webp/Heizkreispumpe2@2x.webp";
import sensor_126_v3_webp from "./Group_1/webp/Heizkreispumpe2@3x.webp";

import sensor_126_jpg from "./Group_1/jpg/Heizkreispumpe2.jpg";
import sensor_126_v2_jpg from "./Group_1/jpg/Heizkreispumpe2@2x.jpg";
import sensor_126_v3_jpg from "./Group_1/jpg/Heizkreispumpe2@3x.jpg";

import sensor_127_webp from "./Group_1/webp/Heizkreispumpe3.webp";
import sensor_127_v2_webp from "./Group_1/webp/Heizkreispumpe3@2x.webp";
import sensor_127_v3_webp from "./Group_1/webp/Heizkreispumpe3@3x.webp";

import sensor_127_jpg from "./Group_1/jpg/Heizkreispumpe3.jpg";
import sensor_127_v2_jpg from "./Group_1/jpg/Heizkreispumpe3@2x.jpg";
import sensor_127_v3_jpg from "./Group_1/jpg/Heizkreispumpe3@3x.jpg";

import sensor_128_webp from "./Group_1/webp/Heizkreispumpe4.webp";
import sensor_128_v2_webp from "./Group_1/webp/Heizkreispumpe4@2x.webp";
import sensor_128_v3_webp from "./Group_1/webp/Heizkreispumpe4@3x.webp";

import sensor_128_jpg from "./Group_1/jpg/Heizkreispumpe4.jpg";
import sensor_128_v2_jpg from "./Group_1/jpg/Heizkreispumpe4@2x.jpg";
import sensor_128_v3_jpg from "./Group_1/jpg/Heizkreispumpe4@3x.jpg";

import sensor_129_webp from "./Group_1/webp/Fernwarme.webp";
import sensor_129_v2_webp from "./Group_1/webp/Fernwarme@2x.webp";
import sensor_129_v3_webp from "./Group_1/webp/Fernwarme@3x.webp";

import sensor_129_jpg from "./Group_1/jpg/Fernwarme.jpg";
import sensor_129_v2_jpg from "./Group_1/jpg/Fernwarme@2x.jpg";
import sensor_129_v3_jpg from "./Group_1/jpg/Fernwarme@3x.jpg";

import sensor_130_webp from "./Group_1/webp/Warmetauscher.webp";
import sensor_130_v2_webp from "./Group_1/webp/Warmetauscher@2x.webp";
import sensor_130_v3_webp from "./Group_1/webp/Warmetauscher@3x.webp";

import sensor_130_jpg from "./Group_1/jpg/Warmetauscher.jpg";
import sensor_130_v2_jpg from "./Group_1/jpg/Warmetauscher@2x.jpg";
import sensor_130_v3_jpg from "./Group_1/jpg/Warmetauscher@3x.jpg";

import sensor_134_webp from "./Group_1/webp/Speicherladepumpe.webp";
import sensor_134_v2_webp from "./Group_1/webp/Speicherladepumpe@2x.webp";
import sensor_134_v3_webp from "./Group_1/webp/Speicherladepumpe@3x.webp";

import sensor_134_jpg from "./Group_1/jpg/Speicherladepumpe.jpg";
import sensor_134_v2_jpg from "./Group_1/jpg/Speicherladepumpe@2x.jpg";
import sensor_134_v3_jpg from "./Group_1/jpg/Speicherladepumpe@3x.jpg";

import sensor_135_webp from "./Group_1/webp/Warmwasserspeicher.webp";
import sensor_135_v2_webp from "./Group_1/webp/Warmwasserspeicher@2x.webp";
import sensor_135_v3_webp from "./Group_1/webp/Warmwasserspeicher@3x.webp";

import sensor_135_jpg from "./Group_1/jpg/Warmwasserspeicher.jpg";
import sensor_135_v2_jpg from "./Group_1/jpg/Warmwasserspeicher@2x.jpg";
import sensor_135_v3_jpg from "./Group_1/jpg/Warmwasserspeicher@3x.jpg";

import sensor_136_webp from "./Group_2/webp/Kaltwassereingang.webp";
import sensor_136_v2_webp from "./Group_2/webp/Kaltwassereingang@2x.webp";
import sensor_136_v3_webp from "./Group_2/webp/Kaltwassereingang@3x.webp";

import sensor_136_jpg from "./Group_2/jpg/Kaltwassereingang.jpg";
import sensor_136_v2_jpg from "./Group_2/jpg/Kaltwassereingang@2x.jpg";
import sensor_136_v3_jpg from "./Group_2/jpg/Kaltwassereingang@3x.jpg";

import sensor_138_webp from "./Group_2/webp/Ruckspulfilter.webp";
import sensor_138_v2_webp from "./Group_2/webp/Ruckspulfilter@2x.webp";
import sensor_138_v3_webp from "./Group_2/webp/Ruckspulfilter@3x.webp";

import sensor_138_jpg from "./Group_2/jpg/Ruckspulfilter.jpg";
import sensor_138_v2_jpg from "./Group_2/jpg/Ruckspulfilter@2x.jpg";
import sensor_138_v3_jpg from "./Group_2/jpg/Ruckspulfilter@3x.jpg";

import sensor_143_webp from "./Group_2/webp/Speicherladepumpe.webp";
import sensor_143_v2_webp from "./Group_2/webp/Speicherladepumpe@2x.webp";
import sensor_143_v3_webp from "./Group_2/webp/Speicherladepumpe@3x.webp";

import sensor_143_jpg from "./Group_2/jpg/Speicherladepumpe.jpg";
import sensor_143_v2_jpg from "./Group_2/jpg/Speicherladepumpe@2x.jpg";
import sensor_143_v3_jpg from "./Group_2/jpg/Speicherladepumpe@3x.jpg";

import sensor_144_webp from "./Group_2/webp/Warmwasserspeicher.webp";
import sensor_144_v2_webp from "./Group_2/webp/Warmwasserspeicher@2x.webp";
import sensor_144_v3_webp from "./Group_2/webp/Warmwasserspeicher@3x.webp";

import sensor_144_jpg from "./Group_2/jpg/Warmwasserspeicher.jpg";
import sensor_144_v2_jpg from "./Group_2/jpg/Warmwasserspeicher@2x.jpg";
import sensor_144_v3_jpg from "./Group_2/jpg/Warmwasserspeicher@3x.jpg";

import sensor_145_webp from "./Group_2/webp/Zirkulationspumpe.webp";
import sensor_145_v2_webp from "./Group_2/webp/Zirkulationspumpe@2x.webp";
import sensor_145_v3_webp from "./Group_2/webp/Zirkulationspumpe@3x.webp";

import sensor_145_jpg from "./Group_2/jpg/Zirkulationspumpe.jpg";
import sensor_145_v2_jpg from "./Group_2/jpg/Zirkulationspumpe@2x.jpg";
import sensor_145_v3_jpg from "./Group_2/jpg/Zirkulationspumpe@3x.jpg";


const sCharlottenburgerstrabe = {
    webp: {
        s_123: [sensor_123_webp, sensor_123_v2_webp, sensor_123_v3_webp],
        s_124: [sensor_124_webp, sensor_124_v2_webp, sensor_124_v3_webp],
        s_125: [sensor_125_webp, sensor_125_v2_webp, sensor_125_v3_webp],
        s_126: [sensor_126_webp, sensor_126_v2_webp, sensor_126_v3_webp],
        s_127: [sensor_127_webp, sensor_127_v2_webp, sensor_127_v3_webp],
        s_128: [sensor_128_webp, sensor_128_v2_webp, sensor_128_v3_webp],
        s_129: [sensor_129_webp, sensor_129_v2_webp, sensor_129_v3_webp],
        s_130: [sensor_130_webp, sensor_130_v2_webp, sensor_130_v3_webp],
        s_134: [sensor_134_webp, sensor_134_v2_webp, sensor_134_v3_webp],
        s_135: [sensor_135_webp, sensor_135_v2_webp, sensor_135_v3_webp]
    },
    jpg: {
        s_123: [sensor_123_jpg, sensor_123_v2_jpg, sensor_123_v3_jpg],
        s_124: [sensor_124_jpg, sensor_124_v2_jpg, sensor_124_v3_jpg],
        s_125: [sensor_125_jpg, sensor_125_v2_jpg, sensor_125_v3_jpg],
        s_126: [sensor_126_jpg, sensor_126_v2_jpg, sensor_126_v3_jpg],
        s_127: [sensor_127_jpg, sensor_127_v2_jpg, sensor_127_v3_jpg],
        s_128: [sensor_128_jpg, sensor_128_v2_jpg, sensor_128_v3_jpg],
        s_129: [sensor_129_jpg, sensor_129_v2_jpg, sensor_129_v3_jpg],
        s_130: [sensor_130_jpg, sensor_130_v2_jpg, sensor_130_v3_jpg],
        s_134: [sensor_134_jpg, sensor_134_v2_jpg, sensor_134_v3_jpg],
        s_135: [sensor_135_jpg, sensor_135_v2_jpg, sensor_135_v3_jpg]
    }
}

const sPlotzenseerstrabe = {
    webp: {
        s_136: [sensor_136_webp, sensor_136_v2_webp, sensor_136_v3_webp],
        s_138: [sensor_138_webp, sensor_138_v2_webp, sensor_138_v3_webp],
        s_143: [sensor_143_webp, sensor_143_v2_webp, sensor_143_v3_webp],
        s_144: [sensor_144_webp, sensor_144_v2_webp, sensor_144_v3_webp],
        s_145: [sensor_145_webp, sensor_145_v2_webp, sensor_145_v3_webp]
    },
    jpg: {
        s_136: [sensor_136_jpg, sensor_136_v2_jpg, sensor_136_v3_jpg],
        s_138: [sensor_138_jpg, sensor_138_v2_jpg, sensor_138_v3_jpg],
        s_143: [sensor_143_jpg, sensor_143_v2_jpg, sensor_143_v3_jpg],
        s_144: [sensor_144_jpg, sensor_144_v2_jpg, sensor_144_v3_jpg],
        s_145: [sensor_145_jpg, sensor_145_v2_jpg, sensor_145_v3_jpg]
    }
}

export { sCharlottenburgerstrabe, sPlotzenseerstrabe };