import { buildings } from '../images/buildings';
import { s5 as sensors_241_5, s7 as sensors_241_7 } from '../images/houses/62061f10452e21129b8ca241';
import { sNull as sensors_242_null } from '../images/houses/62061f10452e21129b8ca242';
import { sNiehlerGurtel as sensors_NiehlerGurtel } from '../images/houses/62e90c3e3b1685117c8fe1e1';
import { images_5 as sensors_241_images_5 } from '../images/houses/62061f10452e21129b8ca241/5/details';
import { images_7 as sensors_241_images_7 } from '../images/houses/62061f10452e21129b8ca241/7/details';
import { images as sensors_242_images } from '../images/houses/62061f10452e21129b8ca242/details_index';
import { images_NiehlerGurtel as sensors_NiehlerGurtel_images } from '../images/houses/62e90c3e3b1685117c8fe1e1/details_index';
import { sDefault } from '../images/houses/default_images';
import { default_images } from "../images/houses/default_images/details_index";
import { s12 as main_Heimatring_images_12, s8_9 as main_Heimatring_images_8_9, s10_11 as main_Heimatring_images_10_11, s12_14 as main_Heimatring_images_12_14 } from '../images/houses/62f9f4293f62cee2df80a3ec/Heimatring';
import { detailsHeimatring as sensors_Heimatring_images } from '../images/houses/62f9f4293f62cee2df80a3ec/Heimatring/details_index';
import { s3_4 as main_Anter_den_Akazien_images_3_4, s7_9 as main_Anter_den_Akazien_images_7_9, s10_12 as main_Anter_den_Akazien_images_10_12 } from '../images/houses/62f9f4293f62cee2df80a3ec/Unter_den_Akazien';
import { detailsAnter_den_Akazien as sensors_Anter_den_Akazien_images } from '../images/houses/62f9f4293f62cee2df80a3ec/Unter_den_Akazien/details_index';
import { s1 as main_Anter_den_Platanen_images_1, s7_9 as main_Anter_den_Platanen_images_7_9, s13_16 as main_Anter_den_Platanen_images_13_16 } from '../images/houses/62f9f4293f62cee2df80a3ec/Unter_den_Platanen';
import { detailsAnter_den_Platanen as sensors_Anter_den_Platanen_images } from '../images/houses/62f9f4293f62cee2df80a3ec/Unter_den_Platanen/details_index';
import { s5_7 as main_Unter_den_Linden_images_5_7, s14_16 as main_Unter_den_Linden_images_14_16, s17_18 as main_Unter_den_Linden_images_17_18 } from '../images/houses/62f9f4293f62cee2df80a3ec/Unter_den_Linden';
import { detailsUnter_den_Linden as sensors_Unter_den_Linden_images } from '../images/houses/62f9f4293f62cee2df80a3ec/Unter_den_Linden/details_index';
import { s3_4 as main_Unter_den_Eschen_images_3_4, s5_7 as main_Unter_den_Eschen_images_5_7, s8_10 as main_Unter_den_Eschen_images_8_10, s11_13 as main_Unter_den_Eschen_images_11_13, s14_15 as main_Unter_den_Eschen_images_14_15 } from '../images/houses/62f9f4293f62cee2df80a3ec/Unter_den_Eschen';
import { detailsUnter_den_Eschen as sensors_Unter_den_Eschen_images } from '../images/houses/62f9f4293f62cee2df80a3ec/Unter_den_Eschen/details_index';
import { s37 as main_Oberhutte_Albau_37_images } from '../images/houses/63402b5b68662770736f5c69';
import { detailsOberhutte as sensors_Oberhutte_images } from '../images/houses/63402b5b68662770736f5c69/details_index';
import { sBrusseler_15 as main_Busseler_15_images } from '../images/houses/631993e9580e73164e7b12b2';
import { detailsBrusseler as sensors_Brusseler_images } from '../images/houses/631993e9580e73164e7b12b2/details_index';
import { sDerfflinger as main_Derfflinger_images } from '../images/houses/6319942f580e73164e7b12b3';
import { detailsDerfflinger as sensors_Derfflinger_images } from '../images/houses/6319942f580e73164e7b12b3/details_index';
import { detailsCharlotten_Plotzenseer as sensors_Charlotten_Plotzenseer_images } from '../images/houses/62e90c753b1685117c8fe1e2/details_index';
import { sCharlottenburgerstrabe as main_Charlottenburgerstrabe_images, sPlotzenseerstrabe as main_Plotzenseerstrabe_images } from '../images/houses/62e90c753b1685117c8fe1e2';
import { sAmBlomkenberg as main_AmBlomkenberg_images } from '../images/houses/631f0690b6c762f01414a1da';
import { images_AmBlomkenberg as sensors_AmBlomkenberg_images } from '../images/houses/631f0690b6c762f01414a1da/details_index';
import { mainz } from '../images/360/mainz';
import { rhein5, rhein7 } from '../images/360/rhein';

export const devicesRule = [
  {
    device_uid: '1118232908-865583041247411',
    device_type: 'PAUL',
  },
  {
    device_uid: '1121100843-865583041319806',
    device_type: 'PAUL',
  },
  {
    device_uid: '1121100923-865583041280818',
    device_type: 'PAUL',
  },
  {
    device_uid: '1121103402-865583041282681',
    device_type: 'PAUL',
  },
  {
    device_uid: '1121104341-865583041299149',
    device_type: 'PAUL',
  },
  {
    device_uid: '1121101194-865583041294769',
    device_type: 'PAUL',
  },
  {
    device_uid: '1121101672-865583041293092',
    device_type: 'PAUL',
  },
  {
    device_uid: '1118232914-865583041233460',
    device_type: 'PAUL',
  },
  {
    device_uid: '1121101562-865583041311803',
    device_type: 'PAUL',
  },
  {
    device_uid: '1121101671-865583041316372',
    device_type: 'PAUL',
  },
  {
    device_uid: '1121100898-865583041291807',
    device_type: 'PAUL',
  },
  {
    device_uid: '1121100816-865583041301440',
    device_type: 'PAUL',
  },
  {
    device_uid: '1121102574-865583041325647',
    device_type: 'PAUL',
  },
  {
    device_uid: '1118232910-865583041249334',
    device_type: 'PAUL',
  },
  {
    device_uid: '1120452042-865583041297911',
    device_type: 'PAUL',
  },
  {
    device_uid: '1118232911-865583041247452',
    device_type: 'PAUL',
  },
  {
    device_uid: '1120452035-865583041297077',
    device_type: 'PAUL',
  },
  {
    device_uid: '1121101801-865583041313650',
    device_type: 'PAUL',
  },
  {
    device_uid: '1113467909-865583040805789',
    device_type: 'PAUL',
  },
  {
    device_uid: '1120452179-865583041303420',
    device_type: 'PAUL',
  },
  {
    device_uid: '1121100813-865583041301580',
    device_type: 'PAUL',
  },
  {
    device_uid: '1121101804-865583041317271',
    device_type: 'PAUL',
  },
  {
    device_uid: '1120452303-865583041310029',
    device_type: 'PAUL',
  },
  {
    device_uid: '1121102214-865583041310193',
    device_type: 'PAUL',
  },
  {
    device_uid: '1120452021-865583041314732',
    device_type: 'PAUL',
  },
  {
    device_uid: '1118228777-865583041214940',
    device_type: 'PAUL',
  },
  {
    device_uid: '1120452187-865583041302042',
    device_type: 'PAUL',
  },
  {
    device_uid: '1118228773-865583041219774',
    device_type: 'PAUL',
  },
  {
    device_uid: '1120452502-865583041298943',
    device_type: 'PAUL',
  },
  {
    device_uid: '1120452051-865583041300368',
    device_type: 'PAUL',
  },
  {
    device_uid: '1121100895-865583041291930',
    device_type: 'PAUL',
  },
  {
    device_uid: '1120452244-865583041302315',
    device_type: 'PAUL',
  },
  {
    device_uid: '1119720743-865583041347989',
    device_type: 'PAUL',
  },
  {
    device_uid: '1120452299-865583041309922',
    device_type: 'PAUL',
  },
  {
    device_uid: '1121102219-865583041307884',
    device_type: 'PAUL',
  },
  {
    device_uid: '1120452247-865583041302307',
    device_type: 'PAUL',
  },
  {
    device_uid: '1120452306-865583041309997',
    device_type: 'PAUL',
  },
  {
    device_uid: '1118227466-865583041251652',
    device_type: 'PAUL',
  },
  {
    device_uid: '1118227463-865583041251645',
    device_type: 'PAUL',
  },
  {
    device_uid: '1121103027-865583041348839',
    device_type: 'PAUL',
  },
  {
    device_uid: '1120452499-865583041298893',
    device_type: 'PAUL',
  },
  {
    device_uid: '1120452304-865583041277624',
    device_type: 'PAUL',
  },
  {
    device_uid: '1121103788-865583041321828',
    device_type: 'PAUL',
  },
  {
    device_uid: '1120452036-865583041297101',
    device_type: 'PAUL',
  },
  {
    device_uid: '1121102217-865583041310326',
    device_type: 'PAUL',
  },
  {
    device_uid: '1120452186-865583041294793',
    device_type: 'PAUL',
  },
  {
    device_uid: '1118232953-865583041233338',
    device_type: 'PAUL',
  },
  {
    device_uid: '1121102674-865583041323030',
    device_type: 'PAUL',
  },
  {
    device_uid: '1118232950-865583041233502',
    device_type: 'PAUL',
  },
  {
    device_uid: '1118229504-865583041231373',
    device_type: 'PAUL',
  },
  {
    device_uid: '1121103023-865583041352971',
    device_type: 'PAUL',
  },
  {
    device_uid: '901405300029882-867997030810526',
    device_type: 'Techem',
  },
  {
    device_uid: '901405300029885-867997030811136',
    device_type: 'Techem',
  },
  {
    device_uid: '901405300029884-867997032302340',
    device_type: 'Techem',
  },
  {
    device_uid: '100.99.216.29-0',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.29-1',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.29-2',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.29-3',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.29-4',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.29-5',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.29-6',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.29-7',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.29-8',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.29-9',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.29-10',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.29-11',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.29-12',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.29-13',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.29-14',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.29-16',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.29-20',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.29-30',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.29-31',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.61-0',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.61-1',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.61-2',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.61-3',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.61-4',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.61-5',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.61-6',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.61-7',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.61-8',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.61-9',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.61-10',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.61-13',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.61-14',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.61-15',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.61-16',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.61-17',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.61-51',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.62-52',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.62-5',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.62-6',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.62-12',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.62-1',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.62-3',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.62-7',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.62-13',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.62-4',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.62-10',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.62-30',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.62-15',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.62-70',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.62-11',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.62-9',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.62-0',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.195-1',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.195-2',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.195-3',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.195-4',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.195-5',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.195-6',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.195-7',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.195-8',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.195-9',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.195-10',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.195-11',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.195-200',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.130-0',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.130-2',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.130-3',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.130-4',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.130-5',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.130-6',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.130-7',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.130-8',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.130-9',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.130-10',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.130-11',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.130-12',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.130-13',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.130-14',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.216.130-201',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-134',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-135',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-136',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-137',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-138',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-139',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-140',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-141',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-142',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-143',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-144',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-145',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-146',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-147',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-148',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-149',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-150',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-151',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-152',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-153',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-154',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-155',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-156',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-0',
    device_type: 'PAUL',
  },
  {
    device_uid: '100.99.217.155-201',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-2',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-3',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-4',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-5',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-6',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-7',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-8',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-9',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-10',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-11',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-12',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-13',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-14',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-15',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-16',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-17',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-18',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-19',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-20',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-21',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-22',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-23',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-24',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-25',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-185',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-186',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-195',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-200',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-201',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-202',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-203',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-204',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-205',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-206',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-207',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-232',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-233',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-234',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-235',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-212',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-213',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-214',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-215',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-216',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-217',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-218',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-219',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-245',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-240',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-241',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-246',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-242',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-243',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-247',
    device_type: 'PAUL',
  },
  {
    device_uid: '10.185.135.127-244',
    device_type: 'PAUL',
  },
];

export const sensorListRule = {
  '62061f10452e21129b8ca241': {
    'Trinkwasser': [152, 151, 101, 100, 160, 161, 162, 163, 164, 165, 166],
    'Heizung': [200, 201, 212, 213, 232, 233]
  },
  '62061f10452e21129b8ca242': {
    'Trinkwasser': [152, 151, 101, 100, 160, 161, 162, 163, 164, 165, 166],
    'Heizung': [200, 201, 212, 213, 232, 233]
  },
  '62e90c3e3b1685117c8fe1e1': {
    'Trinkwasser': [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 20, 30, 31, 51, 240],
    'Heizung': [200, 201, 212, 213, 232, 233]
  },
  '62e90c753b1685117c8fe1e2': {
    'Trinkwasser': [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 30, 51, 52, 70],
    'Heizung': []
  },
  '62f9f4293f62cee2df80a3ec': {
    'Trinkwasser': [2, 3, 4, 5, 6, 7, 8, 9, 20, 100, 200, 243, 245],
    'Heizung': [232, 233]
  },
  '631993e9580e73164e7b12b2': {
    'Trinkwasser': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 20, 100, 200, 243, 245],
    'Heizung': [232, 233]
  },
  '6319942f580e73164e7b12b3': {
    'Trinkwasser': [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 20, 100, 200, 201, 243, 245],
    'Heizung': [232, 233]
  },
  '631f0690b6c762f01414a1da': {
    'Trinkwasser': [0, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 201],
    'Heizung': []
  },
  '63402b5b68662770736f5c69': {
    'Trinkwasser': [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 185, 186, 195, 234, 235, 240, 241, 242, 243, 245, 246],
    'Heizung': [200, 201, 202, 203, 204, 205, 206, 207, 212, 213, 214, 215, 216, 217, 218, 219, 232, 233]
  },
}

export const modbusName = {
  '62e90c3e3b1685117c8fe1e1': {
    0: {
      componentType: "hotWater",
      value: 1,
      localization: {
        nameType: "Exit"
      },
      building: null,
    },
    1: {
      componentType: "smartValve",
      value: 1,
      localization: {
        nameType: ""
      },
      building: null,
    },
    2: {
      componentType: "smartValve",
      value: 10,
      localization: {
        nameType: ""
      },
      building: null,
    },
    3: {
      componentType: "smartValve",
      value: 11,
      localization: {
        nameType: ""
      },
      building: null,
    },
    4: {
      componentType: "smartValve",
      value: 4,
      localization: {
        nameType: ""
      },
      building: null,
    },
    5: {
      componentType: "smartValve",
      value: 13,
      localization: {
        nameType: ""
      },
      building: null,
    },
    6: {
      componentType: "smartValve",
      value: 14,
      localization: {
        nameType: ""
      },
      building: null,
    },
    7: {
      componentType: "smartValve",
      value: 12,
      localization: {
        nameType: ""
      },
      building: null,
    },
    8: {
      componentType: "smartValve",
      value: 9,
      localization: {
        nameType: ""
      },
      building: null,
    },
    9: {
      componentType: "smartValve",
      value: 15,
      localization: {
        nameType: ""
      },
      building: null,
    },
    10: {
      componentType: "smartValve",
      value: 5,
      localization: {
        nameType: ""
      },
      building: null,
    },
    11: {
      componentType: "smartValve",
      value: 3,
      localization: {
        nameType: ""
      },
      building: null,
    },
    12: {
      componentType: "smartValve",
      value: 16,
      localization: {
        nameType: ""
      },
      building: null,
    },
    13: {
      componentType: "smartValve",
      value: 8,
      localization: {
        nameType: ""
      },
      building: null,
    },
    14: {
      componentType: "smartValve",
      value: 7,
      localization: {
        nameType: ""
      },
      building: null,
    },
    16: {
      componentType: "smartValve",
      value: 2,
      localization: {
        nameType: ""
      },
      building: null,
    },
    20: {
      componentType: "smartValve",
      value: 6,
      localization: {
        nameType: ""
      },
      building: null,
    },
    30: {
      componentType: "flushValve",
      localization: {
        nameType: "",
      },
      building: null,
    },
    31: {
      componentType: "flushValve",
      localization: {
        nameType: ""
      },
      building: null,
    },
  },
  '62061f10452e21129b8ca241': {
    22: {
      componentType: "chilledWater",
      localization: {
        nameType: "EntranceH5"
      },
      building: 5,
    },
    100: {
      componentType: "chilledWater",
      localization: {
        nameType: "EntranceH7",
      },
      building: 7,
    },
    101: {
      componentType: "chilledWater",
      localization: {
        nameType: "EntranceH5"
      },
      building: 5,
    },
    151: {
      componentType: "hotWater",
      localization: {
        nameType: "Exit"
      },
      building: 5,
    },
    152: {
      componentType: "circulation",
      localization: {
        nameType: "Rewind"
      },
      building:  5,
    },
    160: {
      componentType: "Pipe1",
      localization: {
        nameType: "house7"
      },
      building: 7,
    },
    161: {
      componentType: "Pipe2",
      localization: {
        nameType: "house7"
      },
      building: 7,
    },
    162: {
      componentType: "Pipe3",
      localization: {
        nameType: "house7"
      },
      building: 7,
    },
    163: {
      componentType: "Pipe4",
      localization: {
        nameType: "house7"
      },
      building: 7,
    },
    164: {
      componentType: "Pipe5",
      localization: {
        nameType: "house5"
      },
      building: 7,
    },
    165: {
      componentType: "Pipe6",
      localization: {
        nameType: "house5"
      },
      building: 7,
    },
    166: {
      componentType: "Pipe7",
      localization: {
        nameType: "house5"
      },
      building: 7,
    },
    200: {
      componentType: "Boiler",
      localization: {
        nameType: "FastForward"
      },
      building: 5,
    },
    201: {
      componentType: "Boiler",
      localization: {
        nameType: "Rewind"
      },
      building: 5,
    },
    202: {
      component: "VL",
      localization: {
        name: "WEZ_ID202"
      },
      building: null,
    },
    203: {
      component: "RL",
      localization: {
        name: "WEZ_ID203"
      },
      building: null,
    },
    204: {
      component: "VL",
      localization: {
        name: "WEZ_ID204"
      },
      building: null,
    },
    206: {
      component: "VL",
      localization: {
        name: "HW_ID206"
      },
      building: null,
    },
    207: {
      component: "RL",
      localization: {
        name: "HW_ID207"
      },
      building: null,
    },
    208: {
      component: "VL",
      localization: {
        name: "HW_ID208"
      },
      building: null,
    },
    209: {
      component: "RL",
      localization: {
        name: "HW_ID209"
      },
      building: null,
    },
    212: {
      componentType: "HeatingCircuit",
      localization: {
        nameType: "FastForward"
      },
      building: 5,
    },
    213: {
      componentType: "HeatingCircuit",
      localization: {
        nameType: "Rewind"
      },
      building: 5,
    },
    232: {
      componentType: "StorageWaterHeater",
      localization: {
        nameType: "FastForward"
      },
      building: 5,
    },
    233: {
      componentType: "StorageWaterHeater",
      localization: {
        nameType: "Rewind"
      },
      building: 5,
    }
  },
  '62061f10452e21129b8ca242': {
    22: {
      componentType: "chilledWater",
      localization: {
        nameType: "Entrance"
      },
      building: null,
    },
    100: {
      componentType: "chilledWater",
      localization: {
        nameType: "Entrance",
      },
      building: null,
    },
    101: {
      componentType: "chilledWater",
      localization: {
        nameType: "Entrance"
      },
      building: null,
    },
    151: {
      componentType: "hotWater",
      localization: {
        nameType: "Exit"
      },
      building: null,
    },
    152: {
      componentType: "circulation",
      localization: {
        nameType: "Rewind"
      },
      building:  null,
    },
    160: {
      componentType: "Pipe1",
      localization: {
        name: ""
      },
      building: null,
    },
    161: {
      componentType: "Pipe2",
      localization: {
        name: ""
      },
      building: null,
    },
    162: {
      componentType: "Pipe3",
      localization: {
        name: ""
      },
      building: null,
    },
    200: {
      componentType: "Boiler",
      localization: {
        nameType: "FastForward"
      },
      building: null,
    },
    201: {
      componentType: "Boiler",
      localization: {
        nameType: "Rewind"
      },
      building: null,
    },
    202: {
      component: "VL",
      localization: {
        name: "WEZ_ID202"
      },
      building: null,
    },
    203: {
      component: "RL",
      localization: {
        name: "WEZ_ID203"
      },
      building: null,
    },
    204: {
      component: "VL",
      localization: {
        name: "WEZ_ID204"
      },
      building: null,
    },
    206: {
      component: "VL",
      localization: {
        name: "HW_ID206"
      },
      building: null,
    },
    207: {
      component: "RL",
      localization: {
        name: "HW_ID207"
      },
      building: null,
    },
    208: {
      component: "VL",
      localization: {
        name: "HW_ID208"
      },
      building: null,
    },
    209: {
      component: "RL",
      localization: {
        name: "HW_ID209"
      },
      building: null,
    },
    212: {
      componentType: "HeatingCircuit",
      localization: {
        nameType: "FastForward",
      },
      building: null,
    },
    213: {
      componentType: "HeatingCircuit",
      localization: {
        nameType: "Rewind"
      },
      building: null,
    },
    232: {
      componentType: "StorageWaterHeater",
      localization: {
        nameType: "FastForward"
      },
      building: null,
    },
    233: {
      componentType: "StorageWaterHeater",
      localization: {
        nameType: "Rewind"
      },
      building: null,
    }
  },
  '62e90c753b1685117c8fe1e2': {
    0: {
      componentType: "flushValve",
      localization: {
        nameType: "buildingName"
      },
      building: 3,
    },
    1: {
      componentType: "smartValve",
      value: 1,
      localization: {
        nameType: "buildingName"
      },
      building: 1,
    },
    2: {
      componentType: "smartValve",
      value: 1,
      localization: {
        nameType: "buildingName"
      },
      building: 3,
    },
    3: {
      componentType: "smartValve",
      value: 1,
      localization: {
        nameType: "buildingName"
      },
      building: 5,
    },
    4: {
      componentType: "smartValve",
      value: 2,
      localization: {
        nameType: "buildingName"
      },
      building: 1,
    },
    5: {
      componentType: "smartValve",
      value: 2,
      localization: {
        nameType: "buildingName"
      },
      building: 7,
    },
    6: {
      componentType: "smartValve",
      value: 2,
      localization: {
        nameType: "buildingName"
      },
      building: 5,
    },
    7: {
      componentType: "smartValve",
      value: 2,
      localization: {
        nameType: "buildingName"
      },
      building: 3,
    },
    8: {
      componentType: "smartValve",
      value: 2,
      localization: {
        nameType: "buildingName"
      },
      building: 10,
    },
    9: {
      componentType: "smartValve",
      value: 3,
      localization: {
        nameType: "buildingName"
      },
      building: 10,
    },
    10: {
      componentType: "smartValve",
      value: 4,
      localization: {
        nameType: "buildingName"
      },
      building: 10,
    },
    11: {
      componentType: "smartValve",
      value: 5,
      localization: {
        nameType: "buildingName"
      },
      building: 10,
    },
    12: {
      componentType: "smartValve",
      value: 2,
      localization: {
        nameType: "buildingName"
      },
      building: 7,
    },
    13: {
      componentType: "smartValve",
      value: 1,
      localization: {
        nameType: "buildingName"
      },
      building: 9,
    },
    14: {
      componentType: "smartValve",
      value: 1,
      localization: {
        nameType: "buildingName"
      },
      building: 10,
    },
    15: {
      componentType: "smartValve",
      value: 5,
      localization: {
        nameType: "buildingName"
      },
      building: 10,
    },
    16: {
      componentType: "smartValve",
      value: 2,
      localization: {
        nameType: "buildingName"
      },
      building: 9,
    },
    17: {
      componentType: "smartValve",
      value: 6,
      localization: {
        nameType: "buildingName"
      },
      building: 10,
    },
    30: {
      componentType: "smartValve",
      value: 7,
      localization: {
        nameType: "buildingName"
      },
      building: 10,
    },
    70: {
      componentType: "smartValve",
      value: 7,
      localization: {
        nameType: "buildingName"
      },
      building: 10,
    },
    51: {
      componentType: "hotWater",
      localization: {
        nameType: "buildingName"
      },
      building: 3,
    },
    52: {
      componentType: "hotWater",
      localization: {
        nameType: "buildingName"
      },
      building: 3,
    }
  },
  '62f9f4293f62cee2df80a3ec': {
    2: {
      componentType: "smartValve",
      value: 1,
      localization: {
        nameType: "building"
      },
      building: 3,
    },
    3: {
      componentType: "smartValve",
      value: 2,
      localization: {
        nameType: "building"
      },
      building: 3,
    },
    6: {
      componentType: "smartValve",
      value: 3,
      localization: {
        nameType: "building"
      },
      building: 3,
    },
    7: {
      componentType: "smartValve",
      value: 7,
      localization: {
        nameType: "building"
      },
      building: 3,
    },
    8: {
      componentType: "smartValve",
      value: 8,
      localization: {
        nameType: "building"
      },
      building: 3,
    },
    4: {
      componentType: "smartValve",
      value: 4,
      localization: {
        nameType: "building"
      },
      building: 4,
    },
    5: {
      componentType: "smartValve",
      value: 5,
      localization: {
        nameType: "building"
      },
      building: 4,
    },
    9: {
      componentType: "smartValve",
      value: 4,
      localization: {
        nameType: "building"
      },
      building: 3,
    },
    20: {
      componentType: "balancingValve",
      localization: {
        nameType: "building"
      },
      building: 3,
    },
    200: {
      componentType: "flushValve",
      localization: {
        nameType: "CentralHeatingSystem"
      },
      building: null,
    },
    100: {
      componentType: "hotWater",
      localization: {
        nameType: "building"
      },
      building: 3,
    },
    243: {
      componentType: "hotWater",
      localization: {
        nameType: "CentralHeatingSystem"
      },
      building: null,
    },
    245: {
      componentType: "pointOfEntry",
      localization: {
        nameType: "CentralHeatingSystem"
      },
      building: null,
    },
    233: {
      componentType: "HeatingFlow",
      localization: {
        nameType: "CentralHeatingSystem"
      },
      building: null,
    },
    232: {
      componentType: "HeatingReturnFlow",
      localization: {
        nameType: "CentralHeatingSystem"
      },
      building: null,
    }
  },
  '631993e9580e73164e7b12b2': {
    2: {
      componentType: "smartValve",
      value: 1,
      localization: {
        nameType: ""
      },
      building: null,
    },
    3: {
      componentType: "smartValve",
      value: 2,
      localization: {
        nameType: ""
      },
      building: null,
    },
    4: {
      componentType: "smartValve",
      value: 3,
      localization: {
        nameType: ""
      },
      building: null,
    },
    5: {
      componentType: "smartValve",
      value: 4,
      localization: {
        nameType: ""
      },
      building: null,
    },
    6: {
      componentType: "smartValve",
      value: 5,
      localization: {
        nameType: ""
      },
      building: null,
    },
    7: {
      componentType: "smartValve",
      value: 6,
      localization: {
        nameType: ""
      },
      building: null,
    },
    8: {
      componentType: "smartValve",
      value: 7,
      localization: {
        nameType: ""
      },
      building: null,
    },
    9: {
      componentType: "smartValve",
      value: 8,
      localization: {
        nameType: ""
      },
      building: null,
    },
    10: {
      componentType: "smartValve",
      value: 9,
      localization: {
        nameType: ""
      },
      building: null,
    },
    11: {
      componentType: "smartValve",
      value: 10,
      localization: {
        nameType: ""
      },
      building: null,
    },
    20: {
      componentType: "balancingValve",
      localization: {
        nameType: ""
      },
      building: null,
    },
    200: {
      componentType: "flushValve",
      localization: {
        nameType: ""
      },
      building: null,
    },
    1: {
      componentType: "hotWater",
      localization: {
        nameType: ""
      },
      building: null,
    }
  },
  '6319942f580e73164e7b12b3': {
    14: {
      componentType: "smartValve",
      value: 1,
      localization: {
        nameType: "building"
      },
      building: 32,
    },
    2: {
      componentType: "smartValve",
      value: 2,
      localization: {
        nameType: "building"
      },
      building: 32,
    },
    5: {
      componentType: "smartValve",
      value: 1,
      localization: {
        nameType: "building"
      },
      building: 34,
    },
    3: {
      componentType: "smartValve",
      value: 2,
      localization: {
        nameType: "building"
      },
      building: 34,
    },
    6: {
      componentType: "smartValve",
      value: 3,
      localization: {
        nameType: "building"
      },
      building: 34,
    },
    4: {
      componentType: "smartValve",
      value: 4,
      localization: {
        nameType: "building"
      },
      building: 34,
    },
    12: {
      componentType: "smartValve",
      value: 1,
      localization: {
        nameType: "building"
      },
      building: '34a',
    },
    11: {
      componentType: "smartValve",
      value: 2,
      localization: {
        nameType: "building"
      },
      building: '34a',
    },
    13: {
      componentType: "smartValve",
      value: 3,
      localization: {
        nameType: "building"
      },
      building: '34a',
    },
    10: {
      componentType: "smartValve",
      value: 4,
      localization: {
        nameType: "building"
      },
      building: '34a',
    },
    8: {
      componentType: "smartValve",
      value: 5,
      localization: {
        nameType: "building"
      },
      building: '34a',
    },
    9: {
      componentType: "smartValve",
      value: 6,
      localization: {
        nameType: "building"
      },
      building: '34a',
    },
    7: {
      componentType: "smartValve",
      value: 7,
      localization: {
        nameType: "building"
      },
      building: '34a',
    },
    // 20: {
    //   componentType: "balancingValve",
    //   localization: {
    //     nameType: ""
    //   },
    //   building: null,
    // },
    201: {
      componentType: "flushValve",
      localization: {
        nameType: ""
      },
      building: null,
    },
    0: {
      componentType: "hotWater",
      localization: {
        nameType: ""
      },
      building: null,
    },
    // 243: {
    //   componentType: "hotWater",
    //   localization: {
    //     nameType: "CentralHeatingSystem"
    //   },
    //   building: null,
    // },
    // 245: {
    //   componentType: "pointOfEntry",
    //   localization: {
    //     nameType: "CentralHeatingSystem"
    //   },
    //   building: null,
    // },
    // 233: {
    //   componentType: "HeatingFlow",
    //   localization: {
    //     nameType: "CentralHeatingSystem"
    //   },
    //   building: null,
    // },
    // 232: {
    //   componentType: "HeatingReturnFlow",
    //   localization: {
    //     nameType: "CentralHeatingSystem"
    //   },
    //   building: null,
    // }
  },
  '631f0690b6c762f01414a1da': {
    134: {
      componentType: "smartValve",
      value: 1,
      localization: {
        nameType: ""
      },
      building: null,
    },
    135: {
      componentType: "smartValve",
      value: 2,
      localization: {
        nameType: ""
      },
      building: null,
    },
    136: {
      componentType: "smartValve",
      value: 3,
      localization: {
        nameType: ""
      },
      building: null,
    },
    137: {
      componentType: "smartValve",
      value: 4,
      localization: {
        nameType: ""
      },
      building: null,
    },
    138: {
      componentType: "smartValve",
      value: 5,
      localization: {
        nameType: ""
      },
      building: null,
    },
    139: {
      componentType: "smartValve",
      value: 6,
      localization: {
        nameType: ""
      },
      building: null,
    },
    140: {
      componentType: "smartValve",
      value: 7,
      localization: {
        nameType: ""
      },
      building: null,
    },
    141: {
      componentType: "smartValve",
      value: 8,
      localization: {
        nameType: ""
      },
      building: null,
    },
    142: {
      componentType: "smartValve",
      value: 9,
      localization: {
        nameType: ""
      },
      building: null,
    },
    143: {
      componentType: "smartValve",
      value: 10,
      localization: {
        nameType: ""
      },
      building: null,
    },
    144: {
      componentType: "smartValve",
      value: 11,
      localization: {
        nameType: ""
      },
      building: null,
    },
    145: {
      componentType: "smartValve",
      value: 12,
      localization: {
        nameType: ""
      },
      building: null,
    },
    146: {
      componentType: "smartValve",
      value: 13,
      localization: {
        nameType: ""
      },
      building: null,
    },
    147: {
      componentType: "smartValve",
      value: 14,
      localization: {
        nameType: ""
      },
      building: null,
    },
    148: {
      componentType: "smartValve",
      value: 15,
      localization: {
        nameType: ""
      },
      building: null,
    },
    149: {
      componentType: "smartValve",
      value: 16,
      localization: {
        nameType: ""
      },
      building: null,
    },
    150: {
      componentType: "smartValve",
      value: 17,
      localization: {
        nameType: ""
      },
      building: null,
    },
    151: {
      componentType: "smartValve",
      value: 18,
      localization: {
        nameType: ""
      },
      building: null,
    },
    152: {
      componentType: "smartValve",
      value: 19,
      localization: {
        nameType: ""
      },
      building: null,
    },
    153: {
      componentType: "smartValve",
      value: 20,
      localization: {
        nameType: ""
      },
      building: null,
    },
    154: {
      componentType: "smartValve",
      value: 21,
      localization: {
        nameType: ""
      },
      building: null,
    },
    155: {
      componentType: "smartValve",
      value: 22,
      localization: {
        nameType: ""
      },
      building: null,
    },
    156: {
      componentType: "smartValve",
      value: 23,
      localization: {
        nameType: ""
      },
      building: null,
    },
    201: {
      componentType: "flushValve",
      localization: {
        nameType: ""
      },
      building: null,
    },
    0: {
      componentType: "hotWater",
      localization: {
        nameType: ""
      },
      building: null,
    }
  },
  '63402b5b68662770736f5c69': {
    2: {
      componentType: "smartValve",
      value: 1,
      localization: {
        nameType: ""
      },
      building: null,
    },
    3: {
      componentType: "smartValve",
      value: 2,
      localization: {
        nameType: ""
      },
      building: null,
    },
    4: {
      componentType: "smartValve",
      value: 3,
      localization: {
        nameType: ""
      },
      building: null,
    },
    5: {
      componentType: "smartValve",
      value: 4,
      localization: {
        nameType: ""
      },
      building: null,
    },
    6: {
      componentType: "smartValve",
      value: 5,
      localization: {
        nameType: ""
      },
      building: null,
    },
    7: {
      componentType: "smartValve",
      value: 6,
      localization: {
        nameType: ""
      },
      building: null,
    },
    8: {
      componentType: "smartValve",
      value: 7,
      localization: {
        nameType: ""
      },
      building: null,
    },
    9: {
      componentType: "smartValve",
      value: 8,
      localization: {
        nameType: ""
      },
      building: null,
    },
    10: {
      componentType: "smartValve",
      value: 9,
      localization: {
        nameType: ""
      },
      building: null,
    },
    11: {
      componentType: "smartValve",
      value: 10,
      localization: {
        nameType: ""
      },
      building: null,
    },
    12: {
      componentType: "smartValve",
      value: 11,
      localization: {
        nameType: ""
      },
      building: null,
    },
    13: {
      componentType: "smartValve",
      value: 12,
      localization: {
        nameType: ""
      },
      building: null,
    },
    14: {
      componentType: "smartValve",
      value: 13,
      localization: {
        nameType: ""
      },
      building: null,
    },
    15: {
      componentType: "smartValve",
      value: 14,
      localization: {
        nameType: ""
      },
      building: null,
    },
    16: {
      componentType: "smartValve",
      value: 15,
      localization: {
        nameType: ""
      },
      building: null,
    },
    17: {
      componentType: "smartValve",
      value: 16,
      localization: {
        nameType: ""
      },
      building: null,
    },
    18: {
      componentType: "smartValve",
      value: 17,
      localization: {
        nameType: ""
      },
      building: null,
    },
    19: {
      componentType: "smartValve",
      value: 18,
      localization: {
        nameType: ""
      },
      building: null,
    },
    20: {
      componentType: "smartValve",
      value: 19,
      localization: {
        nameType: ""
      },
      building: null,
    },
    21: {
      componentType: "smartValve",
      value: 20,
      localization: {
        nameType: ""
      },
      building: null,
    },
    22: {
      componentType: "smartValve",
      value: 21,
      localization: {
        nameType: ""
      },
      building: null,
    },
    23: {
      componentType: "smartValve",
      value: 22,
      localization: {
        nameType: ""
      },
      building: null,
    },
    24: {
      componentType: "smartValve",
      value: 23,
      localization: {
        nameType: ""
      },
      building: null,
    },
    25: {
      componentType: "smartValve",
      value: 24,
      localization: {
        nameType: ""
      },
      building: null,
    },
    195: {
      componentType: "flushValve",
      localization: {
        nameType: ""
      },
      building: null,
    },
    243: {
      componentType: "hotWater",
      localization: {
        nameType: ""
      },
      building: null,
    },
    245: {
      componentType: "chilledWater",
      localization: {
        nameType: "Entrance"
      },
      building: null,
    },
    185: {
      componentType: "backwashFilter",
      value: 2,
      localization: {
        nameType: ""
      },
      building: null,
    },
    186: {
      componentType: "backwashFilter",
      value: 1,
      localization: {
        nameType: ""
      },
      building: null,
    },
    214: {
      componentType: "dynamicHeatingCircuit",
      value: 1,
      localization: {
        nameType: "Flow"
      },
      building: null,
    },
    215: {
      componentType: "dynamicHeatingCircuit",
      value: 1,
      localization: {
        nameType: "Return"
      },
      building: null,
    },
    216: {
      componentType: "dynamicHeatingCircuit",
      value: 2,
      localization: {
        nameType: "Flow"
      },
      building: null,
    },
    217: {
      componentType: "dynamicHeatingCircuit",
      value: 2,
      localization: {
        nameType: "Return"
      },
      building: null,
    },
    218: {
      componentType: "dynamicHeatingCircuit",
      value: 3,
      localization: {
        nameType: "Flow"
      },
      building: null,
    },
    219: {
      componentType: "dynamicHeatingCircuit",
      value: 3,
      localization: {
        nameType: "Return"
      },
      building: null,
    },
    241: {
      componentType: "hotWater",
      localization: {
        nameType: "storage1"
      },
      building: null,
    },
    242: {
      componentType: "hotWater",
      localization: {
        nameType: "storage2"
      },
      building: null,
    },
    246: {
      componentType: "dynamicStorageCharging",
      value: 2,
      localization: {
        nameType: "Flow"
      },
      building: null,
    },
    240: {
      componentType: "dynamicStorageCharging",
      value: 1,
      localization: {
        nameType: "Flow"
      },
      building: null,
    },
    235: {
      componentType: "dynamicStorageCharging",
      value: 2,
      localization: {
        nameType: "Return"
      },
      building: null,
    },
    234: {
      componentType: "dynamicStorageCharging",
      value: 1,
      localization: {
        nameType: "Return"
      },
      building: null,
    },
    200: {
      componentType: "dynamicBoiler",
      value: 1,
      localization: {
        nameType: "Flow"
      },
      building: null,
    },
    201: {
      componentType: "dynamicBoiler",
      value: 1,
      localization: {
        nameType: "Return"
      },
      building: null,
    },
    202: {
      componentType: "dynamicBoiler",
      value: 2,
      localization: {
        nameType: "Flow"
      },
      building: null,
    },
    203: {
      componentType: "dynamicBoiler",
      value: 2,
      localization: {
        nameType: "Return"
      },
      building: null,
    },
    204: {
      componentType: "dynamicBoiler",
      value: 3,
      localization: {
        nameType: "Flow"
      },
      building: null,
    },
    205: {
      componentType: "dynamicBoiler",
      value: 3,
      localization: {
        nameType: "Return"
      },
      building: null,
    },
    232: {
      componentType: "hotWaterSupply",
      localization: {
        nameType: "Flow"
      },
      building: null,
    },
    233: {
      componentType: "hotWaterSupply",
      localization: {
        nameType: "Return"
      },
      building: null,
    },
    212: {
      componentType: "heatingCircuitDistributor",
      localization: {
        nameType: "Flow"
      },
      building: null,
    },
    213: {
      componentType: "heatingCircuitDistributor",
      localization: {
        nameType: "Return"
      },
      building: null,
    },
    206: {
      componentType: "hydraulicSwitch",
      localization: {
        nameType: "Flow"
      },
      building: null,
    },
    207: {
      componentType: "hydraulicSwitch",
      localization: {
        nameType: "Return"
      },
      building: null,
    }
  }
}

export const imagesRole = [
  {
    device_id: "62061f10452e21129b8ca241",
    images: [
      {
        id: 1,
        name: ["images360.staircaseRoom", "images360.house5"],
        roomId: 1,
        house: 5,
        imgWebp: rhein5.webp.staircase[0],
        imgPng: rhein5.png.staircase[0],
        yaw: 310,
        pitch: 0,
        horizonPitch: 0,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 1,
            pitch: -1,
            yaw: 269,
            type: 'scene',
            sceneId: 2
          },
          {
            id: 2,
            pitch: -1,
            yaw: -2,
            type: 'scene',
            sceneId: 14
          }
        ]
      },
      {
        id: 2,
        name: ["images360.hallwayRoom", "images360.house5"],
        roomId: 2,
        house: 5,
        imgWebp: rhein5.webp.hallway[0],
        imgPng: rhein5.png.hallway[0],
        yaw: 355,
        pitch: -5,
        horizonPitch: 0,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 3,
            pitch: -40,
            yaw: 185,
            type: 'scene',
            sceneId: 1
          },
          {
            id: 4,
            pitch: -25,
            yaw: -1,
            type: 'scene',
            sceneId: 3
          }
        ]
      },
      {
        id: 3,
        roomId: 2,
        house: 5,
        imgWebp: rhein5.webp.hallway[1],
        imgPng: rhein5.png.hallway[1],
        yaw: 100,
        pitch: 0,
        horizonPitch: 8,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 5,
            pitch: 0,
            yaw: 180,
            type: 'scene',
            sceneId: 1
          },
          {
            id: 6,
            pitch: -12,
            yaw: -10,
            type: 'scene',
            sceneId: 4
          },
          {
            id: 7,
            pitch: -27,
            yaw: 120,
            type: 'scene',
            sceneId: 6
          }
        ]
      },
      {
        id: 4,
        name: ["images360.coldWaterInletRoom", "images360.house5"],
        roomId: 3,
        house: 5,
        imgWebp: rhein5.webp.coldWaterInlet[0],
        imgPng: rhein5.png.coldWaterInlet[0],
        yaw: 255,
        pitch: 0,
        horizonPitch: 18,
        horizonRoll: 2,
        hfov: 140,
        hotSpots: [
          {
            id: 8,
            pitch: -45,
            yaw: 237,
            type: 'scene',
            sceneId: 5
          },
          {
            id: 9,
            pitch: -57,
            yaw: 20,
            type: 'scene',
            sceneId: 11
          }
        ]
      },
      {
        id: 5,
        roomId: 3,
        house: 5,
        imgWebp: rhein5.webp.coldWaterInlet[1],
        imgPng: rhein5.png.coldWaterInlet[1],
        yaw: 10,
        pitch: 0,
        horizonPitch: -15,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 10,
            pitch: -5,
            yaw: -75,
            type: 'scene',
            sceneId: 3
          },
          {
            id: 11,
            pitch: -35,
            yaw: 165,
            type: 'scene',
            sceneId: 11
          }
        ]
      },
      {
        id: 6,
        name: ["images360.boilerRoom", "images360.house5"],
        roomId: 4,
        house: 5,
        imgWebp: rhein5.webp.boilerRoom[0],
        imgPng: rhein5.png.boilerRoom[0],
        yaw: 90,
        pitch: 0,
        horizonPitch: 5,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 12,
            pitch: -0.5,
            yaw: 195,
            type: 'scene',
            sceneId: 3
          },
          {
            id: 13,
            pitch: -30,
            yaw: 20,
            type: 'scene',
            sceneId: 7
          },
          {
            id: 14,
            pitch: -30,
            yaw: 160,
            type: 'scene',
            sceneId: 10
          },
          {
            id: 15,
            pitch: -35,
            yaw: 60,
            type: 'scene',
            sceneId: 8
          },
          {
            id: 16,
            pitch: 8,
            yaw: 165,
            name: "icon",
            imgWebp: rhein5.webp.boilerRoom[5],
            imgPng: rhein5.png.boilerRoom[5],
            cssClass: "custom-hotspot"
          },
        ]
      },
      {
        id: 7,
        roomId: 4,
        house: 5,
        imgWebp: rhein5.webp.boilerRoom[1],
        imgPng: rhein5.png.boilerRoom[1],
        yaw: 10,
        pitch: -10,
        horizonPitch: 5,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 17,
            pitch: -1,
            yaw: 96,
            type: 'scene',
            sceneId: 3
          },
          {
            id: 18,
            pitch: -20,
            yaw: 75,
            type: 'scene',
            sceneId: 10
          },
          {
            id: 19,
            pitch: -32,
            yaw: 40,
            type: 'scene',
            sceneId: 8
          },
          {
            id: 20,
            pitch: 363,
            yaw: 77,
            name: "icon",
            imgWebp: rhein5.webp.boilerRoom[5],
            imgPng: rhein5.png.boilerRoom[5],
            cssClass: "custom-hotspot"
          },
        ]
      },
      {
        id: 8,
        roomId: 4,
        house: 5,
        imgWebp: rhein5.webp.boilerRoom[2],
        imgPng: rhein5.png.boilerRoom[2],
        yaw: 10,
        pitch: 0,
        horizonPitch: -5,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 21,
            pitch: -45,
            yaw: -125,
            type: 'scene',
            sceneId: 7
          },
          {
            id: 22,
            pitch: -32,
            yaw: 117,
            type: 'scene',
            sceneId: 9
          },
          {
            id: 23,
            pitch: -2,
            yaw: 117,
            type: 'scene',
            sceneId: 3
          },
          {
            id: 24,
            pitch: 365,
            yaw: 96,
            name: "icon",
            imgWebp: rhein5.webp.boilerRoom[5],
            imgPng: rhein5.png.boilerRoom[5],
            cssClass: "custom-hotspot"
          },
        ]
      },
      {
        id: 9,
        roomId: 4,
        house: 5,
        imgWebp: rhein5.webp.boilerRoom[3],
        imgPng: rhein5.png.boilerRoom[3],
        yaw: 320,
        pitch: 0,
        horizonPitch: -5,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 25,
            pitch: -2,
            yaw: 82,
            type: 'scene',
            sceneId: 3
          },
          {
            id: 26,
            pitch: -35,
            yaw: 0,
            type: 'scene',
            sceneId: 10
          },
          {
            id: 27,
            pitch: -22,
            yaw: -57,
            type: 'scene',
            sceneId: 8
          },
          {
            id: 28,
            pitch: -22,
            yaw: 265,
            type: 'scene',
            sceneId: 7
          },
          {
            id: 29,
            pitch: 10,
            yaw: 38,
            name: "icon",
            imgWebp: rhein5.webp.boilerRoom[5],
            imgPng: rhein5.png.boilerRoom[5],
            cssClass: "custom-hotspot"
          },
        ]
      },
      {
        id: 10,
        roomId: 4,
        house: 5,
        imgWebp: rhein5.webp.boilerRoom[4],
        imgPng: rhein5.png.boilerRoom[4],
        yaw: 5,
        pitch: 0,
        horizonPitch: -10,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 30,
            pitch: -13,
            yaw: 137,
            type: 'scene',
            sceneId: 3
          },
          {
            id: 31,
            pitch: -15,
            yaw: -63,
            type: 'scene',
            sceneId: 8
          },
          {
            id: 32,
            pitch: -18,
            yaw: 273,
            type: 'scene',
            sceneId: 7
          },
          {
            id: 33,
            pitch: 22,
            yaw: 78,
            name: "icon",
            imgWebp: rhein5.webp.boilerRoom[5],
            imgPng: rhein5.png.boilerRoom[5],
            cssClass: "custom-hotspot"
          },
        ]
      },
      {
        id: 11,
        name: ["images360.gasPipeRoom", "images360.house5"],
        roomId: 5,
        house: 5,
        imgWebp: rhein5.webp.gasPipe[0],
        imgPng: rhein5.png.gasPipe[0],
        yaw: 10,
        pitch: 0,
        horizonPitch: 6,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 34,
            pitch: -32,
            yaw: 260,
            type: 'scene',
            sceneId: 5
          },
          {
            id: 35,
            pitch: -1,
            yaw: 235,
            type: 'scene',
            sceneId: 3
          }
        ]
      },
      {
        id: 12,
        name: ["images360.staircaseRoom", "images360.house7"],
        roomId: 1,
        house: 7,
        imgWebp: rhein7.webp.staircase[0],
        imgPng: rhein7.png.staircase[0],
        yaw: 360,
        pitch: 0,
        horizonPitch: 4,
        horizonRoll: 2,
        hfov: 140,
        hotSpots: [
          {
            id: 36,
            pitch: -15,
            yaw: -2,
            type: 'scene',
            sceneId: 13
          },
          {
            id: 37,
            pitch: -25,
            yaw: 100,
            type: 'scene',
            sceneId: 18
          }
        ]
      },
      {
        id: 13,
        roomId: 1,
        house: 7,
        imgWebp: rhein7.webp.staircase[1],
        imgPng: rhein7.png.staircase[1],
        yaw: 365,
        pitch: 0,
        horizonPitch: 8,
        horizonRoll: -1,
        hfov: 140,
        hotSpots: [
          {
            id: 38,
            pitch: -2,
            yaw: -25,
            type: 'scene',
            sceneId: 12
          }
        ]
      },
      {
        id: 14,
        name: ["images360.hallwayRoom", "images360.house7"],
        roomId: 2,
        house: 7,
        imgWebp: rhein7.webp.hallway[0],
        imgPng: rhein7.png.hallway[0],
        yaw: 20,
        pitch: 0,
        horizonPitch: 25,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [

          {
            id: 39,
            pitch: 0,
            yaw: -210,
            type: 'scene',
            sceneId: 21
          },
          {
            id: 39,
            pitch: 0,
            yaw: -130,
            type: 'scene',
            sceneId: 1
          },
          {
            id: 40,
            pitch: -55,
            yaw: 50,
            type: 'scene',
            sceneId: 15
          },
          {
            id: 43,
            pitch: -2,
            yaw: 55,
            type: 'scene',
            sceneId: 16
          }
        ]
      },
      {
        id: 15,
        roomId: 2,
        house: 7,
        imgWebp: rhein7.webp.hallway[1],
        imgPng: rhein7.png.hallway[1],
        yaw: 20,
        pitch: 0,
        horizonPitch: -4,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 41,
            pitch: -2,
            yaw: 65,
            type: 'scene',
            sceneId: 1
          },
          {
            id: 42,
            pitch: -2,
            yaw: 0,
            type: 'scene',
            sceneId: 21
          },
          {
            id: 43,
            pitch: -2,
            yaw: 225,
            type: 'scene',
            sceneId: 16
          }
        ]
      },
      {
        id: 16,
        roomId: 2,
        house: 7,
        imgWebp: rhein7.webp.hallway[2],
        imgPng: rhein7.png.hallway[2],
        yaw: 10,
        pitch: 0,
        horizonPitch: -5,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 44,
            pitch: -5,
            yaw: -27,
            type: 'scene',
            sceneId: 17
          },
          {
            id: 45,
            pitch: -20,
            yaw: 97,
            type: 'scene',
            sceneId: 15
          }
        ]
      },
      {
        id: 17,
        roomId: 2,
        house: 7,
        imgWebp: rhein7.webp.hallway[3],
        imgPng: rhein7.png.hallway[3],
        yaw: 350,
        pitch: 0,
        horizonPitch: 0,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 46,
            pitch: -20,
            yaw: 0,
            type: 'scene',
            sceneId: 18
          },
          {
            id: 46,
            pitch: -30,
            yaw: -125,
            type: 'scene',
            sceneId: 16
          }
        ]
      },
      {
        id: 18,
        roomId: 2,
        house: 7,
        imgWebp: rhein7.webp.hallway[4],
        imgPng: rhein7.png.hallway[4],
        yaw: 10,
        pitch: 0,
        horizonPitch: 2,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 47,
            pitch: -35,
            yaw: 87,
            type: 'scene',
            sceneId: 19
          },
          {
            id: 48,
            pitch: -5,
            yaw: 15,
            type: 'scene',
            sceneId: 12
          },
          {
            id: 49,
            pitch: -1,
            yaw: 181,
            type: 'scene',
            sceneId: 17
          }
        ]
      },
      {
        id: 19,
        name: ["images360.coldWaterInletRoom", "images360.house7"],
        roomId: 3,
        house: 7,
        imgWebp: rhein7.webp.coldWaterInlet[0],
        imgPng: rhein7.png.coldWaterInlet[0],
        yaw: 40,
        pitch: 0,
        horizonPitch: 10,
        horizonRoll: 1,
        hfov: 140,
        hotSpots: [
          {
            id: 50,
            pitch: 0,
            yaw: 175,
            type: 'scene',
            sceneId: 18
          },
          {
            id: 51,
            pitch: -37,
            yaw: 50,
            type: 'scene',
            sceneId: 20
          }
        ]
      },
      {
        id: 20,
        roomId: 3,
        house: 7,
        imgWebp: rhein7.webp.coldWaterInlet[1],
        imgPng: rhein7.png.coldWaterInlet[1],
        yaw: 5,
        pitch: -20,
        horizonPitch: -20,
        horizonRoll: -2,
        hfov: 140,
        hotSpots: [
          {
            id: 52,
            pitch: -45,
            yaw: 105,
            type: 'scene',
            sceneId: 19
          },
          {
            id: 53,
            pitch: -5,
            yaw: 98,
            type: 'scene',
            sceneId: 18
          }
        ]
      },
      {
        id: 21,
        name: ["images360.electricityMeterRoom", "images360.house7"],
        roomId: 4,
        house: 7,
        imgWebp: rhein7.webp.electricityMeter[0],
        imgPng: rhein7.png.electricityMeter[0],
        yaw: 220,
        pitch: 0,
        horizonPitch: 16,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 54,
            pitch: 15,
            yaw: -120,
            type: 'scene',
            sceneId: 22
          },
          {
            id: 55,
            pitch: 0,
            yaw: 180,
            type: 'scene',
            sceneId: 15
          }
        ]
      },
      {
        id: 22,
        roomId: 4,
        house: 7,
        imgWebp: rhein7.webp.electricityMeter[1],
        imgPng: rhein7.png.electricityMeter[1],
        yaw: -10,
        pitch: 0,
        horizonPitch: 0,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 56,
            pitch: -62,
            yaw: 145,
            type: 'scene',
            sceneId: 21
          }
        ]
      }
    ]
  },
  {
    device_id: "62061f10452e21129b8ca242",
    images: [
      {
        id: 1,
        name: ["images360.staircaseRoom"],
        roomId: 1,
        imgWebp: mainz.webp.staircase[0],
        imgPng: mainz.png.staircase[0],
        yaw: 355,
        pitch: -5,
        horizonPitch: 0,
        horizonRoll: 0,
        hfov: 140,
        house: null,
        hotSpots: [
          {
            id: 1,
            pitch: 187,
            yaw: 236,
            type: 'scene',
            sceneId: 2
          },
          {
            id: 2,
            pitch: 187,
            yaw: 120,
            type: 'scene',
            sceneId: 13
          }
        ]
      },
      {
        id: 2,
        name: ["images360.hallwayRoom"],
        roomId: 2,
        imgWebp: mainz.webp.hallway[0],
        imgPng: mainz.png.hallway[0],
        house: null,
        yaw: 0,
        pitch: 0,
        horizonPitch: 20,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 3,
            pitch: 187,
            yaw: 137,
            type: 'scene',
            sceneId: 1
          },
          {
            id: 4,
            pitch: 200,
            yaw: 5,
            type: 'scene',
            sceneId: 3
          },
          {
            id: 5,
            pitch: 187,
            yaw: 220,
            type: 'scene',
            sceneId: 5
          }
        ]
      },
      {
        id: 3,
        name: ["images360.coldWaterInletRoom"],
        roomId: 3,
        yaw: 10,
        pitch: 0,
        horizonPitch: -20,
        horizonRoll: 0,
        hfov: 140,
        imgWebp: mainz.webp.coldWaterInlet[0],
        imgPng: mainz.png.coldWaterInlet[0],
        house: null,
        hotSpots: [
          {
            id: 6,
            pitch: 180,
            yaw: 185,
            type: 'scene',
            sceneId: 2
          },
          {
            id: 7,
            pitch: 215,
            yaw: 185,
            type: 'scene',
            sceneId: 4
          }
        ]
      },
      {
        id: 4,
        roomId: 3,
        yaw: -30,
        pitch: -10,
        horizonPitch: 0,
        horizonRoll: 2,
        hfov: 140,
        imgWebp: mainz.webp.coldWaterInlet[1],
        imgPng: mainz.png.coldWaterInlet[1],
        house: null,
        hotSpots: [
          {
            id: 8,
            pitch: -25,
            yaw: 80,
            type: 'scene',
            sceneId: 2
          },
          {
            id: 9,
            pitch: 205,
            yaw: 90,
            type: 'scene',
            sceneId: 9
          },
        ]
      },
      {
        id: 5,
        name: ["images360.boilerRoom"],
        roomId: 4,
        yaw: 40,
        pitch: -5,
        horizonPitch: 0,
        horizonRoll: 0,
        hfov: 140,
        imgWebp: mainz.webp.boilerRoom[0],
        imgPng: mainz.png.boilerRoom[0],
        house: null,
        hotSpots: [
          {
            id: 10,
            pitch: 180,
            yaw: -10,
            type: 'scene',
            sceneId: 2
          },
          {
            id: 11,
            pitch: 203,
            yaw: 225,
            type: 'scene',
            sceneId: 6
          }
        ]
      },
      {
        id: 6,
        roomId: 4,
        yaw: 0,
        pitch: 0,
        horizonPitch: 19,
        horizonRoll: 1,
        hfov: 140,
        imgWebp: mainz.webp.boilerRoom[1],
        imgPng: mainz.png.boilerRoom[1],
        house: null,
        hotSpots: [
          {
            id: 12,
            pitch: -40,
            yaw: 80,
            type: 'scene',
            sceneId: 5
          },
          {
            id: 13,
            pitch: 185,
            yaw: 220,
            type: 'scene',
            sceneId: 2
          },
          {
            id: 14,
            pitch: -47,
            yaw: 260,
            type: 'scene',
            sceneId: 7
          },
          {
            id: 100,
            pitch: 12,
            yaw: 276,
            name: "icon",
            imgWebp: mainz.webp.boilerRoom[4],
            imgPng: mainz.png.boilerRoom[4],
            cssClass: "custom-hotspot"
          }
        ]
      },
      {
        id: 7,
        roomId: 4,
        yaw: 90,
        pitch: -10,
        horizonPitch: 9,
        horizonRoll: 2,
        hfov: 140,
        imgWebp: mainz.webp.boilerRoom[2],
        imgPng: mainz.png.boilerRoom[2],
        house: null,
        hotSpots: [
          {
            id: 15,
            pitch: -43,
            yaw: 170,
            type: 'scene',
            sceneId: 6
          },
          {
            id: 16,
            pitch: -55,
            yaw: 65,
            type: 'scene',
            sceneId: 8
          },
          {
            id: 101,
            pitch: 20,
            yaw: -2,
            name: "icon",
            imgWebp: mainz.webp.boilerRoom[4],
            imgPng: mainz.png.boilerRoom[4],
            cssClass: "custom-hotspot"
          }
        ]
      },
      {
        id: 8,
        roomId: 4,
        yaw: 10,
        pitch: 0,
        horizonPitch: 18,
        horizonRoll: 0,
        hfov: 140,
        imgWebp: mainz.webp.boilerRoom[3],
        imgPng: mainz.png.boilerRoom[3],
        house: null,
        hotSpots: [
          {
            id: 17,
            pitch: -55,
            yaw: -120,
            type: 'scene',
            sceneId: 7
          },
          {
            id: 102,
            pitch: -15,
            yaw: -40,
            name: "icon",
            imgWebp: mainz.webp.boilerRoom[4],
            imgPng: mainz.png.boilerRoom[4],
            cssClass: "custom-hotspot"
          }
        ]
      },
      {
        id: 9,
        name: ["images360.electricityMeterRoom"],
        roomId: 5,
        yaw: 270,
        pitch: -10,
        horizonPitch: -5,
        horizonRoll: 0,
        hfov: 140,
        imgWebp: mainz.webp.electricityMeter[0],
        imgPng: mainz.png.electricityMeter[0],
        house: null,
        hotSpots: [
          {
            id: 18,
            pitch: 210,
            yaw: 27,
            type: 'scene',
            sceneId: 10
          },
          {
            id: 19,
            pitch: 192,
            yaw: 85,
            type: 'scene',
            sceneId: 11
          },
        ]
      },
      {
        id: 10,
        roomId: 5,
        yaw: 8,
        pitch: -5,
        horizonPitch: -6,
        horizonRoll: 0,
        hfov: 140,
        imgWebp: mainz.webp.electricityMeter[1],
        imgPng: mainz.png.electricityMeter[1],
        house: null,
        hotSpots: [
          {
            id: 20,
            pitch: 197,
            yaw: 185,
            type: 'scene',
            sceneId: 4
          }
        ]
      },
      {
        id: 11,
        roomId: 5,
        yaw: 10,
        pitch: 0,
        horizonPitch: -5,
        horizonRoll: 0,
        hfov: 140,
        imgWebp: mainz.webp.electricityMeter[2],
        imgPng: mainz.png.electricityMeter[2],
        house: null,
        hotSpots: [
          {
            id: 21,
            pitch: 205,
            yaw: 125,
            type: 'scene',
            sceneId: 10
          },
          {
            id: 22,
            pitch: 182,
            yaw: 228,
            type: 'scene',
            sceneId: 12
          },
        ]
      },
      {
        id: 12,
        roomId: 5,
        yaw: 0,
        pitch: -5,
        horizonPitch: -8,
        horizonRoll: 0,
        hfov: 140,
        imgWebp: mainz.webp.electricityMeter[3],
        imgPng: mainz.png.electricityMeter[3],
        house: null,
        hotSpots: [
          {
            id: 23,
            pitch: 176,
            yaw: 135,
            type: 'scene',
            sceneId: 11
          },
          {
            id: 24,
            pitch: 200,
            yaw: 115,
            type: 'scene',
            sceneId: 10
          }
        ]
      },
      {
        id: 13,
        name: ["images360.gasPipeRoom"],
        roomId: 6,
        yaw: 10,
        pitch: 20,
        horizonPitch: 20,
        horizonRoll: 0,
        hfov: 140,
        imgWebp: mainz.webp.gasPipe[0],
        imgPng:  mainz.png.gasPipe[0],
        house: null,
        hotSpots: [
          {
            id: 25,
            pitch: 180,
            yaw: 260,
            type: 'scene',
            sceneId: 1
          }
        ]
      }
    ]
  }
];

export const filterRule = {
  "62061f10452e21129b8ca241": [
    {
      name: 'heater',
      modbus: [200, 201, 212, 213, 232, 233],
    },
    {
      name: 'hot_water',
      modbus: [232, 233, 151, 51],
    },
    {
      name: 'chilled_water',
      modbus: [101, 100],
    },
    {
      name: 'zirculation',
      modbus: [152, 160, 161, 162, 163, 164, 165, 166, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 20, 30, 31],
    }
  ],
  "62061f10452e21129b8ca242": [
    {
      name: 'heater',
      modbus: [200, 201, 212, 213, 232, 233],
    },
    {
      name: 'hot_water',
      modbus: [232, 233, 151, 51],
    },
    {
      name: 'chilled_water',
      modbus: [101, 100],
    },
    {
      name: 'zirculation',
      modbus: [152, 160, 161, 162, 163, 164, 165, 166, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 20, 30, 31],
    }
  ],
  "62e90c3e3b1685117c8fe1e1": [
    {
      name: 'heater',
      modbus: [200, 201, 212, 213, 232, 233],
    },
    {
      name: 'hot_water',
      modbus: [232, 233, 151, 51],
    },
    {
      name: 'chilled_water',
      modbus: [101, 100],
    },
    {
      name: 'zirculation',
      modbus: [152, 160, 161, 162, 163, 164, 165, 166, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 20, 30, 31],
    }
  ],
  "62e90c753b1685117c8fe1e2": [
    {
      name: 'heater',
      modbus: [200, 201, 212, 213, 232, 233],
    },
    {
      name: 'hot_water',
      modbus: [232, 233, 151, 51],
    },
    {
      name: 'chilled_water',
      modbus: [101, 100],
    },
    {
      name: 'zirculation',
      modbus: [152, 160, 161, 162, 163, 164, 165, 166, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 20, 30, 31],
    }
  ],
  "62f9f4293f62cee2df80a3ec": [
    {
      name: 'heater',
      modbus: [232, 233],
    },
    {
      name: 'hot_water',
      modbus: [100, 243],
    },
    {
      name: 'chilled_water',
      modbus: [245],
    },
    {
      name: 'zirculation',
      modbus: [2, 3, 4, 5, 6, 7, 8, 9, 20, 200],
    },
  ],
  "631993e9580e73164e7b12b2": [
    {
      name: 'heater',
      modbus: [],
    },
    {
      name: 'hot_water',
      modbus: [1],
    },
    {
      name: 'chilled_water',
      modbus: [],
    },
    {
      name: 'zirculation',
      modbus: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 200],
    },
  ],
  "6319942f580e73164e7b12b3": [
    {
      name: 'heater',
      modbus: [],
    },
    {
      name: 'hot_water',
      modbus: [0],
    },
    {
      name: 'chilled_water',
      modbus: [],
    },
    {
      name: 'zirculation',
      modbus: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 201],
    },
  ],
  "631f0690b6c762f01414a1da": [
    {
      name: 'heater',
      modbus: [],
    },
    {
      name: 'hot_water',
      modbus: [0],
    },
    {
      name: 'chilled_water',
      modbus: [],
    },
    {
      name: 'zirculation',
      modbus: [134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 201],
    },
  ],
  "63402b5b68662770736f5c69": [
    {
      name: 'heater',
      modbus: [200, 201, 202, 203, 204, 205, 206, 207, 212, 213, 214, 215, 216, 217, 218, 219, 232, 233],
    },
    {
      name: 'hot_water',
      modbus: [240, 241, 242, 243, 246],
    },
    {
      name: 'chilled_water',
      modbus: [185, 186, 234, 235, 245],
    },
    {
      name: 'zirculation',
      modbus: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 195],
    },
  ],
}

export const temperatureRule = {
  '62061f10452e21129b8ca241': [
    {
      modbus: [160, 161, 162, 163, 164, 165, 166],
      rule: {
        red: [
          {temperature: [null, 54.9], text: 'isLow'},
          {temperature: [60.1, null], text: 'isHigh'},
        ],
        blue: [ {temperature: [55, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [152],
      rule: {
        red: [
          {temperature: [null, 54.9], text: 'isLow'},
          {temperature: [60.1, null], text: 'isHigh'},
        ],
        blue: [ {temperature: [55, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [151],
      rule: {
        red: [
          {temperature: [null, 59.9], text: 'isLow'},
          {temperature: [64.1, null], text: 'isHigh'},
        ],
        blue: [{temperature: [60, 64], text: 'optimal'}],
      },
    },
    {
      modbus: [101, 100, 22],
      rule: {
        red: [
          {temperature: [null, 5], text: 'isLow'},
          {temperature: [25, null], text: 'isHigh'},
        ],
        blue: [ {temperature: [5.1, 24.9], text: 'optimal'} ],
      },
    },
    {
      modbus: [200, 201],
      rule: {
        blue: [ {temperature: [60, 75], text: 'optimal'} ],
      },
    },
    {
      modbus: [212],
      rule: {
        blue: [ {temperature: [40, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [213],
      rule: {
        blue: [ {temperature: [40, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [232, 233],
      rule: {
        blue: [ {temperature: [60, 74.9], text: 'optimal'} ],
      },
    },
  ],
  '62061f10452e21129b8ca242': [
    {
      modbus: [160, 161, 162],
      rule: {
        red: [
          {temperature: [null, 54.9], text: 'isLow'},
          {temperature: [60.1, null], text: 'isHigh'},
        ],
        blue: [ {temperature: [55, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [100],
      rule: {
        red: [
          {temperature: [null, 5], text: 'isLow'},
          {temperature: [25, null], text: 'isHigh'},
        ],
        blue: [ {temperature: [5.1, 24.9], text: 'optimal'} ],
      },
    },
    {
      modbus: [152],
      rule: {
        red: [
          {temperature: [null, 54.9], text: 'isLow'},
          {temperature: [60.1, null], text: 'isHigh'},
        ],
        blue: [ {temperature: [55, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [232, 233],
      rule: {
        blue: [ {temperature: [60, 74.9], text: 'optimal'} ],
      },
    },
    {

      modbus: [201],
      rule: {
        blue: [ {temperature: [40,50], text: 'optimal'} ],
      },
    },
    {
      modbus: [200],
      rule: {
        blue: [ {temperature: [60, 75], text: 'optimal'} ],
      },
    },
    {
      modbus: [212],
      rule: {
        blue: [ {temperature: [40, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [213],
      rule: {
        blue: [ {temperature: [40, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [151],
      rule: {
        red: [
          {temperature: [null, 59.9], text: 'isLow'},
          {temperature: [64.1, null], text: 'isHigh'},
        ],
        blue: [{temperature: [60, 64], text: 'optimal'}],
      },
    },
  ],
  '62e90c3e3b1685117c8fe1e1': [
    {
      modbus: [1, 2, 3, 4, 5, 6, 7, 8 , 9, 10, 11, 12, 13, 14, 16, 20],
      rule: {
        red: [
          {temperature: [null, 54.9], text: 'isLow'},
          {temperature: [60.1, null], text: 'isHigh'},
        ],
        blue: [ {temperature: [55, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [30, 31],
      rule: {
        blue: [ {temperature: [null, 100], text: 'optimal'} ],
      },
    },
    {
      modbus: [0, 200, 240],
      rule: {
        red: [{temperature: [null, 59.9], text: 'isLow'}],
        blue: [ {temperature: [60, 64], text: 'optimal'} ],
      },
    },
  ],
  '62e90c753b1685117c8fe1e2': [
    {
      modbus: [1, 2, 3, 4, 5, 6, 7, 8 , 9, 10, 11, 12, 13, 14, 15, 16, 17, 30, 51, 52, 70],
      rule: {
        red: [
          {temperature: [null, 54.9], text: 'isLow'},
          {temperature: [60.1, null], text: 'isHigh'},
        ],
        blue: [ {temperature: [55, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [0],
      rule: {
        red: [{temperature: [null, 59.9], text: 'isLow'}],
        blue: [ {temperature: [60, 64], text: 'optimal'} ],
      },
    },
  ],
  '62f9f4293f62cee2df80a3ec': [
    {
      modbus: [2, 3, 4, 5, 6, 7, 8, 9, 20, 200],
      rule: {
        red: [
          {temperature: [null, 54.9], text: 'isLow'},
          {temperature: [60.1, null], text: 'isHigh'},
        ],
        blue: [ {temperature: [55, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [100, 243],
      rule: {
        red: [{temperature: [null, 59.9], text: 'isLow'}],
        blue: [ {temperature: [60, 64], text: 'optimal'} ],
      },
    },
    {
      modbus: [232],
      rule: {
        blue: [ {temperature: [60, 70], text: 'optimal'} ],
      },
    },
    {
      modbus: [233],
      rule: {
        blue: [ {temperature: [60, 90], text: 'optimal'} ],
      },
    },
    {
      modbus: [245],
      rule: {
        red: [
          {temperature: [null, 5], text: 'isLow'},
          {temperature: [25, null], text: 'isHigh'},
        ],
        blue: [ {temperature: [5.1, 24.9], text: 'optimal'} ],
      },
    },
  ],
  '631993e9580e73164e7b12b2': [
    {
      modbus: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 200],
      rule: {
        red: [
          {temperature: [null, 54.9], text: 'isLow'},
          {temperature: [60.1, null], text: 'isHigh'},
        ],
        blue: [ {temperature: [55, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [1],
      rule: {
        red: [{temperature: [null, 59.9], text: 'isLow'}],
        blue: [ {temperature: [60, 64], text: 'optimal'} ],
      },
    },
  ],
  '6319942f580e73164e7b12b3': [
    {
      modbus: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 201],
      rule: {
        red: [
          {temperature: [null, 54.9], text: 'isLow'},
          {temperature: [60.1, null], text: 'isHigh'},
        ],
        blue: [ {temperature: [55, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [0],
      rule: {
        red: [{temperature: [null, 59.9], text: 'isLow'}],
        blue: [ {temperature: [60, 64], text: 'optimal'} ],
      },
    },
  ],
  '631f0690b6c762f01414a1da': [
    {
      modbus: [134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 201],
      rule: {
        red: [
          {temperature: [null, 54.9], text: 'isLow'},
          {temperature: [60.1, null], text: 'isHigh'},
        ],
        blue: [ {temperature: [55, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [0],
      rule: {
        red: [{temperature: [null, 59.9], text: 'isLow'}],
        blue: [ {temperature: [60, 64], text: 'optimal'} ],
      },
    },
  ],
  '63402b5b68662770736f5c69': [
    {
      modbus: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 195],
      rule: {
        red: [
          {temperature: [null, 54.9], text: 'isLow'},
          {temperature: [60.1, null], text: 'isHigh'},
        ],
        blue: [ {temperature: [55, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [243, 241, 242],
      rule: {
        red: [
          {temperature: [null, 59.9], text: 'isLow'},
          {temperature: [64.1, null], text: 'isHigh'},
        ],
        blue: [ {temperature: [60, 64], text: 'optimal'} ],
      },
    },
    {
      modbus: [185, 186, 245],
      rule: {
        red: [
          {temperature: [null, 5], text: 'isLow'},
          {temperature: [25, null], text: 'isHigh'},
        ],
        blue: [ {temperature: [5.1, 24.9], text: 'optimal'} ],
      },
    },
    {
      modbus: [234, 235, 240, 246],
      rule: {
        red: [
          {temperature: [null, 59.9], text: 'isLow'},
          {temperature: [75, null], text: 'isHigh'},
        ],
        blue: [ {temperature: [60, 74.9], text: 'optimal'} ],
      },
    },
    {
      modbus: [120, 200, 201, 202, 203, 204, 205, 206, 207, 212, 213, 214, 215, 216, 217, 218, 219, 232, 233],
      rule: {
        blue: [ {temperature: [0, null], text: 'optimal'} ],
      },
    },
  ]
};

export const exceptionsTemperatureRule = {
  '62061f10452e21129b8ca241': [100, 101, 22],
  '62061f10452e21129b8ca242': [],
  '62e90c3e3b1685117c8fe1e1': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 20, 30, 31],
  '62e90c753b1685117c8fe1e2': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 51],
  '62f9f4293f62cee2df80a3ec': [2, 3, 4, 5, 6, 7, 8, 9, 20, 100, 200, 232, 233, 245, 244, 243],
  '631993e9580e73164e7b12b2': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 200],
  '6319942f580e73164e7b12b3': [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 201, 0],
  '631f0690b6c762f01414a1da': [134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 201, 0],
  '63402b5b68662770736f5c69': []
}

export const exceptionsTemperatureRuleNotModal = {
  '62061f10452e21129b8ca241': [100],
  '62061f10452e21129b8ca242': [],
  '62e90c3e3b1685117c8fe1e1': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 20, 30, 31],
  '62e90c753b1685117c8fe1e2': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 51],
  '62f9f4293f62cee2df80a3ec': [2, 3, 4, 5, 6, 7, 8, 9, 20, 100, 200, 232, 233, 245, 243, 244, 243],
  '631993e9580e73164e7b12b2': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 200],
  '6319942f580e73164e7b12b3': [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 201, 0],
  '631f0690b6c762f01414a1da': [134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 201, 0],
  '63402b5b68662770736f5c69': []
}

export const propertyImgRule = {
  '62061f10452e21129b8ca241': { imgWebp: buildings.webp.building_1_webp, imgPng: buildings.png.building_1_png },
  '62061f10452e21129b8ca242': { imgWebp: buildings.webp.building_2_webp, imgPng: buildings.png.building_2_png },
  '62e90c3e3b1685117c8fe1e1': { imgWebp: buildings.webp.building_3_webp, imgPng: buildings.png.building_3_png },
  '62e90c753b1685117c8fe1e2': { imgWebp: buildings.webp.building_4_webp, imgPng: buildings.png.building_4_png },
  '62f9f4293f62cee2df80a3ec': { imgWebp: buildings.webp.building_5_webp, imgPng: buildings.png.building_5_png },
  '631993e9580e73164e7b12b2': { imgWebp: buildings.webp.building_6_webp, imgPng: buildings.png.building_6_png },
  '6319942f580e73164e7b12b3': { imgWebp: buildings.webp.building_7_webp, imgPng: buildings.png.building_7_png },
  '631f0690b6c762f01414a1da': { imgWebp: buildings.webp.building_8_webp, imgPng: buildings.png.building_8_png },
};

export const houseDetailsRule = {
  1: {
    imgWebp: sensors_241_images_5.Kaltwassereingang.webp,
    imgPng: sensors_241_images_5.Kaltwassereingang.jpg,
  },
  2: {
    imgWebp: sensors_241_images_5.Rukspulfilter.webp,
    imgPng: sensors_241_images_5.Rukspulfilter.jpg,
  },
  3: {
    imgWebp: sensors_241_images_5.Warmwasserspeicher.webp,
    imgPng: sensors_241_images_5.Warmwasserspeicher.jpg,
  },
  4: {
    imgWebp: sensors_241_images_5.Heizkessel.webp,
    imgPng: sensors_241_images_5.Heizkessel.png,
  },
  5: {
    imgWebp: sensors_241_images_5.Speicherladepumpe.webp,
    imgPng: sensors_241_images_5.Speicherladepumpe.jpg,
  },
  6: {
    imgWebp: sensors_241_images_5.Heizkreispumpe.webp,
    imgPng: sensors_241_images_5.Heizkreispumpe.jpg,
  },
  7: {
    imgWebp: sensors_241_images_5.Zirkulationspumpe.webp,
    imgPng: sensors_241_images_5.Zirkulationspumpe.jpg,
  },
  8: {
    imgWebp: sensors_241_images_7.Kaltwassereingang.webp,
    imgPng: sensors_241_images_7.Kaltwassereingang.jpg,
  },
  9: {
    imgWebp: sensors_241_images_7.Rukspulfilter.webp,
    imgPng: sensors_241_images_7.Rukspulfilter.jpg,
  },
  10: {
    imgWebp: sensors_242_images.Kaltwassereingang.webp,
    imgPng: sensors_242_images.Kaltwassereingang.jpg,
  },
  11: {
    imgWebp: sensors_242_images.Rukspulfilter.webp,
    imgPng: sensors_242_images.Rukspulfilter.jpg,
  },
  12: {
    imgWebp: sensors_242_images.Warmwasserspeicher.webp,
    imgPng: sensors_242_images.Warmwasserspeicher.jpg,
  },
  13: {
    imgWebp: sensors_242_images.Heizkessel.webp,
    imgPng: sensors_242_images.Heizkessel.jpg,
  },
  14: {
    imgWebp: sensors_242_images.Speicherladepumpe.webp,
    imgPng: sensors_242_images.Speicherladepumpe.jpg,
  },
  15: {
    imgWebp: sensors_242_images.Heizkreispumpe.webp,
    imgPng: sensors_242_images.Heizkreispumpe.jpg,
  },
  16: {
    imgWebp: sensors_242_images.Zirkulationspumpe.webp,
    imgPng: sensors_242_images.Zirkulationspumpe.jpg,
  },
  17: {
    imgWebp: sensors_NiehlerGurtel_images.Kaltwassereingang.webp,
    imgPng: sensors_NiehlerGurtel_images.Kaltwassereingang.jpg,
  },
  18: {
    imgWebp: sensors_NiehlerGurtel_images.Rukspulfilter.webp,
    imgPng: sensors_NiehlerGurtel_images.Rukspulfilter.jpg,
  },
  19: {
    imgWebp: sensors_NiehlerGurtel_images.Warmwasserspeicher.Warmwasserspeicher_1.webp,
    imgPng: sensors_NiehlerGurtel_images.Warmwasserspeicher.Warmwasserspeicher_1.jpg,
  },
  20: {
    imgWebp: sensors_NiehlerGurtel_images.Warmwasserspeicher.Warmwasserspeicher_2.webp,
    imgPng: sensors_NiehlerGurtel_images.Warmwasserspeicher.Warmwasserspeicher_2.jpg,
  },
  21: {
    imgWebp: sensors_NiehlerGurtel_images.Speicherladepumpe.webp,
    imgPng: sensors_NiehlerGurtel_images.Speicherladepumpe.jpg,
  },
  22: {
    imgWebp: sensors_NiehlerGurtel_images.Heizkreispumpe.Heizkreispumpe_1.webp,
    imgPng: sensors_NiehlerGurtel_images.Heizkreispumpe.Heizkreispumpe_1.jpg,
  },
  23: {
    imgWebp: sensors_NiehlerGurtel_images.Heizkreispumpe.Heizkreispumpe_2.webp,
    imgPng: sensors_NiehlerGurtel_images.Heizkreispumpe.Heizkreispumpe_2.jpg,
  },
  24: {
    imgWebp: sensors_NiehlerGurtel_images.Heizkreispumpe.Heizkreispumpe_3.webp,
    imgPng: sensors_NiehlerGurtel_images.Heizkreispumpe.Heizkreispumpe_3.jpg,
  },
  25: {
    imgWebp: sensors_NiehlerGurtel_images.Heizkreispumpe.Heizkreispumpe_4.webp,
    imgPng: sensors_NiehlerGurtel_images.Heizkreispumpe.Heizkreispumpe_4.jpg,
  },
  26: {
    imgWebp: sensors_NiehlerGurtel_images.Zirkulationspumpe.Zirkulationspumpe_1.webp,
    imgPng: sensors_NiehlerGurtel_images.Zirkulationspumpe.Zirkulationspumpe_1.jpg,
  },
  27: {
    imgWebp: sensors_NiehlerGurtel_images.Zirkulationspumpe.Zirkulationspumpe_2.webp,
    imgPng: sensors_NiehlerGurtel_images.Zirkulationspumpe.Zirkulationspumpe_2.jpg,
  },
  28: {
    imgWebp: sensors_NiehlerGurtel_images.Warmetauscher.Warmetauscher_1.webp,
    imgPng: sensors_NiehlerGurtel_images.Warmetauscher.Warmetauscher_1.jpg,
  },
  28001: {
    imgWebp: sensors_NiehlerGurtel_images.Warmetauscher.Warmetauscher_2.webp,
    imgPng: sensors_NiehlerGurtel_images.Warmetauscher.Warmetauscher_2.jpg,
  },
  29: {
    imgWebp: sensors_NiehlerGurtel_images.Vorlagepumpe.webp,
    imgPng: sensors_NiehlerGurtel_images.Vorlagepumpe.jpg,
  },
  30: {
    imgWebp: sensors_NiehlerGurtel_images.Kesselpumpe.Kesselpumpe_1.webp,
    imgPng: sensors_NiehlerGurtel_images.Kesselpumpe.Kesselpumpe_1.jpg,
  },
  31: {
    imgWebp: sensors_NiehlerGurtel_images.Kesselpumpe.Kesselpumpe_2.webp,
    imgPng: sensors_NiehlerGurtel_images.Kesselpumpe.Kesselpumpe_2.jpg,
  },
  32: {
    imgWebp: sensors_NiehlerGurtel_images.Heizkessel.Heizkessel_1.webp,
    imgPng: sensors_NiehlerGurtel_images.Heizkessel.Heizkessel_1.jpg,
  },
  33: {
    imgWebp: sensors_NiehlerGurtel_images.Heizkessel.Heizkessel_2.webp,
    imgPng: sensors_NiehlerGurtel_images.Heizkessel.Heizkessel_2.jpg,
  },
  34: {
    imgWebp: sensors_NiehlerGurtel_images.Speicherladesystem.Speicherladesystem_1.webp,
    imgPng: sensors_NiehlerGurtel_images.Speicherladesystem.Speicherladesystem_1.png,
  },
  34001: {
    imgWebp: sensors_NiehlerGurtel_images.Speicherladesystem.Speicherladesystem_2.webp,
    imgPng: sensors_NiehlerGurtel_images.Speicherladesystem.Speicherladesystem_2.png,
  },
  35: {
    imgWebp: default_images.Warmetauscher.webp,
    imgPng: default_images.Warmetauscher.png,
  },
  36: {
    imgWebp: default_images.Warmetauscher.webp,
    imgPng: default_images.Warmetauscher.png,
  },
  38: {
    imgWebp: sensors_Heimatring_images[12].Kaltwassereingang.webp,
    imgPng: sensors_Heimatring_images[12].Kaltwassereingang.jpg,
  },
  39: {
    imgWebp: sensors_Heimatring_images[12].Rukspulfilter.webp,
    imgPng: sensors_Heimatring_images[12].Rukspulfilter.jpg,
  },
  40: {
    imgWebp: sensors_Heimatring_images[12].Speicherladekreis.webp,
    imgPng: sensors_Heimatring_images[12].Speicherladekreis.jpg,
  },
  41: {
    imgWebp: sensors_Heimatring_images[12].Fernwarme.webp,
    imgPng: sensors_Heimatring_images[12].Fernwarme.jpg,
  },
  42: {
    imgWebp: sensors_Heimatring_images[12].Heizkreis.Heizkreis_1.webp,
    imgPng: sensors_Heimatring_images[12].Heizkreis.Heizkreis_1.jpg,
  },
  43: {
    imgWebp: sensors_Heimatring_images[12].Heizkreis.Heizkreis_2.webp,
    imgPng: sensors_Heimatring_images[12].Heizkreis.Heizkreis_2.jpg,
  },
  44: {
    imgWebp: sensors_Heimatring_images[12].Heizkreis.Heizkreis_3.webp,
    imgPng: sensors_Heimatring_images[12].Heizkreis.Heizkreis_3.jpg,
  },
  45: {
    imgWebp: sensors_Heimatring_images[12].Heizkreispumpe.Heizkreispumpe_1.webp,
    imgPng: sensors_Heimatring_images[12].Heizkreispumpe.Heizkreispumpe_1.jpg,
  },
  46: {
    imgWebp: sensors_Heimatring_images[12].Heizkreispumpe.Heizkreispumpe_2.webp,
    imgPng: sensors_Heimatring_images[12].Heizkreispumpe.Heizkreispumpe_2.jpg,
  },
  47: {
    imgWebp: sensors_Heimatring_images[12].Heizkreispumpe.Heizkreispumpe_3.webp,
    imgPng: sensors_Heimatring_images[12].Heizkreispumpe.Heizkreispumpe_3.jpg,
  },
  48: {
    imgWebp: sensors_Heimatring_images[12].Warmwasserspeicher.webp,
    imgPng: sensors_Heimatring_images[12].Warmwasserspeicher.jpg,
  },
  49: {
    imgWebp: sensors_Heimatring_images[12].Zirkulationspumpe.webp,
    imgPng: sensors_Heimatring_images[12].Zirkulationspumpe.jpg,
  },
  50: {
    imgWebp: sensors_Heimatring_images[12].Abwasser.webp,
    imgPng: sensors_Heimatring_images[12].Abwasser.jpg,
  },
  52: {
    imgWebp: sensors_Anter_den_Akazien_images['3_4'].Kaltwassereingang.webp,
    imgPng: sensors_Anter_den_Akazien_images['3_4'].Kaltwassereingang.jpg,
  },
  54: {
    imgWebp: sensors_Anter_den_Akazien_images['7_9'].Kaltwassereingang.webp,
    imgPng: sensors_Anter_den_Akazien_images['7_9'].Kaltwassereingang.jpg,
  },
  55: {
    imgWebp: sensors_Anter_den_Akazien_images['10_12'].Kaltwassereingang.webp,
    imgPng: sensors_Anter_den_Akazien_images['10_12'].Kaltwassereingang.jpg,
  },
  56: {
    imgWebp: sensors_Anter_den_Akazien_images['10_12'].Rukspulfilter.webp,
    imgPng: sensors_Anter_den_Akazien_images['10_12'].Rukspulfilter.jpg,
  },
  58: {
    imgWebp: sensors_Anter_den_Platanen_images['1'].Kaltwassereingang.webp,
    imgPng: sensors_Anter_den_Platanen_images['1'].Kaltwassereingang.jpg,
  },
  62: {
    imgWebp: sensors_Anter_den_Platanen_images['7_9'].Kaltwassereingang.webp,
    imgPng: sensors_Anter_den_Platanen_images['7_9'].Kaltwassereingang.jpg,
  },
  63: {
    imgWebp: sensors_Anter_den_Platanen_images['13_16'].Kaltwassereingang.Kaltwassereingang_16.webp,
    imgPng: sensors_Anter_den_Platanen_images['13_16'].Kaltwassereingang.Kaltwassereingang_16.jpg,
  },
  64: {
    imgWebp: sensors_Anter_den_Platanen_images['13_16'].Kaltwassereingang.Kaltwassereingang_13.webp,
    imgPng: sensors_Anter_den_Platanen_images['13_16'].Kaltwassereingang.Kaltwassereingang_13.jpg,
  },
  65: {
    imgWebp: sensors_Anter_den_Platanen_images['13_16'].Rukspulfilter.webp,
    imgPng: sensors_Anter_den_Platanen_images['13_16'].Rukspulfilter.jpg,
  },
  66: {
    imgWebp: sensors_Heimatring_images['8_9'].Kaltwassereingang.webp,
    imgPng: sensors_Heimatring_images['8_9'].Kaltwassereingang.jpg,
  },
  68: {
    imgWebp: sensors_Heimatring_images['10_11'].Rukspulfilter.webp,
    imgPng: sensors_Heimatring_images['10_11'].Rukspulfilter.jpg,
  },
  69: {
    imgWebp: sensors_Heimatring_images['12_14'].Kaltwassereingang.webp,
    imgPng: sensors_Heimatring_images['12_14'].Kaltwassereingang.jpg,
  },
  74: {
    imgWebp: sensors_Unter_den_Linden_images['5_7'].Kaltwassereingang.webp,
    imgPng: sensors_Unter_den_Linden_images['5_7'].Kaltwassereingang.jpg,
  },
  77: {
    imgWebp: sensors_Unter_den_Linden_images['14_16'].Kaltwassereingang.webp,
    imgPng: sensors_Unter_den_Linden_images['14_16'].Kaltwassereingang.jpg,
  },
  78: {
    imgWebp: sensors_Unter_den_Linden_images['14_16'].Rukspulfilter.webp,
    imgPng: sensors_Unter_den_Linden_images['14_16'].Rukspulfilter.jpg,
  },
  79: {
    imgWebp: sensors_Unter_den_Linden_images['17_18'].Kaltwassereingang.webp,
    imgPng: sensors_Unter_den_Linden_images['17_18'].Kaltwassereingang.jpg,
  },
  80: {
    imgWebp: sensors_Unter_den_Linden_images['17_18'].Rukspulfilter.webp,
    imgPng: sensors_Unter_den_Linden_images['17_18'].Rukspulfilter.jpg,
  },
  81: {
    imgWebp: sensors_Unter_den_Eschen_images['8_10'].Kaltwassereingang.Kaltwassereingang_10.webp,
    imgPng: sensors_Unter_den_Eschen_images['8_10'].Kaltwassereingang.Kaltwassereingang_10.jpg,
  },
  82: {
    imgWebp: sensors_Unter_den_Eschen_images['3_4'].Kaltwassereingang.webp,
    imgPng: sensors_Unter_den_Eschen_images['3_4'].Kaltwassereingang.jpg,
  },
  83: {
    imgWebp: sensors_Unter_den_Eschen_images['5_7'].Kaltwassereingang.webp,
    imgPng: sensors_Unter_den_Eschen_images['5_7'].Kaltwassereingang.jpg,
  },
  84: {
    imgWebp: sensors_Unter_den_Eschen_images['8_10'].Kaltwassereingang.Kaltwassereingang_8.webp,
    imgPng: sensors_Unter_den_Eschen_images['8_10'].Kaltwassereingang.Kaltwassereingang_8.jpg,
  },
  85: {
    imgWebp: sensors_Unter_den_Eschen_images['8_10'].Rukspulfilter.webp,
    imgPng: sensors_Unter_den_Eschen_images['8_10'].Rukspulfilter.jpg,
  },
  86: {
    imgWebp: sensors_Unter_den_Eschen_images['11_13'].Kaltwassereingang.webp,
    imgPng: sensors_Unter_den_Eschen_images['11_13'].Kaltwassereingang.jpg,
  },
  87: {
    imgWebp: sensors_Unter_den_Eschen_images['14_15'].Kaltwassereingang.webp,
    imgPng: sensors_Unter_den_Eschen_images['14_15'].Kaltwassereingang.jpg,
  },
  88: {
    imgWebp: sensors_Unter_den_Eschen_images['14_15'].Rukspulfilter.webp,
    imgPng: sensors_Unter_den_Eschen_images['14_15'].Rukspulfilter.jpg,
  },
  89: {
    imgWebp: default_images.Kaltwassereingang.webp,
    imgPng: default_images.Kaltwassereingang.png,
  },
  90: {
    imgWebp: default_images.Kaltwassereingang.webp,
    imgPng: default_images.Kaltwassereingang.png,
  },
  91: {
    imgWebp: default_images.Kaltwassereingang.webp,
    imgPng: default_images.Kaltwassereingang.png,
  },
  92: {
    imgWebp: sensors_Oberhutte_images['Albau'].Hauswasserzahler.webp,
    imgPng: sensors_Oberhutte_images['Albau'].Hauswasserzahler.jpg,
  },
  93: {
    imgWebp: sensors_Oberhutte_images['Albau'].Ruckspulfilter.Ruckspulfilter_1.webp,
    imgPng: sensors_Oberhutte_images['Albau'].Ruckspulfilter.Ruckspulfilter_1.jpg,
  },
  94: {
    imgWebp: sensors_Oberhutte_images['Albau'].Ruckspulfilter.Ruckspulfilter_2.webp,
    imgPng: sensors_Oberhutte_images['Albau'].Ruckspulfilter.Ruckspulfilter_2.jpg,
  },
  95: {
    imgWebp: sensors_Oberhutte_images['Albau'].Heizkessel.webp,
    imgPng: sensors_Oberhutte_images['Albau'].Heizkessel.jpg,
  },
  96: {
    imgWebp: sensors_Oberhutte_images['Albau'].Speicherladepumpe.webp,
    imgPng: sensors_Oberhutte_images['Albau'].Speicherladepumpe.jpg,
  },
  97: {
    imgWebp: sensors_Oberhutte_images['Albau'].Zirkulationpumpe.webp,
    imgPng: sensors_Oberhutte_images['Albau'].Zirkulationpumpe.jpg,
  },
  98: {
    imgWebp: sensors_Oberhutte_images['Albau'].HydraulischeWeiche.webp,
    imgPng: sensors_Oberhutte_images['Albau'].HydraulischeWeiche.jpg,
  },
  99: {
    imgWebp: sensors_Oberhutte_images['Albau'].HeatingCircuitPump.HeatingCircuitPump_1.webp,
    imgPng: sensors_Oberhutte_images['Albau'].HeatingCircuitPump.HeatingCircuitPump_1.jpg,
  },
  100: {
    imgWebp: sensors_Oberhutte_images['Albau'].HeatingCircuitPump.HeatingCircuitPump_2.webp,
    imgPng: sensors_Oberhutte_images['Albau'].HeatingCircuitPump.HeatingCircuitPump_2.jpg,
  },
  101: {
    imgWebp: sensors_Oberhutte_images['Albau'].HeatingCircuitPump.HeatingCircuitPump_3.webp,
    imgPng: sensors_Oberhutte_images['Albau'].HeatingCircuitPump.HeatingCircuitPump_3.jpg,
  },
  102: {
    imgWebp: sensors_Oberhutte_images['Albau'].Warmetauscher.webp,
    imgPng: sensors_Oberhutte_images['Albau'].Warmetauscher.jpg,
  },
  103: {
    imgWebp: sensors_Oberhutte_images['Albau'].Warmwasserspeicher.Warmwasserspeicher_1.webp,
    imgPng: sensors_Oberhutte_images['Albau'].Warmwasserspeicher.Warmwasserspeicher_1.jpg,
  },
  104: {
    imgWebp: sensors_Oberhutte_images['Albau'].Warmwasserspeicher.Warmwasserspeicher_2.webp,
    imgPng: sensors_Oberhutte_images['Albau'].Warmwasserspeicher.Warmwasserspeicher_2.jpg,
  },
  105: {
    imgWebp: sensors_Brusseler_images.Hauswasserzahler.webp,
    imgPng: sensors_Brusseler_images.Hauswasserzahler.jpg,
  },
  107: {
    imgWebp: sensors_Brusseler_images.Warmwasserspeicher.webp,
    imgPng: sensors_Brusseler_images.Warmwasserspeicher.jpg,
  },
  108: {
    imgWebp: sensors_Brusseler_images.Frischwasserstation.webp,
    imgPng: sensors_Brusseler_images.Frischwasserstation.jpg,
  },
  109: {
    imgWebp: sensors_Brusseler_images.Speicherladepumpe.webp,
    imgPng: sensors_Brusseler_images.Speicherladepumpe.jpg,
  },
  110: {
    imgWebp: sensors_Brusseler_images.Zirkulationpumpe.webp,
    imgPng: sensors_Brusseler_images.Zirkulationpumpe.jpg,
  },
  111: {
    imgWebp: sensors_Brusseler_images.Heizkreispumpe.Heizkreispumpe_1.webp,
    imgPng: sensors_Brusseler_images.Heizkreispumpe.Heizkreispumpe_1.jpg,
  },
  112: {
    imgWebp: sensors_Brusseler_images.Heizkreispumpe.Heizkreispumpe_2.webp,
    imgPng: sensors_Brusseler_images.Heizkreispumpe.Heizkreispumpe_2.jpg,
  },
  113: {
    imgWebp: sensors_Brusseler_images.Heizkreispumpe.Heizkreispumpe_3.webp,
    imgPng: sensors_Brusseler_images.Heizkreispumpe.Heizkreispumpe_3.jpg,
  },
  114: {
    imgWebp: sensors_Derfflinger_images.Hauswasserzahler.webp,
    imgPng: sensors_Derfflinger_images.Hauswasserzahler.jpg,
  },
  115: {
    imgWebp: sensors_Derfflinger_images.Ruckspulfilter.webp,
    imgPng: sensors_Derfflinger_images.Ruckspulfilter.jpg,
  },
  116: {
    imgWebp: sensors_Derfflinger_images.Zirkulationpumpe.webp,
    imgPng: sensors_Derfflinger_images.Zirkulationpumpe.jpg,
  },
  117: {
    imgWebp: sensors_Derfflinger_images.Warmwasserspeicher.webp,
    imgPng: sensors_Derfflinger_images.Warmwasserspeicher.jpg,
  },
  118: {
    imgWebp: sensors_Derfflinger_images.Heizkreise.webp,
    imgPng: sensors_Derfflinger_images.Heizkreise.jpg,
  },
  119: {
    imgWebp: sensors_Derfflinger_images.Heizkreispumpe.webp,
    imgPng: sensors_Derfflinger_images.Heizkreispumpe.jpg,
  },
  120: {
    imgWebp: sensors_Derfflinger_images.Speicherladepumpe.webp,
    imgPng: sensors_Derfflinger_images.Speicherladepumpe.jpg,
  },
  121: {
    imgWebp: sensors_Derfflinger_images.Heizkessel.webp,
    imgPng: sensors_Derfflinger_images.Heizkessel.jpg,
  },
  122: {
    imgWebp: sensors_Derfflinger_images.Warmetauscher.webp,
    imgPng: sensors_Derfflinger_images.Warmetauscher.jpg,
  },
  123: {
    imgWebp: sensors_Charlotten_Plotzenseer_images['Charlottenburger'].Kaltwassereingang.webp,
    imgPng: sensors_Charlotten_Plotzenseer_images['Charlottenburger'].Kaltwassereingang.jpg,
  },
  124: {
    imgWebp: sensors_Charlotten_Plotzenseer_images['Charlottenburger'].Ruckspulfilter.webp,
    imgPng: sensors_Charlotten_Plotzenseer_images['Charlottenburger'].Ruckspulfilter.jpg,
  },
  125: {
    imgWebp: sensors_Charlotten_Plotzenseer_images['Charlottenburger'].Heizkreispumpe.Heizkreispumpe_1.webp,
    imgPng: sensors_Charlotten_Plotzenseer_images['Charlottenburger'].Heizkreispumpe.Heizkreispumpe_1.jpg,
  },
  126: {
    imgWebp: sensors_Charlotten_Plotzenseer_images['Charlottenburger'].Heizkreispumpe.Heizkreispumpe_2.webp,
    imgPng: sensors_Charlotten_Plotzenseer_images['Charlottenburger'].Heizkreispumpe.Heizkreispumpe_2.jpg,
  },
  127: {
    imgWebp: sensors_Charlotten_Plotzenseer_images['Charlottenburger'].Heizkreispumpe.Heizkreispumpe_3.webp,
    imgPng: sensors_Charlotten_Plotzenseer_images['Charlottenburger'].Heizkreispumpe.Heizkreispumpe_3.jpg,
  },
  128: {
    imgWebp: sensors_Charlotten_Plotzenseer_images['Charlottenburger'].Heizkreispumpe.Heizkreispumpe_4.webp,
    imgPng: sensors_Charlotten_Plotzenseer_images['Charlottenburger'].Heizkreispumpe.Heizkreispumpe_4.jpg,
  },
  129: {
    imgWebp: sensors_Charlotten_Plotzenseer_images['Charlottenburger'].Fernwarme.webp,
    imgPng: sensors_Charlotten_Plotzenseer_images['Charlottenburger'].Fernwarme.jpg,
  },
  130: {
    imgWebp: sensors_Charlotten_Plotzenseer_images['Charlottenburger'].Warmetauscher.webp,
    imgPng: sensors_Charlotten_Plotzenseer_images['Charlottenburger'].Warmetauscher.jpg,
  },
  131: {
    imgWebp: default_images.Pumpe.webp,
    imgPng: default_images.Pumpe.png,
  },
  132: {
    imgWebp: default_images.Pumpe.webp,
    imgPng: default_images.Pumpe.png,
  },
  133: {
    imgWebp: default_images.Pumpe.webp,
    imgPng: default_images.Pumpe.png,
  },
  134: {
    imgWebp: sensors_Charlotten_Plotzenseer_images['Charlottenburger'].Speicherladepumpe.webp,
    imgPng: sensors_Charlotten_Plotzenseer_images['Charlottenburger'].Speicherladepumpe.jpg,
  },
  135: {
    imgWebp: sensors_Charlotten_Plotzenseer_images['Charlottenburger'].Warmwasserspeicher.webp,
    imgPng: sensors_Charlotten_Plotzenseer_images['Charlottenburger'].Warmwasserspeicher.jpg,
  },
  136: {
    imgWebp: sensors_Charlotten_Plotzenseer_images['Plotzenseer'].Kaltwassereingang.webp,
    imgPng: sensors_Charlotten_Plotzenseer_images['Plotzenseer'].Kaltwassereingang.jpg,
  },
  137: {
    imgWebp: default_images.Pumpe.webp,
    imgPng: default_images.Pumpe.png,
  },
  138: {
    imgWebp: sensors_Charlotten_Plotzenseer_images['Plotzenseer'].Ruckspulfilter.webp,
    imgPng: sensors_Charlotten_Plotzenseer_images['Plotzenseer'].Ruckspulfilter.jpg,
  },
  139: {
    imgWebp: default_images.Fernwarme.webp,
    imgPng: default_images.Fernwarme.png,
  },
  140: {
    imgWebp: default_images.Pumpe.webp,
    imgPng: default_images.Pumpe.png,
  },
  141: {
    imgWebp: default_images.Pumpe.webp,
    imgPng: default_images.Pumpe.png,
  },
  143: {
    imgWebp: sensors_Charlotten_Plotzenseer_images['Plotzenseer'].Speicherladepumpe.webp,
    imgPng: sensors_Charlotten_Plotzenseer_images['Plotzenseer'].Speicherladepumpe.jpg,
  },
  144: {
    imgWebp: sensors_Charlotten_Plotzenseer_images['Plotzenseer'].Warmwasserspeicher.webp,
    imgPng: sensors_Charlotten_Plotzenseer_images['Plotzenseer'].Warmwasserspeicher.jpg,
  },
  145: {
    imgWebp: sensors_Charlotten_Plotzenseer_images['Plotzenseer'].Zirkulationspumpe.webp,
    imgPng: sensors_Charlotten_Plotzenseer_images['Plotzenseer'].Zirkulationspumpe.jpg,
  },
  146: {
    imgWebp: sensors_AmBlomkenberg_images.Kaltwassereingang.webp,
    imgPng: sensors_AmBlomkenberg_images.Kaltwassereingang.jpg,
  },
  147: {
    imgWebp: sensors_AmBlomkenberg_images.Rukspulfilter.webp,
    imgPng: sensors_AmBlomkenberg_images.Rukspulfilter.jpg,
  },
  148: {
    imgWebp: sensors_AmBlomkenberg_images.Warmwasserspeicher.webp,
    imgPng: sensors_AmBlomkenberg_images.Warmwasserspeicher.jpg,
  },
  149: {
    imgWebp: sensors_AmBlomkenberg_images.Heizkressel.webp,
    imgPng: sensors_AmBlomkenberg_images.Heizkressel.jpg,
  },
  150: {
    imgWebp: sensors_AmBlomkenberg_images.BNKW.webp,
    imgPng: sensors_AmBlomkenberg_images.BNKW.jpg,
  },
  151: {
    imgWebp: sensors_AmBlomkenberg_images.Pufferspeicher.webp,
    imgPng: sensors_AmBlomkenberg_images.Pufferspeicher.jpg,
  },
  152: {
    imgWebp: sensors_AmBlomkenberg_images.Speicherladepumpe.webp,
    imgPng: sensors_AmBlomkenberg_images.Speicherladepumpe.jpg,
  },
  153: {
    imgWebp: sensors_AmBlomkenberg_images.FreshWaterStation.webp,
    imgPng: sensors_AmBlomkenberg_images.FreshWaterStation.jpg,
  },
  154: {
    imgWebp: sensors_AmBlomkenberg_images.Heizkreis.webp,
    imgPng: sensors_AmBlomkenberg_images.Heizkreis.jpg,
  },
  155: {
    imgWebp: default_images.Pumpe.webp,
    imgPng: default_images.Pumpe.png,
  }
}

export const housesRule = {
  '62061f10452e21129b8ca241': {
    5: [
      {
        id: 1,
        textType: "ColdWaterInlet",
        imgWebp: sensors_241_5.webp.s_4,
        imgPng: sensors_241_5.png.s_4,
        modbus: 101,
        hasDetails: true,
        details: {
          tabs: ['images', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 2,
        textType: "BackwashFilter",
        imgWebp: sensors_241_5.webp.s_1,
        imgPng: sensors_241_5.png.s_1,
        modbus: null,
        hasDetails: true,
        details: {
          info: {
            name: 'Judo JRSF 1',
            NominalDiameter: 'DN32',
            PressureLoss: '0,2 bar',
            Flow: '14 m³/h',
          },
          tabs: ['info', 'washing_process']
        }
      },
      {
        id: 3,
        textType: 'StorageWaterHeater',
        imgWebp: sensors_241_5.webp.s_7,
        imgPng: sensors_241_5.png.s_7,
        modbus: 151,
        hasDetails: true,
        details: {
          info: {
            name: 'Ditech A104',
            StorageType: 'Speicherladesystem',
            MemoryContent: '395 L',
            HeatOutput: '150 kW',
            SerialNumber: '0248-13 MC/E'
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 4,
        textType: 'Boiler',
        imgWebp: sensors_241_5.webp.s_2,
        imgPng: sensors_241_5.png.s_2,
        modbus: { sensor_in: 200, sensor_out: 201},
        hasDetails: true,
        details: {
          info: {
            name: 'Buderus Logano G334XZ',
            HeatGenerators: 'Boiler',
            EnergySource: 'Gas',
            NominalHeatOutput: '98,2 kW',
            BoilerContents: '43 L',
            SerialNumber: '05621813-00-2253-01317',
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 5,
        textType: 'StorageChargingPump',
        imgWebp: sensors_241_5.webp.s_6,
        imgPng: sensors_241_5.png.s_6,
        modbus: { sensor_in: 232, sensor_out: 233},
        hasDetails: true,
        details: {
          info: {
            name: 'Speck Inova NH 32/60',
            NominalDiameter: 'DN32',
            RotationalSpeed: '50 Hz',
            CurrentConsumption: '40W',
            HeadOfFunding: '60 mb',
            InstallationLength: '180 mm',
            SafetyClass: 'IP X2D',
            SerialNumber: '4174114',
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 6,
        textType: 'HeatingCircuitPump',
        imgWebp: sensors_241_5.webp.s_3,
        imgPng: sensors_241_5.png.s_3,
        modbus: { sensor_in: 212, sensor_out: 213},
        hasDetails: true,
        details: {
          info: {
            name: 'Grundfos Magna 3 F 220',

            NominalDiameter: 'DN40',
            RotationalSpeed: '50 Hz',
            CurrentConsumption: '348 W',
            HeadOfFunding: '100 mb',
            InstallationLength: '220 mm',
            SafetyClass: 'IP X4D',
            SerialNumber: '10016426',
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 7,
        textType: 'CirculationPump',
        imgWebp: sensors_241_5.webp.s_8,
        imgPng: sensors_241_5.png.s_8,
        modbus: 152,
        hasDetails: true,
        details: {
          info: {
            name: 'Grundfos UP 20-30',
            NominalDiameter: 'DN20',
            RotationalSpeed: '50 Hz',
            CurrentConsumption: '75 W',
            HeadOfFunding: '30 mb',
            InstallationLength: '150 mm',
            SafetyClass: 'IP 44',
            SerialNumber: '59643500',
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
    ],
    7: [
      {
        id: 8,
        textType: 'ColdWaterInlet',
        imgWebp: sensors_241_7.webp.s_9,
        imgPng: sensors_241_7.png.s_9,
        modbus: 100,
        hasDetails: true,
        details: {
          tabs: ['images', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 9,
        textType: 'BackwashFilters',
        imgWebp: sensors_241_7.webp.s_5,
        imgPng: sensors_241_7.png.s_5,
        modbus: null,
        hasDetails: true,
        details: {
          info: {
            name: 'Judo JRSF 1',
            NominalDiameter: 'DN32',
            PressureLoss: '0,2 bar',
            Flow: '14 m³/h',
          },
          tabs: ['info', 'washing_process']
        }
      },
    ],
  },
  '62061f10452e21129b8ca242': {
    null: [
      {
        id: 10,
        textType: 'ColdWaterInlet',
        imgWebp: sensors_242_null.webp.s_11,
        imgPng: sensors_242_null.png.s_11,
        modbus: 100,
        hasDetails: true,
        details: {
          tabs: ['images', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 11,
        textType: 'BackwashFilter',
        imgWebp: sensors_242_null.webp.s_12,
        imgPng: sensors_242_null.png.s_12,
        modbus: null,
        hasDetails: true,
        details: {
          info: {
            name: 'Honeywell HS10S-11/2aa',
            NominalDiameter: 'DN40',
            PressureLoss: '1,1 bar',
            Flow: '9,1 m³/h',
            SerialNumber: 'DW 9021AT2318',
          },
          tabs: ['info', 'washing_process']
        }
      },
      {
        id: 12,
        textType: 'StorageWaterHeater',
        imgWebp: sensors_242_null.webp.s_16,
        imgPng: sensors_242_null.png.s_16,
        modbus: 151,
        hasDetails: true,
        details: {
          info: {
            name: 'Viessmann Vitocell 100',
            StorageType: 'Speicherladesystem',
            MemoryContent: '300 L',
            HeatOutput: '33 kW',
            SerialNumber: '7265117100815107',
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 13,
        textType: 'Boiler',
        imgWebp: sensors_242_null.webp.s_14,
        imgPng: sensors_242_null.png.s_14,
        modbus: { sensor_in: 200, sensor_out: 201 },
        hasDetails: true,
        details: {
          info: {
            name: 'Viessmann Vitocrossal 300',
            HeatGenerators: 'Heizkessel',
            EnergySource: 'Gas',
            NominalHeatOutput: '47,8 kW',
            BoilerContents: '98 L',
            SerialNumber: '7226078100332',
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 14,
        textType: 'StorageChargingPump',
        imgWebp: sensors_242_null.webp.s_15,
        imgPng: sensors_242_null.png.s_15,
        modbus: { sensor_in: 232, sensor_out: 233 },
        hasDetails: true,
        details: {
          info: {
            name: 'Grundfos UP 25-40',
            NominalDiameter: 'DN25',
            RotationalSpeed: '50 Hz',
            CurrentConsumption: '60 W',
            HeadOfFunding: '40 mb',
            InstallationLength: '180 mm',
            SafetyClass: 'IP 44',
            SerialNumber: '59544123',
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 15,
        textType: 'HeatingCircuitPump',
        imgWebp: sensors_242_null.webp.s_10,
        imgPng: sensors_242_null.png.s_10,
        modbus: { sensor_in: 212, sensor_out: 213 },
        hasDetails: true,
        details: {
          info: {
            name: 'Speck Inova NH 25/60',
            NominalDiameter: 'DN25',
            RotationalSpeed: '50 Hz',
            CurrentConsumption: '40 W',
            HeadOfFunding: '60 mb',
            InstallationLength: '180 mm',
            SafetyClass: 'IP X4D',
            SerialNumber: '4150195',
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 16,
        textType: 'CirculationPump',
        imgWebp: sensors_242_null.webp.s_13,
        imgPng: sensors_242_null.png.s_13,
        modbus: 152,
        hasDetails: true,
        details: {
          info: {
            name: 'Grundfos UP 15-14B',
            NominalDiameter: 'DN15',
            RotationalSpeed: '50 Hz',
            CurrentConsumption: '25 W',
            HeadOfFunding: '14 mb',
            InstallationLength: '80 mm',
            SafetyClass: 'IP 42',
            SerialNumber: '96433883',
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
    ]
  },
  '62e90c3e3b1685117c8fe1e1': {
    null: [
      {
        id: 17,
        textType: 'DomesticWaterMeter',
        imgWebp: sensors_NiehlerGurtel.webp.s_17,
        imgPng: sensors_NiehlerGurtel.png.s_17,
        modbus: null,
        hasDetails: true,
        details: {
          tabs: ['images']
        }
      },
      {
        id: 18,
        textType: 'BackwashFilter',
        imgWebp: sensors_NiehlerGurtel.webp.s_18,
        imgPng: sensors_NiehlerGurtel.png.s_18,
        modbus: null,
        hasDetails: true,
        details: {
          info: {
            name: 'Judo JPF-QC 100',
            NominalDiameter: 'DN100',
            PressureLoss: '0.2 Bar',
            Flow: '60 m³/h',
            SerialNumber: '02/613060009'
          },
          tabs: ['info', 'washing_process']
        }
      },
      {
        id: 26,
        textType: 'CirculationPump',
        componentNumber: 1,
        imgWebp: sensors_NiehlerGurtel.webp.s_26,
        imgPng: sensors_NiehlerGurtel.png.s_26,
        modbus: null,
        hasDetails: true,
        details: {
          info: {
            name: 'WILO Stratos-Z 30/1 RG',
            NominalDiameter: 'DN30',
            RotationalSpeed: '3700 rpm',
            CurrentConsumption: '1.10 A',
            HeadOfFunding: '8 m',
            InstallationLength: '180 mm',
            SafetyClass: 'IPx4D',
            SerialNumber: '-',
          },
          tabs: ['info']
        }
      },
      {
        id: 27,
        textType: 'CirculationPump',
        componentNumber: 2,
        imgWebp: sensors_NiehlerGurtel.webp.s_27,
        imgPng: sensors_NiehlerGurtel.png.s_27,
        modbus: null,
        hasDetails: true,
        details: {
          info: {
            name: 'WILO Stratos-Z 30/1 RG',
            NominalDiameter: 'DN30',
            RotationalSpeed: '3700 rpm',
            CurrentConsumption: '1.10 A',
            HeadOfFunding: '8 m',
            InstallationLength: '180 mm',
            SafetyClass: 'IPx4D',
            SerialNumber: '-',
          },
          tabs: ['info']
        }
      },
      {
        id: 19,
        textType: 'StorageWaterHeater',
        componentNumber: 1,
        imgWebp: sensors_NiehlerGurtel.webp.s_19,
        imgPng: sensors_NiehlerGurtel.png.s_19,
        modbus: '0',
        hasDetails: true,
        details: {
          info: {
            name: 'Buderus BESF 500',
            StorageType: 'Speicherladung',
            MemoryContent: '500 L',
            HeatOutput: '-',
            SerialNumber: '7747214339',
          },
          tabs: ['info', 'live_date_paul']
        }
      },
      {
        id: 20,
        textType: 'StorageWaterHeater',
        componentNumber: 2,
        imgWebp: sensors_NiehlerGurtel.webp.s_20,
        imgPng: sensors_NiehlerGurtel.png.s_20,
        modbus: '0',
        hasDetails: true,
        details: {
          info: {
            name: 'Buderus BESF 750',
            StorageType: 'Speicherladung',
            MemoryContent: '750 L',
            HeatOutput: '-',
            SerialNumber: '7747214341',
          },
          tabs: ['info', 'live_date_paul']
        }
      },
      {
        id: 34,
        textType: 'StorageChargingSystem',
        componentNumber: 1,
        imgWebp: sensors_NiehlerGurtel.webp.s_34,
        imgPng: sensors_NiehlerGurtel.png.s_34,
        modbus: null,
        hasDetails: true,
            details: {
              tabs: ['images']
            }
      },
      {
        id: 34001,
        textType: 'StorageChargingSystem',
        componentNumber: 2,
        imgWebp: sensors_NiehlerGurtel.webp.s_35,
        imgPng: sensors_NiehlerGurtel.png.s_35,
        modbus: null,
        hasDetails: true,
            details: {
              tabs: ['images']
            }
      },
      // {
      //   id: 21,
      //   textType: 'StorageChargingSystem',
      //   componentNumber: 2,
      //   imgWebp: sensors_NiehlerGurtel.webp.s_21,
      //   imgPng: sensors_NiehlerGurtel.png.s_21,
      //   modbus: null,
      //   hasDetails: true,
      //   details: {
      //     info: {
      //       name: 'Grundfos UPS 65-60 F2',
      //       NominalDiameter: '-',
      //       RotationalSpeed: '-',
      //       CurrentConsumption: '-',
      //       HeadOfFunding: '-',
      //       InstallationLength: '-',
      //       SafetyClass: '-',
      //       SerialNumber: '-',
      //     },
      //     tabs: ['info']
      //   }
      // },
      {
        id: 22,
        textType: 'HeatingCircuitPump',
        componentNumber: 1,
        imgWebp: sensors_NiehlerGurtel.webp.s_22,
        imgPng: sensors_NiehlerGurtel.png.s_22,
        modbus: null,
        hasDetails: true,
        details: {
          info: {
            name: 'Grundfos MAGNA3 50-60 F 240',
            NominalDiameter: 'DN50',
            RotationalSpeed: '50/60 Hz',
            CurrentConsumption: '0.23 ... 1.18 A',
            HeadOfFunding: '60 dm',
            InstallationLength: '240 mm',
            SafetyClass: 'X4D',
            SerialNumber: '10007043',
          },
          tabs: ['info']
        }
      },
      {
        id: 23,
        textType: 'HeatingCircuitPump',
        componentNumber: 2,
        imgWebp: sensors_NiehlerGurtel.webp.s_23,
        imgPng: sensors_NiehlerGurtel.png.s_23,
        modbus: null,
        hasDetails: true,
        details: {
          info: {
            name: 'Grundfos MAGNA3 80-120 F380',
            NominalDiameter: 'DN80',
            RotationalSpeed: '50/60 Hz',
            CurrentConsumption: '0.32 ... 6.65 A',
            HeadOfFunding: '120 dm',
            InstallationLength: '360 mm',
            SafetyClass: 'X4D',
            SerialNumber: '10008049',
          },
          tabs: ['info']
        }
      },
      {
        id: 24,
        textType: 'HeatingCircuitPump',
        componentNumber: 3,
        imgWebp: sensors_NiehlerGurtel.webp.s_24,
        imgPng: sensors_NiehlerGurtel.png.s_24,
        modbus: null,
        hasDetails: true,
        details: {
          info: {
            name: 'Grundfos MAGNA3 50-60 F 240',
            NominalDiameter: 'DN50',
            RotationalSpeed: '50/60 Hz',
            CurrentConsumption: '0.23 ... 1.18 A',
            HeadOfFunding: '60 dm',
            InstallationLength: '240 mm',
            SafetyClass: 'X4D',
            SerialNumber: '10010587',
          },
          tabs: ['info']
        }
      },
      {
        id: 25,
        textType: 'HeatingCircuitPump',
        componentNumber: 4,
        imgWebp: sensors_NiehlerGurtel.webp.s_25,
        imgPng: sensors_NiehlerGurtel.png.s_25,
        modbus: null,
        hasDetails: true,
        details: {
          info: {
            name: 'Grundfos UPS 65-60 /2F',
            NominalDiameter: 'DN65',
            RotationalSpeed: '50/60 Hz',
            CurrentConsumption: '0.56 ... 1.05 A',
            HeadOfFunding: '60 dm',
            InstallationLength: '280 mm',
            SafetyClass: 'ÍP 44',
            SerialNumber: '96402260',
          },
          tabs: ['info']
        }
      },
      {
        id: 29,
        textType: 'PrechargePump',
        imgWebp: sensors_NiehlerGurtel.webp.s_29,
        imgPng: sensors_NiehlerGurtel.png.s_29,
        modbus: null,
        hasDetails: true,
        details: {
          info: {
            name: 'Grundfos UPED 100-60 F',
            NominalDiameter: 'DN100',
            RotationalSpeed: '50 Hz',
            CurrentConsumption: '0.27 ... 2.13 A',
            HeadOfFunding: '60 dm',
            InstallationLength: '450 mm',
            SafetyClass: 'IP42',
            SerialNumber: '96405834',
          },
          tabs: ['info']
        }
      },
      {
        id: 30,
        textType: 'BoilerPump',
        componentNumber: 1,
        imgWebp: sensors_NiehlerGurtel.webp.s_30,
        imgPng: sensors_NiehlerGurtel.png.s_30,
        modbus: null,
        hasDetails: true,
        details: {
          info: {
            name: 'Grundfos Magna 3 100-60f F450',
            NominalDiameter: 'DN100',
            RotationalSpeed: '50/60 Hz',
            CurrentConsumption: '0.27 ... 3.11A',
            HeadOfFunding: '60 dm',
            InstallationLength: '450 mm',
            SafetyClass: 'X4D',
            SerialNumber: '10001046',
          },
          tabs: ['info']
        }
      },
      {
        id: 31,
        textType: 'BoilerPump',
        componentNumber: 2,
        imgWebp: sensors_NiehlerGurtel.webp.s_31,
        imgPng: sensors_NiehlerGurtel.png.s_31,
        modbus: null,
        hasDetails: true,
        details: {
          info: {
            name: 'Grundfos Magna 3 100-40 F450',
            NominalDiameter: 'DN100',
            RotationalSpeed: '50/60 Hz',
            CurrentConsumption: '0.27 ... 2.35 A',
            HeadOfFunding: '40 dm',
            InstallationLength: '450 mm',
            SafetyClass: 'X4D',
            SerialNumber: '10000129',
          },
          tabs: ['info']
        }
      },
      {
        id: 28,
        textType: 'HeatExchanger',
        componentNumber: 1,
        imgWebp: sensors_NiehlerGurtel.webp.s_28,
        imgPng: sensors_NiehlerGurtel.png.s_28,
        modbus: null,
        hasDetails: true,
        details: {
          tabs: ['images']
        }
      },
      {
        id: 28001,
        textType: 'HeatExchanger',
        componentNumber: 2,
        imgWebp: sensors_NiehlerGurtel.webp.s_28001,
        imgPng: sensors_NiehlerGurtel.png.s_28001,
        modbus: null,
        hasDetails: true,
        details: {
          tabs: ['images']
        }
      },
      {
        id: 32,
        textType: 'Boiler',
        componentNumber: 1,
        imgWebp: sensors_NiehlerGurtel.webp.s_32,
        imgPng: sensors_NiehlerGurtel.png.s_32,
        modbus: null,
        hasDetails: true,
        details: {
          info: {
            name: 'Buderus Logano GE515',
            HeatGenerators: 'Heizkessel',
            EnergySource: 'Gas',
            NominalHeatOutput: '510 kW',
            BoilerContents: '86 L',
            SerialNumber: '05622052-00-4090-0316',
          },
          tabs: ['info']
        }
      },
      {
        id: 33,
        textType: 'Boiler',
        componentNumber: 2,
        imgWebp: sensors_NiehlerGurtel.webp.s_33,
        imgPng: sensors_NiehlerGurtel.png.s_33,
        modbus: null,
        hasDetails: true,
        details: {
          info: {
            name: 'Buderus Logano GE515',
            HeatGenerators: 'Heizkessel',
            EnergySource: 'Gas',
            NominalHeatOutput: '510 kW',
            BoilerContents: '86 L',
            SerialNumber: '05622052-00-4090-0316',
          },
          tabs: ['info']
        }
      }
    ]
  }
};

export const PipesDetailsSettings = {
  '62061f10452e21129b8ca241': {
    header: 'circulation_pump_group',
    modbus: {
      pipes: [160, 161, 162, 163, 164, 165, 166],
      returnFlow: [232],
      storageTank: [152]
    },
    styles: {
      pipes: {
        color: '#6479B7',
        strokeDasharray: '0'
      },
      returnFlow: {
        color: '#FF797E',
        strokeDasharray: '0'
      },
      storageTank: {
        color: '#3671E9',
        strokeDasharray: '5, 3'
      }
    }
  },
  '62061f10452e21129b8ca242': {
    header: 'circulation_pump_group',
    modbus: {
      pipes: [160, 161, 162, 163, 164, 165, 166],
      returnFlow: [232],
      storageTank: [152]
    },
    styles: {
      pipes: {
        color: '#6479B7',
        strokeDasharray: '0'
      },
      returnFlow: {
        color: '#FF797E',
        strokeDasharray: '0'
      },
      storageTank: {
        color: '#3671E9',
        strokeDasharray: '5, 3'
      }
    }
  },
  '62f9f4293f62cee2df80a3ec': {
    header: 'circulation_pump_group',
    modbus: {
      pipes: [2, 3, 4, 5, 6, 7, 8, 9],
      returnFlow: [243],
      storageTank: [200]
    },
    styles: {
      pipes: {
        color: '#6479B7',
        strokeDasharray: '0'
      },
      returnFlow: {
        color: '#FF797E',
        strokeDasharray: '0'
      },
      storageTank: {
        color: '#3671E9',
        strokeDasharray: '5, 3'
      }
    }
  },
  '62e90c3e3b1685117c8fe1e1': {
    header: 'circulation_pump_group',
    modbus: {
      pipes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 20],
      returnFlow: [0],
      storageTank: [30, 31]
    },
    styles: {
      pipes: {
        color: '#6479B7',
        strokeDasharray: '0'
      },
      returnFlow: {
        color: '#FF797E',
        strokeDasharray: '0'
      },
      storageTank: {
        color: '#3671E9',
        strokeDasharray: '5, 3'
      }
    }
  },
  '631993e9580e73164e7b12b2': {
    header: 'circulation_pump_group',
    modbus: {
      pipes: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      returnFlow: [1],
      storageTank: [200]
    },
    styles: {
      pipes: {
        color: '#6479B7',
        strokeDasharray: '0'
      },
      returnFlow: {
        color: '#FF797E',
        strokeDasharray: '0'
      },
      storageTank: {
        color: '#3671E9',
        strokeDasharray: '5, 3'
      }
    }
  },
  '6319942f580e73164e7b12b3': {
    header: 'circulation_pump_group',
    modbus: {
      pipes: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
      returnFlow: [0],
      storageTank: [201]
    },
    styles: {
      pipes: {
        color: '#6479B7',
        strokeDasharray: '0'
      },
      returnFlow: {
        color: '#FF797E',
        strokeDasharray: '0'
      },
      storageTank: {
        color: '#3671E9',
        strokeDasharray: '5, 3'
      }
    }
  },
  '62e90c753b1685117c8fe1e2': {
    header: 'circulation_pump_group',
    modbus: {
      pipes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 30, 70],
      returnFlow: [51, 52],
      storageTank: [0]
    },
    styles: {
      pipes: {
        color: '#6479B7',
        strokeDasharray: '0'
      },
      returnFlow: {
        color: '#FF797E',
        strokeDasharray: '0'
      },
      storageTank: {
        color: '#3671E9',
        strokeDasharray: '5, 3'
      }
    }
  },
  '631f0690b6c762f01414a1da': {
    header: 'circulation_pump_group',
    modbus: {
      pipes: [134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156],
      returnFlow: [0],
      storageTank: [201]
    },
    styles: {
      pipes: {
        color: '#6479B7',
        strokeDasharray: '0'
      },
      returnFlow: {
        color: '#FF797E',
        strokeDasharray: '0'
      },
      storageTank: {
        color: '#3671E9',
        strokeDasharray: '5, 3'
      }
    }
  }
}

export const defaultPoints = [
  {
    type: "Feature",
    properties: {
      cluster: false,
      crimeId: "62061f10452e21129b8ca241",
      category: "property"
    },
    geometry: {
      type: "Point",
      coordinates: [8.124576182035836, 50.02926842822507]
    }
  },
  {
    type: "Feature",
    properties: {
      cluster: false,
      crimeId: "62061f10452e21129b8ca242",
      category: "property"
    },
    geometry: {
      type: "Point",
      coordinates: [8.285063970425599, 50.00791170829136]
    }
  }
]

export const languages = ['de', "en"]

export const disabledRedZone = {
  '62061f10452e21129b8ca241': [200, 212, 213, 233],
  '62061f10452e21129b8ca242': [200, 201, 212, 213]
}

const defaultPoints3d = [
  { modbus: 200, pointId: 'd2'},
  { modbus: 243, pointId: 'd3'},
  { modbus: 233, pointId: 'd5'},
  { modbus: 232, pointId: 'd6'},
  { modbus: 245, pointId: 'd7'},
  { modbus: 20, pointId: 'd1'},
  { modbus: 100, pointId: 'd4'},
];

const defaultDevices = [
  { modbus: 232, device_uid: '1121103023-865583041352971' },
  { modbus: 233, device_uid: '1121103023-865583041352971' },
  { modbus: 245, device_uid: '1121103023-865583041352971' },
  { modbus: 243, device_uid: '1121103023-865583041352971' },
  { modbus: 200, device_uid: '1121103023-865583041352971' },
];

const configColdWaterInletComponent = (config) => {
  return {
    id: config.id,
    textType: 'ColdWaterInlet',
    building: config.building || '',
    componentNumber: config.componentNumber || null,
    imgWebp: config.detailsImgWebp || sDefault.webp.fullPointOfEntry,
    imgPng: config.detailsImgPng || sDefault.png.fullPointOfEntry,
    modbus: config.modbus || null,
    hasDetails: true,
    details: {
      tabs: ['images', config.tabs || '']
    }
  }
}

const configBackwashFilterComponent = (config) => {
  return {
    id: config.id,
    textType: 'BackwashFilter',
    componentNumber: config.componentNumber || null,
    imgWebp: config.detailsImgWebp || [],
    imgPng: config.detailsImgPng || [],
    modbus: config.modbus || null,
    hasDetails: true,
    details: {
      info: {
        name: config.detailsInfo.name || '-',
        NominalDiameter: config.detailsInfo.nominalDiameter || '-',
        PressureLoss: config.detailsInfo.pressureLoss || '-',
        Flow: config.detailsInfo.flow || '-',
        SerialNumber: config.detailsInfo.serialNumber || '-'
      },
      tabs: ['info', 'washing_process', config.tabs || '']
    }
  }
}

const configBoilerComponent = (config) => {
  return {
    id: config.id,
    textType: 'Boiler',
    imgWebp: config.detailsImgWebp || [],
    imgPng: config.detailsImgPng || [],
    modbus: config.modbus || null,
    hasDetails: true,
    details: {
      info: {
        name: config.detailsInfo.name || '-',
        HeatGenerators: config.detailsInfo.heatGenerators || '-',
        EnergySource: config.detailsInfo.energySource || '-',
        NominalHeatOutput: config.detailsInfo.nominalHeatOutput || '-',
        BoilerContents: config.detailsInfo.boilerContents || '-',
        SerialNumber: config.detailsInfo.serialNumber || '-'
      },
      tabs: ['info', config.tabs || '']
    }
  }
}

const configBHKWComponent = (config) => {
  return {
    id: config.id,
    textType: 'BHKW',
    imgWebp: config.detailsImgWebp || [],
    imgPng: config.detailsImgPng || [],
    modbus: config.modbus || null,
    hasDetails: true,
    details: {
      info: {
        name: config.detailsInfo.name || '-',
        HeatGenerators: config.detailsInfo.heatGenerators || '-',
        EnergySource: config.detailsInfo.energySource || '-',
        NominalHeatOutput: config.detailsInfo.nominalHeatOutput || '-',
        BoilerContents: config.detailsInfo.boilerContents || '-',
        SerialNumber: config.detailsInfo.serialNumber || '-'
      },
      tabs: ['info', config.tabs || '']
    }
  }
}

const configStorageChargingCircuitComponent = (config) => {
  return {
    id: config.id,
    textType: 'StorageChargingCircuit',
    imgWebp: config.detailsImgWebp || [],
    imgPng: config.detailsImgPng || [],
    modbus: config.modbus || null,
    hasDetails: true,
    details: {
      info: {
        name: config.detailsInfo.name || '-',
        NominalDiameterFlow: config.detailsInfo.nominalDiameterFlow || '-',
        MaterialFlow: config.detailsInfo.materialFlow || '-',
        NominalDiameterReturn: config.detailsInfo.nominalDiameterReturn || '-',
        MaterialReturn: config.detailsInfo.materialReturn || '-',
        ControlType: config.detailsInfo.controlType || '-',
        HeatingSystem: config.detailsInfo.heatingSystem || '-'
      },
      tabs: ['info', config.tabs || '']
    }
  }
}

const configStorageChargingPumpComponent = (config) => {
  return {
    id: config.id,
    textType: 'StorageChargingPump',
    imgWebp: config.detailsImgWebp || sDefault.webp.fullPump,
    imgPng: config.detailsImgPng || sDefault.png.fullPump,
    modbus: config.modbus || null,
    hasDetails: true,
    details: {
      info: {
        name: config.detailsInfo.name || '-',
        NominalDiameter: config.detailsInfo.nominalDiameter || '-',
        RotationalSpeed: config.detailsInfo.rotationalSpeed || '-',
        CurrentConsumption: config.detailsInfo.currentConsumption || '-',
        HeadOfFunding: config.detailsInfo.headOfFunding || '-',
        InstallationLength: config.detailsInfo.installationLength || '-',
        SafetyClass: config.detailsInfo.safetyClass || '-',
        SerialNumber: config.detailsInfo.serialNumber || '-'
      },
      tabs: ['info', config.tabs || '']
    }
  }
}

const configHeatingCircuitComponent = (config) => {
  return {
    id: config.id,
    textType: 'HeatingCircuit',
    componentNumber: config.componentNumber || null,
    imgWebp: config.detailsImgWebp || [],
    imgPng: config.detailsImgPng || [],
    modbus: config.modbus || null,
    hasDetails: true,
    details: {
      info: {
        name: config.detailsInfo.name || '-',
        NominalDiameterFlow: config.detailsInfo.nominalDiameterFlow || '-',
        MaterialFlow: config.detailsInfo.materialFlow || '-',
        NominalDiameterReturn: config.detailsInfo.nominalDiameterReturn || '-',
        MaterialReturn: config.detailsInfo.materialReturn || '-',
        ControlType: config.detailsInfo.controlType || '-',
        HeatingSystem: config.detailsInfo.heatingSystem || '-'
      },
      tabs: ['info', config.tabs || '']
    }
  }
}

const configHeatExchangerComponent = (config) => {
  return {
    id: config.id,
    textType: 'HeatExchanger',
    imgWebp: config.detailsImgWebp || [],
    imgPng: config.detailsImgPng || [],
    modbus: config.modbus || null,
    hasDetails: true,
    details: {
      tabs: ['images', config.tabs || '']
    }
  }
}

const configStorageWaterHeaterComponent = (config) => {
  return {
    id: config.id,
    textType: 'StorageWaterHeater',
    componentNumber: config.componentNumber || null,
    imgWebp: config.detailsImgWebp || [],
    imgPng: config.detailsImgPng || [],
    modbus: typeof +config.modbus === "number" ? +config.modbus : config.modbus || null,
    hasDetails: true,
    details: {
      info: {
        name: config.detailsInfo.name || '-',
        StorageType: config.detailsInfo.storageType || '-',
        MemoryContent: config.detailsInfo.memoryContent || '-',
        HeatOutput: config.detailsInfo.heatOutput || '-',
        SerialNumber: config.detailsInfo.serialNumber || '-'
      },
      tabs: ['info', config.tabs || '']
    }
  }
}

const configBufferStorageComponent = (config) => {
  return {
    id: config.id,
    textType: 'BufferStarage',
    componentNumber: config.componentNumber || null,
    imgWebp: config.detailsImgWebp || [],
    imgPng: config.detailsImgPng || [],
    modbus: typeof +config.modbus === "number" ? +config.modbus : config.modbus || null,
    hasDetails: true,
    details: {
      info: {
        name: config.detailsInfo.name || '-',
        StorageType: config.detailsInfo.storageType || '-',
        MemoryContent: config.detailsInfo.memoryContent || '-',
        HeatOutput: config.detailsInfo.heatOutput || '-',
        SerialNumber: config.detailsInfo.serialNumber || '-'
      },
      tabs: ['info', config.tabs || '']
    }
  }
}

const configCirculationPumpComponent = (config) => {
  return {
    id: config.id,
    textType: 'CirculationPump',
    imgWebp: config.detailsImgWebp || sDefault.webp.fullPump,
    imgPng: config.detailsImgPng || sDefault.png.fullPump,
    modbus: config.modbus || null,
    hasDetails: true,
    details: {
      info: {
        name: config.detailsInfo.name || '-',
        NominalDiameter: config.detailsInfo.nominalDiameter || '-',
        RotationalSpeed: config.detailsInfo.rotationalSpeed || '-',
        CurrentConsumption: config.detailsInfo.currentConsumption || '-',
        HeadOfFunding: config.detailsInfo.headOfFunding || '-',
        InstallationLength: config.detailsInfo.installationLength || '-',
        SafetyClass: config.detailsInfo.safetyClass || '-',
        SerialNumber: config.detailsInfo.serialNumber || '-'
      },
      tabs: ['info', config.tabs || '']
    }
  }
}

const configHeatingCircuitPumpComponent = (config) => {
  return {
    id: config.id,
    textType: 'HeatingCircuitPump',
    componentNumber: config.componentNumber || null,
    imgWebp: config.detailsImgWebp || sDefault.webp.fullPump,
    imgPng: config.detailsImgPng || sDefault.png.fullPump,
    modbus: config.modbus || null,
    hasDetails: true,
    details: {
      info: {
        name: config.detailsInfo.name || '-',
        NominalDiameter: config.detailsInfo.nominalDiameter || '-',
        RotationalSpeed: config.detailsInfo.rotationalSpeed || '-',
        CurrentConsumption: config.detailsInfo.currentConsumption || '-',
        HeadOfFunding: config.detailsInfo.headOfFunding || '-',
        InstallationLength: config.detailsInfo.installationLength || '-',
        SafetyClass: config.detailsInfo.safetyClass || '-',
        SerialNumber: config.detailsInfo.serialNumber || '-'
      },
      tabs: ['info', config.tabs || '']
    }
  }
}

const configSewageComponent = (config) => {
  return {
    id: config.id,
    textType: 'Sewage',
    imgWebp: config.detailsImgWebp || [],
    imgPng: config.detailsImgPng || [],
    modbus: config.modbus || null,
    hasDetails: true,
    details: {
      tabs: ['images', config.tabs || '']
    }
  }
}

const configHydraulicSwitchComponent = (config) => {
  return {
    id: config.id,
    textType: 'HydraulicSwitch',
    imgWebp: config.detailsImgWebp || [],
    imgPng: config.detailsImgPng || [],
    modbus: config.modbus || null,
    hasDetails: true,
    details: {
      tabs: ['images', config.tabs || '']
    }
  }
}

const configDistrictHeatingComponent = (config) => {
  return {
    id: config.id,
    textType: 'DistrictHeating',
    imgWebp: config.detailsImgWebp || sDefault.webp.fullfernwarme,
    imgPng: config.detailsImgPng || sDefault.png.fullfernwarme,
    modbus: config.modbus || null,
    hasDetails: true,
    details: {
      tabs: ['images', config.tabs || '']
    }
  }
}

const configFreshWaterSystemComponent = (config) => {
  return {
    id: config.id,
    textType: 'FreshWaterStation',
    building: config.building || '',
    componentNumber: config.componentNumber || null,
    imgWebp: config.detailsImgWebp || [],
    imgPng: config.detailsImgPng || [],
    modbus: config.modbus || null,
    hasDetails: true,
    details: {
      info: {
        name: config.detailsInfo.name || '-',
        HeatGenerators: config.detailsInfo.heatGenerators || '-',
        EnergySource: config.detailsInfo.energySource || '-',
        Supply: config.detailsInfo.supply || '-',
        NominalHeatOutput: config.detailsInfo.nominalHeatOutput || '-',
        TemperatureSpread: config.detailsInfo.temperatureSpread || '-',
        PermittedPressure: config.detailsInfo.permittedPressure || '-',
        SerialNumber: config.detailsInfo.serialNumber || '-'
      },
      tabs: ['info', config.tabs || '']
    }
  }
}

const configDefaultComponent = (config) => {
  return {
    id: config.id,
    textType: config.textType,
    building: config.building || '',
    componentNumber: config.componentNumber || null,
    imgWebp: config.detailsImgWebp || [],
    imgPng: config.detailsImgPng || [],
    modbus: config.modbus || null,
    hasDetails: true,
    details: {
      tabs: ['images', config.tabs || '']
    }
  }
}


const defaultComponentsForNassauische = {
  id: 2,
  type: 'centralHeating',
  building: 'Heimatring 12',
  cards: [
    configColdWaterInletComponent({ id: 38, modbus: 245, tabs: 'live_date_paul', detailsImgWebp: main_Heimatring_images_12.webp.s_38, detailsImgPng: main_Heimatring_images_12.jpg.s_38 }),
    configBackwashFilterComponent({ id: 39, detailsImgWebp: main_Heimatring_images_12.webp.s_39, detailsImgPng: main_Heimatring_images_12.jpg.s_39, detailsInfo: { name: 'Judo JPF Qc', nominalDiameter: 'DN20', pressureLoss: '0.2 bar', flow: '13 - 16 m³/h', serialNumber: '8107013'} }),
    configStorageChargingCircuitComponent({ id: 40, modbus: { sensor_in: 233, sensor_out: 232 }, tabs: 'live_date_paul', detailsImgWebp: main_Heimatring_images_12.webp.s_40, detailsImgPng: main_Heimatring_images_12.jpg.s_40, detailsInfo: { name: 'Speicherladekreis 1', nominalDiameterFlow: 'DN80', materialFlow: 'Stahl', nominalDiameterReturn: 'DN80', materialReturn: 'Stahl', controlType: 'Parallel', heatingSystem: 'Heizung 1'}}),
    configDistrictHeatingComponent({ id: 41, modbus: { sensor_in: 233, sensor_out: 232 }, tabs: 'live_date_paul', detailsImgWebp: main_Heimatring_images_12.webp.s_41, detailsImgPng: main_Heimatring_images_12.jpg.s_41}),
    configHeatingCircuitComponent({ id: 42, componentNumber: 1, detailsImgWebp: main_Heimatring_images_12.webp.s_42, detailsImgPng: main_Heimatring_images_12.jpg.s_42, detailsInfo: { name: 'Heizkreis 1', nominalDiameterFlow: 'DN100', materialFlow: 'Stahl', nominalDiameterReturn: 'DN100', materialReturn: 'Stahl', controlType: 'Parallel', heatingSystem: 'Heizung 1'} }),
    configHeatingCircuitComponent({ id: 43, componentNumber: 2, detailsImgWebp: main_Heimatring_images_12.webp.s_43, detailsImgPng: main_Heimatring_images_12.jpg.s_43, detailsInfo: { name: 'Heizkreis 2', nominalDiameterFlow: 'DN100', materialFlow: 'Stahl', nominalDiameterReturn: 'DN100', materialReturn: 'Stahl', controlType: 'Parallel', heatingSystem: 'Heizung 1'} }),
    configHeatingCircuitComponent({ id: 44, componentNumber: 3, detailsImgWebp: main_Heimatring_images_12.webp.s_44, detailsImgPng: main_Heimatring_images_12.jpg.s_44, detailsInfo: { name: 'Heizkreis 3', nominalDiameterFlow: 'DN80', materialFlow: 'Stahl', nominalDiameterReturn: 'DN80', materialReturn: 'Stahl', controlType: 'Parallel', heatingSystem: 'Heizung 1'} }),
    configHeatingCircuitPumpComponent({ id: 45, componentNumber: 1, detailsImgWebp: main_Heimatring_images_12.webp.s_45, detailsImgPng: main_Heimatring_images_12.jpg.s_45, detailsInfo: { name: 'Grundfos UPED 80-120/F', nominalDiameter: 'DN80', rotationalSpeed: '50 Hz', currentConsumption: '0.27 … 2.56 A', headOfFunding: '120 dm', installationLength: '360mm', safetyClass: 'IP42', serialNumber: '96403133'} }),
    configHeatingCircuitPumpComponent({ id: 46, componentNumber: 2, detailsImgWebp: main_Heimatring_images_12.webp.s_46, detailsImgPng: main_Heimatring_images_12.jpg.s_46, detailsInfo: { name: 'Grundfos UPED 80-120/F',  nominalDiameter: 'DN80', rotationalSpeed: '50 Hz', currentConsumption: '0.27 … 2.56 A', headOfFunding: '120 dm', installationLength: '360mm', safetyClass: 'IP42', serialNumber: '96403133'} }),
    configHeatingCircuitPumpComponent({ id: 47, componentNumber: 3, detailsImgWebp: main_Heimatring_images_12.webp.s_47, detailsImgPng: main_Heimatring_images_12.jpg.s_47, detailsInfo: { name: 'Grundfos UPED 65-120/F',  nominalDiameter: 'DN65', rotationalSpeed: '50/60 Hz', currentConsumption: '0.3 … 6.18 A', headOfFunding: '150 dm', installationLength: '340mm', safetyClass: 'X4D', serialNumber: '96403133'} }),
    configStorageWaterHeaterComponent({ id: 48, modbus: 243, tabs: 'live_date_paul', detailsImgWebp: main_Heimatring_images_12.webp.s_48, detailsImgPng: main_Heimatring_images_12.jpg.s_48, detailsInfo: { name: 'Buderus Logalux SU1000', storageType: 'Warmwasserspeicher', memoryContent: '955 L', heatOutput: '111.8 kW', serialNumber: '77355008'} }),
    configCirculationPumpComponent({ id: 49, modbus: 200, tabs: 'live_date_paul', detailsImgWebp: main_Heimatring_images_12.webp.s_49, detailsImgPng: main_Heimatring_images_12.jpg.s_49, detailsInfo: { name: 'Grundfos Magna 3 65-150 FN 340', nominalDiameter: 'DN65', rotationalSpeed: '50/60 Hz', currentConsumption: '0.3 … 6.18 A', headOfFunding: '150 dm', installationLength: '340 mm', safetyClass: 'X4D', serialNumber: '10000097'} }),
    configSewageComponent({ id: 50, detailsImgWebp: main_Heimatring_images_12.webp.s_50, detailsImgPng: main_Heimatring_images_12.jpg.s_50 })
  ]
}

export const configPerformanceByProperty = {
  "62e90c3e3b1685117c8fe1e1": {
    buildings: [
      {
        number: "104",
        street: "Niehler Gürtel",
        config3d: {
          type: '8_NiehlerGurtel',
          devices: [
            { modbus: 30, pointId: 'd1'},
            { modbus: 31, pointId: 'd2'},
            { modbus: 0, pointId: 'd3'},
            { modbus: 1, pointId: 1},
            { modbus: 8, pointId: 2},
            { modbus: 16, pointId: 3},
            { modbus: 2, pointId: 4},
            { modbus: 11, pointId: 5},
            { modbus: 3, pointId: 6},
            { modbus: 4, pointId: 7},
            { modbus: 7, pointId: 8},
            { modbus: 10, pointId: 9},
            { modbus: 5, pointId: 10},
            { modbus: 20, pointId: 11},
            { modbus: 6, pointId: 12},
            { modbus: 14, pointId: 13},
            { modbus: 9, pointId: 14},
            { modbus: 13, pointId: 15},
            { modbus: 12, pointId: 16},
          ]
        },
        devices: [
          { modbus: 0, device_uid: '100.99.216.29-0' },
          { modbus: 30, device_uid: '100.99.216.29-30' },
          { modbus: 31, device_uid: '100.99.216.29-31' },
          { modbus: 1, device_uid: '100.99.216.29-1', name: [`Sensors.component.smartValve`, {n: 1}] },
          { modbus: 8, device_uid: '100.99.216.29-8', name: [`Sensors.component.smartValve`, {n: 2}] },
          { modbus: 16, device_uid: '100.99.216.29-16', name: [`Sensors.component.smartValve`, {n: 3}] },
          { modbus: 2, device_uid: '100.99.216.29-2', name: [`Sensors.component.smartValve`, {n: 4}] },
          { modbus: 11, device_uid: '100.99.216.29-11', name: [`Sensors.component.smartValve`, {n: 5}] },
          { modbus: 3, device_uid: '100.99.216.29-3', name: [`Sensors.component.smartValve`, {n: 6}] },
          { modbus: 4, device_uid: '100.99.216.29-4', name: [`Sensors.component.smartValve`, {n: 7}] },
          { modbus: 7, device_uid: '100.99.216.29-7', name: [`Sensors.component.smartValve`, {n: 8}] },
          { modbus: 10, device_uid: '100.99.216.29-10', name: [`Sensors.component.smartValve`, {n: 9}] },
          { modbus: 5, device_uid: '100.99.216.29-5', name: [`Sensors.component.smartValve`, {n: 10}] },
          { modbus: 20, device_uid: '100.99.216.29-20', name: [`Sensors.component.smartValve`, {n: 11}] },
          { modbus: 6, device_uid: '100.99.216.29-6', name: [`Sensors.component.smartValve`, {n: 12}] },
          { modbus: 14, device_uid: '100.99.216.29-14', name: [`Sensors.component.smartValve`, {n: 13}] },
          { modbus: 9, device_uid: '100.99.216.29-9', name: [`Sensors.component.smartValve`, {n: 14}] },
          { modbus: 13, device_uid: '100.99.216.29-13', name: [`Sensors.component.smartValve`, {n: 15}] },
          { modbus: 12, device_uid: '100.99.216.29-12', name: [`Sensors.component.smartValve`, {n: 16}] }
       ],
       startDate: "2022-01-01T00:00:00Z"
      },
    ]
  },
  "62e90c753b1685117c8fe1e2": {
    buildings: [
      {
        number: "1-9/10",
        street: "Charlottenburgerstraße 1-9 / Plötzenseerstraße 10",
        circulationGroup: 1,
        config3d: {
          type: '8_Heizkeise_4',
          devices: [
            { modbus: 0, pointId: 'd2'},
            { modbus: 51, pointId: 'd4'},
            { modbus: 1, pointId: 1},
            { modbus: 4, pointId: 2},
            { modbus: 2, pointId: 3},
            { modbus: 17, pointId: 4},
            { modbus: 3, pointId: 5},
            { modbus: 6, pointId: 6},
            { modbus: 7, pointId: 7},
            { modbus: 5, pointId: 8},
            { modbus: 13, pointId: 9},
            { modbus: 16, pointId: 10},
            { modbus: 14, pointId: 11},
            { modbus: 8, pointId: 12},
            { modbus: 9, pointId: 13},
            { modbus: 10, pointId: 14},
            { modbus: 15, pointId: 15}
          ]
        },
        devices: [
          { modbus: 0, device_uid: '100.99.216.61-0', house: "Charlottenburgerstraße 3" },
          { modbus: 51, device_uid: '100.99.216.61-51', house: "Charlottenburgerstraße 3" },
          { modbus: 1, device_uid: '100.99.216.61-1', name: [`Sensors.component.smartValve`, {n: 1}], house: "Charlottenburgerstraße 1" },
          { modbus: 4, device_uid: '100.99.216.61-4', name: [`Sensors.component.smartValve`, {n: 2}], house: "Charlottenburgerstraße 1" },
          { modbus: 2, device_uid: '100.99.216.61-2', name: [`Sensors.component.smartValve`, {n: 3}], house: "Charlottenburgerstraße 3" },
          { modbus: 17, device_uid: '100.99.216.61-17', name: [`Sensors.component.smartValve`, {n: 4}], house: "Charlottenburgerstraße 3" },
          { modbus: 3, device_uid: '100.99.216.61-3', name: [`Sensors.component.smartValve`, {n: 5}], house: "Charlottenburgerstraße 5" },
          { modbus: 6, device_uid: '100.99.216.61-6', name: [`Sensors.component.smartValve`, {n: 6}], house: "Charlottenburgerstraße 5" },
          { modbus: 7, device_uid: '100.99.216.61-7', name: [`Sensors.component.smartValve`, {n: 7}], house: "Charlottenburgerstraße 7" },
          { modbus: 5, device_uid: '100.99.216.61-5', name: [`Sensors.component.smartValve`, {n: 8}], house: "Charlottenburgerstraße 7" },
          { modbus: 13, device_uid: '100.99.216.61-13', name: [`Sensors.component.smartValve`, {n: 9}], house: "Charlottenburgerstraße 9" },
          { modbus: 16, device_uid: '100.99.216.61-16', name: [`Sensors.component.smartValve`, {n: 10}], house: "Charlottenburgerstraße 9" },
          { modbus: 14, device_uid: '100.99.216.61-14', name: [`Sensors.component.smartValve`, {n: 11}], house: "Plötzenseerstraße 10" },
          { modbus: 8, device_uid: '100.99.216.61-8', name: [`Sensors.component.smartValve`, {n: 12}], house: "Plötzenseerstraße 10" },
          { modbus: 9, device_uid: '100.99.216.61-9', name: [`Sensors.component.smartValve`, {n: 13}], house: "Plötzenseerstraße 10" },
          { modbus: 10, device_uid: '100.99.216.61-10', name: [`Sensors.component.smartValve`, {n: 14}], house: "Plötzenseerstraße 10" },
          { modbus: 15, device_uid: '100.99.216.61-15', name: [`Sensors.component.smartValve`, {n: 15}], house: "Plötzenseerstraße 10" }
       ],
       components: [
        {
          id: 1,
          type: 'components',
          building: '',
          cards: [
            configColdWaterInletComponent({ id: 123, detailsImgWebp: main_Charlottenburgerstrabe_images.webp.s_123, detailsImgPng: main_Charlottenburgerstrabe_images.jpg.s_123}),
            configBackwashFilterComponent({ id: 124, detailsInfo: { name: "Judo JRSF DB80"}, detailsImgWebp: main_Charlottenburgerstrabe_images.webp.s_124, detailsImgPng: main_Charlottenburgerstrabe_images.jpg.s_124}),
            configHeatingCircuitPumpComponent({ id: 125, componentNumber: 1, detailsInfo: { name: 'Grundfos Alpha 1 25-60 180' }, detailsImgWebp: main_Charlottenburgerstrabe_images.webp.s_125, detailsImgPng: main_Charlottenburgerstrabe_images.jpg.s_125}),
            configHeatingCircuitPumpComponent({ id: 126, componentNumber: 2, detailsInfo: { name: 'Grundfos UP 26-65' }, detailsImgWebp: main_Charlottenburgerstrabe_images.webp.s_126, detailsImgPng: main_Charlottenburgerstrabe_images.jpg.s_126}),
            configHeatingCircuitPumpComponent({ id: 127, componentNumber: 3, detailsInfo: { name: 'Grundfos UP 26-80 180' }, detailsImgWebp: main_Charlottenburgerstrabe_images.webp.s_127, detailsImgPng: main_Charlottenburgerstrabe_images.jpg.s_127}),
            configHeatingCircuitPumpComponent({ id: 128, componentNumber: 4, detailsInfo: { name: 'Grundfos Magna 25-80 180' }, detailsImgWebp: main_Charlottenburgerstrabe_images.webp.s_128, detailsImgPng: main_Charlottenburgerstrabe_images.jpg.s_128}),
            configDistrictHeatingComponent({ id: 129, detailsImgWebp: main_Charlottenburgerstrabe_images.webp.s_129, detailsImgPng: main_Charlottenburgerstrabe_images.jpg.s_129 }),
            configHeatExchangerComponent({ id: 130, detailsImgWebp: main_Charlottenburgerstrabe_images.webp.s_130, detailsImgPng: main_Charlottenburgerstrabe_images.jpg.s_130}),
            configCirculationPumpComponent({ id: 131, detailsInfo: { name: 'WILO Stratos 30/1 -8'}}),
            configStorageChargingPumpComponent({ id: 134, detailsInfo: { name: 'Grundfos UPS 32-80 N180'}, detailsImgWebp: main_Charlottenburgerstrabe_images.webp.s_134, detailsImgPng: main_Charlottenburgerstrabe_images.jpg.s_134}),
            configStorageWaterHeaterComponent({ id: 135, modbus: 51, tabs: 'live_date_paul', detailsInfo: { name: 'Allah laval Aquarium Tank 316 Ti -500/2', storageType: 'Speicherladung', memoryContent: '100 L', }, detailsImgWebp: main_Charlottenburgerstrabe_images.webp.s_135, detailsImgPng: main_Charlottenburgerstrabe_images.jpg.s_135})
          ]
        }
      ],
      startDate: "2022-01-01T00:00:00Z"
      },
      {
        number: "2-10",
        street: "Plötzenseerstraße 2-10",
        circulationGroup: 2,
        config3d: {
          type: '8_Heizkeise_3',
          devices: [
            { modbus: 0, pointId: 'd2'},
            { modbus: 52, pointId: 'd4'},
            { modbus: 5, pointId: 1},
            { modbus: 6, pointId: 2},
            { modbus: 12, pointId: 3},
            { modbus: 1, pointId: 4},
            { modbus: 3, pointId: 5},
            { modbus: 7, pointId: 6},
            { modbus: 13, pointId: 7},
            { modbus: 4, pointId: 8},
            { modbus: 10, pointId: 9},
            { modbus: 30, pointId: 10},
            { modbus: 15, pointId: 11},
            { modbus: 70, pointId: 12},
            { modbus: 11, pointId: 13},
            { modbus: 9, pointId: 14},
          ]
        },
        devices: [
          { modbus: 0, device_uid: '100.99.216.62-0', house: "Plötzenseerstraße 8" },
          { modbus: 52, device_uid: '100.99.216.62-52', house: "Plötzenseerstraße 8" },
          { modbus: 5, device_uid: '100.99.216.62-5', name: [`Sensors.component.smartValve`, {n: 1}], house: "Plötzenseerstraße 2" },
          { modbus: 6, device_uid: '100.99.216.62-6', name: [`Sensors.component.smartValve`, {n: 2}], house: "Plötzenseerstraße 2" },
          { modbus: 12, device_uid: '100.99.216.62-12', name: [`Sensors.component.smartValve`, {n: 3}], house: "Plötzenseerstraße 4" },
          { modbus: 1, device_uid: '100.99.216.62-1', name: [`Sensors.component.smartValve`, {n: 4}], house: "Plötzenseerstraße 4" },
          { modbus: 3, device_uid: '100.99.216.62-3', name: [`Sensors.component.smartValve`, {n: 5}], house: "Plötzenseerstraße 6" },
          { modbus: 7, device_uid: '100.99.216.62-7', name: [`Sensors.component.smartValve`, {n: 6}], house: "Plötzenseerstraße 6" },
          { modbus: 13, device_uid: '100.99.216.62-13', name: [`Sensors.component.smartValve`, {n: 7}], house: "Plötzenseerstraße 8" },
          { modbus: 4, device_uid: '100.99.216.62-4', name: [`Sensors.component.smartValve`, {n: 8}], house: "Plötzenseerstraße 8" },
          { modbus: 10, device_uid: '100.99.216.62-10', name: [`Sensors.component.smartValve`, {n: 9}], house: "Plötzenseerstraße 10" },
          { modbus: 30, device_uid: '100.99.216.62-30', name: [`Sensors.component.smartValve`, {n: 10}], house: "Plötzenseerstraße 10" },
          { modbus: 15, device_uid: '100.99.216.62-15', name: [`Sensors.component.smartValve`, {n: 11}], house: "Plötzenseerstraße 10" },
          { modbus: 70, device_uid: '100.99.216.62-70', name: [`Sensors.component.smartValve`, {n: 12}], house: "Plötzenseerstraße 10" },
          { modbus: 11, device_uid: '100.99.216.62-11', name: [`Sensors.component.smartValve`, {n: 13}], house: "Plötzenseerstraße 10" },
          { modbus: 9, device_uid: '100.99.216.62-9', name: [`Sensors.component.smartValve`, {n: 14}], house: "Plötzenseerstraße 10" }
       ],
       components: [
        {
          id: 2,
          type: 'components',
          building: '',
          cards: [
            configColdWaterInletComponent({ id: 136, componentNumber: 1, detailsImgWebp: main_Plotzenseerstrabe_images.webp.s_136, detailsImgPng: main_Plotzenseerstrabe_images.jpg.s_136}),
            configBackwashFilterComponent({ id: 138, componentNumber: 1, detailsInfo: { name: "Cornel Clean RD"}, detailsImgWebp: main_Plotzenseerstrabe_images.webp.s_138, detailsImgPng: main_Plotzenseerstrabe_images.jpg.s_138}),
            configStorageChargingPumpComponent({ id: 143, detailsInfo: { name: 'Grundfos UPS O 25-55N180'}, detailsImgWebp: main_Plotzenseerstrabe_images.webp.s_143, detailsImgPng: main_Plotzenseerstrabe_images.jpg.s_143}),
            configStorageWaterHeaterComponent({ id: 144, modbus: 52, tabs: 'live_date_paul', detailsInfo: { name: 'Allah laval Aquarium Tank 316 Ti 500', storageType: 'Speicherladung', memoryContent: '1000 L', }, detailsImgWebp: main_Plotzenseerstrabe_images.webp.s_144, detailsImgPng: main_Plotzenseerstrabe_images.jpg.s_144}),
            configCirculationPumpComponent({ id: 145, detailsInfo: { name: 'Grundfos UPS 32-80 N180'}, detailsImgWebp: main_Plotzenseerstrabe_images.webp.s_145, detailsImgPng: main_Plotzenseerstrabe_images.jpg.s_145 }),
            configStorageChargingPumpComponent({ id: 132, detailsInfo: { name: 'Grundfos UPS O 25-55N180'}}),
            configHeatingCircuitPumpComponent({ id: 137, componentNumber: 1, detailsInfo: { name: 'Grundfos Magna 25-100 180' }}),
            configHeatingCircuitPumpComponent({ id: 140, componentNumber: 2, detailsInfo: { name: 'Grundfos Magna 25-60 180' }}),
            configHeatingCircuitPumpComponent({ id: 141, componentNumber: 3, detailsInfo: { name: 'Grundfos Magna 25-100 180' }}),
            configDistrictHeatingComponent({ id: 139 })
          ] 
        }
      ],
      startDate: "2022-01-01T00:00:00Z"
      },
    ]
  },
  "62f9f4293f62cee2df80a3ec": {
    buildings: [
      {
        number: "1-2",
        street: "Unter den Akazien",
        config3d: {
          type: '6_nofilter',
          devices: [
            ...defaultPoints3d,
            { modbus: 2, pointId: 1},
            { modbus: 3, pointId: 2},
            { modbus: 4, pointId: 3},
            { modbus: 5, pointId: 4},
            { modbus: 6, pointId: 5},
            { modbus: 7, pointId: 6},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1121102217-865583041310326', name: [`Sensors.component.hotWater`], house: 2 },
          { modbus: 20, device_uid: '1121102217-865583041310326', name: [`Sensors.component.balancingValve`], house: 2 },
          { modbus: 2, device_uid: '1120452186-865583041294793', name: [`Sensors.component.smartValve`, {n: 1}], house: 1 },
          { modbus: 3, device_uid: '1120452186-865583041294793', name: [`Sensors.component.smartValve`, {n: 2}], house: 1 },
          { modbus: 4, device_uid: '1120452186-865583041294793', name: [`Sensors.component.smartValve`, {n: 3}], house: 1 },
          { modbus: 5, device_uid: '1120452186-865583041294793', name: [`Sensors.component.smartValve`, {n: 4}], house: 1 },
          { modbus: 6, device_uid: '1120452186-865583041294793', name: [`Sensors.component.smartValve`, {n: 5}], house: 1 },
          { modbus: 7, device_uid: '1120452186-865583041294793', name: [`Sensors.component.smartValve`, {n: 6}], house: 1 },
        ],
        components: [
          defaultComponentsForNassauische
        ],
        startDate: "2022-08-04T00:00:00Z"
      },
      {
        number: "3-4",
        street: "Unter den Akazien",
        config3d: {
          type: '5_nofilter',
          devices: [
            ...defaultPoints3d,
            { modbus: 2, pointId: 1},
            { modbus: 3, pointId: 2},
            { modbus: 6, pointId: 3},
            { modbus: 4, pointId: 4},
            { modbus: 5, pointId: 5},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1121103788-865583041321828', name: [`Sensors.component.hotWater`], house: 3 },
          { modbus: 20, device_uid: '1121103788-865583041321828', name: [`Sensors.component.balancingValve`], house: 3 },
          { modbus: 2, device_uid: '1121103788-865583041321828', name: [`Sensors.component.smartValve`, {n: 1}], house: 3 },
          { modbus: 3, device_uid: '1121103788-865583041321828', name: [`Sensors.component.smartValve`, {n: 2}], house: 3 },
          { modbus: 6, device_uid: '1120452036-865583041297101', name: [`Sensors.component.smartValve`, {n: 3}], house: 3 },
          { modbus: 4, device_uid: '1121103788-865583041321828', name: [`Sensors.component.smartValve`, {n: 4}], house: 4 },
          { modbus: 5, device_uid: '1121103788-865583041321828', name: [`Sensors.component.smartValve`, {n: 5}], house: 4 },
        ],
        components: [
          {
            id: 1,
            type: 'drinkingWaterSystem',
            building: 'Unter den Akazien 3-4',
            cards: [
              configColdWaterInletComponent({ id: 52, detailsImgWebp: main_Anter_den_Akazien_images_3_4.webp.s_52, detailsImgPng: main_Anter_den_Akazien_images_3_4.jpg.s_52 }),
            ]
          },
          defaultComponentsForNassauische
        ],
        startDate: "2022-08-04T00:00:00Z"
      },
      {
        number: "5-6",
        street: "Unter den Akazien",
        config3d: {
          type: '4_nofilter',
          devices: [
            ...defaultPoints3d,
            { modbus: 2, pointId: 1},
            { modbus: 3, pointId: 2},
            { modbus: 4, pointId: 3},
            { modbus: 5, pointId: 4},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1120452304-865583041277624', name: [`Sensors.component.hotWater`], house: 5 },
          { modbus: 20, device_uid: '1120452304-865583041277624', name: [`Sensors.component.balancingValve`], house: 5 },
          { modbus: 2, device_uid: '1120452304-865583041277624', name: [`Sensors.component.smartValve`, {n: 1}], house: 5 },
          { modbus: 3, device_uid: '1120452304-865583041277624', name: [`Sensors.component.smartValve`, {n: 2}], house: 5 },
          { modbus: 4, device_uid: '1120452304-865583041277624', name: [`Sensors.component.smartValve`, {n: 3}], house: 6 },
          { modbus: 5, device_uid: '1120452304-865583041277624', name: [`Sensors.component.smartValve`, {n: 4}], house: 6 },
       ],
       components: [
        defaultComponentsForNassauische
       ],
       startDate: "2022-06-18T00:00:00Z"
      },
      {
        number: "7-9",
        street: "Unter den Akazien",
        config3d: {
          type: '6_nofilter',
          devices: [
            ...defaultPoints3d,
            { modbus: 6, pointId: 1},
            { modbus: 7, pointId: 2},
            { modbus: 4, pointId: 3},
            { modbus: 5, pointId: 4},
            { modbus: 2, pointId: 5},
            { modbus: 3, pointId: 6},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1120452499-865583041298893', name: [`Sensors.component.hotWater`], house: 8 },
          { modbus: 20, device_uid: '1120452499-865583041298893', name: [`Sensors.component.balancingValve`], house: 8 },
          { modbus: 6, device_uid: '1120452499-865583041298893', name: [`Sensors.component.smartValve`, {n: 1}], house: 7 },
          { modbus: 7, device_uid: '1120452499-865583041298893', name: [`Sensors.component.smartValve`, {n: 2}], house: 7 },
          { modbus: 4, device_uid: '1120452499-865583041298893', name: [`Sensors.component.smartValve`, {n: 3}], house: 8 },
          { modbus: 5, device_uid: '1120452499-865583041298893', name: [`Sensors.component.smartValve`, {n: 4}], house: 8 },
          { modbus: 2, device_uid: '1120452499-865583041298893', name: [`Sensors.component.smartValve`, {n: 5}], house: 9 },
          { modbus: 3, device_uid: '1120452499-865583041298893', name: [`Sensors.component.smartValve`, {n: 6}], house: 9 },
       ],
       components: [
        {
          id: 1,
          type: 'drinkingWaterSystem',
          building: 'Unter den Akazien 7-9',
          cards: [
            configColdWaterInletComponent({ id: 54, detailsImgWebp: main_Anter_den_Akazien_images_7_9.webp.s_54, detailsImgPng: main_Anter_den_Akazien_images_7_9.jpg.s_54 }),
          ]
        },
        defaultComponentsForNassauische
       ],
       startDate: "2022-06-08T00:00:00Z"
      },
      {
        number: "10-12",
        street: "Unter den Akazien",
        config3d: {
          type: '6_filter',
          devices: [
            ...defaultPoints3d,
            { modbus: 6, pointId: 1},
            { modbus: 7, pointId: 2},
            { modbus: 4, pointId: 3},
            { modbus: 5, pointId: 4},
            { modbus: 2, pointId: 5},
            { modbus: 3, pointId: 6},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1121103027-865583041348839', name: [`Sensors.component.hotWater`], house: 11 },
          { modbus: 20, device_uid: '1121103027-865583041348839', name: [`Sensors.component.balancingValve`], house: 11 },
          { modbus: 6, device_uid: '1121103027-865583041348839', name: [`Sensors.component.smartValve`, {n: 1}], house: 10 },
          { modbus: 7, device_uid: '1121103027-865583041348839', name: [`Sensors.component.smartValve`, {n: 2}], house: 10 },
          { modbus: 4, device_uid: '1121103027-865583041348839', name: [`Sensors.component.smartValve`, {n: 3}], house: 11 },
          { modbus: 5, device_uid: '1121103027-865583041348839', name: [`Sensors.component.smartValve`, {n: 4}], house: 11 },
          { modbus: 2, device_uid: '1121103027-865583041348839', name: [`Sensors.component.smartValve`, {n: 5}], house: 12 },
          { modbus: 3, device_uid: '1121103027-865583041348839', name: [`Sensors.component.smartValve`, {n: 6}], house: 12 },
       ],
       components: [
        {
          id: 1,
          type: 'drinkingWaterSystem',
          building: 'Unter den Akazien 10-12',
          cards: [
            configColdWaterInletComponent({ id: 55, detailsImgWebp: main_Anter_den_Akazien_images_10_12.webp.s_55, detailsImgPng: main_Anter_den_Akazien_images_10_12.jpg.s_55 }),
            configBackwashFilterComponent({ id: 56, detailsImgWebp: main_Anter_den_Akazien_images_10_12.webp.s_56, detailsImgPng: main_Anter_den_Akazien_images_10_12.jpg.s_56, detailsInfo: { name: "Conel Clear KG"}}),
          ]
        },
        defaultComponentsForNassauische
       ],
       startDate: "2022-05-25T00:00:00Z"
      },
      {
        number: "13-15",
        street: "Unter den Akazien",
        config3d: {
          type: '6_nofilter',
          devices: [
            ...defaultPoints3d,
            { modbus: 6, pointId: 1},
            { modbus: 7, pointId: 2},
            { modbus: 4, pointId: 3},
            { modbus: 5, pointId: 4},
            { modbus: 2, pointId: 5},
            { modbus: 3, pointId: 6},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1120452306-865583041309997', name: [`Sensors.component.hotWater`], house: 13 },
          { modbus: 20, device_uid: '1120452306-865583041309997', name: [`Sensors.component.balancingValve`], house: 13 },
          { modbus: 6, device_uid: '1120452306-865583041309997', name: [`Sensors.component.smartValve`, {n: 1}], house: 13 },
          { modbus: 7, device_uid: '1120452306-865583041309997', name: [`Sensors.component.smartValve`, {n: 2}], house: 13 },
          { modbus: 4, device_uid: '1118227466-865583041251652', name: [`Sensors.component.smartValve`, {n: 3}], house: 14 },
          { modbus: 5, device_uid: '1118227466-865583041251652', name: [`Sensors.component.smartValve`, {n: 4}], house: 14 },
          { modbus: 2, device_uid: '1118227463-865583041251645', name: [`Sensors.component.smartValve`, {n: 5}], house: 15 },
          { modbus: 3, device_uid: '1118227463-865583041251645', name: [`Sensors.component.smartValve`, {n: 6}], house: 15 },
       ],
       components: [
        defaultComponentsForNassauische
       ],
       startDate: "2022-06-08T00:00:00Z"
      },
      {
        number: "1",
        street: "Unter den Platanen",
        config3d: {
          type: '4_nofilter',
          devices: [
            ...defaultPoints3d,
            { modbus: 2, pointId: 1},
            { modbus: 3, pointId: 2},
            { modbus: 4, pointId: 3},
            { modbus: 5, pointId: 4},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1120452299-865583041309922', name: [`Sensors.component.hotWater`], house: 1 },
          { modbus: 20, device_uid: '1120452299-865583041309922', name: [`Sensors.component.balancingValve`], house: 1 },
          { modbus: 2, device_uid: '1120452299-865583041309922', name: [`Sensors.component.smartValve`, {n: 1}], house: 1 },
          { modbus: 3, device_uid: '1120452299-865583041309922', name: [`Sensors.component.smartValve`, {n: 2}], house: 1 },
          { modbus: 4, device_uid: '1120452299-865583041309922', name: [`Sensors.component.smartValve`, {n: 3}], house: 1 },
          { modbus: 5, device_uid: '1120452299-865583041309922', name: [`Sensors.component.smartValve`, {n: 4}], house: 1 },
       ],
       components: [
        {
          id: 1,
          type: 'drinkingWaterSystem',
          building: 'Unter den Platanen 1',
          cards: [
            configColdWaterInletComponent({ id: 58, detailsImgWebp: main_Anter_den_Platanen_images_1.webp.s_58, detailsImgPng: main_Anter_den_Platanen_images_1.jpg.s_58 })
          ]
        },
        defaultComponentsForNassauische
       ],
       startDate: "2022-04-27T00:00:00Z"
      },
      {
        number: "2",
        street: "Unter den Platanen",
        config3d: {
          type: '4_nofilter',
          devices: [
            ...defaultPoints3d,
            { modbus: 2, pointId: 1},
            { modbus: 3, pointId: 2},
            { modbus: 4, pointId: 3},
            { modbus: 5, pointId: 4},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1119720743-865583041347989', name: [`Sensors.component.hotWater`], house: 2 },
          { modbus: 20, device_uid: '1119720743-865583041347989', name: [`Sensors.component.balancingValve`], house: 2 },
          { modbus: 2, device_uid: '1119720743-865583041347989', name: [`Sensors.component.smartValve`, {n: 1}], house: 2 },
          { modbus: 3, device_uid: '1119720743-865583041347989', name: [`Sensors.component.smartValve`, {n: 2}], house: 2 },
          { modbus: 4, device_uid: '1119720743-865583041347989', name: [`Sensors.component.smartValve`, {n: 3}], house: 2 },
          { modbus: 5, device_uid: '1119720743-865583041347989', name: [`Sensors.component.smartValve`, {n: 4}], house: 2 },
       ],
       components: [
        defaultComponentsForNassauische
       ],
       startDate: "2022-04-26T00:00:00Z"
      },
      {
        number: "3-4",
        street: "Unter den Platanen",
        config3d: {
          type: '5_nofilter',
          devices: [
            ...defaultPoints3d,
            { modbus: 2, pointId: 1},
            { modbus: 3, pointId: 2},
            { modbus: 6, pointId: 3},
            { modbus: 4, pointId: 4},
            { modbus: 5, pointId: 5},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1120452244-865583041302315', name: [`Sensors.component.hotWater`], house: 3 },
          { modbus: 20, device_uid: '1120452244-865583041302315', name: [`Sensors.component.balancingValve`], house: 3 },
          { modbus: 2, device_uid: '1120452244-865583041302315', name: [`Sensors.component.smartValve`, {n: 1}], house: 3 },
          { modbus: 3, device_uid: '1120452244-865583041302315', name: [`Sensors.component.smartValve`, {n: 2}], house: 3 },
          { modbus: 6, device_uid: '1120452244-865583041302315', name: [`Sensors.component.smartValve`, {n: 3}], house: 3 },
          { modbus: 4, device_uid: '1120452244-865583041302315', name: [`Sensors.component.smartValve`, {n: 4}], house: 4 },
          { modbus: 5, device_uid: '1120452244-865583041302315', name: [`Sensors.component.smartValve`, {n: 5}], house: 4 },
       ],
       components: [
        defaultComponentsForNassauische
       ],
       startDate: "2022-05-24T00:00:00Z"
      },
      {
        number: "5-6",
        street: "Unter den Platanen",
        config3d: {
          type: '4_nofilter',
          devices: [
            ...defaultPoints3d,
            { modbus: 4, pointId: 1},
            { modbus: 5, pointId: 2},
            { modbus: 2, pointId: 3},
            { modbus: 3, pointId: 4},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1121100895-865583041291930', name: [`Sensors.component.hotWater`], house: 5 },
          { modbus: 20, device_uid: '1121100895-865583041291930', name: [`Sensors.component.balancingValve`], house: 5 },
          { modbus: 4, device_uid: '1121100895-865583041291930', name: [`Sensors.component.smartValve`, {n: 1}], house: 5 },
          { modbus: 5, device_uid: '1121100895-865583041291930', name: [`Sensors.component.smartValve`, {n: 2}], house: 5 },
          { modbus: 2, device_uid: '1121100895-865583041291930', name: [`Sensors.component.smartValve`, {n: 3}], house: 6 },
          { modbus: 3, device_uid: '1121100895-865583041291930', name: [`Sensors.component.smartValve`, {n: 4}], house: 6 },
       ],
       components: [
        defaultComponentsForNassauische
       ],
       startDate: "2022-05-18T00:00:00Z"
      },
      {
        number: "7-9",
        street: "Unter den Platanen",
        config3d: {
          type: '6_nofilter',
          devices: [
            ...defaultPoints3d,
            { modbus: 6, pointId: 1},
            { modbus: 7, pointId: 2},
            { modbus: 4, pointId: 3},
            { modbus: 5, pointId: 4},
            { modbus: 2, pointId: 5},
            { modbus: 3, pointId: 6},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1120452051-865583041300368', name: [`Sensors.component.hotWater`], house: 8 },
          { modbus: 20, device_uid: '1120452051-865583041300368', name: [`Sensors.component.balancingValve`], house: 8 },
          { modbus: 6, device_uid: '1120452051-865583041300368', name: [`Sensors.component.smartValve`, {n: 1}], house: 7 },
          { modbus: 7, device_uid: '1120452051-865583041300368', name: [`Sensors.component.smartValve`, {n: 2}], house: 7 },
          { modbus: 4, device_uid: '1120452051-865583041300368', name: [`Sensors.component.smartValve`, {n: 3}], house: 8 },
          { modbus: 5, device_uid: '1120452051-865583041300368', name: [`Sensors.component.smartValve`, {n: 4}], house: 8 },
          { modbus: 2, device_uid: '1120452051-865583041300368', name: [`Sensors.component.smartValve`, {n: 5}], house: 9 },
          { modbus: 3, device_uid: '1120452051-865583041300368', name: [`Sensors.component.smartValve`, {n: 6}], house: 9 },
       ],
       components: [
        {
          id: 1,
          type: 'drinkingWaterSystem',
          building: 'Unter den Platanen 7-9',
          cards: [
            configColdWaterInletComponent({ id: 62, detailsImgWebp: main_Anter_den_Platanen_images_7_9.webp.s_62, detailsImgPng: main_Anter_den_Platanen_images_7_9.jpg.s_62 })
          ]
        },
        defaultComponentsForNassauische
       ],
       startDate: "2022-05-18T00:00:00Z"
      },
      {
        number: "10-12",
        street: "Unter den Platanen",
        config3d: {
          type: '6_nofilter',
          devices: [
            ...defaultPoints3d,
            { modbus: 6, pointId: 1},
            { modbus: 7, pointId: 2},
            { modbus: 4, pointId: 3},
            { modbus: 5, pointId: 4},
            { modbus: 2, pointId: 5},
            { modbus: 3, pointId: 6},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1120452502-865583041298943', name: [`Sensors.component.hotWater`], house: 11 },
          { modbus: 20, device_uid: '1120452502-865583041298943', name: [`Sensors.component.balancingValve`], house: 11 },
          { modbus: 6, device_uid: '1120452502-865583041298943', name: [`Sensors.component.smartValve`, {n: 1}], house: 10 },
          { modbus: 7, device_uid: '1120452502-865583041298943', name: [`Sensors.component.smartValve`, {n: 2}], house: 10 },
          { modbus: 4, device_uid: '1120452502-865583041298943', name: [`Sensors.component.smartValve`, {n: 3}], house: 11 },
          { modbus: 5, device_uid: '1120452502-865583041298943', name: [`Sensors.component.smartValve`, {n: 4}], house: 11 },
          { modbus: 2, device_uid: '1120452502-865583041298943', name: [`Sensors.component.smartValve`, {n: 5}], house: 12 },
          { modbus: 3, device_uid: '1120452502-865583041298943', name: [`Sensors.component.smartValve`, {n: 6}], house: 12 },
       ],
       components: [
        defaultComponentsForNassauische
       ],
       startDate: "2022-05-13T00:00:00Z"
      },
      {
        number: "13-16",
        street: "Unter den Platanen",
        config3d: {
          type: '8_filter',
          devices: [
            ...defaultPoints3d,
            { modbus: 6, pointId: 1, device_uid: '1120452187-865583041302042'},
            { modbus: 8, pointId: 2},
            { modbus: 2, pointId: 3},
            { modbus: 3, pointId: 4},
            { modbus: 7, pointId: 5},
            { modbus: 4, pointId: 6},
            { modbus: 5, pointId: 7},
            { modbus: 6, pointId: 8, device_uid: '1118228773-865583041219774'},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1120452187-865583041302042', name: [`Sensors.component.hotWater`], house: 14 },
          { modbus: 20, device_uid: '1120452187-865583041302042', name: [`Sensors.component.balancingValve`], house: 14 },
          { modbus: 6, device_uid: '1120452187-865583041302042', name: [`Sensors.component.smartValve`, {n: 1}], house: 13 },
          { modbus: 8, device_uid: '1120452187-865583041302042', name: [`Sensors.component.smartValve`, {n: 2}], house: 13 },
          { modbus: 2, device_uid: '1120452187-865583041302042', name: [`Sensors.component.smartValve`, {n: 3}], house: 14 },
          { modbus: 3, device_uid: '1120452187-865583041302042', name: [`Sensors.component.smartValve`, {n: 4}], house: 14 },
          { modbus: 7, device_uid: '1120452187-865583041302042', name: [`Sensors.component.smartValve`, {n: 5}], house: 15 },
          { modbus: 4, device_uid: '1120452187-865583041302042', name: [`Sensors.component.smartValve`, {n: 6}], house: 15 },
          { modbus: 5, device_uid: '1118228773-865583041219774', name: [`Sensors.component.smartValve`, {n: 7}], house: 16 },
          { modbus: 6, device_uid: '1118228773-865583041219774', name: [`Sensors.component.smartValve`, {n: 8}], house: 16 },
       ],
       components: [
        {
          id: 1,
          type: 'drinkingWaterSystem',
          building: 'Unter den Platanen 13-16',
          cards: [
            configColdWaterInletComponent({ id: 63, detailsImgWebp: main_Anter_den_Platanen_images_13_16.webp.s_63, detailsImgPng: main_Anter_den_Platanen_images_13_16.jpg.s_63 }),
            configColdWaterInletComponent({ id: 64, detailsImgWebp: main_Anter_den_Platanen_images_13_16.webp.s_64, detailsImgPng: main_Anter_den_Platanen_images_13_16.jpg.s_64 }),
            configBackwashFilterComponent({ id: 65, detailsImgWebp: main_Anter_den_Platanen_images_13_16.webp.s_65, detailsImgPng: main_Anter_den_Platanen_images_13_16.jpg.s_65, detailsInfo: { name: "Judo JPM-QC"}})
          ]
        },
        defaultComponentsForNassauische
       ],
       startDate: "2022-05-13T00:00:00Z"
      },
      {
        number: "8-9",
        street: "Heimatring",
        config3d: {
          type: '4_nofilter',
          devices: [
            ...defaultPoints3d,
            { modbus: 2, pointId: 1},
            { modbus: 3, pointId: 2},
            { modbus: 4, pointId: 3},
            { modbus: 5, pointId: 4},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1121101671-865583041316372', name: [`Sensors.component.hotWater`], house: 8 },
          { modbus: 20, device_uid: '1121101671-865583041316372', name: [`Sensors.component.balancingValve`], house: 8 },
          { modbus: 2, device_uid: '1121101671-865583041316372', name: [`Sensors.component.smartValve`, {n: 1}], house: 8 },
          { modbus: 3, device_uid: '1121101671-865583041316372', name: [`Sensors.component.smartValve`, {n: 2}], house: 8 },
          { modbus: 4, device_uid: '1121101671-865583041316372', name: [`Sensors.component.smartValve`, {n: 3}], house: 9 },
          { modbus: 5, device_uid: '1121101671-865583041316372', name: [`Sensors.component.smartValve`, {n: 4}], house: 9 },
       ],
       components: [
        {
          id: 1,
          type: 'drinkingWaterSystem',
          building: 'Heimatring 8-9',
          cards: [
            configColdWaterInletComponent({ id: 66, detailsImgWebp: main_Heimatring_images_8_9.webp.s_66, detailsImgPng: main_Heimatring_images_8_9.jpg.s_66 }),
          ]
        },
        defaultComponentsForNassauische
       ],
       startDate: "2022-07-08T00:00:00Z"
      },
      {
        number: "10-11",
        street: "Heimatring",
        config3d: {
          type: '4_filter',
          devices: [
            ...defaultPoints3d,
            { modbus: 2, pointId: 1},
            { modbus: 3, pointId: 2},
            { modbus: 4, pointId: 3},
            { modbus: 5, pointId: 4},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1121100898-865583041291807', name: [`Sensors.component.hotWater`], house: 10 },
          { modbus: 20, device_uid: '1121100898-865583041291807', name: [`Sensors.component.balancingValve`], house: 10 },
          { modbus: 2, device_uid: '1121100898-865583041291807', name: [`Sensors.component.smartValve`, {n: 1}], house: 10 },
          { modbus: 3, device_uid: '1121100898-865583041291807', name: [`Sensors.component.smartValve`, {n: 2}], house: 10 },
          { modbus: 4, device_uid: '1121100898-865583041291807', name: [`Sensors.component.smartValve`, {n: 3}], house: 11 },
          { modbus: 5, device_uid: '1121100898-865583041291807', name: [`Sensors.component.smartValve`, {n: 4}], house: 11 },
       ],
       components: [
        {
          id: 1,
          type: 'drinkingWaterSystem',
          building: 'Heimatring 10-11',
          cards: [
            configBackwashFilterComponent({ id: 68, building: 'Heimatring 10', detailsImgWebp: main_Heimatring_images_10_11.webp.s_68, detailsImgPng: main_Heimatring_images_10_11.jpg.s_68, detailsInfo: { name: "Judo JPF QC"}}),
          ]
        },
        defaultComponentsForNassauische
       ],
       startDate: "2022-07-08T00:00:00Z"
      },
      {
        number: "12-14",
        street: "Heimatring",
        config3d: {
          type: '8_nofilter',
          devices: [
            ...defaultPoints3d,
            { modbus: 2, pointId: 1, device_uid: "1118228777-865583041214940" },
            { modbus: 3, pointId: 2, device_uid: "1118228777-865583041214940" },
            { modbus: 2, pointId: 3, device_uid: "1120452021-865583041314732" },
            { modbus: 3, pointId: 4, device_uid: "1120452021-865583041314732" },
            { modbus: 4, pointId: 5},
            { modbus: 5, pointId: 6},
            { modbus: 6, pointId: 7},
            { modbus: 7, pointId: 8},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1118228777-865583041214940', name: [`Sensors.component.hotWater`], house: 12 },
          { modbus: 20, device_uid: '1118228777-865583041214940', name: [`Sensors.component.balancingValve`], house: 12 },
          { modbus: 2, device_uid: '1118228777-865583041214940', name: [`Sensors.component.smartValve`, {n: 1}], house: 12 },
          { modbus: 3, device_uid: '1118228777-865583041214940', name: [`Sensors.component.smartValve`, {n: 2}], house: 12 },
          { modbus: 2, device_uid: '1120452021-865583041314732', name: [`Sensors.component.smartValve`, {n: 3}], house: 13 },
          { modbus: 3, device_uid: '1120452021-865583041314732', name: [`Sensors.component.smartValve`, {n: 4}], house: 13 },
          { modbus: 4, device_uid: '1120452021-865583041314732', name: [`Sensors.component.smartValve`, {n: 5}], house: 13 },
          { modbus: 5, device_uid: '1120452021-865583041314732', name: [`Sensors.component.smartValve`, {n: 6}], house: 14 },
          { modbus: 6, device_uid: '1120452021-865583041314732', name: [`Sensors.component.smartValve`, {n: 7}], house: 14 },
          { modbus: 7, device_uid: '1120452021-865583041314732', name: [`Sensors.component.smartValve`, {n: 8}], house: 14 },
       ],
       components: [
        {
          id: 1,
          type: 'drinkingWaterSystem',
          building: 'Heimatring 12-14',
          cards: [
            configColdWaterInletComponent({ id: 69, detailsImgWebp: main_Heimatring_images_12_14.webp.s_69, detailsImgPng: main_Heimatring_images_12_14.jpg.s_69 })
          ]
        },
        defaultComponentsForNassauische
       ],
       startDate: "2022-04-28T00:00:00Z"
      },
      {
        number: "15-18",
        street: "Heimatring",
        config3d: {
          type: '8_nofilter',
          devices: [
            ...defaultPoints3d,
            { modbus: 2, pointId: 1, device_uid: '1121102219-865583041307884'},
            { modbus: 3, pointId: 2, device_uid: '1121102219-865583041307884'},
            { modbus: 4, pointId: 3, device_uid: '1121102219-865583041307884'},
            { modbus: 9, pointId: 4, device_uid: '1121102219-865583041307884'},
            { modbus: 5, pointId: 5, device_uid: '1121102219-865583041307884'},
            { modbus: 6, pointId: 6, device_uid: '1121102219-865583041307884'},
            { modbus: 7, pointId: 7, device_uid: '1121102219-865583041307884'},
            { modbus: 8, pointId: 8, device_uid: '1121102219-865583041307884'},
            { modbus: 2, pointId: 9, device_uid: '1120452247-865583041302307'},
            { modbus: 3, pointId: 10, device_uid: '1120452247-865583041302307'},
            { modbus: 4, pointId: 11, device_uid: '1120452247-865583041302307'},
            { modbus: 5, pointId: 12, device_uid: '1120452247-865583041302307'},
            { modbus: 6, pointId: 13, device_uid: '1120452247-865583041302307'},
            { modbus: 7, pointId: 14, device_uid: '1120452247-865583041302307'},
            { modbus: 8, pointId: 15, device_uid: '1120452247-865583041302307'},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1121102219-865583041307884', name: [`Sensors.component.hotWater`], house: 16 },
          { modbus: 20, device_uid: '1121102219-865583041307884', name: [`Sensors.component.balancingValve`], house: 16 },
          { modbus: 2, device_uid: '1121102219-865583041307884', name: [`Sensors.component.smartValve`, {n: 1}], house: 15 },
          { modbus: 3, device_uid: '1121102219-865583041307884', name: [`Sensors.component.smartValve`, {n: 2}], house: 15 },
          { modbus: 4, device_uid: '1121102219-865583041307884', name: [`Sensors.component.smartValve`, {n: 3}], house: 15 },
          { modbus: 9, device_uid: '1121102219-865583041307884', name: [`Sensors.component.smartValve`, {n: 4}], house: 15 },
          { modbus: 5, device_uid: '1121102219-865583041307884', name: [`Sensors.component.smartValve`, {n: 5}], house: 16 },
          { modbus: 6, device_uid: '1121102219-865583041307884', name: [`Sensors.component.smartValve`, {n: 6}], house: 16 },
          { modbus: 7, device_uid: '1121102219-865583041307884', name: [`Sensors.component.smartValve`, {n: 7}], house: 16 },
          { modbus: 8, device_uid: '1121102219-865583041307884', name: [`Sensors.component.smartValve`, {n: 8}], house: 16 },
          { modbus: 2, device_uid: '1120452247-865583041302307', name: [`Sensors.component.smartValve`, {n: 9}], house: 17 },
          { modbus: 3, device_uid: '1120452247-865583041302307', name: [`Sensors.component.smartValve`, {n: 10}], house: 17 },
          { modbus: 4, device_uid: '1120452247-865583041302307', name: [`Sensors.component.smartValve`, {n: 11}], house: 17 },
          { modbus: 5, device_uid: '1120452247-865583041302307', name: [`Sensors.component.smartValve`, {n: 12}], house: 17 },
          { modbus: 6, device_uid: '1120452247-865583041302307', name: [`Sensors.component.smartValve`, {n: 13}], house: 18 },
          { modbus: 7, device_uid: '1120452247-865583041302307', name: [`Sensors.component.smartValve`, {n: 14}], house: 18 },
          { modbus: 8, device_uid: '1120452247-865583041302307', name: [`Sensors.component.smartValve`, {n: 15}], house: 18 },
       ],
       components: [
        defaultComponentsForNassauische
       ],
       startDate: "2022-04-28T00:00:00Z"
      },
      {
        number: "1",
        street: "Unter den Linden",
        config3d: {
          type: '4_nofilter',
          devices: [
            ...defaultPoints3d,
            { modbus: 5, pointId: 1},
            { modbus: 6, pointId: 2},
            { modbus: 7, pointId: 3},
            { modbus: 8, pointId: 4},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1121102214-865583041310193', name: [`Sensors.component.hotWater`], house: 1 },
          { modbus: 20, device_uid: '1121102214-865583041310193', name: [`Sensors.component.balancingValve`], house: 1 },
          { modbus: 5, device_uid: '1121102214-865583041310193', name: [`Sensors.component.smartValve`, {n: 1}], house: 1 },
          { modbus: 6, device_uid: '1121102214-865583041310193', name: [`Sensors.component.smartValve`, {n: 2}], house: 1 },
          { modbus: 7, device_uid: '1121102214-865583041310193', name: [`Sensors.component.smartValve`, {n: 3}], house: 1 },
          { modbus: 8, device_uid: '1121102214-865583041310193', name: [`Sensors.component.smartValve`, {n: 4}], house: 1 },
       ],
       components: [
        defaultComponentsForNassauische
       ],
       startDate: "2022-06-15T00:00:00Z"
      },
      {
        number: "2",
        street: "Unter den Linden",
        config3d: {
          type: '3_nofilter',
          devices: [
            ...defaultPoints3d,
            { modbus: 2, pointId: 1},
            { modbus: 3, pointId: 2},
            { modbus: 4, pointId: 3},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1120452303-865583041310029', name: [`Sensors.component.hotWater`], house: 2 },
          { modbus: 20, device_uid: '1120452303-865583041310029', name: [`Sensors.component.balancingValve`], house: 2 },
          { modbus: 2, device_uid: '1120452303-865583041310029', name: [`Sensors.component.smartValve`, {n: 1}], house: 2 },
          { modbus: 3, device_uid: '1120452303-865583041310029', name: [`Sensors.component.smartValve`, {n: 2}], house: 2 },
          { modbus: 4, device_uid: '1120452303-865583041310029', name: [`Sensors.component.smartValve`, {n: 3}], house: 2 },
       ],
       components: [
        defaultComponentsForNassauische
       ],
       startDate: "2022-06-11T00:00:00Z"
      },
      {
        number: "3a-4",
        street: "Unter den Linden",
        config3d: {
          type: '6_nofilter',
          devices: [
            ...defaultPoints3d,
            { modbus: 3, pointId: 1, device_uid: '1121100813-865583041301580'},
            { modbus: 4, pointId: 2, device_uid: '1121100813-865583041301580'},
            { modbus: 2, pointId: 3, device_uid: '1121101804-865583041317271'},
            { modbus: 5, pointId: 4, device_uid: '1121100813-865583041301580'},
            { modbus: 6, pointId: 5, device_uid: '1121100813-865583041301580'},
            { modbus: 7, pointId: 6, device_uid: '1121100813-865583041301580'},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1121100813-865583041301580', name: [`Sensors.component.hotWater`], house: 3 },
          { modbus: 20, device_uid: '1121100813-865583041301580', name: [`Sensors.component.balancingValve`], house: 3 },
          { modbus: 3, device_uid: '1121100813-865583041301580', name: [`Sensors.component.smartValve`, {n: 1}], house: 3 },
          { modbus: 4, device_uid: '1121100813-865583041301580', name: [`Sensors.component.smartValve`, {n: 2}], house: 3 },
          { modbus: 2, device_uid: '1121101804-865583041317271', name: [`Sensors.component.smartValve`, {n: 3}], house: "3a" },
          { modbus: 5, device_uid: '1121100813-865583041301580', name: [`Sensors.component.smartValve`, {n: 4}], house: 4 },
          { modbus: 6, device_uid: '1121100813-865583041301580', name: [`Sensors.component.smartValve`, {n: 5}], house: 4 },
          { modbus: 7, device_uid: '1121100813-865583041301580', name: [`Sensors.component.smartValve`, {n: 6}], house: 4 },
       ],
       components: [
        defaultComponentsForNassauische
       ],
       startDate: "2022-06-18T00:00:00Z"
      },
      {
        number: "5-7",
        street: "Unter den Linden",
        config3d: {
          type: '8_nofilter',
          devices: [
            ...defaultPoints3d,
            { modbus: 2, pointId: 1, device_uid: '1113467909-865583040805789'},
            { modbus: 3, pointId: 2, device_uid: '1113467909-865583040805789'},
            { modbus: 4, pointId: 3, device_uid: '1113467909-865583040805789'},
            { modbus: 2, pointId: 4, device_uid: '1120452179-865583041303420'},
            { modbus: 3, pointId: 5, device_uid: '1120452179-865583041303420'},
            { modbus: 4, pointId: 6, device_uid: '1120452179-865583041303420'},
            { modbus: 5, pointId: 7, device_uid: '1120452179-865583041303420'},
            { modbus: 6, pointId: 8, device_uid: '1120452179-865583041303420'},
            { modbus: 7, pointId: 9, device_uid: '1120452179-865583041303420'},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1120452179-865583041303420', name: [`Sensors.component.hotWater`], house: 6 },
          { modbus: 20, device_uid: '1120452179-865583041303420', name: [`Sensors.component.balancingValve`], house: 6 },
          { modbus: 2, device_uid: '1113467909-865583040805789', name: [`Sensors.component.smartValve`, {n: 1}], house: 5 },
          { modbus: 3, device_uid: '1113467909-865583040805789', name: [`Sensors.component.smartValve`, {n: 2}], house: 5 },
          { modbus: 4, device_uid: '1113467909-865583040805789', name: [`Sensors.component.smartValve`, {n: 3}], house: 5 },
          { modbus: 2, device_uid: '1120452179-865583041303420', name: [`Sensors.component.smartValve`, {n: 4}], house: 6 },
          { modbus: 3, device_uid: '1120452179-865583041303420', name: [`Sensors.component.smartValve`, {n: 5}], house: 6 },
          { modbus: 4, device_uid: '1120452179-865583041303420', name: [`Sensors.component.smartValve`, {n: 6}], house: 6 },
          { modbus: 5, device_uid: '1120452179-865583041303420', name: [`Sensors.component.smartValve`, {n: 7}], house: 7 },
          { modbus: 6, device_uid: '1120452179-865583041303420', name: [`Sensors.component.smartValve`, {n: 8}], house: 7 },
          { modbus: 7, device_uid: '1120452179-865583041303420', name: [`Sensors.component.smartValve`, {n: 9}], house: 7 },
       ],
       components: [
        {
          id: 1,
          type: 'drinkingWaterSystem',
          building: 'Unter den Linden 5-7',
          cards: [
            configColdWaterInletComponent({ id: 74, detailsImgWebp: main_Unter_den_Linden_images_5_7.webp.s_74, detailsImgPng: main_Unter_den_Linden_images_5_7.jpg.s_74 })
          ]
        },
        defaultComponentsForNassauische
       ],
       startDate: "2022-07-16T00:00:00Z"
      },
      {
        number: "8-10",
        street: "Unter den Linden",
        config3d: {
          type: '8_nofilter',
          devices: [
            ...defaultPoints3d,
            { modbus: 5, pointId: 1, device_uid: '1121101801-865583041313650'},
            { modbus: 6, pointId: 2, device_uid: '1121101801-865583041313650'},
            { modbus: 7, pointId: 3, device_uid: '1121101801-865583041313650'},
            { modbus: 2, pointId: 4, device_uid: '1120452035-865583041297077'},
            { modbus: 3, pointId: 5, device_uid: '1120452035-865583041297077'},
            { modbus: 4, pointId: 6, device_uid: '1120452035-865583041297077'},
            { modbus: 5, pointId: 7, device_uid: '1120452035-865583041297077'},
            { modbus: 6, pointId: 8, device_uid: '1120452035-865583041297077'},
            { modbus: 7, pointId: 9, device_uid: '1120452035-865583041297077'},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1120452035-865583041297077', name: [`Sensors.component.hotWater`], house: 9 },
          { modbus: 20, device_uid: '1120452035-865583041297077', name: [`Sensors.component.balancingValve`], house: 9 },
          { modbus: 5, device_uid: '1121101801-865583041313650', name: [`Sensors.component.smartValve`, {n: 1}], house: 8 },
          { modbus: 6, device_uid: '1121101801-865583041313650', name: [`Sensors.component.smartValve`, {n: 2}], house: 8 },
          { modbus: 7, device_uid: '1121101801-865583041313650', name: [`Sensors.component.smartValve`, {n: 3}], house: 8 },
          { modbus: 2, device_uid: '1120452035-865583041297077', name: [`Sensors.component.smartValve`, {n: 4}], house: 9 },
          { modbus: 3, device_uid: '1120452035-865583041297077', name: [`Sensors.component.smartValve`, {n: 5}], house: 9 },
          { modbus: 4, device_uid: '1120452035-865583041297077', name: [`Sensors.component.smartValve`, {n: 6}], house: 9 },
          { modbus: 5, device_uid: '1120452035-865583041297077', name: [`Sensors.component.smartValve`, {n: 7}], house: 10 },
          { modbus: 6, device_uid: '1120452035-865583041297077', name: [`Sensors.component.smartValve`, {n: 8}], house: 10 },
          { modbus: 7, device_uid: '1120452035-865583041297077', name: [`Sensors.component.smartValve`, {n: 9}], house: 10 },
       ],
       components: [
        defaultComponentsForNassauische
       ],
       startDate: "2022-07-19T00:00:00Z"
      },
      {
        number: "11-13",
        street: "Unter den Linden",
        config3d: {
          type: '8_nofilter',
          devices: [
            ...defaultPoints3d,
            { modbus: 2, pointId: 1, device_uid: '1118232911-865583041247452'},
            { modbus: 3, pointId: 2, device_uid: '1118232911-865583041247452'},
            { modbus: 4, pointId: 3, device_uid: '1118232911-865583041247452'},
            { modbus: 5, pointId: 4, device_uid: '1120452042-865583041297911'},
            { modbus: 6, pointId: 5, device_uid: '1120452042-865583041297911'},
            { modbus: 7, pointId: 6, device_uid: '1120452042-865583041297911'},
            { modbus: 2, pointId: 7, device_uid: '1120452042-865583041297911'},
            { modbus: 3, pointId: 8, device_uid: '1120452042-865583041297911'},
            { modbus: 4, pointId: 9, device_uid: '1120452042-865583041297911'},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1120452042-865583041297911', name: [`Sensors.component.hotWater`], house: 12 },
          { modbus: 20, device_uid: '1120452042-865583041297911', name: [`Sensors.component.balancingValve`], house: 12 },
          { modbus: 2, device_uid: '1118232911-865583041247452', name: [`Sensors.component.smartValve`, {n: 1}], house: 11 },
          { modbus: 3, device_uid: '1118232911-865583041247452', name: [`Sensors.component.smartValve`, {n: 2}], house: 11 },
          { modbus: 4, device_uid: '1118232911-865583041247452', name: [`Sensors.component.smartValve`, {n: 3}], house: 11 },
          { modbus: 5, device_uid: '1120452042-865583041297911', name: [`Sensors.component.smartValve`, {n: 4}], house: 12 },
          { modbus: 6, device_uid: '1120452042-865583041297911', name: [`Sensors.component.smartValve`, {n: 5}], house: 12 },
          { modbus: 7, device_uid: '1120452042-865583041297911', name: [`Sensors.component.smartValve`, {n: 6}], house: 12 },
          { modbus: 2, device_uid: '1120452042-865583041297911', name: [`Sensors.component.smartValve`, {n: 7}], house: 13 },
          { modbus: 3, device_uid: '1120452042-865583041297911', name: [`Sensors.component.smartValve`, {n: 8}], house: 13 },
          { modbus: 4, device_uid: '1120452042-865583041297911', name: [`Sensors.component.smartValve`, {n: 9}], house: 13 },
       ],
       components: [
        defaultComponentsForNassauische
       ],
       startDate: "2022-07-26T00:00:00Z"
      },
      {
        number: "14-16",
        street: "Unter den Linden",
        config3d: {
          type: '8_filter',
          devices: [
            ...defaultPoints3d,
            { modbus: 2, pointId: 1, device_uid: '1118232910-865583041249334'},
            { modbus: 3, pointId: 2, device_uid: '1118232910-865583041249334'},
            { modbus: 4, pointId: 3, device_uid: '1118232910-865583041249334'},
            { modbus: 5, pointId: 4, device_uid: '1121102574-865583041325647'},
            { modbus: 6, pointId: 5, device_uid: '1121102574-865583041325647'},
            { modbus: 7, pointId: 6, device_uid: '1121102574-865583041325647'},
            { modbus: 2, pointId: 7, device_uid: '1121102574-865583041325647'},
            { modbus: 3, pointId: 8, device_uid: '1121102574-865583041325647'},
            { modbus: 4, pointId: 9, device_uid: '1121102574-865583041325647'},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1121102574-865583041325647', name: [`Sensors.component.hotWater`], house: 15 },
          { modbus: 20, device_uid: '1121102574-865583041325647', name: [`Sensors.component.balancingValve`], house: 15 },
          { modbus: 2, device_uid: '1118232910-865583041249334', name: [`Sensors.component.smartValve`, {n: 1}], house: 14 },
          { modbus: 3, device_uid: '1118232910-865583041249334', name: [`Sensors.component.smartValve`, {n: 2}], house: 14 },
          { modbus: 4, device_uid: '1118232910-865583041249334', name: [`Sensors.component.smartValve`, {n: 3}], house: 14 },
          { modbus: 5, device_uid: '1121102574-865583041325647', name: [`Sensors.component.smartValve`, {n: 4}], house: 15 },
          { modbus: 6, device_uid: '1121102574-865583041325647', name: [`Sensors.component.smartValve`, {n: 5}], house: 15 },
          { modbus: 7, device_uid: '1121102574-865583041325647', name: [`Sensors.component.smartValve`, {n: 6}], house: 15 },
          { modbus: 2, device_uid: '1121102574-865583041325647', name: [`Sensors.component.smartValve`, {n: 7}], house: 16 },
          { modbus: 3, device_uid: '1121102574-865583041325647', name: [`Sensors.component.smartValve`, {n: 8}], house: 16 },
          { modbus: 4, device_uid: '1121102574-865583041325647', name: [`Sensors.component.smartValve`, {n: 9}], house: 16 },
       ],
       components: [
        {
          id: 1,
          type: 'drinkingWaterSystem',
          building: 'Unter den Linden 14-16',
          cards: [
            configColdWaterInletComponent({ id: 77, detailsImgWebp: main_Unter_den_Linden_images_14_16.webp.s_77, detailsImgPng: main_Unter_den_Linden_images_14_16.jpg.s_77 }),
            configBackwashFilterComponent({ id: 78, detailsImgWebp: main_Unter_den_Linden_images_14_16.webp.s_78, detailsImgPng: main_Unter_den_Linden_images_14_16.jpg.s_78, detailsInfo: { name: "Judo JPF QC"}})
          ]
        },
        defaultComponentsForNassauische
       ],
       startDate: "2022-07-28T00:00:00Z"
      },
      {
        number: "17-18",
        street: "Unter den Linden",
        config3d: {
          type: '5_filter',
          devices: [
            ...defaultPoints3d,
            { modbus: 5, pointId: 1},
            { modbus: 4, pointId: 2},
            { modbus: 6, pointId: 3},
            { modbus: 2, pointId: 4},
            { modbus: 3, pointId: 5},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1121100816-865583041301440', name: [`Sensors.component.hotWater`], house: 17 },
          { modbus: 20, device_uid: '1121100816-865583041301440', name: [`Sensors.component.balancingValve`], house: 17 },
          { modbus: 5, device_uid: '1121100816-865583041301440', name: [`Sensors.component.smartValve`, {n: 1}], house: 17 },
          { modbus: 4, device_uid: '1121100816-865583041301440', name: [`Sensors.component.smartValve`, {n: 2}], house: 17 },
          { modbus: 6, device_uid: '1121100816-865583041301440', name: [`Sensors.component.smartValve`, {n: 3}], house: 17 },
          { modbus: 2, device_uid: '1121100816-865583041301440', name: [`Sensors.component.smartValve`, {n: 4}], house: 18 },
          { modbus: 3, device_uid: '1121100816-865583041301440', name: [`Sensors.component.smartValve`, {n: 5}], house: 18 },
       ],
       components: [
        {
          id: 1,
          type: 'drinkingWaterSystem',
          building: 'Unter den Linden 17-18',
          cards: [
            configColdWaterInletComponent({ id: 79, detailsImgWebp: main_Unter_den_Linden_images_17_18.webp.s_79, detailsImgPng: main_Unter_den_Linden_images_17_18.jpg.s_79 }),
            configBackwashFilterComponent({ id: 80, detailsImgWebp: main_Unter_den_Linden_images_17_18.webp.s_80, detailsImgPng: main_Unter_den_Linden_images_17_18.jpg.s_80, detailsInfo: { name: "Judo JPF QC"}})
          ]
        },
        defaultComponentsForNassauische
       ],
       startDate: "2022-06-24T00:00:00Z"
      },
      {
        number: "1-2",
        street: "Unter den Eschen",
        config3d: {
          type: '6_nofilter',
          devices: [
            ...defaultPoints3d,
            { modbus: 2, pointId: 1, device_uid: '1121100843-865583041319806'},
            { modbus: 3, pointId: 2, device_uid: '1121100843-865583041319806'},
            { modbus: 4, pointId: 3, device_uid: '1121100843-865583041319806'},
            { modbus: 2, pointId: 4, device_uid: '1121100923-865583041280818'},
            { modbus: 3, pointId: 5, device_uid: '1121100923-865583041280818'},
            { modbus: 4, pointId: 6, device_uid: '1121100923-865583041280818'},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1118232908-865583041247411', name: [`Sensors.component.hotWater`], house: 2 },
          { modbus: 20, device_uid: '1118232908-865583041247411', name: [`Sensors.component.balancingValve`], house: 2 },
          { modbus: 2, device_uid: '1121100843-865583041319806', name: [`Sensors.component.smartValve`, {n: 1}], house: 1 },
          { modbus: 3, device_uid: '1121100843-865583041319806', name: [`Sensors.component.smartValve`, {n: 2}], house: 1 },
          { modbus: 4, device_uid: '1121100843-865583041319806', name: [`Sensors.component.smartValve`, {n: 3}], house: 1 },
          { modbus: 2, device_uid: '1121100923-865583041280818', name: [`Sensors.component.smartValve`, {n: 4}], house: 2 },
          { modbus: 3, device_uid: '1121100923-865583041280818', name: [`Sensors.component.smartValve`, {n: 5}], house: 2 },
          { modbus: 4, device_uid: '1121100923-865583041280818', name: [`Sensors.component.smartValve`, {n: 6}], house: 2 },
       ],
       components: [
        defaultComponentsForNassauische
       ],
       startDate: "2022-07-12T00:00:00Z"
      },
      {
        number: "3a-4",
        street: "Unter den Eschen",
        config3d: {
          type: '5_nofilter',
          devices: [
            ...defaultPoints3d,
            { modbus: 3, pointId: 1},
            { modbus: 4, pointId: 2},
            { modbus: 2, pointId: 3},
            { modbus: 5, pointId: 4},
            { modbus: 6, pointId: 5},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1121103402-865583041282681', name: [`Sensors.component.hotWater`], house: 3 },
          { modbus: 20, device_uid: '1121103402-865583041282681', name: [`Sensors.component.balancingValve`], house: 3 },
          { modbus: 3, device_uid: '1121103402-865583041282681', name: [`Sensors.component.smartValve`, {n: 1}], house: 3 },
          { modbus: 4, device_uid: '1121103402-865583041282681', name: [`Sensors.component.smartValve`, {n: 2}], house: 3 },
          { modbus: 2, device_uid: '1121103402-865583041282681', name: [`Sensors.component.smartValve`, {n: 3}], house: '3a' },
          { modbus: 5, device_uid: '1121103402-865583041282681', name: [`Sensors.component.smartValve`, {n: 4}], house: 4 },
          { modbus: 6, device_uid: '1121103402-865583041282681', name: [`Sensors.component.smartValve`, {n: 5}], house: 4 },
       ],
       components: [
        {
          id: 1,
          type: 'drinkingWaterSystem',
          building: 'Unter den Eschen 3a-4',
          cards: [
            configColdWaterInletComponent({ id: 82, detailsImgWebp: main_Unter_den_Eschen_images_3_4.webp.s_82, detailsImgPng: main_Unter_den_Eschen_images_3_4.jpg.s_82 })
          ]
        },
        defaultComponentsForNassauische
       ],
       startDate: "2022-06-25T00:00:00Z"
      },
      {
        number: "5-7",
        street: "Unter den Eschen",
        config3d: {
          type: '6_nofilter',
          devices: [
            ...defaultPoints3d,
            { modbus: 3, pointId: 1},
            { modbus: 2, pointId: 2},
            { modbus: 5, pointId: 3},
            { modbus: 4, pointId: 4},
            { modbus: 7, pointId: 5},
            { modbus: 6, pointId: 6},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1121104341-865583041299149', name: [`Sensors.component.hotWater`], house: 6 },
          { modbus: 20, device_uid: '1121104341-865583041299149', name: [`Sensors.component.balancingValve`], house: 6 },
          { modbus: 3, device_uid: '1121104341-865583041299149', name: [`Sensors.component.smartValve`, {n: 1}], house: 5 },
          { modbus: 2, device_uid: '1121104341-865583041299149', name: [`Sensors.component.smartValve`, {n: 2}], house: 5 },
          { modbus: 5, device_uid: '1121104341-865583041299149', name: [`Sensors.component.smartValve`, {n: 3}], house: 6 },
          { modbus: 4, device_uid: '1121104341-865583041299149', name: [`Sensors.component.smartValve`, {n: 4}], house: 6 },
          { modbus: 7, device_uid: '1121104341-865583041299149', name: [`Sensors.component.smartValve`, {n: 5}], house: 7 },
          { modbus: 6, device_uid: '1121104341-865583041299149', name: [`Sensors.component.smartValve`, {n: 6}], house: 7 },
       ],
       components: [
        {
          id: 1,
          type: 'drinkingWaterSystem',
          building: 'Unter den Eschen 5-7',
          cards: [
            configColdWaterInletComponent({ id: 83, detailsImgWebp: main_Unter_den_Eschen_images_5_7.webp.s_83, detailsImgPng: main_Unter_den_Eschen_images_5_7.jpg.s_83 })
          ]
        },
        defaultComponentsForNassauische
       ],
       startDate: "2022-06-30T00:00:00Z"
      },
      {
        number: "8-10",
        street: "Unter den Eschen",
        config3d: {
          type: '7_filter',
          devices: [
            ...defaultPoints3d,
            { modbus: 3, pointId: 1},
            { modbus: 2, pointId: 2},
            { modbus: 5, pointId: 3},
            { modbus: 4, pointId: 4},
            { modbus: 8, pointId: 5},
            { modbus: 7, pointId: 6},
            { modbus: 6, pointId: 7},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1121101194-865583041294769', name: [`Sensors.component.hotWater`], house: 9 },
          { modbus: 20, device_uid: '1121101194-865583041294769', name: [`Sensors.component.balancingValve`], house: 9 },
          { modbus: 3, device_uid: '1121101194-865583041294769', name: [`Sensors.component.smartValve`, {n: 1}], house: 8 },
          { modbus: 2, device_uid: '1121101194-865583041294769', name: [`Sensors.component.smartValve`, {n: 2}], house: 8 },
          { modbus: 5, device_uid: '1121101194-865583041294769', name: [`Sensors.component.smartValve`, {n: 3}], house: 9 },
          { modbus: 4, device_uid: '1121101194-865583041294769', name: [`Sensors.component.smartValve`, {n: 4}], house: 9 },
          { modbus: 8, device_uid: '1121101194-865583041294769', name: [`Sensors.component.smartValve`, {n: 5}], house: 10 },
          { modbus: 7, device_uid: '1121101194-865583041294769', name: [`Sensors.component.smartValve`, {n: 6}], house: 10 },
          { modbus: 6, device_uid: '1121101194-865583041294769', name: [`Sensors.component.smartValve`, {n: 7}], house: 10 },
       ],
       components: [
        {
          id: 1,
          type: 'drinkingWaterSystem',
          building: 'Unter den Eschen 8-10',
          cards: [
            configColdWaterInletComponent({ id: 84, building: 'Unter den Eschen 8', detailsImgWebp: main_Unter_den_Eschen_images_8_10.webp.s_84, detailsImgPng: main_Unter_den_Eschen_images_8_10.jpg.s_84 }),
            configColdWaterInletComponent({ id: 81, building: 'Unter den Eschen 10', detailsImgWebp: main_Unter_den_Eschen_images_8_10.webp.s_81, detailsImgPng: main_Unter_den_Eschen_images_8_10.jpg.s_81 }),
            configBackwashFilterComponent({ id: 85, detailsImgWebp: main_Unter_den_Eschen_images_8_10.webp.s_85, detailsImgPng: main_Unter_den_Eschen_images_8_10.jpg.s_85, detailsInfo: { name: "Judo JPF QC"}})
          ]
        },
        defaultComponentsForNassauische
       ],
       startDate: "2022-07-05T00:00:00Z"
      },
      {
        number: "11-13",
        street: "Unter den Eschen",
        config3d: {
          type: '8_nofilter',
          devices: [
            ...defaultPoints3d,
            { modbus: 2, pointId: 1, device_uid: '1118232914-865583041233460'},
            { modbus: 3, pointId: 2, device_uid: '1118232914-865583041233460'},
            { modbus: 4, pointId: 3, device_uid: '1118232914-865583041233460'},
            { modbus: 5, pointId: 4, device_uid: '1121101672-865583041293092'},
            { modbus: 6, pointId: 5, device_uid: '1121101672-865583041293092'},
            { modbus: 7, pointId: 6, device_uid: '1121101672-865583041293092'},
            { modbus: 2, pointId: 7, device_uid: '1121101672-865583041293092'},
            { modbus: 3, pointId: 8, device_uid: '1121101672-865583041293092'},
            { modbus: 4, pointId: 9, device_uid: '1121101672-865583041293092'},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1121101672-865583041293092', name: [`Sensors.component.hotWater`], house: 12 },
          { modbus: 20, device_uid: '1121101672-865583041293092', name: [`Sensors.component.balancingValve`], house: 12 },
          { modbus: 2, device_uid: '1118232914-865583041233460', name: [`Sensors.component.smartValve`, {n: 1}], house: 11 },
          { modbus: 3, device_uid: '1118232914-865583041233460', name: [`Sensors.component.smartValve`, {n: 2}], house: 11 },
          { modbus: 4, device_uid: '1118232914-865583041233460', name: [`Sensors.component.smartValve`, {n: 3}], house: 11 },
          { modbus: 5, device_uid: '1121101672-865583041293092', name: [`Sensors.component.smartValve`, {n: 4}], house: 12 },
          { modbus: 6, device_uid: '1121101672-865583041293092', name: [`Sensors.component.smartValve`, {n: 5}], house: 12 },
          { modbus: 7, device_uid: '1121101672-865583041293092', name: [`Sensors.component.smartValve`, {n: 6}], house: 12 },
          { modbus: 2, device_uid: '1121101672-865583041293092', name: [`Sensors.component.smartValve`, {n: 7}], house: 13 },
          { modbus: 3, device_uid: '1121101672-865583041293092', name: [`Sensors.component.smartValve`, {n: 8}], house: 13 },
          { modbus: 4, device_uid: '1121101672-865583041293092', name: [`Sensors.component.smartValve`, {n: 9}], house: 13 },
       ],
       components: [
        {
          id: 1,
          type: 'drinkingWaterSystem',
          building: 'Unter den Eschen 11-13',
          cards: [
            configColdWaterInletComponent({ id: 86, detailsImgWebp: main_Unter_den_Eschen_images_11_13.webp.s_86, detailsImgPng: main_Unter_den_Eschen_images_11_13.jpg.s_86 })
          ]
        },
        defaultComponentsForNassauische
       ],
       startDate: "2022-07-06T00:00:00Z"
      },
      {
        number: "14-15",
        street: "Unter den Eschen",
        config3d: {
          type: '5_filter',
          devices: [
            ...defaultPoints3d,
            { modbus: 2, pointId: 1},
            { modbus: 3, pointId: 2},
            { modbus: 4, pointId: 3},
            { modbus: 5, pointId: 4},
            { modbus: 6, pointId: 5},
          ]
        },
        devices: [
          ...defaultDevices,
          { modbus: 100, device_uid: '1121101562-865583041311803', name: [`Sensors.component.hotWater`], house: 14 },
          { modbus: 20, device_uid: '1121101562-865583041311803', name: [`Sensors.component.balancingValve`], house: 14 },
          { modbus: 2, device_uid: '1121101562-865583041311803', name: [`Sensors.component.smartValve`, {n: 1}], house: 14 },
          { modbus: 3, device_uid: '1121101562-865583041311803', name: [`Sensors.component.smartValve`, {n: 2}], house: 14 },
          { modbus: 4, device_uid: '1121101562-865583041311803', name: [`Sensors.component.smartValve`, {n: 3}], house: 14 },
          { modbus: 5, device_uid: '1121101562-865583041311803', name: [`Sensors.component.smartValve`, {n: 4}], house: 15 },
          { modbus: 6, device_uid: '1121101562-865583041311803', name: [`Sensors.component.smartValve`, {n: 5}], house: 15 },
       ],
       components: [
        {
          id: 1,
          type: 'drinkingWaterSystem',
          building: 'Unter den Eschen 14-15',
          cards: [
            configColdWaterInletComponent({ id: 87, detailsImgWebp: main_Unter_den_Eschen_images_14_15.webp.s_87, detailsImgPng: main_Unter_den_Eschen_images_14_15.jpg.s_87 }),
            configBackwashFilterComponent({ id: 88, detailsImgWebp: main_Unter_den_Eschen_images_14_15.webp.s_88, detailsImgPng: main_Unter_den_Eschen_images_14_15.jpg.s_88, detailsInfo: { name: "Conel"}})
          ]
        },
        defaultComponentsForNassauische
       ],
       startDate: "2022-07-05T00:00:00Z"
      }
    ]
  },
  "631993e9580e73164e7b12b2": {
    buildings: [
      {
        number: "15",
        street: "Brüsseler Straße",
        config3d: {
          type: '8_Brusseler',
          devices: [
            { modbus: 1, pointId: 'd4'},
            { modbus: 200, pointId: 'd2'},
            { modbus: 2, pointId: 1},
            { modbus: 3, pointId: 2},
            { modbus: 4, pointId: 3},
            { modbus: 5, pointId: 4},
            { modbus: 6, pointId: 5},
            { modbus: 7, pointId: 6},
            { modbus: 8, pointId: 7},
            { modbus: 9, pointId: 8},
            { modbus: 10, pointId: 9},
            { modbus: 11, pointId: 10},
          ]
        },
        devices: [
          { modbus: 1, device_uid: '100.99.216.195-1' },
          { modbus: 200, device_uid: '100.99.216.195-200' },
          { modbus: 2, device_uid: '100.99.216.195-2', name: [`Sensors.component.smartValve`, {n: 1}] },
          { modbus: 3, device_uid: '100.99.216.195-3', name: [`Sensors.component.smartValve`, {n: 2}] },
          { modbus: 4, device_uid: '100.99.216.195-4', name: [`Sensors.component.smartValve`, {n: 3}] },
          { modbus: 5, device_uid: '100.99.216.195-5', name: [`Sensors.component.smartValve`, {n: 4}] },
          { modbus: 6, device_uid: '100.99.216.195-6', name: [`Sensors.component.smartValve`, {n: 5}] },
          { modbus: 7, device_uid: '100.99.216.195-7', name: [`Sensors.component.smartValve`, {n: 6}] },
          { modbus: 8, device_uid: '100.99.216.195-8', name: [`Sensors.component.smartValve`, {n: 7}] },
          { modbus: 9, device_uid: '100.99.216.195-9', name: [`Sensors.component.smartValve`, {n: 8}] },
          { modbus: 10, device_uid: '100.99.216.195-10', name: [`Sensors.component.smartValve`, {n: 9}] },
          { modbus: 11, device_uid: '100.99.216.195-11', name: [`Sensors.component.smartValve`, {n: 10}] },
       ],
       components: [
        {
          id: 1,
          type: 'components',
          building: '',
          cards: [
            configColdWaterInletComponent({ id: 105, detailsImgWebp: main_Busseler_15_images.webp.s_105, detailsImgPng: main_Busseler_15_images.jpg.s_105}),
            configStorageWaterHeaterComponent({ id: 107, modbus: 1, tabs: 'live_date_paul', detailsInfo: { name: 'Rudert RET 500-1 1HL', memoryContent: '500 L', storageType: 'Speicherladung', serialNumber: 'P 9987'}, detailsImgWebp: main_Busseler_15_images.webp.s_107, detailsImgPng: main_Busseler_15_images.jpg.s_107 }),
            configFreshWaterSystemComponent({ id: 108, detailsInfo: { name: 'Pewo', heatGenerators: 'Fernwärme', energySource: 'Fernwärme', supply: 'indirekt', nominalHeatOutput: '191 kW', temperatureSpread: '110/52 °C', permittedPressure: '16 bar'}, detailsImgWebp: main_Busseler_15_images.webp.s_108, detailsImgPng: main_Busseler_15_images.jpg.s_108 }),
            configStorageChargingPumpComponent({ id: 109, detailsInfo: { name: 'Grundfos ALPHA2 25-60 N 180', nominalDiameter: 'DN25', rotationalSpeed: '50/60 Hz', currentConsumption: '0.04 … 0.32 A', headOfFunding: '60 dm', installationLength: '180 mm', safetyClass: 'X4D', serialNumber: '99271971'}, detailsImgWebp: main_Busseler_15_images.webp.s_109, detailsImgPng: main_Busseler_15_images.jpg.s_109}),
            configCirculationPumpComponent({ id: 110, detailsInfo: { name: 'Grundfos UPS 25-60 N 180', nominalDiameter: 'DN25', rotationalSpeed: '50 Hz', currentConsumption: '0.21 … 0.25 A', headOfFunding: '60 dm', installationLength: '180 mm', safetyClass: 'IP44', serialNumber: '96913085'}, detailsImgWebp: main_Busseler_15_images.webp.s_110, detailsImgPng: main_Busseler_15_images.jpg.s_110}),
            configHeatingCircuitPumpComponent({ id: 111, componentNumber: 1, detailsInfo: { name: 'Grundfos MAGNA 25-60 180', nominalDiameter: 'DN25', rotationalSpeed: '50 Hz', currentConsumption: '0.09 … 0.6 A', headOfFunding: '60 dm', installationLength: '180 mm', safetyClass: 'X4D', serialNumber: '96748480'}, detailsImgWebp: main_Busseler_15_images.webp.s_111, detailsImgPng: main_Busseler_15_images.jpg.s_111}),
            configHeatingCircuitPumpComponent({ id: 112, componentNumber: 2, detailsInfo: { name: 'Grundfos MAGNA 25-100 180', nominalDiameter: 'DN25', rotationalSpeed: '50 Hz', currentConsumption: '0.09 … 1.25 A', headOfFunding: '100 dm', installationLength: '180 mm', safetyClass: 'X4D', serialNumber: '96748481'}, detailsImgWebp: main_Busseler_15_images.webp.s_112, detailsImgPng: main_Busseler_15_images.jpg.s_112}),
            configHeatingCircuitPumpComponent({ id: 113, componentNumber: 3, detailsInfo: { name: 'Grundfos MAGNA 32-100 F 220', nominalDiameter: 'DN32', rotationalSpeed: '50/60 Hz', currentConsumption: '0.1 … 1.23 A', headOfFunding: '100 dm', installationLength: '220 mm', safetyClass: 'X4D', serialNumber: '96748497'}, detailsImgWebp: main_Busseler_15_images.webp.s_113, detailsImgPng: main_Busseler_15_images.jpg.s_113}),
          ]
        }
      ],
      startDate: "2022-01-01T00:00:00Z"
      }
    ]
  },
  "6319942f580e73164e7b12b3": {
    buildings: [
      {
        number: "32-34",
        street: "Derfflinger Straße",
        config3d: {
          type: '8_Derfflinger',
          devices: [
            { modbus: 0, pointId: 'd4'},
            { modbus: 201, pointId: 'd2'},
            { modbus: 14, pointId: 1},
            { modbus: 2, pointId: 2},
            { modbus: 5, pointId: 3},
            { modbus: 3, pointId: 4},
            { modbus: 6, pointId: 5},
            { modbus: 4, pointId: 6},
            { modbus: 12, pointId: 7},
            { modbus: 11, pointId: 8},
            { modbus: 13, pointId: 9},
            { modbus: 10, pointId: 10},
            { modbus: 8, pointId: 11},
            { modbus: 9, pointId: 12},
            { modbus: 7, pointId: 13},
          ]
        },
        devices: [
          { modbus: 201, device_uid: '100.99.216.130-201' },
          { modbus: 0, device_uid: '100.99.216.130-0' },
          { modbus: 14, device_uid: '100.99.216.130-14', name: [`Sensors.component.smartValve`, {n: 1}], house: 32 },
          { modbus: 2, device_uid: '100.99.216.130-2', name: [`Sensors.component.smartValve`, {n: 2}], house: 32 },
          { modbus: 5, device_uid: '100.99.216.130-5', name: [`Sensors.component.smartValve`, {n: 1}], house: 34 },
          { modbus: 3, device_uid: '100.99.216.130-3', name: [`Sensors.component.smartValve`, {n: 2}], house: 34 },
          { modbus: 6, device_uid: '100.99.216.130-6', name: [`Sensors.component.smartValve`, {n: 3}], house: 34 },
          { modbus: 4, device_uid: '100.99.216.130-4', name: [`Sensors.component.smartValve`, {n: 4}], house: 34 },
          { modbus: 12, device_uid: '100.99.216.130-12', name: [`Sensors.component.smartValve`, {n: 1}], house: '34a' },
          { modbus: 11, device_uid: '100.99.216.130-11', name: [`Sensors.component.smartValve`, {n: 2}], house: '34a' },
          { modbus: 13, device_uid: '100.99.216.130-13', name: [`Sensors.component.smartValve`, {n: 3}], house: '34a' },
          { modbus: 10, device_uid: '100.99.216.130-10', name: [`Sensors.component.smartValve`, {n: 4}], house: '34a'},
          { modbus: 8, device_uid: '100.99.216.130-8', name: [`Sensors.component.smartValve`, {n: 5}], house: '34a' },
          { modbus: 9, device_uid: '100.99.216.130-9', name: [`Sensors.component.smartValve`, {n: 6}], house: '34a' },
          { modbus: 7, device_uid: '100.99.216.130-7', name: [`Sensors.component.smartValve`, {n: 7}], house: '34a' },
       ],
       components: [
        {
          id: 1,
          type: 'components',
          building: '',
          cards: [
            configColdWaterInletComponent({ id: 114, detailsImgWebp: main_Derfflinger_images.webp.s_114, detailsImgPng: main_Derfflinger_images.jpg.s_114}),
            configBackwashFilterComponent({ id: 115, detailsInfo: { name: 'Ditech Syr Rückspülfilter RF max', nominalDiameter: 'DN40', pressureLoss: '0.2 bar', flow: '9.0 m³/h', serialNumber: 'SY2315.00.866'}, detailsImgWebp: main_Derfflinger_images.webp.s_115, detailsImgPng: main_Derfflinger_images.jpg.s_115 }),
            configCirculationPumpComponent({ id: 116, detailsInfo: { name: 'Grundfos UP 20-45 N150', nominalDiameter: 'DN20', rotationalSpeed: '50 Hz', currentConsumption: '0.52 A', headOfFunding: '45 dm', installationLength: '150 mm', safetyClass: 'X2D', serialNumber: '99255562'}, detailsImgWebp: main_Derfflinger_images.webp.s_116, detailsImgPng: main_Derfflinger_images.jpg.s_116}),
            configStorageWaterHeaterComponent({ id: 117, modbus: '0', tabs: 'live_date_paul', detailsInfo: { name: 'Remeha HFS 750-2', memoryContent: '695 L', heatOutput: '195 kW', storageType: 'Warmwasserspeicher', serialNumber: '326405E+12'}, detailsImgWebp: main_Derfflinger_images.webp.s_117, detailsImgPng: main_Derfflinger_images.jpg.s_117 }),
            configDefaultComponent({ id: 118, textType: 'HeatingCircuit', detailsImgWebp: main_Derfflinger_images.webp.s_118, detailsImgPng: main_Derfflinger_images.jpg.s_118}),
            configHeatingCircuitPumpComponent({ id: 119, detailsInfo: { name: 'Grundfos Magna3 40-80 F 220', nominalDiameter: 'DN40', rotationalSpeed: '50/60 Hz', currentConsumption: '0.19 … 1.26 A', headOfFunding: '80 dm', installationLength: '220 mm', safetyClass: 'X4D', serialNumber: '10032907'}, detailsImgWebp: main_Derfflinger_images.webp.s_119, detailsImgPng: main_Derfflinger_images.jpg.s_119}),
            configStorageChargingPumpComponent({ id: 120, detailsInfo: { name: 'Grundfos Magna3 40-80 F 220', nominalDiameter: 'DN40', rotationalSpeed: '50/60 Hz', currentConsumption: '0.19 … 1.26 A', headOfFunding: '80 dm', installationLength: '220 mm', safetyClass: 'X4D', serialNumber: '10033583'}, detailsImgWebp: main_Derfflinger_images.webp.s_120, detailsImgPng: main_Derfflinger_images.jpg.s_120}),
            configBoilerComponent({ id: 121, detailsInfo: { name: 'Remeha GAS 210 ECO PRO 200', heatGenerators: 'Boiler', energySource: 'Gas', nominalHeatOutput: '217 kW', boilerContents: '64 L', serialNumber: '2128427290520'}, detailsImgWebp: main_Derfflinger_images.webp.s_121, detailsImgPng: main_Derfflinger_images.jpg.s_121 }),
            configHeatExchangerComponent({ id: 122, detailsImgWebp: main_Derfflinger_images.webp.s_122, detailsImgPng: main_Derfflinger_images.jpg.s_122 }),
          ]
        }
      ],
       startDate: "2022-01-01T00:00:00Z"
      },
    ]
  },
  "631f0690b6c762f01414a1da": {
    buildings: [
      {
        number: "1",
        street: "Am Blömkenberg",
        config3d: {
          type: '8_AmBlomkenberg',
          devices: [
            { modbus: 0, pointId: 'd4'},
            { modbus: 201, pointId: 'd2'},
            { modbus: 134, pointId: 1},
            { modbus: 135, pointId: 2},
            { modbus: 136, pointId: 3},
            { modbus: 137, pointId: 4},
            { modbus: 138, pointId: 5},
            { modbus: 139, pointId: 6},
            { modbus: 140, pointId: 7},
            { modbus: 141, pointId: 8},
            { modbus: 142, pointId: 9},
            { modbus: 143, pointId: 10},
            { modbus: 144, pointId: 11},
            { modbus: 145, pointId: 12},
            { modbus: 146, pointId: 13},
            { modbus: 147, pointId: 14},
            { modbus: 148, pointId: 15},
            { modbus: 149, pointId: 16},
            { modbus: 150, pointId: 17},
            { modbus: 151, pointId: 18},
            { modbus: 152, pointId: 19},
            { modbus: 153, pointId: 20},
            { modbus: 154, pointId: 21},
            { modbus: 155, pointId: 22},
            { modbus: 156, pointId: 23},
          ]
        },
        devices: [
          { modbus: 0, device_uid: '100.99.217.155-0' },
          { modbus: 201, device_uid: '100.99.217.155-201' },
          { modbus: 134, device_uid: '100.99.217.155-134', name: [`Sensors.component.smartValve`, {n: 1}] },
          { modbus: 135, device_uid: '100.99.217.155-135', name: [`Sensors.component.smartValve`, {n: 2}] },
          { modbus: 136, device_uid: '100.99.217.155-136', name: [`Sensors.component.smartValve`, {n: 3}] },
          { modbus: 137, device_uid: '100.99.217.155-137', name: [`Sensors.component.smartValve`, {n: 4}] },
          { modbus: 138, device_uid: '100.99.217.155-138', name: [`Sensors.component.smartValve`, {n: 5}] },
          { modbus: 139, device_uid: '100.99.217.155-139', name: [`Sensors.component.smartValve`, {n: 6}] },
          { modbus: 140, device_uid: '100.99.217.155-140', name: [`Sensors.component.smartValve`, {n: 7}] },
          { modbus: 141, device_uid: '100.99.217.155-141', name: [`Sensors.component.smartValve`, {n: 8}] },
          { modbus: 142, device_uid: '100.99.217.155-142', name: [`Sensors.component.smartValve`, {n: 9}] },
          { modbus: 143, device_uid: '100.99.217.155-143', name: [`Sensors.component.smartValve`, {n: 10}] },
          { modbus: 144, device_uid: '100.99.217.155-144', name: [`Sensors.component.smartValve`, {n: 11}] },
          { modbus: 145, device_uid: '100.99.217.155-145', name: [`Sensors.component.smartValve`, {n: 12}] },
          { modbus: 146, device_uid: '100.99.217.155-146', name: [`Sensors.component.smartValve`, {n: 13}] },
          { modbus: 147, device_uid: '100.99.217.155-147', name: [`Sensors.component.smartValve`, {n: 14}] },
          { modbus: 148, device_uid: '100.99.217.155-148', name: [`Sensors.component.smartValve`, {n: 15}] },
          { modbus: 149, device_uid: '100.99.217.155-149', name: [`Sensors.component.smartValve`, {n: 16}] },
          { modbus: 150, device_uid: '100.99.217.155-150', name: [`Sensors.component.smartValve`, {n: 17}] },
          { modbus: 151, device_uid: '100.99.217.155-151', name: [`Sensors.component.smartValve`, {n: 18}] },
          { modbus: 152, device_uid: '100.99.217.155-152', name: [`Sensors.component.smartValve`, {n: 19}] },
          { modbus: 153, device_uid: '100.99.217.155-153', name: [`Sensors.component.smartValve`, {n: 20}] },
          { modbus: 154, device_uid: '100.99.217.155-154', name: [`Sensors.component.smartValve`, {n: 21}] },
          { modbus: 155, device_uid: '100.99.217.155-155', name: [`Sensors.component.smartValve`, {n: 22}] },
          { modbus: 156, device_uid: '100.99.217.155-156', name: [`Sensors.component.smartValve`, {n: 23}] },
       ],
       components: [
        {
          id: 1,
          type: 'components',
          building: '',
          cards: [
            configColdWaterInletComponent({ id: 146, detailsImgWebp: main_AmBlomkenberg_images.webp.s_146, detailsImgPng: main_AmBlomkenberg_images.jpg.s_146}),
            configBackwashFilterComponent({ id: 147, detailsInfo: { name: 'Conel Clear 2.0r DN50'}, detailsImgWebp: main_AmBlomkenberg_images.webp.s_147, detailsImgPng: main_AmBlomkenberg_images.jpg.s_147 }),
            configStorageWaterHeaterComponent({ id: 148, modbus: '0',  tabs: 'live_date_paul',  detailsInfo: { name: 'Kesap RDS 60-750-200-E-VF', storageType: 'Warmwasserspeicher'}, detailsImgWebp: main_AmBlomkenberg_images.webp.s_148, detailsImgPng: main_AmBlomkenberg_images.jpg.s_148 }),
            configBoilerComponent({ id: 149, detailsInfo: { name: 'Buderus RMC110°B m BC110', energySource: 'Gas'}, detailsImgWebp: main_AmBlomkenberg_images.webp.s_149, detailsImgPng: main_AmBlomkenberg_images.jpg.s_149 }),
            configBHKWComponent({ id: 150, detailsInfo: { name: 'EC Power XRGI 20', energySource: 'Gas'}, detailsImgWebp: main_AmBlomkenberg_images.webp.s_150, detailsImgPng: main_AmBlomkenberg_images.jpg.s_150 }),
            configBufferStorageComponent({ id: 151, detailsInfo: { name: ''}, detailsImgWebp: main_AmBlomkenberg_images.webp.s_151, detailsImgPng: main_AmBlomkenberg_images.jpg.s_151 }),
            configStorageChargingPumpComponent({ id: 152, detailsInfo: { name: 'WILO Pico 30 1-6'}, detailsImgWebp: main_AmBlomkenberg_images.webp.s_152, detailsImgPng: main_AmBlomkenberg_images.jpg.s_152}),
            configFreshWaterSystemComponent({ id: 153, detailsInfo: { name: ''}, detailsImgWebp: main_AmBlomkenberg_images.webp.s_153, detailsImgPng: main_AmBlomkenberg_images.jpg.s_153 }),
            configHeatingCircuitComponent({ id: 154, detailsInfo: { name: ''}, detailsImgWebp: main_AmBlomkenberg_images.webp.s_154, detailsImgPng: main_AmBlomkenberg_images.jpg.s_154}),
            configCirculationPumpComponent({ id: 155, detailsInfo: { name: 'WILO Stratos Para Z 30/1-8 T11 HS'}}),
          ]
        }
      ],
      startDate: "2022-09-02T00:00:00Z"
      },
    ]
  },
  "63402b5b68662770736f5c69": {
    buildings: [
      {
        number: "37",
        street: "Oberhütte Altbau",
        config3d: {
          type: '8_Oberhutte',
          devices: [
            { modbus: 195, pointId: 'd1'},
            { modbus: 243, pointId: 'd2'},
            { modbus: 241, pointId: 'd3'},
            { modbus: 242, pointId: 'd4'},
            { modbus: 246, pointId: 'd5'},
            { modbus: 240, pointId: 'd6'},
            { modbus: 235, pointId: 'd7'},
            { modbus: 234, pointId: 'd8'},
            { modbus: 245, pointId: 'd9'},
            { modbus: 185, pointId: 'd10'},
            { modbus: 186, pointId: 'd11'},
            { modbus: 232, pointId: 'd12'},
            { modbus: 214, pointId: 'd13'},
            { modbus: 216, pointId: 'd14'},
            { modbus: 218, pointId: 'd15'},
            { modbus: 212, pointId: 'd16'},
            { modbus: 206, pointId: 'd17'},
            { modbus: 200, pointId: 'd18'},
            { modbus: 202, pointId: 'd19'},
            { modbus: 204, pointId: 'd20'},
            { modbus: 233, pointId: 'd21'},
            { modbus: 215, pointId: 'd22'},
            { modbus: 217, pointId: 'd23'},
            { modbus: 219, pointId: 'd24'},
            { modbus: 213, pointId: 'd25'},
            { modbus: 207, pointId: 'd26'},
            { modbus: 201, pointId: 'd27'},
            { modbus: 203, pointId: 'd28'},
            { modbus: 205, pointId: 'd29'},
            { modbus: 2, pointId: 1},
            { modbus: 3, pointId: 2},
            { modbus: 4, pointId: 3},
            { modbus: 5, pointId: 4},
            { modbus: 6, pointId: 5},
            { modbus: 7, pointId: 6},
            { modbus: 8, pointId: 7},
            { modbus: 9, pointId: 8},
            { modbus: 10, pointId: 9},
            { modbus: 11, pointId: 10},
            { modbus: 12, pointId: 11},
            { modbus: 13, pointId: 12},
            { modbus: 14, pointId: 13},
            { modbus: 15, pointId: 14},
            { modbus: 16, pointId: 15},
            { modbus: 17, pointId: 16},
            { modbus: 18, pointId: 17},
            { modbus: 19, pointId: 18},
            { modbus: 20, pointId: 19},
            { modbus: 21, pointId: 20},
            { modbus: 22, pointId: 21},
            { modbus: 23, pointId: 22},
            { modbus: 24, pointId: 23},
          ]
        },
        devices: [
          { modbus: 200, device_uid: '10.185.135.127-200' },
          { modbus: 201, device_uid: '10.185.135.127-201' },
          { modbus: 202, device_uid: '10.185.135.127-202' },
          { modbus: 203, device_uid: '10.185.135.127-203' },
          { modbus: 204, device_uid: '10.185.135.127-204' },
          { modbus: 205, device_uid: '10.185.135.127-205' },
          { modbus: 206, device_uid: '10.185.135.127-206' },
          { modbus: 207, device_uid: '10.185.135.127-207' },
          { modbus: 212, device_uid: '10.185.135.127-212' },
          { modbus: 213, device_uid: '10.185.135.127-213' },
          { modbus: 214, device_uid: '10.185.135.127-214' },
          { modbus: 215, device_uid: '10.185.135.127-215' },
          { modbus: 216, device_uid: '10.185.135.127-216' },
          { modbus: 217, device_uid: '10.185.135.127-217' },
          { modbus: 218, device_uid: '10.185.135.127-218' },
          { modbus: 219, device_uid: '10.185.135.127-219' },
          { modbus: 232, device_uid: '10.185.135.127-232' },
          { modbus: 233, device_uid: '10.185.135.127-233' },
          { modbus: 186, device_uid: '10.185.135.127-186' },
          { modbus: 185, device_uid: '10.185.135.127-185' },
          { modbus: 245, device_uid: '10.185.135.127-245' },
          { modbus: 240, device_uid: '10.185.135.127-240' },
          { modbus: 234, device_uid: '10.185.135.127-234' },
          { modbus: 246, device_uid: '10.185.135.127-246' },
          { modbus: 235, device_uid: '10.185.135.127-235' },
          { modbus: 241, device_uid: '10.185.135.127-241' },
          { modbus: 242, device_uid: '10.185.135.127-242' },
          { modbus: 243, device_uid: '10.185.135.127-243' },
          { modbus: 195, device_uid: '10.185.135.127-195' },
          { modbus: 2, device_uid: '10.185.135.127-2', name: [`Sensors.component.smartValve`, {n: 1}] },
          { modbus: 3, device_uid: '10.185.135.127-3', name: [`Sensors.component.smartValve`, {n: 2}] },
          { modbus: 4, device_uid: '10.185.135.127-4', name: [`Sensors.component.smartValve`, {n: 3}] },
          { modbus: 5, device_uid: '10.185.135.127-5', name: [`Sensors.component.smartValve`, {n: 4}] },
          { modbus: 6, device_uid: '10.185.135.127-6', name: [`Sensors.component.smartValve`, {n: 5}] },
          { modbus: 7, device_uid: '10.185.135.127-7', name: [`Sensors.component.smartValve`, {n: 6}] },
          { modbus: 8, device_uid: '10.185.135.127-8', name: [`Sensors.component.smartValve`, {n: 7}] },
          { modbus: 9, device_uid: '10.185.135.127-9', name: [`Sensors.component.smartValve`, {n: 8}] },
          { modbus: 10, device_uid: '10.185.135.127-10', name: [`Sensors.component.smartValve`, {n: 9}] },
          { modbus: 11, device_uid: '10.185.135.127-11', name: [`Sensors.component.smartValve`, {n: 10}] },
          { modbus: 12, device_uid: '10.185.135.127-12', name: [`Sensors.component.smartValve`, {n: 11}] },
          { modbus: 13, device_uid: '10.185.135.127-13', name: [`Sensors.component.smartValve`, {n: 12}] },
          { modbus: 14, device_uid: '10.185.135.127-14', name: [`Sensors.component.smartValve`, {n: 13}] },
          { modbus: 15, device_uid: '10.185.135.127-15', name: [`Sensors.component.smartValve`, {n: 14}] },
          { modbus: 16, device_uid: '10.185.135.127-16', name: [`Sensors.component.smartValve`, {n: 15}] },
          { modbus: 17, device_uid: '10.185.135.127-17', name: [`Sensors.component.smartValve`, {n: 16}] },
          { modbus: 18, device_uid: '10.185.135.127-18', name: [`Sensors.component.smartValve`, {n: 17}] },
          { modbus: 19, device_uid: '10.185.135.127-19', name: [`Sensors.component.smartValve`, {n: 18}] },
          { modbus: 20, device_uid: '10.185.135.127-20', name: [`Sensors.component.smartValve`, {n: 19}] },
          { modbus: 21, device_uid: '10.185.135.127-21', name: [`Sensors.component.smartValve`, {n: 20}] },
          { modbus: 22, device_uid: '10.185.135.127-22', name: [`Sensors.component.smartValve`, {n: 21}] },
          { modbus: 23, device_uid: '10.185.135.127-23', name: [`Sensors.component.smartValve`, {n: 22}] },
          { modbus: 24, device_uid: '10.185.135.127-24', name: [`Sensors.component.smartValve`, {n: 23}] },
       ],
       components: [
        {
          id: 1,
          type: 'components',
          building: '',
          cards: [
            configColdWaterInletComponent({ id: 92, detailsImgWebp: main_Oberhutte_Albau_37_images.webp.s_92, detailsImgPng: main_Oberhutte_Albau_37_images.jpg.s_92 }),
            configBackwashFilterComponent({ id: 93, detailsImgWebp: main_Oberhutte_Albau_37_images.webp.s_93, detailsImgPng: main_Oberhutte_Albau_37_images.jpg.s_93, modbus: 185, detailsInfo: { name: "SYR Drufi Max DFR"}}),
            configBackwashFilterComponent({ id: 94, detailsImgWebp: main_Oberhutte_Albau_37_images.webp.s_94, detailsImgPng: main_Oberhutte_Albau_37_images.jpg.s_94, modbus: 186, detailsInfo: { name: "Optima Drufi+ Max DFR"}}),
            configBoilerComponent({id: 95, detailsImgWebp: main_Oberhutte_Albau_37_images.webp.s_95, detailsImgPng: main_Oberhutte_Albau_37_images.jpg.s_95, modbus: { sensor_in: 206, sensor_out: 207}, detailsInfo: { name: "Buderus Logamax Plus GB 162-80 G20", energySource: "Erdgas", nominalHeatOutput: 80}}),
            configStorageChargingPumpComponent({id: 96, detailsImgWebp: main_Oberhutte_Albau_37_images.webp.s_96, detailsImgPng: main_Oberhutte_Albau_37_images.jpg.s_96, modbus: 206, detailsInfo: { name: "Grundfos Alpha 2 25-40 N"}}),
            configCirculationPumpComponent({id: 97, detailsImgWebp: main_Oberhutte_Albau_37_images.webp.s_97, detailsImgPng: main_Oberhutte_Albau_37_images.jpg.s_97, modbus: 195, detailsInfo: { name: "WILO Pico Z32 1-5"}}),
            configHydraulicSwitchComponent({id: 98, detailsImgWebp: main_Oberhutte_Albau_37_images.webp.s_98, detailsImgPng: main_Oberhutte_Albau_37_images.jpg.s_98, modbus: { sensor_in: 206, sensor_out: 207}}),
            configHeatingCircuitPumpComponent({ id: 99, detailsImgWebp: main_Oberhutte_Albau_37_images.webp.s_99, detailsImgPng: main_Oberhutte_Albau_37_images.jpg.s_99, modbus: { sensor_in: 214, sensor_out: 215}, componentNumber: 1,  detailsInfo: { name: 'Grundfos UPS 32-60 120'} }),
            configHeatingCircuitPumpComponent({ id: 100, detailsImgWebp: main_Oberhutte_Albau_37_images.webp.s_100, detailsImgPng: main_Oberhutte_Albau_37_images.jpg.s_100, modbus: { sensor_in: 216, sensor_out: 217}, componentNumber: 2,  detailsInfo: { name: 'Grundfos Magnat 40-100 F220'} }),
            configHeatingCircuitPumpComponent({ id: 101, detailsImgWebp: main_Oberhutte_Albau_37_images.webp.s_101, detailsImgPng: main_Oberhutte_Albau_37_images.jpg.s_101, modbus: { sensor_in: 218, sensor_out: 219}, componentNumber: 3,  detailsInfo: { name: 'Grundfos UPE 40-120 F'} }),
            configHeatExchangerComponent({ id: 102, detailsImgWebp: main_Oberhutte_Albau_37_images.webp.s_102, detailsImgPng: main_Oberhutte_Albau_37_images.jpg.s_102, modbus: { sensor_in: 232, sensor_out: 233}}),
            configStorageWaterHeaterComponent({ id: 103, detailsImgWebp: main_Oberhutte_Albau_37_images.webp.s_103, detailsImgPng: main_Oberhutte_Albau_37_images.jpg.s_103, modbus: 241, componentNumber: 1,  detailsInfo: { name: 'Buderus TBS Isocal', heatOutput: '150 L'}}),
            configStorageWaterHeaterComponent({ id: 104, detailsImgWebp: main_Oberhutte_Albau_37_images.webp.s_104, detailsImgPng: main_Oberhutte_Albau_37_images.jpg.s_104, modbus: 242, componentNumber: 2,  detailsInfo: { name: 'Buderus TBS Isocal', heatOutput: '150 L'}})
          ]
        },
      ]
      }
    ]
  }
}

export const disabledTabsProperties = [
  '62e90c3e3b1685117c8fe1e1',
  '62e90c753b1685117c8fe1e2',
  '62f9f4293f62cee2df80a3ec',
  '631993e9580e73164e7b12b2',
  '6319942f580e73164e7b12b3',
  '631f0690b6c762f01414a1da',
  '63402b5b68662770736f5c69'
];

export const configPerformanceDefault = {
  // '5_nofilter': [],
  '12': [
    { modbus: 160, pointId: 1},
    { modbus: 161, pointId: 2},
    { modbus: 162, pointId: 3},
    { modbus: 151, pointId: 4},
    { modbus: 152, pointId: 5},
    { modbus: 232, pointId: 6},
    { modbus: 212, pointId: 7},
    { modbus: 213, pointId: 8},
    { modbus: 200, pointId: 9},
    { modbus: 201, pointId: 10},
    { modbus: 233, pointId: 11},
    { modbus: 100, pointId: 12},
  ],
  '12_prototype': [
    { modbus: 160, pointId: 1},
    { modbus: 161, pointId: 2},
    { modbus: 162, pointId: 3},
    { modbus: 151, pointId: 4},
    { modbus: 152, pointId: 5},
    { modbus: 232, pointId: 6},
    { modbus: 212, pointId: 7},
    { modbus: 213, pointId: 8},
    { modbus: 233, pointId: 9},
    { modbus: 100, pointId: 10},
    { modbus: 97, pointId: 11},
  ],
  '18': [
    { modbus: 160, pointId: 1},
    { modbus: 161, pointId: 2},
    { modbus: 162, pointId: 3},
    { modbus: 163, pointId: 4},
    { modbus: 164, pointId: 5},
    { modbus: 165, pointId: 6},
    { modbus: 166, pointId: 7},
    { modbus: 151, pointId: 8},
    { modbus: 152, pointId: 9},
    { modbus: 232, pointId: 10},
    { modbus: 212, pointId: 11},
    { modbus: 213, pointId: 12},
    { modbus: 200, pointId: 13},
    { modbus: 201, pointId: 14},
    { modbus: 233, pointId: 15},
    { modbus: 22, pointId: 16},
    { modbus: 101, pointId: 17},
    { modbus: 100, pointId: 18},
  ],
  // '25': [
  //   { modbus: 30, pointId: 1},
  //   { modbus: 31, pointId: 2},
  //   { modbus: 1, pointId: 3},
  //   { modbus: 8, pointId: 4},
  //   { modbus: 16, pointId: 5},
  //   { modbus: 2, pointId: 6},
  //   { modbus: 11, pointId: 7},
  //   { modbus: 3, pointId: 8},
  //   { modbus: 4, pointId: 9},
  //   { modbus: 7, pointId: 10},
  //   { modbus: 10, pointId: 11},
  //   { modbus: 5, pointId: 12},
  //   { modbus: 20, pointId: 13},
  //   { modbus: 6, pointId: 14},
  //   { modbus: 14, pointId: 15},
  //   { modbus: 9, pointId: 16},
  //   { modbus: 13, pointId: 17},
  //   { modbus: 12, pointId: 18},
  //   { modbus: 0, pointId: 19},
  // ],
}

const filterPerfConfigDefault = {
  chilledWater: ['d7'],
  hotWater: ['d3', 'd4'],
  circulation: ['d1', 'd2', 1, 2, 3, 4, 5, 6, 7, 8],
  heatingLeadTime: ['d5'],
  heatingReturnFlow: ['d6'],
}

export const filterPerformanceConfig = {
  // '5_nofilter': {
  //   // chilledWater: ['d7'],
  //   // hotWater: ['d3', 'd4'],
  //   // circulation: [1, 2, 3, 4, 5, 'd1'],
  //   // heatingLeadTime: ['d5'],
  //   // heatingReturnFlow: ['d6'],
  //   chilledWater: [...filterPerfConfigDefault.chilledWater],
  //   hotWater: [...filterPerfConfigDefault.hotWater],
  //   circulation: [...filterPerfConfigDefault.circulation, 'd1'],
  //   heatingLeadTime: [...filterPerfConfigDefault.heatingLeadTime],
  //   heatingReturnFlow: [...filterPerfConfigDefault.heatingReturnFlow],
  // },
  '12': {
    hotWater: [4, 6, 11],
    chilledWater: [12],
    circulation: [5, 1, 2, 3],
    heatingLeadTime: [7, 6, 9],
    heatingReturnFlow: [8, 11, 10],
  },
  '12_prototype': {
    hotWater: [4, 6, 9],
    chilledWater: [10],
    circulation: [5, 1, 2, 3],
    heatingLeadTime: [7, 6],
    heatingReturnFlow: [8, 9, 11],
  },
  '18': {
      hotWater: [8, 10, 15],
      chilledWater: [16, 17, 18],
      circulation: [1, 2, 3, 4, 5, 6, 7, 9],
      heatingLeadTime: [10, 11, 13],
      heatingReturnFlow: [12, 15, 14],
  },
  '8_NiehlerGurtel': {
    hotWater: ['d3'],
    chilledWater: [],
    circulation: ['d1', 1, 2, 3, 4, 5, 6, 7, 8],
    heatingLeadTime: [],
    heatingReturnFlow: [],
  },
  '8_Oberhutte': {
    hotWater: ['d2', 'd3', 'd4', 'd5', 'd6'],
    chilledWater: ['d7', 'd8', 'd9', 'd10', 'd11'],
    circulation: ['d1', 1, 2, 3, 4, 5, 6, 7, 8],
    heatingLeadTime: ['d12', 'd13', 'd14', 'd15', 'd16', 'd17', 'd18', 'd19', 'd20'],
    heatingReturnFlow: ['d21', 'd22', 'd23', 'd24', 'd25', 'd26', 'd27', 'd28', 'd29'],
  },
  '7_Oberhutte': {
    hotWater: ['d2', 'd3', 'd4', 'd5', 'd6'],
    chilledWater: ['d7', 'd8', 'd9', 'd10', 'd11'],
    circulation: ['d1', 1, 2, 3, 4, 5, 6, 7, 8],
    heatingLeadTime: ['d12', 'd13', 'd14', 'd15', 'd16', 'd17', 'd18', 'd19', 'd20'],
    heatingReturnFlow: ['d21', 'd22', 'd23', 'd24', 'd25', 'd26', 'd27', 'd28', 'd29'],
  },
  default: filterPerfConfigDefault,
}

export const startDateOfSensorsOperation = {
  '62061f10452e21129b8ca241': { date: "2022-02-24T00:00:00Z"},
  '62061f10452e21129b8ca242': { date: "2022-02-24T00:00:00Z"}
}
