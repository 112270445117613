import mainz_staircase_webp from "./webp/Staircase.webp";
import mainz_hallway_webp from "./webp/Hallway.webp";
import mainz_gasPipe_webp from "./webp/Gas_pipe.webp";
import mainz_electricityMeter_webp from "./webp/Electricity_meter.webp";
import mainz_electricityMeter_1_webp from "./webp/Electricity_meter_1.webp";
import mainz_electricityMeter_2_webp from "./webp/Electricity_meter_2.webp";
import mainz_electricityMeter_3_webp from "./webp/Electricity_meter_3.webp";
import mainz_coldWaterInlet_webp from "./webp/Cold_water_Inlet.webp";
import mainz_coldWaterInlet_1_webp from "./webp/Cold_water_Inlet_1.webp";
import mainz_boilerRoom_webp from "./webp/Boiler_room.webp";
import mainz_boilerRoom_1_webp from "./webp/Boiler_room_1.webp";
import mainz_boilerRoom_2_webp from "./webp/Boiler_room_2.webp";
import mainz_boilerRoom_3_webp from "./webp/Boiler_room_3.webp";
import mainz_boilerRoom_map_webp from "./webp/Boiler_room_map.webp";

import mainz_staircase_png from "./png/Staircase.png";
import mainz_hallway_png from "./png/Hallway.png";
import mainz_gasPipe_png from "./png/Gas_pipe.png";
import mainz_electricityMeter_png from "./png/Electricity_meter.png";
import mainz_electricityMeter_1_png from "./png/Electricity_meter_1.png";
import mainz_electricityMeter_2_png from "./png/Electricity_meter_2.png";
import mainz_electricityMeter_3_png from "./png/Electricity_meter_3.png";
import mainz_coldWaterInlet_png from "./png/Cold_water_Inlet.png";
import mainz_coldWaterInlet_1_png from "./png/Cold_water_Inlet_1.png";
import mainz_boilerRoom_png from "./png/Boiler_room.png";
import mainz_boilerRoom_1_png from "./png/Boiler_room_1.png";
import mainz_boilerRoom_2_png from "./png/Boiler_room_2.png";
import mainz_boilerRoom_3_png from "./png/Boiler_room_3.png";
import mainz_boilerRoom_map_png from "./png/Boiler_room_map.png";

const mainz = {
    webp: {
        staircase: [mainz_staircase_webp],
        hallway: [mainz_hallway_webp],
        gasPipe: [mainz_gasPipe_webp],
        electricityMeter: [mainz_electricityMeter_webp, mainz_electricityMeter_1_webp, mainz_electricityMeter_2_webp, mainz_electricityMeter_3_webp],
        coldWaterInlet: [mainz_coldWaterInlet_webp, mainz_coldWaterInlet_1_webp],
        boilerRoom: [mainz_boilerRoom_webp, mainz_boilerRoom_1_webp, mainz_boilerRoom_2_webp, mainz_boilerRoom_3_webp, mainz_boilerRoom_map_webp]
    },
    png: {
        staircase: [mainz_staircase_png],
        hallway: [mainz_hallway_png],
        gasPipe: [mainz_gasPipe_png],
        electricityMeter: [mainz_electricityMeter_png, mainz_electricityMeter_1_png, mainz_electricityMeter_2_png, mainz_electricityMeter_3_png],
        coldWaterInlet: [mainz_coldWaterInlet_png, mainz_coldWaterInlet_1_png],
        boilerRoom: [mainz_boilerRoom_png, mainz_boilerRoom_1_png, mainz_boilerRoom_2_png, mainz_boilerRoom_3_png, mainz_boilerRoom_map_png]
    }
}

export { mainz };