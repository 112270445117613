import * as React from 'react';
import {TableCell, Table, TableContainer, TableHead, TableRow, Paper, TableBody} from "@mui/material";
import styles from '../styles/components/DenseTable.module.css';

const DenseTable = props => {
    const { header = [], children} = props;
    return (
        <TableContainer component={Paper} className={styles.table_wrap}>
            <Table size="small" aria-label="a dense table" className={styles.table}>
                <TableHead>
                    <TableRow>
                        {header.map((h = {}, i) => (
                            <TableCell key={i} align={h.align || 'center'} style={h.style}>{h.text || ''}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {children}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
export default DenseTable