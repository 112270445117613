import img_1_v2_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/Kaltwassereingang@2x.webp";
import img_1_v3_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/Kaltwassereingang@3x.webp";

import img_1_v2_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/Kaltwassereingang@2x.jpg";
import img_1_v3_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/Kaltwassereingang@3x.jpg";

import img_1_v2_ruckspulfilter_webp from "./02_Ruckspulfilter/webp/Ruckspulfilter@2x.webp";
import img_1_v3_ruckspulfilter_webp from "./02_Ruckspulfilter/webp/Ruckspulfilter@3x.webp";
import img_2_v2_ruckspulfilter_webp from "./02_Ruckspulfilter/webp/Ruckspulfilter_1@2x.webp";
import img_2_v3_ruckspulfilter_webp from "./02_Ruckspulfilter/webp/Ruckspulfilter_1@3x.webp";

import img_1_v2_ruckspulfilter_jpg from "./02_Ruckspulfilter/jpg/Ruckspulfilter@2x.jpg";
import img_1_v3_ruckspulfilter_jpg from "./02_Ruckspulfilter/jpg/Ruckspulfilter@3x.jpg";
import img_2_v2_ruckspulfilter_jpg from "./02_Ruckspulfilter/jpg/Ruckspulfilter_1@2x.jpg";
import img_2_v3_ruckspulfilter_jpg from "./02_Ruckspulfilter/jpg/Ruckspulfilter_1@3x.jpg";

import img_1_v2_speicherladepumpe_webp from "./03_Speicherladepumpe/webp/Speicherladepumpe@2x.webp";
import img_1_v3_speicherladepumpe_webp from "./03_Speicherladepumpe/webp/Speicherladepumpe@3x.webp";
import img_2_v2_speicherladepumpe_webp from "./03_Speicherladepumpe/webp/Speicherladepumpe_1@2x.webp";
import img_2_v3_speicherladepumpe_webp from "./03_Speicherladepumpe/webp/Speicherladepumpe_1@3x.webp";
import img_3_v2_speicherladepumpe_webp from "./03_Speicherladepumpe/webp/Speicherladepumpe_2@2x.webp";
import img_3_v3_speicherladepumpe_webp from "./03_Speicherladepumpe/webp/Speicherladepumpe_2@3x.webp";

import img_1_v2_speicherladepumpe_jpg from "./03_Speicherladepumpe/jpg/Speicherladepumpe@2x.jpg";
import img_1_v3_speicherladepumpe_jpg from "./03_Speicherladepumpe/jpg/Speicherladepumpe@3x.jpg";
import img_2_v2_speicherladepumpe_jpg from "./03_Speicherladepumpe/jpg/Speicherladepumpe_1@2x.jpg";
import img_2_v3_speicherladepumpe_jpg from "./03_Speicherladepumpe/jpg/Speicherladepumpe_1@3x.jpg";
import img_3_v2_speicherladepumpe_jpg from "./03_Speicherladepumpe/jpg/Speicherladepumpe_2@2x.jpg";
import img_3_v3_speicherladepumpe_jpg from "./03_Speicherladepumpe/jpg/Speicherladepumpe_2@3x.jpg";

import img_1_v2_warmwasserspeicher_webp from "./04_Warmwasserspeicher/webp/Warmwasserspeicher@2x.webp";
import img_1_v3_warmwasserspeicher_webp from "./04_Warmwasserspeicher/webp/Warmwasserspeicher@3x.webp";
import img_2_v2_warmwasserspeicher_webp from "./04_Warmwasserspeicher/webp/Warmwasserspeicher_1@2x.webp";
import img_2_v3_warmwasserspeicher_webp from "./04_Warmwasserspeicher/webp/Warmwasserspeicher_1@3x.webp";

import img_1_v2_warmwasserspeicher_jpg from "./04_Warmwasserspeicher/jpg/Warmwasserspeicher@2x.jpg";
import img_1_v3_warmwasserspeicher_jpg from "./04_Warmwasserspeicher/jpg/Warmwasserspeicher@3x.jpg";
import img_2_v2_warmwasserspeicher_jpg from "./04_Warmwasserspeicher/jpg/Warmwasserspeicher_1@2x.jpg";
import img_2_v3_warmwasserspeicher_jpg from "./04_Warmwasserspeicher/jpg/Warmwasserspeicher_1@3x.jpg";

import img_1_v2_zirkulationspumpe_webp from "./05_Zirkulationspumpe/webp/Zirkulationspumpe@2x.webp";
import img_1_v3_zirkulationspumpe_webp from "./05_Zirkulationspumpe/webp/Zirkulationspumpe@3x.webp";
import img_2_v2_zirkulationspumpe_webp from "./05_Zirkulationspumpe/webp/Zirkulationspumpe_1@2x.webp";
import img_2_v3_zirkulationspumpe_webp from "./05_Zirkulationspumpe/webp/Zirkulationspumpe_1@3x.webp";

import img_1_v2_zirkulationspumpe_jpg from "./05_Zirkulationspumpe/jpg/Zirkulationspumpe@2x.jpg";
import img_1_v3_zirkulationspumpe_jpg from "./05_Zirkulationspumpe/jpg/Zirkulationspumpe@3x.jpg";
import img_2_v2_zirkulationspumpe_jpg from "./05_Zirkulationspumpe/jpg/Zirkulationspumpe_1@2x.jpg";
import img_2_v3_zirkulationspumpe_jpg from "./05_Zirkulationspumpe/jpg/Zirkulationspumpe_1@3x.jpg";

const images_Plotzenseerstrabe = {
    'Kaltwassereingang': {
        webp: {
            img_1: [img_1_v2_kaltwassereingang_webp, img_1_v3_kaltwassereingang_webp]
        },
        png: {
            img_1: [img_1_v2_kaltwassereingang_jpg, img_1_v3_kaltwassereingang_jpg]
        }
    },
    'Ruckspulfilter': {
        webp: {
            img_1: [img_1_v2_ruckspulfilter_webp, img_1_v3_ruckspulfilter_webp],
            img_2: [img_2_v2_ruckspulfilter_webp, img_2_v3_ruckspulfilter_webp]
        },
        png: {
            img_1: [img_1_v2_ruckspulfilter_jpg, img_1_v3_ruckspulfilter_jpg],
            img_2: [img_2_v2_ruckspulfilter_jpg, img_2_v3_ruckspulfilter_jpg]
        }
    },
    'Speicherladepumpe': {
        webp: {
            img_1: [img_1_v2_speicherladepumpe_webp, img_1_v3_speicherladepumpe_webp],
            img_2: [img_2_v2_speicherladepumpe_webp, img_2_v3_speicherladepumpe_webp],
            img_3: [img_3_v2_speicherladepumpe_webp, img_3_v3_speicherladepumpe_webp]
        },
        png: {
            img_1: [img_1_v2_speicherladepumpe_jpg, img_1_v3_speicherladepumpe_jpg],
            img_2: [img_2_v2_speicherladepumpe_jpg, img_2_v3_speicherladepumpe_jpg],
            img_3: [img_3_v2_speicherladepumpe_jpg, img_3_v3_speicherladepumpe_jpg]
        }
    },
    'Warmwasserspeicher': {
        webp: {
            img_1: [img_1_v2_warmwasserspeicher_webp, img_1_v3_warmwasserspeicher_webp],
            img_2: [img_2_v2_warmwasserspeicher_webp, img_2_v3_warmwasserspeicher_webp]
        },
        png: {
            img_1: [img_1_v2_warmwasserspeicher_jpg, img_1_v3_warmwasserspeicher_jpg],
            img_2: [img_2_v2_warmwasserspeicher_jpg, img_2_v3_warmwasserspeicher_jpg]
        }
    },
    'Zirkulationspumpe': {
        webp: {
            img_1: [img_1_v2_zirkulationspumpe_webp, img_1_v3_zirkulationspumpe_webp],
            img_2: [img_2_v2_zirkulationspumpe_webp, img_2_v3_zirkulationspumpe_webp]
        },
        png: {
            img_1: [img_1_v2_zirkulationspumpe_jpg, img_1_v3_zirkulationspumpe_jpg],
            img_2: [img_2_v2_zirkulationspumpe_jpg, img_2_v3_zirkulationspumpe_jpg]
        }
    }
}

export { images_Plotzenseerstrabe };