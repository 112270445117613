import building_1_webp from "./webp/DSC08859.webp";
import building_2_webp from "./webp/DSC08856.webp";
import building_3_webp from "./webp/NG.webp";
import building_4_webp from "./webp/plot.webp";
import building_5_webp from "./webp/Nassauische.webp";
import building_6_webp from "./webp/Brusseler15.webp";
import building_7_webp from "./webp/Derfflinger32.webp";
import building_8_webp from "./webp/AmBlomkenberg.webp";
import building_def_webp from "./webp/4.webp";

import building_1_png from "./png/DSC08859.png";
import building_2_png from "./png/DSC08856.png";
import building_3_png from "./png/NG.png";
import building_4_png from "./png/plot.png";
import building_5_png from "./png/Nassauische.png";
import building_6_png from "./png/Brusseler15.png";
import building_7_png from "./png/Derfflinger32.png";
import building_8_png from "./png/AmBlomkenberg.png";
import building_def_png from "./png/4.png";

const buildings = {
    webp: { building_1_webp, building_2_webp, building_3_webp, building_4_webp, building_5_webp, building_6_webp, building_7_webp, building_8_webp, building_def_webp },
    png: { building_1_png, building_2_png, building_3_png, building_4_png, building_5_png, building_6_png, building_7_png, building_8_png, building_def_png }
}

export { buildings };
