import { takeEvery, all } from 'redux-saga/effects';
import * as userTypes from '../types/user';
import * as propertiesTypes from '../types/properties';
import * as profileTypes from '../types/profile';
import * as user from "./user";
import * as properties from "./properties";
import * as profile from "./profile";

export default function* appSagas(){
  yield all([
    yield takeEvery(userTypes.SIGN_IN, user.SignIn),
    yield takeEvery(userTypes.SIGN_OUT, user.SignOut),
    yield takeEvery(userTypes.CHECK_CONNECTION, user.checkConnection),

    yield takeEvery(propertiesTypes.GET_PROPERTIES, properties.getProperties),
    yield takeEvery(propertiesTypes.GET_PROPERTY, properties.getProperty),
    yield takeEvery(propertiesTypes.GET_CHART_DATA, properties._getChartData),

    yield takeEvery(profileTypes.GET_PROFILE_INFO, profile.GetProfileInfo),
    yield takeEvery(profileTypes.UPDATE_PROFILE_INFO, profile.UpdateProfileInfo),
  ])
}
