import img_webp from './webp/01_Complete_0001.webp';
import ChilledWaterImg_webp from './webp/23_Kaltwasser_0001.webp';
import heatingReturnFlowImg_webp from './webp/21_Heizrucklauf_0001.webp';
import heatingLeadTimeImg_webp from './webp/22_Heizvorlauf_0001.webp';
import hotWaterImg_webp from './webp/24_Warmwasser_0001.webp';
import circulationImg_webp from './webp/25_Zirkulation_0001.webp';

import img_png from './png/01_Complete_0001.png';
import ChilledWaterImg_png from './png/23_Kaltwasser_0001.png';
import heatingReturnFlowImg_png from './png/21_Heizrucklauf_0001.png';
import heatingLeadTimeImg_png from './png/22_Heizvorlauf_0001.png';
import hotWaterImg_png from './png/24_Warmwasser_0001.png';
import circulationImg_png from './png/25_Zirkulation_0001.png';

const performanceImgType8_filter = {
    webp: [img_webp, ChilledWaterImg_webp, heatingReturnFlowImg_webp, heatingLeadTimeImg_webp, hotWaterImg_webp, circulationImg_webp],
    png: [img_png, ChilledWaterImg_png, heatingReturnFlowImg_png, heatingLeadTimeImg_png, hotWaterImg_png, circulationImg_png]
}

export { performanceImgType8_filter }