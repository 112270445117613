import { images_Heimatring_8_9 } from "./8_9/details";
import { images_Heimatring_10_11 } from "./10_11/details";
import { images_Heimatring_12 } from "./12/details";
import { images_Heimatring_12_14 } from "./12_14/details";


const detailsHeimatring = {
    '8_9': images_Heimatring_8_9,
    '10_11': images_Heimatring_10_11,
    '12': images_Heimatring_12,
    '12_14': images_Heimatring_12_14
}

export { detailsHeimatring } ;