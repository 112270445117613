import { images_Unter_den_Akazien_3_4 } from "./3_4/details";
import { images_Unter_den_Akazien_7_9 } from "./7_9/details";
import { images_Unter_den_Akazien_10_12 } from "./10_12/details";

const detailsAnter_den_Akazien = {
    '3_4': images_Unter_den_Akazien_3_4,
    '7_9': images_Unter_den_Akazien_7_9,
    '10_12': images_Unter_den_Akazien_10_12
}

export { detailsAnter_den_Akazien };