import sensor_105_webp from "./webp/Hauswasserzahler.webp";
import sensor_105_v2_webp from "./webp/Hauswasserzahler@2x.webp";
import sensor_105_v3_webp from "./webp/Hauswasserzahler@3x.webp";
import sensor_107_webp from "./webp/Warmwasserspeicher.webp";
import sensor_107_v2_webp from "./webp/Warmwasserspeicher@2x.webp";
import sensor_107_v3_webp from "./webp/Warmwasserspeicher@3x.webp";
import sensor_108_webp from "./webp/Frischwasserstation.webp";
import sensor_108_v2_webp from "./webp/Frischwasserstation@2x.webp";
import sensor_108_v3_webp from "./webp/Frischwasserstation@3x.webp";
import sensor_109_webp from "./webp/Speicherladepumpe.webp";
import sensor_109_v2_webp from "./webp/Speicherladepumpe@2x.webp";
import sensor_109_v3_webp from "./webp/Speicherladepumpe@3x.webp";
import sensor_110_webp from "./webp/Zirculationspumpe.webp";
import sensor_110_v2_webp from "./webp/Zirculationspumpe@2x.webp";
import sensor_110_v3_webp from "./webp/Zirculationspumpe@3x.webp";
import sensor_111_webp from "./webp/Heizkreispumpe1.webp";
import sensor_111_v2_webp from "./webp/Heizkreispumpe1@2x.webp";
import sensor_111_v3_webp from "./webp/Heizkreispumpe1@3x.webp";
import sensor_112_webp from "./webp/Heizkreispumpe2.webp";
import sensor_112_v2_webp from "./webp/Heizkreispumpe2@2x.webp";
import sensor_112_v3_webp from "./webp/Heizkreispumpe2@3x.webp";
import sensor_113_webp from "./webp/Heizkreispumpe3.webp";
import sensor_113_v2_webp from "./webp/Heizkreispumpe3@2x.webp";
import sensor_113_v3_webp from "./webp/Heizkreispumpe3@3x.webp";

import sensor_105_jpg from "./jpg/Hauswasserzahler.jpg";
import sensor_105_v2_jpg from "./jpg/Hauswasserzahler@2x.jpg";
import sensor_105_v3_jpg from "./jpg/Hauswasserzahler@3x.jpg";
import sensor_107_jpg from "./jpg/Warmwasserspeicher.jpg";
import sensor_107_v2_jpg from "./jpg/Warmwasserspeicher@2x.jpg";
import sensor_107_v3_jpg from "./jpg/Warmwasserspeicher@3x.jpg";
import sensor_108_jpg from "./jpg/Frischwasserstation.jpg";
import sensor_108_v2_jpg from "./jpg/Frischwasserstation@2x.jpg";
import sensor_108_v3_jpg from "./jpg/Frischwasserstation@3x.jpg";
import sensor_109_jpg from "./jpg/Speicherladepumpe.jpg";
import sensor_109_v2_jpg from "./jpg/Speicherladepumpe@2x.jpg";
import sensor_109_v3_jpg from "./jpg/Speicherladepumpe@3x.jpg";
import sensor_110_jpg from "./jpg/Zirculationspumpe.jpg";
import sensor_110_v2_jpg from "./jpg/Zirculationspumpe@2x.jpg";
import sensor_110_v3_jpg from "./jpg/Zirculationspumpe@3x.jpg";
import sensor_111_jpg from "./jpg/Heizkreispumpe1.jpg";
import sensor_111_v2_jpg from "./jpg/Heizkreispumpe1@2x.jpg";
import sensor_111_v3_jpg from "./jpg/Heizkreispumpe1@3x.jpg";
import sensor_112_jpg from "./jpg/Heizkreispumpe2.jpg";
import sensor_112_v2_jpg from "./jpg/Heizkreispumpe2@2x.jpg";
import sensor_112_v3_jpg from "./jpg/Heizkreispumpe2@3x.jpg";
import sensor_113_jpg from "./jpg/Heizkreispumpe3.jpg";
import sensor_113_v2_jpg from "./jpg/Heizkreispumpe3@2x.jpg";
import sensor_113_v3_jpg from "./jpg/Heizkreispumpe3@3x.jpg";


const sBrusseler_15 = {
    webp: {
        s_105: [sensor_105_webp, sensor_105_v2_webp, sensor_105_v3_webp],
        s_107: [sensor_107_webp, sensor_107_v2_webp, sensor_107_v3_webp],
        s_108: [sensor_108_webp, sensor_108_v2_webp, sensor_108_v3_webp],
        s_109: [sensor_109_webp, sensor_109_v2_webp, sensor_109_v3_webp],
        s_110: [sensor_110_webp, sensor_110_v2_webp, sensor_110_v3_webp],
        s_111: [sensor_111_webp, sensor_111_v2_webp, sensor_111_v3_webp],
        s_112: [sensor_112_webp, sensor_112_v2_webp, sensor_112_v3_webp],
        s_113: [sensor_113_webp, sensor_113_v2_webp, sensor_113_v3_webp]
    },
    jpg: {
        s_105: [sensor_105_jpg, sensor_105_v2_jpg, sensor_105_v3_jpg],
        s_107: [sensor_107_jpg, sensor_107_v2_jpg, sensor_107_v3_jpg],
        s_108: [sensor_108_jpg, sensor_108_v2_jpg, sensor_108_v3_jpg],
        s_109: [sensor_109_jpg, sensor_109_v2_jpg, sensor_109_v3_jpg],
        s_110: [sensor_110_jpg, sensor_110_v2_jpg, sensor_110_v3_jpg],
        s_111: [sensor_111_jpg, sensor_111_v2_jpg, sensor_111_v3_jpg],
        s_112: [sensor_112_jpg, sensor_112_v2_jpg, sensor_112_v3_jpg],
        s_113: [sensor_113_jpg, sensor_113_v2_jpg, sensor_113_v3_jpg]
    }
}

export { sBrusseler_15 };