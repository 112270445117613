import img_1_v2_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/Kaltwasser@2x.webp";
import img_1_v3_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/Kaltwasser@3x.webp";
import img_2_v2_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/Kaltwasser_1@2x.webp";
import img_2_v3_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/Kaltwasser_1@3x.webp";

import img_1_v2_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/Kaltwasser@2x.jpg";
import img_1_v3_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/Kaltwasser@3x.jpg";
import img_2_v2_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/Kaltwasser_1@2x.jpg";
import img_2_v3_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/Kaltwasser_1@3x.jpg";

const images_Unter_den_Platanen_7_9 = {
    'Kaltwassereingang': {
        webp: {
            img_1: [img_1_v2_kaltwassereingang_webp, img_1_v3_kaltwassereingang_webp],
            img_2: [img_2_v2_kaltwassereingang_webp, img_2_v3_kaltwassereingang_webp]
        },
        jpg: {
            img_1: [img_1_v2_kaltwassereingang_jpg, img_1_v3_kaltwassereingang_jpg],
            img_2: [img_2_v2_kaltwassereingang_jpg, img_2_v3_kaltwassereingang_jpg]
        }
    }
}

export { images_Unter_den_Platanen_7_9 };