import rhein_staircase_house_5_webp from "./5/webp/Staircase_hous_5.webp";
import rhein_hallway_house_5_webp from "./5/webp/Hallway_hous_5.webp";
import rhein_hallway_1_house_5_webp from "./5/webp/Hallway_1_hous_5.webp";
import rhein_coldWaterInlet_house_5_webp from "./5/webp/Cold_water_Inlet_hous_5.webp";
import rhein_coldWaterInlet_1_house_5_webp from "./5/webp/Cold_water_Inlet_1_hous_5.webp";
import rhein_boilerRoom_house_5_webp from "./5/webp/Boiler_room_hous_5.webp";
import rhein_boilerRoom_1_house_5_webp from "./5/webp/Boiler_room_1_hous_5.webp";
import rhein_boilerRoom_2_house_5_webp from "./5/webp/Boiler_room_2_hous_5.webp";
import rhein_boilerRoom_3_house_5_webp from "./5/webp/Boiler_room_3_hous_5.webp";
import rhein_boilerRoom_4_house_5_webp from "./5/webp/Boiler_room_4_hous_5.webp";
import rhein_boilerRoom_map_house_5_webp from "./5/webp/Boiler_room_map.webp";
import rhein_gasPipe_house_5_webp from "./5/webp/Gas_pipe_hous_5.webp";

import rhein_staircase_house_7_webp from "./7/webp/Staircase_hous_7.webp";
import rhein_staircase_1_house_7_webp from "./7/webp/Staircase_1_hous_7.webp";
import rhein_hallway_house_7_webp from "./7/webp/Hallway_hous_7.webp";
import rhein_hallway_1_house_7_webp from "./7/webp/Hallway_1_hous_7.webp";
import rhein_hallway_2_house_7_webp from "./7/webp/Hallway_2_hous_7.webp";
import rhein_hallway_3_house_7_webp from "./7/webp/Hallway_3_hous_7.webp";
import rhein_hallway_4_house_7_webp from "./7/webp/Hallway_4_hous_7.webp";
import rhein_coldWaterInlet_house_7_webp from "./7/webp/Cold_water_Inlet_hous_7.webp";
import rhein_coldWaterInlet_1_house_7_webp from "./7/webp/Cold_water_Inlet_1_hous_7.webp";
import rhein_electricityMeter_house_7_webp from "./7/webp/Electricity_meter_hous_7.webp";
import rhein_electricityMeter_1_house_7_webp from "./7/webp/Electricity_meter_1_hous_7.webp";

import rhein_staircase_house_5_png from "./5/png/Staircase_hous_5.png";
import rhein_hallway_house_5_png from "./5/png/Hallway_hous_5.png";
import rhein_hallway_1_house_5_png from "./5/png/Hallway_1_hous_5.png";
import rhein_coldWaterInlet_house_5_png from "./5/png/Cold_water_Inlet_hous_5.png";
import rhein_coldWaterInlet_1_house_5_png from "./5/png/Cold_water_Inlet_1_hous_5.png";
import rhein_boilerRoom_house_5_png from "./5/png/Boiler_room_hous_5.png";
import rhein_boilerRoom_1_house_5_png from "./5/png/Boiler_room_1_hous_5.png";
import rhein_boilerRoom_2_house_5_png from "./5/png/Boiler_room_2_hous_5.png";
import rhein_boilerRoom_3_house_5_png from "./5/png/Boiler_room_3_hous_5.png";
import rhein_boilerRoom_4_house_5_png from "./5/png/Boiler_room_4_hous_5.png";
import rhein_boilerRoom_map_house_5_png from "./5/png/Boiler_room_map.png";
import rhein_gasPipe_house_5_png from "./5/png/Gas_pipe_hous_5.png";

import rhein_staircase_house_7_png from "./7/png/Staircase_hous_7.png";
import rhein_staircase_1_house_7_png from "./7/png/Staircase_1_hous_7.png";
import rhein_hallway_house_7_png from "./7/png/Hallway_hous_7.png";
import rhein_hallway_1_house_7_png from "./7/png/Hallway_1_hous_7.png";
import rhein_hallway_2_house_7_png from "./7/png/Hallway_2_hous_7.png";
import rhein_hallway_3_house_7_png from "./7/png/Hallway_3_hous_7.png";
import rhein_hallway_4_house_7_png from "./7/png/Hallway_4_hous_7.png";
import rhein_coldWaterInlet_house_7_png from "./7/png/Cold_water_Inlet_hous_7.png";
import rhein_coldWaterInlet_1_house_7_png from "./7/png/Cold_water_Inlet_1_hous_7.png";
import rhein_electricityMeter_house_7_png from "./7/png/Electricity_meter_hous_7.png";
import rhein_electricityMeter_1_house_7_png from "./7/png/Electricity_meter_1_hous_7.png";

const rhein5 = {
    webp: {
        staircase: [rhein_staircase_house_5_webp],
        hallway: [rhein_hallway_house_5_webp, rhein_hallway_1_house_5_webp],
        gasPipe: [rhein_gasPipe_house_5_webp],
        coldWaterInlet: [rhein_coldWaterInlet_house_5_webp, rhein_coldWaterInlet_1_house_5_webp],
        boilerRoom: [rhein_boilerRoom_house_5_webp, rhein_boilerRoom_1_house_5_webp, rhein_boilerRoom_2_house_5_webp, rhein_boilerRoom_3_house_5_webp, rhein_boilerRoom_4_house_5_webp, rhein_boilerRoom_map_house_5_webp]
    },
    png: {
        staircase: [rhein_staircase_house_5_png],
        hallway: [rhein_hallway_house_5_png, rhein_hallway_1_house_5_png],
        gasPipe: [rhein_gasPipe_house_5_png],
        coldWaterInlet: [rhein_coldWaterInlet_house_5_png, rhein_coldWaterInlet_1_house_5_png],
        boilerRoom: [rhein_boilerRoom_house_5_png, rhein_boilerRoom_1_house_5_png, rhein_boilerRoom_2_house_5_png, rhein_boilerRoom_3_house_5_png, rhein_boilerRoom_4_house_5_png, rhein_boilerRoom_map_house_5_png]
    }
}

const rhein7 = {
    webp: {
        staircase: [rhein_staircase_house_7_webp, rhein_staircase_1_house_7_webp],
        hallway: [rhein_hallway_house_7_webp, rhein_hallway_1_house_7_webp, rhein_hallway_2_house_7_webp, rhein_hallway_3_house_7_webp, rhein_hallway_4_house_7_webp],
        electricityMeter: [rhein_electricityMeter_house_7_webp, rhein_electricityMeter_1_house_7_webp],
        coldWaterInlet: [rhein_coldWaterInlet_house_7_webp, rhein_coldWaterInlet_1_house_7_webp],
    },
    png: {
        staircase: [rhein_staircase_house_7_png, rhein_staircase_1_house_7_png],
        hallway: [rhein_hallway_house_7_png, rhein_hallway_1_house_7_png, rhein_hallway_2_house_7_png, rhein_hallway_3_house_7_png, rhein_hallway_4_house_7_png],
        electricityMeter: [rhein_electricityMeter_house_7_png, rhein_electricityMeter_1_house_7_png],
        coldWaterInlet: [rhein_coldWaterInlet_house_7_png, rhein_coldWaterInlet_1_house_7_png],
    }
}

export { rhein5, rhein7 }