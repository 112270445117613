import { images_Unter_den_Linden_5_7 } from "./5_7/details";
import { images_Unter_den_Linden_14_16 } from "./14_16/details";
import { images_Unter_den_Linden_17_18 } from "./17_18/details";

const detailsUnter_den_Linden = {
    '5_7': images_Unter_den_Linden_5_7,
    '14_16': images_Unter_den_Linden_14_16,
    '17_18': images_Unter_den_Linden_17_18
}

export { detailsUnter_den_Linden };