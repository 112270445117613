import React from 'react';
import styles from '../../styles/components/MapPanel/BuildingCard.module.css'
import {propertyImgRule} from "../../utils/constants";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {useDispatch, useSelector} from "react-redux";
import {getActiveProperty, getImgSupport} from "../../redux/selectors";
import warningSVG from '../../icons/warning.svg'
import { buildings } from "../../images/buildings";
import {setActiveProperty} from "../../redux/actions";

const BuildingCard = ({card, i, show = true, isForMap = false, goTo = null}) => {
  const { sensors = [] } = card;
  const hasWarning = sensors.find(s => !s.status);
  const imgSupport = useSelector(getImgSupport);
  const activeProperty = useSelector(getActiveProperty);
  const dispatch = useDispatch();

  return (
    <button
      key={card._id}
      className={[
        styles.container
        + ' ' + (activeProperty === card._id && !isForMap ? styles.active : '')
        + ' ' + (show ? '' : styles.hidden)
        + ' ' + (isForMap ? '' : styles.layout_container)
      ]}
      onClick={() => {
        dispatch(setActiveProperty(card._id));
        if (goTo) goTo(card)
      }}
    >
      <div className={`flex-row flex-between ${styles.card_header}`}>
        <b><span className="bold">0{i+1}</span></b>
        {hasWarning ? (
          <img src={warningSVG} alt="" className={styles.warning_icon} />
        ) : null}
      </div>
      <LazyLoadImage
        wrapperClassName={styles.lazy_load_image}
        alt="gebäude bild"
        effect="blur"
        src={propertyImgRule[card._id]
            ? (imgSupport ? propertyImgRule[card._id].imgWebp : propertyImgRule[card._id].imgPng)
            : (imgSupport ? buildings.webp.building_def_webp : buildings.png.building_def_png)}
        width={198}
      />
      <b><span className="bold">{card.name}</span></b>
      <span>{card.zip}{' '}{card.city}</span>
    </button>
  )
};

export default BuildingCard;
