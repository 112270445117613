import img_1_v2_kaltwassereingang_webp from "./details/01_Kaltwassereingang/webp/Kaltwassereingang@2x.webp";
import img_1_v3_kaltwassereingang_webp from "./details/01_Kaltwassereingang/webp/Kaltwassereingang@3x.webp";
import img_2_v2_kaltwassereingang_webp from "./details/01_Kaltwassereingang/webp/Kaltwassereingang_1@2x.webp";
import img_2_v3_kaltwassereingang_webp from "./details/01_Kaltwassereingang/webp/Kaltwassereingang_1@3x.webp";

import img_1_v2_kaltwassereingang_jpg from "./details/01_Kaltwassereingang/jpg/Kaltwassereingang@2x.jpg";
import img_1_v3_kaltwassereingang_jpg from "./details/01_Kaltwassereingang/jpg/Kaltwassereingang@3x.jpg";
import img_2_v2_kaltwassereingang_jpg from "./details/01_Kaltwassereingang/jpg/Kaltwassereingang_1@2x.jpg";
import img_2_v3_kaltwassereingang_jpg from "./details/01_Kaltwassereingang/jpg/Kaltwassereingang_1@3x.jpg";

import img_1_v2_ruckspulfilter_webp from "./details/02_Ruckspulfilter/webp/Ruckspulfilter@2x.webp";
import img_1_v3_ruckspulfilter_webp from "./details/02_Ruckspulfilter/webp/Ruckspulfilter@3x.webp";
import img_2_v2_ruckspulfilter_webp from "./details/02_Ruckspulfilter/webp/Ruckspulfilter_1@2x.webp";
import img_2_v3_ruckspulfilter_webp from "./details/02_Ruckspulfilter/webp/Ruckspulfilter_1@3x.webp";

import img_1_v2_ruckspulfilter_jpg from "./details/02_Ruckspulfilter/jpg/Ruckspulfilter@2x.jpg";
import img_1_v3_ruckspulfilter_jpg from "./details/02_Ruckspulfilter/jpg/Ruckspulfilter@3x.jpg";
import img_2_v2_ruckspulfilter_jpg from "./details/02_Ruckspulfilter/jpg/Ruckspulfilter_1@2x.jpg";
import img_2_v3_ruckspulfilter_jpg from "./details/02_Ruckspulfilter/jpg/Ruckspulfilter_1@3x.jpg";

import img_1_v2_warmwasserspeicher_webp from "./details/03_Warmwasserspeicher/webp/Warmwasserbereitung@2x.webp";
import img_1_v3_warmwasserspeicher_webp from "./details/03_Warmwasserspeicher/webp/Warmwasserbereitung@3x.webp";
import img_2_v2_warmwasserspeicher_webp from "./details/03_Warmwasserspeicher/webp/Warmwasserbereitung_1@2x.webp";
import img_2_v3_warmwasserspeicher_webp from "./details/03_Warmwasserspeicher/webp/Warmwasserbereitung_1@3x.webp";

import img_1_v2_warmwasserspeicher_jpg from "./details/03_Warmwasserspeicher/jpg/Warmwasserbereitung@2x.jpg";
import img_1_v3_warmwasserspeicher_jpg from "./details/03_Warmwasserspeicher/jpg/Warmwasserbereitung@3x.jpg";
import img_2_v2_warmwasserspeicher_jpg from "./details/03_Warmwasserspeicher/jpg/Warmwasserbereitung_1@2x.jpg";
import img_2_v3_warmwasserspeicher_jpg from "./details/03_Warmwasserspeicher/jpg/Warmwasserbereitung_1@3x.jpg";

import img_1_v2_heizkreis_webp from "./details/04_Heizkreis/webp/Heizkreis@2x.webp";
import img_1_v3_heizkreis_webp from "./details/04_Heizkreis/webp/Heizkreis@3x.webp";
import img_2_v2_heizkreis_webp from "./details/04_Heizkreis/webp/Heizkreis_1@2x.webp";
import img_2_v3_heizkreis_webp from "./details/04_Heizkreis/webp/Heizkreis_1@3x.webp";

import img_1_v2_heizkreis_jpg from "./details/04_Heizkreis/jpg/Heizkreis@2x.jpg";
import img_1_v3_heizkreis_jpg from "./details/04_Heizkreis/jpg/Heizkreis@3x.jpg";
import img_2_v2_heizkreis_jpg from "./details/04_Heizkreis/jpg/Heizkreis_1@2x.jpg";
import img_2_v3_heizkreis_jpg from "./details/04_Heizkreis/jpg/Heizkreis_1@3x.jpg";

import img_1_v2_heizkessel_webp from "./details/05_Heizkessel/webp/Heizkessel@2x.webp";
import img_1_v3_heizkessel_webp from "./details/05_Heizkessel/webp/Heizkessel@3x.webp";

import img_1_v2_heizkessel_jpg from "./details/05_Heizkessel/jpg/Heizkessel@2x.jpg";
import img_1_v3_heizkessel_jpg from "./details/05_Heizkessel/jpg/Heizkessel@3x.jpg";

import img_1_v2_speicherladepumpe_webp from "./details/06_Speicherladepumpe/webp/Speicherladepumpe@2x.webp";
import img_1_v3_speicherladepumpe_webp from "./details/06_Speicherladepumpe/webp/Speicherladepumpe@3x.webp";
import img_2_v2_speicherladepumpe_webp from "./details/06_Speicherladepumpe/webp/Speicherladepumpe_1@2x.webp";
import img_2_v3_speicherladepumpe_webp from "./details/06_Speicherladepumpe/webp/Speicherladepumpe_1@3x.webp";

import img_1_v2_speicherladepumpe_jpg from "./details/06_Speicherladepumpe/jpg/Speicherladepumpe@2x.jpg";
import img_1_v3_speicherladepumpe_jpg from "./details/06_Speicherladepumpe/jpg/Speicherladepumpe@3x.jpg";
import img_2_v2_speicherladepumpe_jpg from "./details/06_Speicherladepumpe/jpg/Speicherladepumpe_1@2x.jpg";
import img_2_v3_speicherladepumpe_jpg from "./details/06_Speicherladepumpe/jpg/Speicherladepumpe_1@3x.jpg";

import img_1_v2_freshWaterStation_webp from "./details/07_Fresh_water_station/webp/Fresh_water_station@2x.webp";
import img_1_v3_freshWaterStation_webp from "./details/07_Fresh_water_station/webp/Fresh_water_station@3x.webp";
import img_2_v2_freshWaterStation_webp from "./details/07_Fresh_water_station/webp/Fresh_water_station_1@2x.webp";
import img_2_v3_freshWaterStation_webp from "./details/07_Fresh_water_station/webp/Fresh_water_station_1@3x.webp";
import img_3_v2_freshWaterStation_webp from "./details/07_Fresh_water_station/webp/Fresh_water_station_2@2x.webp";
import img_3_v3_freshWaterStation_webp from "./details/07_Fresh_water_station/webp/Fresh_water_station_2@3x.webp";
import img_4_v2_freshWaterStation_webp from "./details/07_Fresh_water_station/webp/Fresh_water_station_3@2x.webp";
import img_4_v3_freshWaterStation_webp from "./details/07_Fresh_water_station/webp/Fresh_water_station_3@3x.webp";

import img_1_v2_freshWaterStation_jpg from "./details/07_Fresh_water_station/jpg/Fresh_water_station@2x.jpg";
import img_1_v3_freshWaterStation_jpg from "./details/07_Fresh_water_station/jpg/Fresh_water_station@3x.jpg";
import img_2_v2_freshWaterStation_jpg from "./details/07_Fresh_water_station/jpg/Fresh_water_station_1@2x.jpg";
import img_2_v3_freshWaterStation_jpg from "./details/07_Fresh_water_station/jpg/Fresh_water_station_1@3x.jpg";
import img_3_v2_freshWaterStation_jpg from "./details/07_Fresh_water_station/jpg/Fresh_water_station_2@2x.jpg";
import img_3_v3_freshWaterStation_jpg from "./details/07_Fresh_water_station/jpg/Fresh_water_station_2@3x.jpg";
import img_4_v2_freshWaterStation_jpg from "./details/07_Fresh_water_station/jpg/Fresh_water_station_3@2x.jpg";
import img_4_v3_freshWaterStation_jpg from "./details/07_Fresh_water_station/jpg/Fresh_water_station_3@3x.jpg";

import img_1_v2_bhkw_webp from "./details/08_BHKW/webp/BHKW@2x.webp";
import img_1_v3_bhkw_webp from "./details/08_BHKW/webp/BHKW@3x.webp";
import img_2_v2_bhkw_webp from "./details/08_BHKW/webp/BHKW_1@2x.webp";
import img_2_v3_bhkw_webp from "./details/08_BHKW/webp/BHKW_1@3x.webp";

import img_1_v2_bhkw_jpg from "./details/08_BHKW/jpg/BHKW@2x.jpg";
import img_1_v3_bhkw_jpg from "./details/08_BHKW/jpg/BHKW@3x.jpg";
import img_2_v2_bhkw_jpg from "./details/08_BHKW/jpg/BHKW_1@2x.jpg";
import img_2_v3_bhkw_jpg from "./details/08_BHKW/jpg/BHKW_1@3x.jpg";

import img_1_v2_pufferspeicher_webp from "./details/09_Pufferspeicher/webp/Pufferspeicher@2x.webp";
import img_1_v3_pufferspeicher_webp from "./details/09_Pufferspeicher/webp/Pufferspeicher@3x.webp";
import img_2_v2_pufferspeicher_webp from "./details/09_Pufferspeicher/webp/Pufferspeicher_1@2x.webp";
import img_2_v3_pufferspeicher_webp from "./details/09_Pufferspeicher/webp/Pufferspeicher_1@3x.webp";

import img_1_v2_pufferspeicher_jpg from "./details/09_Pufferspeicher/jpg/Pufferspeicher@2x.jpg";
import img_1_v3_pufferspeicher_jpg from "./details/09_Pufferspeicher/jpg/Pufferspeicher@3x.jpg";
import img_2_v2_pufferspeicher_jpg from "./details/09_Pufferspeicher/jpg/Pufferspeicher_1@2x.jpg";
import img_2_v3_pufferspeicher_jpg from "./details/09_Pufferspeicher/jpg/Pufferspeicher_1@3x.jpg";

const images_AmBlomkenberg = {
    'Kaltwassereingang': {
        webp: {
            img_1: [img_1_v2_kaltwassereingang_webp, img_1_v3_kaltwassereingang_webp],
            img_2: [img_2_v2_kaltwassereingang_webp, img_2_v3_kaltwassereingang_webp]
        },
        jpg: {
            img_1: [img_1_v2_kaltwassereingang_jpg, img_1_v3_kaltwassereingang_jpg],
            img_2: [img_2_v2_kaltwassereingang_jpg, img_2_v3_kaltwassereingang_jpg]
        }
    },
    'Rukspulfilter': {
        webp: {
            img_1: [img_1_v2_ruckspulfilter_webp, img_1_v3_ruckspulfilter_webp],
            img_2: [img_2_v2_ruckspulfilter_webp, img_2_v3_ruckspulfilter_webp]
        },
        jpg: {
            img_1: [img_1_v2_ruckspulfilter_jpg, img_1_v3_ruckspulfilter_jpg],
            img_2: [img_2_v2_ruckspulfilter_jpg, img_2_v3_ruckspulfilter_jpg]
        }
    },
    'Warmwasserspeicher': {
        webp: {
            img_1: [img_1_v2_warmwasserspeicher_webp, img_1_v3_warmwasserspeicher_webp],
            img_2: [img_2_v2_warmwasserspeicher_webp, img_2_v3_warmwasserspeicher_webp]
        },
        jpg: {
            img_1: [img_1_v2_warmwasserspeicher_jpg, img_1_v3_warmwasserspeicher_jpg],
            img_2: [img_2_v2_warmwasserspeicher_jpg, img_2_v3_warmwasserspeicher_jpg]
        }
    },
    'Heizkreis': {
        webp: {
            img_1: [img_1_v2_heizkreis_webp, img_1_v3_heizkreis_webp],
            img_2: [img_2_v2_heizkreis_webp, img_2_v3_heizkreis_webp]
        },
        jpg: {
            img_1: [img_1_v2_heizkreis_jpg, img_1_v3_heizkreis_jpg],
            img_2: [img_2_v2_heizkreis_jpg, img_2_v3_heizkreis_jpg]
        }
    },
    'Heizkressel': {
        webp: {
            img_1: [img_1_v2_heizkessel_webp, img_1_v3_heizkessel_webp]
        },
        jpg: {
            img_1: [img_1_v2_heizkessel_jpg, img_1_v3_heizkessel_jpg]
        }
    },
    'Speicherladepumpe': {
        webp: {
            img_1: [img_1_v2_speicherladepumpe_webp, img_1_v3_speicherladepumpe_webp],
            img_2: [img_2_v2_speicherladepumpe_webp, img_2_v3_speicherladepumpe_webp]
        },
        jpg: {
            img_1: [img_1_v2_speicherladepumpe_jpg, img_1_v3_speicherladepumpe_jpg],
            img_2: [img_2_v2_speicherladepumpe_jpg, img_2_v3_speicherladepumpe_jpg]
        }
    },
    'FreshWaterStation': {
        webp: {
            img_1: [img_1_v2_freshWaterStation_webp, img_1_v3_freshWaterStation_webp],
            img_2: [img_2_v2_freshWaterStation_webp, img_2_v3_freshWaterStation_webp],
            img_3: [img_3_v2_freshWaterStation_webp, img_3_v3_freshWaterStation_webp],
            img_4: [img_4_v2_freshWaterStation_webp, img_4_v3_freshWaterStation_webp]
        },
        jpg: {
            img_1: [img_1_v2_freshWaterStation_jpg, img_1_v3_freshWaterStation_jpg],
            img_2: [img_2_v2_freshWaterStation_jpg, img_2_v3_freshWaterStation_jpg],
            img_3: [img_3_v2_freshWaterStation_jpg, img_3_v3_freshWaterStation_jpg],
            img_4: [img_4_v2_freshWaterStation_jpg, img_4_v3_freshWaterStation_jpg]
        }
    },
    'BNKW': {
        webp: {
            img_1: [img_1_v2_bhkw_webp, img_1_v3_bhkw_webp],
            img_2: [img_2_v2_bhkw_webp, img_2_v3_bhkw_webp]
        },
        jpg: {
            img_1: [img_1_v2_bhkw_jpg, img_1_v3_bhkw_jpg],
            img_2: [img_2_v2_bhkw_jpg, img_2_v3_bhkw_jpg]
        }
    },
    'Pufferspeicher': {
        webp: {
            img_1: [img_1_v2_pufferspeicher_webp, img_1_v3_pufferspeicher_webp],
            img_2: [img_2_v2_pufferspeicher_webp, img_2_v3_pufferspeicher_webp]
        },
        jpg: {
            img_1: [img_1_v2_pufferspeicher_jpg, img_1_v3_pufferspeicher_jpg],
            img_2: [img_2_v2_pufferspeicher_jpg, img_2_v3_pufferspeicher_jpg]
        }
    }
}

export { images_AmBlomkenberg };