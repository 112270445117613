
import img_1_v2_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/Kaltwasser@2x.webp";
import img_1_v3_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/Kaltwasser@3x.webp";
import img_2_v2_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/Kaltwasser_1@2x.webp";
import img_2_v3_kaltwassereingang_webp from "./01_Kaltwassereingang/webp/Kaltwasser_1@3x.webp";

import img_1_v2_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/Kaltwasser@2x.jpg";
import img_1_v3_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/Kaltwasser@3x.jpg";
import img_2_v2_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/Kaltwasser_1@2x.jpg";
import img_2_v3_kaltwassereingang_jpg from "./01_Kaltwassereingang/jpg/Kaltwasser_1@3x.jpg";

import img_1_v2_rukspulfilter_webp from "./02_Rukspulfilter/webp/Ruckspulfilter@2x.webp";
import img_1_v3_rukspulfilter_webp from "./02_Rukspulfilter/webp/Ruckspulfilter@3x.webp";
import img_2_v2_rukspulfilter_webp from "./02_Rukspulfilter/webp/Ruckspulfilter_1@2x.webp";
import img_2_v3_rukspulfilter_webp from "./02_Rukspulfilter/webp/Ruckspulfilter_1@3x.webp";

import img_1_v2_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/Ruckspulfilter@2x.jpg";
import img_1_v3_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/Ruckspulfilter@3x.jpg";
import img_2_v2_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/Ruckspulfilter_1@2x.jpg";
import img_2_v3_rukspulfilter_jpg from "./02_Rukspulfilter/jpg/Ruckspulfilter_1@3x.jpg";

import img_1_v2_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/Warmwasserbereitung@2x.webp";
import img_1_v3_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/Warmwasserbereitung@3x.webp";
import img_2_v2_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/Warmwasserbereitung_1@2x.webp";
import img_2_v3_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/Warmwasserbereitung_1@3x.webp";
import img_3_v2_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/Warmwasserbereitung_2@2x.webp";
import img_3_v3_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/Warmwasserbereitung_2@3x.webp";
import img_4_v2_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/Warmwasserbereitung_3@2x.webp";
import img_4_v3_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/Warmwasserbereitung_3@3x.webp";
import img_5_v2_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/Warmwasserbereitung_4@2x.webp";
import img_5_v3_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/Warmwasserbereitung_4@3x.webp";
import img_6_v2_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/Warmwasserbereitung_5@2x.webp";
import img_6_v3_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/Warmwasserbereitung_5@3x.webp";
import img_7_v2_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/Warmwasserbereitung_6@2x.webp";
import img_7_v3_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/Warmwasserbereitung_6@3x.webp";
import img_8_v2_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/Warmwasserbereitung_7@2x.webp";
import img_8_v3_warmwasserspeicher_webp from "./03_Warmwasserspeicher/webp/Warmwasserbereitung_7@3x.webp";

import img_1_v2_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/Warmwasserbereitung@2x.jpg";
import img_1_v3_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/Warmwasserbereitung@3x.jpg";
import img_2_v2_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/Warmwasserbereitung_1@2x.jpg";
import img_2_v3_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/Warmwasserbereitung_1@3x.jpg";
import img_3_v2_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/Warmwasserbereitung_2@2x.jpg";
import img_3_v3_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/Warmwasserbereitung_2@3x.jpg";
import img_4_v2_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/Warmwasserbereitung_3@2x.jpg";
import img_4_v3_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/Warmwasserbereitung_3@3x.jpg";
import img_5_v2_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/Warmwasserbereitung_4@2x.jpg";
import img_5_v3_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/Warmwasserbereitung_4@3x.jpg";
import img_6_v2_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/Warmwasserbereitung_5@2x.jpg";
import img_6_v3_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/Warmwasserbereitung_5@3x.jpg";
import img_7_v2_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/Warmwasserbereitung_6@2x.jpg";
import img_7_v3_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/Warmwasserbereitung_6@3x.jpg";
import img_8_v2_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/Warmwasserbereitung_7@2x.jpg";
import img_8_v3_warmwasserspeicher_jpg from "./03_Warmwasserspeicher/jpg/Warmwasserbereitung_7@3x.jpg";

import img_1_v2_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme@2x.webp";
import img_1_v3_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme@3x.webp";
import img_2_v2_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_1@2x.webp";
import img_2_v3_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_1@3x.webp";
import img_3_v2_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_2@2x.webp";
import img_3_v3_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_2@3x.webp";
import img_4_v2_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_3@2x.webp";
import img_4_v3_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_3@3x.webp";
import img_5_v2_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_4@2x.webp";
import img_5_v3_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_4@3x.webp";
import img_6_v2_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_5@2x.webp";
import img_6_v3_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_5@3x.webp";
import img_7_v2_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_6@2x.webp";
import img_7_v3_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_6@3x.webp";
import img_8_v2_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_7@2x.webp";
import img_8_v3_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_7@3x.webp";
import img_9_v2_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_8@2x.webp";
import img_9_v3_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_8@3x.webp";
import img_10_v2_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_9@2x.webp";
import img_10_v3_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_9@3x.webp";
import img_11_v2_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_10@2x.webp";
import img_11_v3_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_10@3x.webp";
import img_12_v2_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_11@2x.webp";
import img_12_v3_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_11@3x.webp";
import img_13_v2_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_12@2x.webp";
import img_13_v3_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_12@3x.webp";
import img_14_v2_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_13@2x.webp";
import img_14_v3_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_13@3x.webp";
import img_15_v2_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_14@2x.webp";
import img_15_v3_fernwarme_webp from "./04_Fernwarme/webp/Fernwarme_14@3x.webp";

import img_1_v2_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme@2x.jpg";
import img_1_v3_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme@3x.jpg";
import img_2_v2_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_1@2x.jpg";
import img_2_v3_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_1@3x.jpg";
import img_3_v2_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_2@2x.jpg";
import img_3_v3_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_2@3x.jpg";
import img_4_v2_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_3@2x.jpg";
import img_4_v3_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_3@3x.jpg";
import img_5_v2_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_4@2x.jpg";
import img_5_v3_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_4@3x.jpg";
import img_6_v2_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_5@2x.jpg";
import img_6_v3_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_5@3x.jpg";
import img_7_v2_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_6@2x.jpg";
import img_7_v3_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_6@3x.jpg";
import img_8_v2_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_7@2x.jpg";
import img_8_v3_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_7@3x.jpg";
import img_9_v2_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_8@2x.jpg";
import img_9_v3_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_8@3x.jpg";
import img_10_v2_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_9@2x.jpg";
import img_10_v3_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_9@3x.jpg";
import img_11_v2_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_10@2x.jpg";
import img_11_v3_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_10@3x.jpg";
import img_12_v2_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_11@2x.jpg";
import img_12_v3_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_11@3x.jpg";
import img_13_v2_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_12@2x.jpg";
import img_13_v3_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_12@3x.jpg";
import img_14_v2_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_13@2x.jpg";
import img_14_v3_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_13@3x.jpg";
import img_15_v2_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_14@2x.jpg";
import img_15_v3_fernwarme_jpg from "./04_Fernwarme/jpg/Fernwarme_14@3x.jpg";

import img_1_v2_heizkreispumpe_1_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe_1@2x.webp";
import img_1_v3_heizkreispumpe_1_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe_1@3x.webp";
import img_1_v2_heizkreispumpe_2_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe_2@2x.webp";
import img_1_v3_heizkreispumpe_2_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe_2@3x.webp";
import img_2_v2_heizkreispumpe_2_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe_2_1@2x.webp";
import img_2_v3_heizkreispumpe_2_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe_2_1@3x.webp";
import img_1_v2_heizkreispumpe_3_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe_3@2x.webp";
import img_1_v3_heizkreispumpe_3_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe_3@3x.webp";
import img_2_v2_heizkreispumpe_3_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe_3_1@2x.webp";
import img_2_v3_heizkreispumpe_3_webp from "./05_Heizkreispumpe/webp/Heizkreispumpe_3_1@3x.webp";

import img_1_v2_heizkreispumpe_1_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe_1@2x.jpg";
import img_1_v3_heizkreispumpe_1_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe_1@3x.jpg";
import img_1_v2_heizkreispumpe_2_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe_2@2x.jpg";
import img_1_v3_heizkreispumpe_2_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe_2@3x.jpg";
import img_2_v2_heizkreispumpe_2_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe_2_1@2x.jpg";
import img_2_v3_heizkreispumpe_2_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe_2_1@3x.jpg";
import img_1_v2_heizkreispumpe_3_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe_3@2x.jpg";
import img_1_v3_heizkreispumpe_3_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe_3@3x.jpg";
import img_2_v2_heizkreispumpe_3_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe_3_1@2x.jpg";
import img_2_v3_heizkreispumpe_3_jpg from "./05_Heizkreispumpe/jpg/Heizkreispumpe_3_1@3x.jpg";

import img_1_v2_zirkulationspumpe_webp from "./06_Zirkulationspumpe/webp/Zirkulationspumpe@2x.webp";
import img_1_v3_zirkulationspumpe_webp from "./06_Zirkulationspumpe/webp/Zirkulationspumpe@3x.webp";
import img_2_v2_zirkulationspumpe_webp from "./06_Zirkulationspumpe/webp/Zirkulationspumpe_1@2x.webp";
import img_2_v3_zirkulationspumpe_webp from "./06_Zirkulationspumpe/webp/Zirkulationspumpe_1@3x.webp";

import img_1_v2_zirkulationspumpe_jpg from "./06_Zirkulationspumpe/jpg/Zirkulationspumpe@2x.jpg";
import img_1_v3_zirkulationspumpe_jpg from "./06_Zirkulationspumpe/jpg/Zirkulationspumpe@3x.jpg";
import img_2_v2_zirkulationspumpe_jpg from "./06_Zirkulationspumpe/jpg/Zirkulationspumpe_1@2x.jpg";
import img_2_v3_zirkulationspumpe_jpg from "./06_Zirkulationspumpe/jpg/Zirkulationspumpe_1@3x.jpg";

import img_1_v2_speicherladekreis_webp from "./07_Speicherladekreis/webp/Speicherladekreis@2x.webp";
import img_1_v3_speicherladekreis_webp from "./07_Speicherladekreis/webp/Speicherladekreis@3x.webp";
import img_2_v2_speicherladekreis_webp from "./07_Speicherladekreis/webp/Speicherladekreis_1@2x.webp";
import img_2_v3_speicherladekreis_webp from "./07_Speicherladekreis/webp/Speicherladekreis_1@3x.webp";
import img_3_v2_speicherladekreis_webp from "./07_Speicherladekreis/webp/Speicherladekreis_2@2x.webp";
import img_3_v3_speicherladekreis_webp from "./07_Speicherladekreis/webp/Speicherladekreis_2@3x.webp";
import img_4_v2_speicherladekreis_webp from "./07_Speicherladekreis/webp/Speicherladekreis_3@2x.webp";
import img_4_v3_speicherladekreis_webp from "./07_Speicherladekreis/webp/Speicherladekreis_3@3x.webp";
import img_5_v2_speicherladekreis_webp from "./07_Speicherladekreis/webp/Speicherladekreis_4@2x.webp";
import img_5_v3_speicherladekreis_webp from "./07_Speicherladekreis/webp/Speicherladekreis_4@3x.webp";
import img_6_v2_speicherladekreis_webp from "./07_Speicherladekreis/webp/Speicherladekreis_5@2x.webp";
import img_6_v3_speicherladekreis_webp from "./07_Speicherladekreis/webp/Speicherladekreis_5@3x.webp";
import img_7_v2_speicherladekreis_webp from "./07_Speicherladekreis/webp/Speicherladekreis_6@2x.webp";
import img_7_v3_speicherladekreis_webp from "./07_Speicherladekreis/webp/Speicherladekreis_6@3x.webp";
import img_8_v2_speicherladekreis_webp from "./07_Speicherladekreis/webp/Speicherladekreis_7@2x.webp";
import img_8_v3_speicherladekreis_webp from "./07_Speicherladekreis/webp/Speicherladekreis_7@3x.webp";
import img_9_v2_speicherladekreis_webp from "./07_Speicherladekreis/webp/Speicherladekreis_8@2x.webp";
import img_9_v3_speicherladekreis_webp from "./07_Speicherladekreis/webp/Speicherladekreis_8@3x.webp";
import img_10_v2_speicherladekreis_webp from "./07_Speicherladekreis/webp/Speicherladekreis_9@2x.webp";
import img_10_v3_speicherladekreis_webp from "./07_Speicherladekreis/webp/Speicherladekreis_9@3x.webp";

import img_1_v2_speicherladekreis_jpg from "./07_Speicherladekreis/jpg/Speicherladekreis@2x.jpg";
import img_1_v3_speicherladekreis_jpg from "./07_Speicherladekreis/jpg/Speicherladekreis@3x.jpg";
import img_2_v2_speicherladekreis_jpg from "./07_Speicherladekreis/jpg/Speicherladekreis_1@2x.jpg";
import img_2_v3_speicherladekreis_jpg from "./07_Speicherladekreis/jpg/Speicherladekreis_1@3x.jpg";
import img_3_v2_speicherladekreis_jpg from "./07_Speicherladekreis/jpg/Speicherladekreis_2@2x.jpg";
import img_3_v3_speicherladekreis_jpg from "./07_Speicherladekreis/jpg/Speicherladekreis_2@3x.jpg";
import img_4_v2_speicherladekreis_jpg from "./07_Speicherladekreis/jpg/Speicherladekreis_3@2x.jpg";
import img_4_v3_speicherladekreis_jpg from "./07_Speicherladekreis/jpg/Speicherladekreis_3@3x.jpg";
import img_5_v2_speicherladekreis_jpg from "./07_Speicherladekreis/jpg/Speicherladekreis_4@2x.jpg";
import img_5_v3_speicherladekreis_jpg from "./07_Speicherladekreis/jpg/Speicherladekreis_4@3x.jpg";
import img_6_v2_speicherladekreis_jpg from "./07_Speicherladekreis/jpg/Speicherladekreis_5@2x.jpg";
import img_6_v3_speicherladekreis_jpg from "./07_Speicherladekreis/jpg/Speicherladekreis_5@3x.jpg";
import img_7_v2_speicherladekreis_jpg from "./07_Speicherladekreis/jpg/Speicherladekreis_6@2x.jpg";
import img_7_v3_speicherladekreis_jpg from "./07_Speicherladekreis/jpg/Speicherladekreis_6@3x.jpg";
import img_8_v2_speicherladekreis_jpg from "./07_Speicherladekreis/jpg/Speicherladekreis_7@2x.jpg";
import img_8_v3_speicherladekreis_jpg from "./07_Speicherladekreis/jpg/Speicherladekreis_7@3x.jpg";
import img_9_v2_speicherladekreis_jpg from "./07_Speicherladekreis/jpg/Speicherladekreis_8@2x.jpg";
import img_9_v3_speicherladekreis_jpg from "./07_Speicherladekreis/jpg/Speicherladekreis_8@3x.jpg";
import img_10_v2_speicherladekreis_jpg from "./07_Speicherladekreis/jpg/Speicherladekreis_9@2x.jpg";
import img_10_v3_speicherladekreis_jpg from "./07_Speicherladekreis/jpg/Speicherladekreis_9@3x.jpg";

import img_1_v2_heizkreis_1_webp from "./08_Heizkreis/webp/Heizkreis_1@2x.webp";
import img_1_v3_heizkreis_1_webp from "./08_Heizkreis/webp/Heizkreis_1@3x.webp";
import img_2_v2_heizkreis_1_webp from "./08_Heizkreis/webp/Heizkreis_1_1@2x.webp";
import img_2_v3_heizkreis_1_webp from "./08_Heizkreis/webp/Heizkreis_1_1@3x.webp";
import img_3_v2_heizkreis_1_webp from "./08_Heizkreis/webp/Heizkreis_1_2@2x.webp";
import img_3_v3_heizkreis_1_webp from "./08_Heizkreis/webp/Heizkreis_1_2@3x.webp";
import img_1_v2_heizkreis_2_webp from "./08_Heizkreis/webp/Heizkreis_2@2x.webp";
import img_1_v3_heizkreis_2_webp from "./08_Heizkreis/webp/Heizkreis_2@3x.webp";
import img_2_v2_heizkreis_2_webp from "./08_Heizkreis/webp/Heizkreis_2_1@2x.webp";
import img_2_v3_heizkreis_2_webp from "./08_Heizkreis/webp/Heizkreis_2_1@3x.webp";
import img_3_v2_heizkreis_2_webp from "./08_Heizkreis/webp/Heizkreis_2_2@2x.webp";
import img_3_v3_heizkreis_2_webp from "./08_Heizkreis/webp/Heizkreis_2_2@3x.webp";
import img_1_v2_heizkreis_3_webp from "./08_Heizkreis/webp/Heizkreis_3@2x.webp";
import img_1_v3_heizkreis_3_webp from "./08_Heizkreis/webp/Heizkreis_3@3x.webp";
import img_2_v2_heizkreis_3_webp from "./08_Heizkreis/webp/Heizkreis_3_1@2x.webp";
import img_2_v3_heizkreis_3_webp from "./08_Heizkreis/webp/Heizkreis_3_1@3x.webp";
import img_3_v2_heizkreis_3_webp from "./08_Heizkreis/webp/Heizkreis_3_2@2x.webp";
import img_3_v3_heizkreis_3_webp from "./08_Heizkreis/webp/Heizkreis_3_2@3x.webp";

import img_1_v2_heizkreis_1_jpg from "./08_Heizkreis/jpg/Heizkreis_1@2x.jpg";
import img_1_v3_heizkreis_1_jpg from "./08_Heizkreis/jpg/Heizkreis_1@3x.jpg";
import img_2_v2_heizkreis_1_jpg from "./08_Heizkreis/jpg/Heizkreis_1_1@2x.jpg";
import img_2_v3_heizkreis_1_jpg from "./08_Heizkreis/jpg/Heizkreis_1_1@3x.jpg";
import img_3_v2_heizkreis_1_jpg from "./08_Heizkreis/jpg/Heizkreis_1_2@2x.jpg";
import img_3_v3_heizkreis_1_jpg from "./08_Heizkreis/jpg/Heizkreis_1_2@3x.jpg";
import img_1_v2_heizkreis_2_jpg from "./08_Heizkreis/jpg/Heizkreis_2@2x.jpg";
import img_1_v3_heizkreis_2_jpg from "./08_Heizkreis/jpg/Heizkreis_2@3x.jpg";
import img_2_v2_heizkreis_2_jpg from "./08_Heizkreis/jpg/Heizkreis_2_1@2x.jpg";
import img_2_v3_heizkreis_2_jpg from "./08_Heizkreis/jpg/Heizkreis_2_1@3x.jpg";
import img_3_v2_heizkreis_2_jpg from "./08_Heizkreis/jpg/Heizkreis_2_2@2x.jpg";
import img_3_v3_heizkreis_2_jpg from "./08_Heizkreis/jpg/Heizkreis_2_2@3x.jpg";
import img_1_v2_heizkreis_3_jpg from "./08_Heizkreis/jpg/Heizkreis_3@2x.jpg";
import img_1_v3_heizkreis_3_jpg from "./08_Heizkreis/jpg/Heizkreis_3@3x.jpg";
import img_2_v2_heizkreis_3_jpg from "./08_Heizkreis/jpg/Heizkreis_3_1@2x.jpg";
import img_2_v3_heizkreis_3_jpg from "./08_Heizkreis/jpg/Heizkreis_3_1@3x.jpg";
import img_3_v2_heizkreis_3_jpg from "./08_Heizkreis/jpg/Heizkreis_3_2@2x.jpg";
import img_3_v3_heizkreis_3_jpg from "./08_Heizkreis/jpg/Heizkreis_3_2@3x.jpg";

import img_1_v2_abwasser_webp from "./09_Abwasser/webp/Abwasser@2x.webp";
import img_1_v3_abwasser_webp from "./09_Abwasser/webp/Abwasser@3x.webp";
import img_2_v2_abwasser_webp from "./09_Abwasser/webp/Abwasser_1@2x.webp";
import img_2_v3_abwasser_webp from "./09_Abwasser/webp/Abwasser_1@3x.webp";

import img_1_v2_abwasser_jpg from "./09_Abwasser/jpg/Abwasser@2x.jpg";
import img_1_v3_abwasser_jpg from "./09_Abwasser/jpg/Abwasser@3x.jpg";
import img_2_v2_abwasser_jpg from "./09_Abwasser/jpg/Abwasser_1@2x.jpg";
import img_2_v3_abwasser_jpg from "./09_Abwasser/jpg/Abwasser_1@3x.jpg";

const images_Heimatring_12 = {
    'Kaltwassereingang': {
        webp: {
            img_1: [img_1_v2_kaltwassereingang_webp, img_1_v3_kaltwassereingang_webp],
            img_2: [img_2_v2_kaltwassereingang_webp, img_2_v3_kaltwassereingang_webp],
        },
        jpg: {
            img_1: [img_1_v2_kaltwassereingang_jpg, img_1_v3_kaltwassereingang_jpg],
            img_2: [img_2_v2_kaltwassereingang_jpg, img_2_v3_kaltwassereingang_jpg],
        }
    },
    'Rukspulfilter': {
        webp: {
            img_1: [img_1_v2_rukspulfilter_webp, img_1_v3_rukspulfilter_webp],
            img_2: [img_2_v2_rukspulfilter_webp, img_2_v3_rukspulfilter_webp],
        },
        jpg: {
            img_1: [img_1_v2_rukspulfilter_jpg, img_1_v3_rukspulfilter_jpg],
            img_2: [img_2_v2_rukspulfilter_jpg, img_2_v3_rukspulfilter_jpg],
        }
    },
    'Warmwasserspeicher': {
        webp: {
            img_1: [img_1_v2_warmwasserspeicher_webp, img_1_v3_warmwasserspeicher_webp],
            img_2: [img_2_v2_warmwasserspeicher_webp, img_2_v3_warmwasserspeicher_webp],
            img_3: [img_3_v2_warmwasserspeicher_webp, img_3_v3_warmwasserspeicher_webp],
            img_4: [img_4_v2_warmwasserspeicher_webp, img_4_v3_warmwasserspeicher_webp],
            img_5: [img_5_v2_warmwasserspeicher_webp, img_5_v3_warmwasserspeicher_webp],
            img_6: [img_6_v2_warmwasserspeicher_webp, img_6_v3_warmwasserspeicher_webp],
            img_7: [img_7_v2_warmwasserspeicher_webp, img_7_v3_warmwasserspeicher_webp],
            img_8: [img_8_v2_warmwasserspeicher_webp, img_8_v3_warmwasserspeicher_webp]
        },
        jpg: {
            img_1: [img_1_v2_warmwasserspeicher_jpg, img_1_v3_warmwasserspeicher_jpg],
            img_2: [img_2_v2_warmwasserspeicher_jpg, img_2_v3_warmwasserspeicher_jpg],
            img_3: [img_3_v2_warmwasserspeicher_jpg, img_3_v3_warmwasserspeicher_jpg],
            img_4: [img_4_v2_warmwasserspeicher_jpg, img_4_v3_warmwasserspeicher_jpg],
            img_5: [img_5_v2_warmwasserspeicher_jpg, img_5_v3_warmwasserspeicher_jpg],
            img_6: [img_6_v2_warmwasserspeicher_jpg, img_6_v3_warmwasserspeicher_jpg],
            img_7: [img_7_v2_warmwasserspeicher_jpg, img_7_v3_warmwasserspeicher_jpg],
            img_8: [img_8_v2_warmwasserspeicher_jpg, img_8_v3_warmwasserspeicher_jpg]
        }
    },
    'Fernwarme': {
        webp: {
            img_1: [img_1_v2_fernwarme_webp, img_1_v3_fernwarme_webp],
            img_2: [img_2_v2_fernwarme_webp, img_2_v3_fernwarme_webp],
            img_3: [img_3_v2_fernwarme_webp, img_3_v3_fernwarme_webp],
            img_4: [img_4_v2_fernwarme_webp, img_4_v3_fernwarme_webp],
            img_5: [img_5_v2_fernwarme_webp, img_5_v3_fernwarme_webp],
            img_6: [img_6_v2_fernwarme_webp, img_6_v3_fernwarme_webp],
            img_7: [img_7_v2_fernwarme_webp, img_7_v3_fernwarme_webp],
            img_8: [img_8_v2_fernwarme_webp, img_8_v3_fernwarme_webp],
            img_9: [img_9_v2_fernwarme_webp, img_9_v3_fernwarme_webp],
            img_10: [img_10_v2_fernwarme_webp, img_10_v3_fernwarme_webp],
            img_11: [img_11_v2_fernwarme_webp, img_11_v3_fernwarme_webp],
            img_12: [img_12_v2_fernwarme_webp, img_12_v3_fernwarme_webp],
            img_13: [img_13_v2_fernwarme_webp, img_13_v3_fernwarme_webp],
            img_14: [img_14_v2_fernwarme_webp, img_14_v3_fernwarme_webp],
            img_15: [img_15_v2_fernwarme_webp, img_15_v3_fernwarme_webp]
        },
        jpg: {
            img_1: [img_1_v2_fernwarme_jpg, img_1_v3_fernwarme_jpg],
            img_2: [img_2_v2_fernwarme_jpg, img_2_v3_fernwarme_jpg],
            img_3: [img_3_v2_fernwarme_jpg, img_3_v3_fernwarme_jpg],
            img_4: [img_4_v2_fernwarme_jpg, img_4_v3_fernwarme_jpg],
            img_5: [img_5_v2_fernwarme_jpg, img_5_v3_fernwarme_jpg],
            img_6: [img_6_v2_fernwarme_jpg, img_6_v3_fernwarme_jpg],
            img_7: [img_7_v2_fernwarme_jpg, img_7_v3_fernwarme_jpg],
            img_8: [img_8_v2_fernwarme_jpg, img_8_v3_fernwarme_jpg],
            img_9: [img_9_v2_fernwarme_jpg, img_9_v3_fernwarme_jpg],
            img_10: [img_10_v2_fernwarme_jpg, img_10_v3_fernwarme_jpg],
            img_11: [img_11_v2_fernwarme_jpg, img_11_v3_fernwarme_jpg],
            img_12: [img_12_v2_fernwarme_jpg, img_12_v3_fernwarme_jpg],
            img_13: [img_13_v2_fernwarme_jpg, img_13_v3_fernwarme_jpg],
            img_14: [img_14_v2_fernwarme_jpg, img_14_v3_fernwarme_jpg],
            img_15: [img_15_v2_fernwarme_jpg, img_15_v3_fernwarme_jpg]
        }
    },
    'Heizkreispumpe': {
        'Heizkreispumpe_1': {
            webp: {
                img_1: [img_1_v2_heizkreispumpe_1_webp, img_1_v3_heizkreispumpe_1_webp]
            },
            jpg: {
                img_1: [img_1_v2_heizkreispumpe_1_jpg, img_1_v3_heizkreispumpe_1_jpg]
            }
        },
        'Heizkreispumpe_2': {
            webp: {
                img_1: [img_1_v2_heizkreispumpe_2_webp, img_1_v3_heizkreispumpe_2_webp],
                img_2: [img_2_v2_heizkreispumpe_2_webp, img_2_v3_heizkreispumpe_2_webp]
            },
            jpg: {
                img_1: [img_1_v2_heizkreispumpe_2_jpg, img_1_v3_heizkreispumpe_2_jpg],
                img_2: [img_2_v2_heizkreispumpe_2_jpg, img_2_v3_heizkreispumpe_2_jpg]
            }
        },
        'Heizkreispumpe_3': {
            webp: {
                img_1: [img_1_v2_heizkreispumpe_3_webp, img_1_v3_heizkreispumpe_3_webp],
                img_2: [img_2_v2_heizkreispumpe_3_webp, img_2_v3_heizkreispumpe_3_webp]
            },
            jpg: {
                img_1: [img_1_v2_heizkreispumpe_3_jpg, img_1_v3_heizkreispumpe_3_jpg],
                img_2: [img_2_v2_heizkreispumpe_3_jpg, img_2_v3_heizkreispumpe_3_jpg]
            }
        }
    },
    'Zirkulationspumpe': {
        webp: {
            img_1: [img_1_v2_zirkulationspumpe_webp, img_1_v3_zirkulationspumpe_webp],
            img_2: [img_2_v2_zirkulationspumpe_webp, img_2_v3_zirkulationspumpe_webp],
        },
        jpg: {
            img_1: [img_1_v2_zirkulationspumpe_jpg, img_1_v3_zirkulationspumpe_jpg],
            img_2: [img_2_v2_zirkulationspumpe_jpg, img_2_v3_zirkulationspumpe_jpg],
        }
    },
    'Speicherladekreis': {
        webp: {
            img_1: [img_1_v2_speicherladekreis_webp, img_1_v3_speicherladekreis_webp],
            img_2: [img_2_v2_speicherladekreis_webp, img_2_v3_speicherladekreis_webp],
            img_3: [img_3_v2_speicherladekreis_webp, img_3_v3_speicherladekreis_webp],
            img_4: [img_4_v2_speicherladekreis_webp, img_4_v3_speicherladekreis_webp],
            img_5: [img_5_v2_speicherladekreis_webp, img_5_v3_speicherladekreis_webp],
            img_6: [img_6_v2_speicherladekreis_webp, img_6_v3_speicherladekreis_webp],
            img_7: [img_7_v2_speicherladekreis_webp, img_7_v3_speicherladekreis_webp],
            img_8: [img_8_v2_speicherladekreis_webp, img_8_v3_speicherladekreis_webp],
            img_9: [img_9_v2_speicherladekreis_webp, img_9_v3_speicherladekreis_webp],
            img_10: [img_10_v2_speicherladekreis_webp, img_10_v3_speicherladekreis_webp]
        },
        jpg: {
            img_1: [img_1_v2_speicherladekreis_jpg, img_1_v3_speicherladekreis_jpg],
            img_2: [img_2_v2_speicherladekreis_jpg, img_2_v3_speicherladekreis_jpg],
            img_3: [img_3_v2_speicherladekreis_jpg, img_3_v3_speicherladekreis_jpg],
            img_4: [img_4_v2_speicherladekreis_jpg, img_4_v3_speicherladekreis_jpg],
            img_5: [img_5_v2_speicherladekreis_jpg, img_5_v3_speicherladekreis_jpg],
            img_6: [img_6_v2_speicherladekreis_jpg, img_6_v3_speicherladekreis_jpg],
            img_7: [img_7_v2_speicherladekreis_jpg, img_7_v3_speicherladekreis_jpg],
            img_8: [img_8_v2_speicherladekreis_jpg, img_8_v3_speicherladekreis_jpg],
            img_9: [img_9_v2_speicherladekreis_jpg, img_9_v3_speicherladekreis_jpg],
            img_10: [img_10_v2_speicherladekreis_jpg, img_10_v3_speicherladekreis_jpg]
        }
    },
    'Heizkreis': {
        'Heizkreis_1': {
            webp: {
                img_1: [img_1_v2_heizkreis_1_webp, img_1_v3_heizkreis_1_webp],
                img_2: [img_2_v2_heizkreis_1_webp, img_2_v3_heizkreis_1_webp],
                img_3: [img_3_v2_heizkreis_1_webp, img_3_v3_heizkreis_1_webp]
            },
            jpg: {
                img_1: [img_1_v2_heizkreis_1_jpg, img_1_v3_heizkreis_1_jpg],
                img_2: [img_2_v2_heizkreis_1_jpg, img_2_v3_heizkreis_1_jpg],
                img_3: [img_3_v2_heizkreis_1_jpg, img_3_v3_heizkreis_1_jpg]
            }
        },
        'Heizkreis_2': {
            webp: {
                img_1: [img_1_v2_heizkreis_2_webp, img_1_v3_heizkreis_2_webp],
                img_2: [img_2_v2_heizkreis_2_webp, img_2_v3_heizkreis_2_webp],
                img_3: [img_3_v2_heizkreis_2_webp, img_3_v3_heizkreis_2_webp]
            },
            jpg: {
                img_1: [img_1_v2_heizkreis_2_jpg, img_1_v3_heizkreis_2_jpg],
                img_2: [img_2_v2_heizkreis_2_jpg, img_2_v3_heizkreis_2_jpg],
                img_3: [img_3_v2_heizkreis_2_jpg, img_3_v3_heizkreis_2_jpg]
            }
        },
        'Heizkreis_3': {
            webp: {
                img_1: [img_1_v2_heizkreis_3_webp, img_1_v3_heizkreis_3_webp],
                img_2: [img_2_v2_heizkreis_3_webp, img_2_v3_heizkreis_3_webp],
                img_3: [img_3_v2_heizkreis_3_webp, img_3_v3_heizkreis_3_webp]
            },
            jpg: {
                img_1: [img_1_v2_heizkreis_3_jpg, img_1_v3_heizkreis_3_jpg],
                img_2: [img_2_v2_heizkreis_3_jpg, img_2_v3_heizkreis_3_jpg],
                img_3: [img_3_v2_heizkreis_3_jpg, img_3_v3_heizkreis_3_jpg]
            }
        }
    },
    'Abwasser': {
        webp: {
            img_1: [img_1_v2_abwasser_webp, img_1_v3_abwasser_webp],
            img_2: [img_2_v2_abwasser_webp, img_2_v3_abwasser_webp],
        },
        jpg: {
            img_1: [img_1_v2_abwasser_jpg, img_1_v3_abwasser_jpg],
            img_2: [img_2_v2_abwasser_jpg, img_2_v3_abwasser_jpg],
        }
    },
}

export { images_Heimatring_12 } ;